const preventiveCheckUp = {
  mainBanner: {
    intro: 'Vorsorgecheck für Pensionskassen',
    title: 'Transparenz schaffen, Haftungsrisiko minimieren',
    subtitle:
      'Pensionskassen haben den Auftrag einer optimalen Vorsorge ihrer Kunden. Der Vorsorgecheck schafft Transparenz und minimiert das Haftungsrisiko für PKs, Stiftungen und den Stiftungsrat. Sorgen Sie für eine sichere Zukunft Ihrer Vorsorgenehmer.',
    buttonLabel: 'Termin vereinbaren',
  },
  allInfos: 'Alle Infos in 15min Videocall',
  theChallenges: {
    title: 'Die Herausforderungen der modernen Vorsorge',
    description:
      'Die Herausforderungen in der Vorsorge sind vielfältig und komplex. Pensionskassen und Stiftungen müssen ihre Kunden informieren und begleiten, um in unsicheren Zeiten bestmögliche Vorsorge zu gewährleisten.',
  },
  benefits: {
    title: 'Die Vorteile des Vorsorgecheck von gut geregelt',
    description:
      'Der Vorsorgecheck von gut geregelt bietet eine umfassende und transparente Lösung, um Vorsorgenehmer optimal auf die Herausforderungen vorzubereiten und das Haftungsrisiko zu minimeren.',
  },
  liabilityRisk: 'Haftungsrisiko des Stiftungsrats',
  liabilityRiskSubtitle:
    'Eine aktive Kommunikation und das Schaffen von Transparenz ist wichtig, um Schaden von den Vorsorgenehmern abzuwenden und das Haftungsrisiko für Pensionskassen und Stiftungsrat zu minimieren.',
  contactForm: {
    title: 'Erfahren Sie, wie der Vorsorgecheck Pensionskassen unterstützt',
    description:
      'Möchten Sie mehr über den Vorsorgecheck erfahren und wie er Ihnen helfen kann, Ihre Kunden optimal zu unterstützen?',
    cta: 'Jetzt unverbindlichen Termin zur Präsentation vereinbaren via info@gutgeregelt.ch oder Formular.',
  },
};

export default preventiveCheckUp;
