const pensionProvision = {
  title: 'Gut Geregelt: Helping you protect your loved ones and yourself ',
  alreadyHaveAcc: 'Already have an account?',
  signIn: 'Sign in',
  p1: 'The gut geregelt Team are here to support you with important topics everyone needs to address. Starting with three important topics for retirement/provision planning we will continually expand our offering to meet your needs ',
  p2: 'We accompany you, similar to a personal trainer, with important processes that you need to address so that you can avoid problems in difficult situations for bboth yourself and your loved ones. ',
  p3: 'Lets talk details. We recommend that everyone, at the latest by the age of 50, should have completed and finalised following legal documents: ',
  p3a: 'If you start now and take half an hour, we guarantee that you will be well organized.',
  p4: 'Based on your answers, there is a small risk that, like most of our customers, you will not be able to take the necessary steps to manage your life well on the first attempt.',
  p4a: 'Based on your answers, there is a risk that, like most of our customers, you will not manage to take the necessary steps to regulate your life well in the first attempt.',
  p5a: 'In order to support you here, we offer a reminder service.',
  p5b: 'For this you need to give us some data now so that we can remind you of the open steps.',
  p6a: 'However, we would like to offer you our reminder service as a support anyway.',
  p6b: 'Please provide us now with a communication channel through which we may remind you of the open steps.',

  confirmCheckbox:
    'By selecting this checkbox, I acknowledge and affirm that I am consenting to be registered on gut geregelt. ',
  processingAgreement: 'See our processing agreement.',

  c1: {
    title: 'Living Will',
    description:
      'allows you to determine how medical professionals should proceed in the event of an emergency.',
  },
  c2: {
    title: 'Power of Attorney',
    description:
      'avoids problems with the KESB and regulates guardianship for your children.',
  },
  c3: {
    title: 'Last Will',
    description: 'to leave your legacy the way you want it to be left.',
  },

  questions: [
    {
      question:
        'When you have the choice of doing something today or putting it off until tomorrow... What do you do?',
      options: [
        {
          label: 'I do it right away',
        },
        {
          label: "I don't know",
        },
        {
          label: 'I put it off until tomorrow',
        },
      ],
    },
    {
      question:
        'When someone tries to motivate you to do something you don`t like... What works best?',
      options: [
        {
          label: 'Incentives me',
        },
        {
          label: "I don't know",
        },
        {
          label: 'Makes me feel bad',
        },
      ],
    },
    {
      question: 'How do you usually tackle things ?',
      options: [
        {
          label: 'Eyes shut and go for it',
        },
        {
          label: "I don't know",
        },
        {
          label: 'Rather later',
        },
      ],
    },
    {
      question:
        'Tell us how often you want to be reminded when you temporarily pause the process:',
      options: [
        {
          label: 'Weekly',
        },
        {
          label: 'Monthly',
        },
        {
          label: 'Quarterly',
        },
      ],
    },
  ],

  thankyouCard: {
    title: 'Simple and straightforward pension provision',
    thankYou: ' Thank you for your interest in gut geregelt',
    gentlyReminder:
      ' We will gently remind you until you have everything well regulated.',
  },
};

export default pensionProvision;
