import axios from '#/utils/axios';

const requestProvider = {
  get: async <T>(url: string, params?: object) =>
    axios.get<T>(url, {
      ...params,
    }),
  post: async <T>(url: string, data: any) => axios.post<T>(url, data, {}),
  put: async <T>(url: string, data: any, headers?: any) =>
    axios.put<T>(url, data, {
      headers,
    }),
  patch: async <T>(url: string, data: any) => axios.patch<T>(url, data, {}),
  delete: async <T>(url: string) => axios.delete<T>(url, {}),
};

export default requestProvider;
