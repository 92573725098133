const provisionLanding = {
  mainBanner: {
    intro: 'Enfin bien prévoir:',
    title: 'Faites plaisir à vos proches, soyez bien organisé',
    subtitle:
      'En cas d`urgence, vos proches sont confrontés à de nombreuses émotions. Épargnez-leur encore plus de stress lié à des formalités non clarifiées.',
    buttonLabel: 'Prévoyez maintenant',
  },
  sale: '-43% / par personne',
  addMore: 'Ajouter {{membersNo}} de plus',
  youtubeTutorial: 'Pourquoi prévoir ?',
  aboutCards: {
    remainInControl: {
      title: 'Rester maître de la situation',
      description:
        'Si vous ne prenez pas position pour vous-même, d`autres le feront, mais pas nécessairement dans votre meilleur intérêt.',
    },
    protectYourFamily: {
      title: 'Protéger votre famille',
      description:
        'Agissez de manière proactive pour clarifier comment votre famille fera face si vous êtes incapable d`agir ou si vous n`êtes plus là.',
    },
    relieveRelatives: {
      title: 'Soulager les proches',
      description:
        'Pensez à ceux qui ont beaucoup à faire lorsque vous n`êtes plus en mesure de le faire. Assurez-vous de soulager leur charge.',
    },
  },
  video: {
    title: 'Pourquoi prévoir ?',
  },
  whyWithUs: {
    title: 'Pourquoi choisir notre service ?',
    cards: {
      card1: {
        title: 'Compréhensible',
        description:
          'Vous êtes-vous déjà retrouvé confronté à des choses que vous ne comprenez pas du tout ? Pas avec nous. Nous parlons votre langue.',
      },
      card2: {
        title: 'Rapidement fait',
        description:
          'Au lieu de répondre à des dizaines de questions dans un processus long et fastidieux, obtenez ce que vous voulez en moins de 10 minutes - garanti.',
      },
      card3: {
        title: 'Abordable',
        description:
          'Bien organisé vous permet de prendre des dispositions complètes et juridiquement conformes à un prix imbattable.',
      },
    },
  },
  testimonials: {
    testimonial1: {
      name: 'Elisabeth Müller',
      text: 'Mon mari et moi discutons du sujet depuis des années. Notre fille nous a fait découvrir bien organisé. Nous avons été surpris de voir à quel point nous avons pu accomplir rapidement ce que nous avions repoussé pendant des années.',
    },
    testimonial2: {
      name: 'Sascha Zürcher',
      text: "Mon père était un homme d'affaires à l'ancienne. Très occupé, peu de documentation. Lorsqu'il est décédé, ma sœur et moi avons dû créer difficilement de la clarté et de l'ordre. Bien que je ne sois pas encore âgé, je ne veux prendre aucun risque et éviter à ma famille un fardeau similaire. Bien organisé m'a permis de le faire depuis mon canapé.",
    },
    testimonial3: {
      name: 'Vreni Frey',
      text: "Il y a 2 ans, j'ai rédigé un testament avec mon avocat. Grâce à bien organisé, j'ai maintenant aussi une directive anticipée et un mandat de protection future. Le processus était clair et surtout le langage était compréhensible. Bien que j'espère ne jamais en avoir besoin, je suis contente de m'être enfin décidée à créer ces documents.",
    },
  },
  subBanner: {
    title: 'Prévoyance juridiquement conforme, simplifiée',
    subtitle:
      'Bien organisé offre, en cas de besoin, un accès à des conseils juridiques et vous aide à créer des documents juridiquement conformes, à les stocker en toute sécurité et à les partager en cas d`urgence avec ceux qui en ont besoin.',
    buttonLabel: 'Commencez maintenant',
  },
  pricing: {
    title: 'Le bon package pour vous',
    subtitle:
      'Nous avons quelque chose pour tout le monde. Choisissez maintenant le package qui correspond le mieux à vos besoins.',
    oneTime: 'une fois',
    purchaseButton: 'Prévoyez maintenant',
    manageButton: 'Gérer',
    starter: {
      title: 'Débutant',
      finfinderSale: 'Économisez 400 CHF avec FinFinder',
      features: [
        '2 sur 3 documents inclus',
        'Gestion numérique des documents',
        'Fonction de rappel',
      ],
    },
    standard: {
      title: 'Standard',
      features: [
        'Directive anticipée',
        'Mandat de protection future',
        'Testament',
        'Gestion numérique des documents',
        'Fonction de rappel',
      ],
    },
  },
  provisionBanner: {
    title:
      'Même si c`est difficile... Il est important d`y penser dès maintenant',
    subtitle:
      "Beaucoup d'entre nous ont tendance à repousser la prévoyance. Après tout, personne n'aime penser au pire scénario possible. Cependant, il est important de se rappeler que vous ne rendez service ni à vos proches ni à vous-même en le négligeant. Plus tôt vous commencez, mieux c`est. Nous vous aidons à trouver le bon plan de prévoyance pour vous.",
    buttonLabel: 'Prévoyez maintenant',
  },
  newsletter: {
    title: 'Soyez bien organisé',
    buttonLabel: 'Créez vos documents',
  },
};
export default provisionLanding;
