const popOverMenu = [
  {
    title: 'changeAccount',
  },
  {
    title: 'settings',
    link: '/dashboard/user/account',
  },
  {
    title: 'needHelp',
    link: '/dashboard/kontakt',
  },
  {
    title: 'logOut',
    link: '/auth/logout',
  },
];

export default popOverMenu;
