const provisionLanding = {
  mainBanner: {
    intro: 'Finally take good precautions:',
    title: 'Do your loved ones a favor, get gut geregelt',
    subtitle:
      'In an emergency, your family members are exposed to many emotions. Save them even more stress due to unregulated formalities.',
    buttonLabel: 'Get started',
  },
  sale: '-43% / per person',
  addMore: 'Add {{membersNo}} more',
  youtubeTutorial: 'Why make provisions?',
  aboutCards: {
    remainInControl: {
      title: 'Remain in control',
      description:
        "If you don't stand up for yourself, others will - not necessarily in your best interest.",
    },
    protectYourFamily: {
      title: 'Protect your family',
      description:
        'Be proactive in clarifying how your family will cope if you are incapacitated or no longer here.',
    },
    relieveRelatives: {
      title: 'Relieve your relatives',
      description:
        'Think of those who have to do a lot of things when you are no longer able to.',
    },
  },
  video: {
    title: 'Why make provisions?',
  },
  whyWithUs: {
    title: 'Why with us?',
    cards: {
      card1: {
        title: 'Understandable',
        description:
          'You are asked about things you do not understand at all?! Not with us. We speak your language.',
      },
      card2: {
        title: 'Done fast',
        description:
          'Instead of answering dozens of questions in a lengthy process, you get what you want within 10 minutes - security.',
      },
      card3: {
        title: 'Inexpensive',
        description:
          'gut geregelt allows you to make comprehensive and legally compliant provisions at an unbeatable price.',
      },
    },
  },
  testimonials: {
    testimonial1: {
      name: 'Elisabeth Müller',
      text: 'My husband and I have been discussing the topic for years. Our daughter brought gut geregelt to our attention, and we were amazed at how easy and fast we could accomplish what we had been putting off for years.',
    },
    testimonial2: {
      name: 'Sascha Zürcher',
      text: "My father was old school when it came to running his business—busy but poorly documented. When he passed away, my sister and I had to painstakingly bring clarity and order. Even though I'm not yet of old age, I don't want to take any risks and burden my family in a similar way. gut geregelt has allowed me to do just that. And all from the comfort of my couch.",
    },
    testimonial3: {
      name: 'Vreni Frey',
      text: "Two years ago, I created a will with my lawyer. Thanks to gut geregelt, I now also have an advance healthcare directive and a power of attorney. The process was straightforward, and the language was clearly understandable. Although I hope I never need them, I'm glad I mustered the courage to create these documents.",
    },
  },
  subBanner: {
    title: 'Legally compliant provisions, with ease',
    subtitle:
      'gut geregelt supports you with legal advice and helps to create legally compliant documents, keep them safe and share them with those who need them in an emergency.',
    buttonLabel: 'Get started now',
  },
  pricing: {
    title: 'The right package for you',
    subtitle:
      'We offer something for everyone. Subscribe now to the package that best meets your needs.',
    oneTime: 'one time',
    purchaseButton: 'Get started',
    manageButton: 'Manage',
    starter: {
      title: 'Starter',
      features: [
        '2 of 3 documents included',
        'Digital document management',
        'Reminder',
      ],
      finfinderSale: 'Save 200 CHF with FinFinder',
    },

    standard: {
      title: 'Standard',
      finfinderSale: 'Save 400 CHF with FinFinder',
      features: [
        'Living will',
        'Power of attorney',
        'Last will',
        'Digital document management',
        'Reminder',
      ],
    },
  },
  provisionBanner: {
    title: "Even if its hard ... It's important to think about it now",
    subtitle:
      "Many of us tend to procrastinate when it comes to making pension provision. After all, who enjoys contemplating the worst possible outcome? However, it's important to remember that by neglecting, you're not doing your loved ones nor yourself a favor. The sooner you start, the better. We'll help you find the right pension plan for you.",
    buttonLabel: 'Take provision now',
  },
  newsletter: {
    title: 'Get gut geregelt',
    buttonLabel: 'Create your documents',
  },
};
export default provisionLanding;
