import {
  Paper,
  Typography,
  Box,
  Stack,
  Avatar,
  Divider,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/hooks/useLocales';
import useAccountSwitch from '#/hooks/useAccountSwitch';
import { useActivateHostAccount, useListMyConnections } from '#/api/successor';
import AccountRow from '#/components/shared/ui/header/account-switch/AccountRow';
import useAuth from '#/hooks/useAuth';
import ActivatePrimary from '#/components/shared/ui/header/account-switch/ActivatePrimary';
import useLocalStorage from '#/hooks/useLocalStorage';

type SwitchAccountsProps = {
  open: boolean;
  closeModal: () => void;
};

export default function SwitchPopover({
  open,
  closeModal,
}: SwitchAccountsProps) {
  const [, , removeGuestOn] = useLocalStorage('guest_on', null);

  const { translate } = useLocales();
  const theme = useTheme();
  const { user, refetch, isInitialized } = useAuth();
  const { data: connectionsList } = useListMyConnections(
    isInitialized && !!user?.id
  );

  const { mutateAsync: activateHostAccount, isLoading: isActivating } =
    useActivateHostAccount();
  const { currentAccount } = useAccountSwitch();
  const navigate = useNavigate();

  const onHostActivate = async () => {
    try {
      await activateHostAccount({});
      removeGuestOn();
      localStorage.removeItem('ownerID');
      closeModal();
      await refetch();
      navigate('/dashboard/home');
    } catch (error) {
      console.error('Error switching account: ', error);
    }
  };

  const allAccounts = () => {
    if (connectionsList) {
      if (user?.primary_account) {
        return [...connectionsList, user];
      }
      return connectionsList;
    }
    return [];
  };

  return (
    <DialogAnimate open={open} onClose={closeModal} maxWidth="xs">
      <Paper>
        <Typography
          variant="subtitle1"
          sx={{
            paddingX: 2.5,
            paddingY: 1.5,
          }}
        >
          {String(translate('global.settings.changeAccountContent.title'))}
        </Typography>
        <Box
          sx={{
            backgroundColor: theme.palette.action.selected,
            paddingX: 2.5,
            paddingY: 1.5,
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              src={currentAccount?.profile_picture}
              sx={{
                width: 88,
                height: 88,
                borderWidth: 2,
                borderColor: theme.palette.primary.main,
              }}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width={1}
              spacing={2}
            >
              <Stack direction="column">
                <Typography variant="subtitle1" sx={{ lineHeight: 1 }} noWrap>
                  {currentAccount?.first_name} {currentAccount?.last_name}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body2" noWrap color="gray">
                    {String(translate('global.account'))}:
                  </Typography>
                  <Typography variant="body2">
                    {currentAccount?.id === user?.id
                      ? String(translate('global.main'))
                      : String(translate('global.guest'))}
                  </Typography>
                </Stack>
              </Stack>
              <Button
                variant="contained"
                disabled
                color="secondary"
                size="small"
                fullWidth
              >
                <Typography variant="body2">
                  {String(translate('global.current'))}
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
        {allAccounts()
          ?.filter((connection) => connection?.id !== currentAccount?.id)
          ?.map((connection) => (
            <AccountRow
              key={connection?.id}
              id={connection?.id}
              first_name={connection?.first_name}
              last_name={connection?.last_name}
              profile_picture={connection?.profile_picture}
              isMain={connection?.id === user?.id}
              closeModal={closeModal}
            />
          ))}
        {!user?.primary_account && (
          <ActivatePrimary
            isLoading={isActivating}
            onAccActivate={onHostActivate}
          />
        )}
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingX: 2.5,
            paddingY: 1.5,
          }}
        >
          <Button
            variant="outlined"
            color="error"
            onClick={closeModal}
            size="small"
          >
            <Typography color="error" variant="body2">
              {String(translate('global.cancel'))}
            </Typography>
          </Button>
        </Stack>
      </Paper>
    </DialogAnimate>
  );
}
