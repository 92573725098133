const outputDoc = {
  title: 'Advance Directive',
  from: 'From',
  born: 'Born on ',
  civilStatus: {
    single: 'Single',
    married: 'Married',
    widowed: 'Widowed',
    divorced: 'Divorced',
    registered_partnership: 'Registered partnership',
  },
  primary: 'Primary Person',
  deputy: 'Alternate Representative',
  civil_status: 'Marital Status: {{civil_status}}',
  address: 'Address: {{address}}',
  declare: 'I, {{first_name}} {{last_name}}, declare the following:  ',
  paragraph1:
    'In the event that I become incapacitated and am no longer able to form or communicate my will permanently or temporarily, I appoint the following person(s) to act as my advance care and financial representative, representing me in all legal matters:',
  firstParagraph: {
    sameTrustees: 'Personal and financial care, same person as well as',
    differentTrustees: 'Personal and financial care, different person',
  },
  assetCare: 'Financial Care',
  personCare: 'Personal Care',
  mainCharacter: 'Primary Person',
  alternate: 'Alternate Representative',
  compensationDeclare: 'Receives compensation as per the definition below',
  nonCompensationDeclare: 'Does not receive compensation',
  paragraph2: 'I appoint as the alternate representative',
  paragraph3:
    'In the event that I become incapacitated and am no longer able to form or communicate my will permanently or temporarily, I appoint the following alternate person(s) to act as my advance care and financial representative, representing me in all legal matters:',
  personCareDeclare1:
    'The appointed person determines the measures to be taken regarding my care and medical treatment and ensures an organized daily life and appropriate participation in social activities.',
  personCareDeclare2:
    'If I create a separate living will regarding my medical treatment, it takes precedence over the advance directive. The appointed person shall ensure that my living will is followed.',
  assetCare1:
    'The appointed person manages my income and assets, makes decisions about them, and takes all related measures. They provide the necessary instructions and requests to third parties, banks, and authorities for the protection of my interests. The appointed person ensures the payment of my bills via mail or bank and receives payments on my behalf. They are authorized to receive and open all mail addressed to me.',
  assetCare2:
    'The management of bank balances and securities shall follow existing agreements and practices. Asset management explicitly does not fall under the provisions of the Regulation on Asset Management in the context of guardianship or trusteeship.',
  assetCare3:
    'The appointed person is not allowed to make donations from my assets and may not dispose of assets without compensation, except for occasional gifts or obligations of moral duty.',
  repLegal: 'Legal Representation',
  repLegal1:
    'The appointed person is authorized to carry out all necessary legal and, if necessary, procedural actions within the framework of this advance directive, including concluding or terminating the necessary contracts, submitting tax returns, and representing me before authorities and courts or third parties. The appointed person is responsible for all administrative matters.',
  repLegal2:
    'The appointed person is authorized to sell or encumber my real estate, to establish, modify, and delete annotations, reservations, and servitudes registered in my favor or at my expense in the land register, to have such transactions notarized, and to initiate corresponding entries in the land register.',
  custodyOrder: 'Custody Order',
  childrenTrustee:
    'If my children are not yet of legal age, I wish for the following person to be appointed as their guardian and for the children to be placed in their care:',
  childrenTrusteeRelationship: 'Relationship to me: {{relation}}',
  relation: {
    husband: 'Husband',
    wife: 'Wife',
    partner: 'Partner',
    friend: 'Friend',
    brother: 'Brother',
    sister: 'Sister',
    brother_in_law: 'Brother-in-law',
  },
  alternateChildrenTrustee: 'I appoint as the alternate representative',
  childrenTrusteeDeclare:
    'I am aware that this custody order is not legally part of the advance directive, that it is a wish, and that the child protection authority is not bound by it. The relevant authority can only consider the wish if it is in the best interest of the child. There is therefore no legal right for the child protection authority to appoint the aforementioned person(s) as the guardian.',
  releaseFromConfidentiality: 'Release from Confidentiality',
  releaseFromConfidentialityDeclare:
    'I release all persons and authorities bound by professional confidentiality, including banks, doctors, and officials, from professional and official secrecy, in particular towards the appointed person.',
  involvementOfAidsAndAssistants: 'Involvement of Aids and Assistants',
  involvementOfAidsAndAssistantsDeclare:
    'To fulfill the mandate, the appointed person is authorized to purchase aids at my expense and engage aids and substitutes (such as household help, nursing services, tax consultants, lawyers, asset managers, etc.) and to conclude, modify, or terminate contracts with them.',
  expensesAndCompensation: 'Expenses and Compensation',
  expenses: 'Expenses',
  expensesDeclare:
    'The necessary and proven expenses are to be reimbursed to the person appointed for personal or financial care.',
  indemnities: 'Compensations',
  indemnitiesPositive:
    'The person appointed for personal or financial care is entitled to reasonable compensation for their services, which is determined according to the usual rates for compensation of legal representatives at the adult protection authority.',
  indemnitiesNegative:
    "The person(s) appointed for the care of children, persons, or assets, and who, according to the creator's document, is to receive compensation, shall receive compensation based on the usual rates of the adult protection authority for legal representatives.",
  storageRegistration: 'Storage and Registration of the Advance Directive',
  storage: 'Storage',
  storageRegistrationDeclare:
    'I am aware that advance directives can be deposited for safekeeping with the child and adult protection authority responsible for the place of residence. It is my free decision whether I avail myself of this option.',
  storageRegistrationDeclare2:
    'The person entrusted with the mandate is instructed and authorized to contact the adult protection authority at my place of residence with this advance directive if necessary, so that the authority can issue the document required for my representation in accordance with Article 363 of the Swiss Civil Code (ZGB).',
  registration: 'Registration',
  registrationDeclare:
    'I am aware of the possibility of informing the responsible civil registry office about the creation and location of this advance directive for registration in the central database according to Article 361(3) ZGB. It is my free decision whether I avail myself of this option.',
  applicableLaw: 'Applicable Law and Jurisdiction',
  applicableLawDeclare:
    'This advance directive is subject to Swiss law, irrespective of my nationality or place of residence, excluding conflict-of-law norms (IPRG). Jurisdiction is:',
  revocation: 'Revocation',
  revocationDeclare: 'I hereby revoke all advance directives of earlier dates.',
  abilityToAct: 'Capacity to Act',
  abilityToActDeclare:
    'I make this statement after careful consideration and in full responsibility for myself, aware that concerning medical measures, my doctors, caregivers, or authorized persons are bound by the decision of the appointed person. I have discussed this directive with the individuals mentioned at the beginning of the first page, who can confirm that, at that time, according to their perception, I was capable of judgment, and the content reflects my will.',
};

export default outputDoc;
