const assetsRegister = {
  mainTitle: 'Übersicht über Ihren Nachlass',
  mainDescription:
    'Erstellen Sie für sich und Ihre Angehörigen eine zentrale Übersicht über die wichtigsten finanziellen und nicht-finanziellen Vermögenswerte und die Institutionen, bei denen Sie sie halten (z. B. Banken oder Versicherungen).',

  exportAssets: 'Vermögenswerte exportieren',
  exportFinancialInstitutions: 'Finanzinstitute exportieren',
  exportAllData: 'Alle Daten exportieren',

  tabs: {
    assets: 'Vermögenswerte',
    financialInstitutions: 'Finanzinstitute',
  },

  deleteAsset: {
    title:
      'Sie sind dabei, {{asset}} aus der Liste zu entfernen. Sind Sie sicher?',
    description: 'Bitte bestätigen Sie die Löschung.',
    onConfirm: 'Löschen',
    onClose: 'Abbrechen',
  },

  deleteFinancialInstitution: {
    title:
      'Sie sind dabei, {{institution}} aus der Liste zu entfernen. Sind Sie sicher?',
    description: 'Bitte bestätigen Sie die Löschung.',
    onConfirm: 'Löschen',
    onClose: 'Abbrechen',
  },

  assetsTable: {
    tableHeads: {
      description: 'Beschreibung',
      storageLocation: 'Lagerort',
      designatedBeneficiary: 'Begünstigter',
    },
    addAsset: 'Vermögenswert hinzufügen',
  },

  institutionType: {
    secondary: 'Nicht-Hausbank',
    primary: 'Hausbank',
  },

  financialInstitutionsTable: {
    tableHeads: {
      institution: 'Institution',
      type: 'Typ',
    },
    addFinancialInstitution: 'Finanzinstitut hinzufügen',
  },
};

export default assetsRegister;
