const metadata = {
  main: {
    title:
      'Good provision: Living will, power of attorney & last will in 20 minutes',
    description:
      'We help people to a well-regulated life by radically simplifying the essential things in provision.',
    keywords: {
      livingWill: 'Living will',
      powerOfAttorney: 'Power of attorney',
      lastWill: 'Last will',
      personalPreferences: 'Personal provision',
      retirementPlanning: 'Pension planning',
      retirementProvision: 'Retirement provision',
    },
  },
  living_will: {
    title: 'Create a living will online and legally secure in 5 minutes',
    description:
      'Create a living will and spare relatives additional stress in extreme cases.',
    keywords: {
      livingWill: 'Living will',
      printLivingWill: 'Print living will',
      directive: 'Living will',
      printDirective: 'Living wills to print',
    },
  },
  power_of_attorney: {
    title: 'Create a power of attorney online and legally secure in 5 minutes',
    description:
      'With the power of attorney, you proactively clarify who represents you in case of incapacity and stands up for you.',
    keywords: {
      powerOfAttorney: 'Power of attorney',
      powerOfAttorneyTemplate: 'Power of attorney template',
      powerOfAttorneyTemplate2: 'Template power of attorney',
      powerOfAttorneyTemplate3: 'Template for power of attorney',
      powerOfAttorney2: 'Power of attorney',
    },
  },
  last_will: {
    title: 'Create a last will online and legally secure in 10 minutes',
    description:
      'Specify in your last will what happens to your estate and who will inherit what. Now simply provide.',
    keywords: {
      lastWill: 'Last will',
      share: 'Compulsory portion of inheritance',
      share2: 'Inheritance compulsory portion',
      estate: 'Estate',
      succession: 'Legal succession',
    },
  },
  partner_with_us: {
    title: 'Expand your pension offering with gut geregelt',
    description:
      'Use the white label solution from well regulated to make comprehensive provision accessible to your customers. Also integrates into your consulting process.',
    keywords: {
      pensionFoundation: 'Provision foundation',
      solution: 'Provision solution',
      trustees: 'Board of trustees',
      advice: 'Provision advice',
      advisor: 'Provision advisor',
    },
  },
  digital_footprint: {
    title: 'Regulate digital estate',
    description:
      'Use the whitelabel solution from gut geregelt to make comprehensive pension provision available to your customers. It can also be integrated into your consulting process.',
  },
  register_assets: {
    title: 'Maintain asset and property register',
    description:
      'Document your physical and financial assets and save your relatives dozens of hours of cleaning up.',
  },
  grosseItern: {
    title: 'Grosseltern Magazine Special Offer',
  },
};
export default metadata;
