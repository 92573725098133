import { ReactNode, useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
// import typography from './typography';
// import palette from './palette';
// import breakpoints from './breakpoints';
import useSettings from '../lib/hooks/useSettings';
// import shadows, { customShadows } from './shadows';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';
import { generatePartnerTheme } from './theme-tenancy';
import useAuth from '#/hooks/useAuth';
import {
  useGetPartnerSettings,
  useGetPublicPartnerSettings,
} from '#/api/partnerQueries';
import defaultPalette from './palette';

type Props = {
  children: ReactNode;
  subdomain?: string | null;
};

export default function ThemeProvider({ children, subdomain }: Props) {
  const { themeMode } = useSettings();
  const { user } = useAuth();

  const isAdminLoggedIn =
    user?.partner?.role === 'admin' || user?.partner?.role === 'owner';

  const { data: adminTheme } = useGetPartnerSettings(isAdminLoggedIn);
  const { data: partnerTheme } = useGetPublicPartnerSettings(
    subdomain,
    !!subdomain && !subdomain.includes('localhost')
  );

  const isLight = themeMode === 'light';

  const defaultPrimaryColor = defaultPalette.light.primary.main;
  const defaultSecondaryColor = defaultPalette.light.secondary.main;

  const primaryColor =
    adminTheme?.primary_color ||
    partnerTheme?.primary_color ||
    defaultPrimaryColor;

  const secondaryColor =
    adminTheme?.secondary_color ||
    partnerTheme?.secondary_color ||
    defaultSecondaryColor;

  // @ts-expect-error
  const themeOptions: ThemeOptions = useMemo(
    () =>
      generatePartnerTheme(
        subdomain || '',
        isLight,
        primaryColor,
        secondaryColor
      ),
    [isLight, subdomain, adminTheme, partnerTheme]
  );

  const theme = createTheme(themeOptions);

  // Pass partner settings to the overrides
  theme.components = componentsOverride(theme, adminTheme || partnerTheme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
