import { Outlet } from 'react-router-dom';
import Auth from './index';

export default function AuthLayout() {
  return (
    <Auth>
      <Outlet />
    </Auth>
  );
}
