import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
  description?: string;
  keywords?: string;
}
const Page = forwardRef<HTMLDivElement, Props>(
  (
    { children, title = '', meta, keywords = '', description = '', ...other },
    ref
  ) => (
    <>
      <Helmet prioritizeSeoTags>
        <title>{`${title} | gut geregelt`}</title>
        <meta name="description" content={`${description}`} />
        <meta property="og:title" content={`${title} | gut geregelt`} />
        <meta name="keywords" content={`${keywords}`} />
        <meta property="og:description" content={`${description}`} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="de_CH" />
        <meta property="og:site_name" content="Werden Sie gut geregelt" />
        <meta
          property="og:image"
          content="https://storage.gutgeregelt.ch/img/feature-img-gg.png"
        />
        <meta property="og:url" content="https://gutgeregelt.ch/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@gutgeregelt" />
        <meta name="twitter:description" content={`${description}`} />
        <meta
          name="twitter:title"
          content={`${title} | Werden Sie gut geregelt`}
        />
        <meta
          name="twitter:image"
          content="https://storage.gutgeregelt.ch/img/feature-img-gg.png"
        />
      </Helmet>

      <Box ref={ref} {...other} className="myBox">
        {children}
      </Box>
    </>
  )
);

export default Page;
