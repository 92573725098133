const privacyPolicy = {
  title: 'Privacy Policy',
  childrenUnderAge: {
    title: 'Children under 18',
    content: {
      p1: 'Our Website is not intended for children under 18 years of age. No one under age 18 may provide any information on or through the Website. We do not knowingly collect personal information from children under 18. If you are under 18, do not use or prov}ide any information on this Website or on or through any of its features. If we learn we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 18, please contact us at support@gutgeregelt.ch.',
    },
  },
  informationWeCollect: {
    title: 'Information we collect and how we collect it',
    content: {
      p1: 'We collect several types of information from and about users of our Website, including information:',
      p2: '• By which you may be personally identified, such as your name or email address or any other identifier by which you may be contacted online or offline ("personal data");',
      p3: '• That is about you but individually does not identify you; and/or',
      p4: '• About your internet connection, the equipment you use to access our Website, and details of your usage.',
      p5: 'We collect this information:',
      p6: '• Directly from you when you provide it to us.',
      p7: '• Automatically as you navigate through the Website. Information automatically collected may include usage details, IP addresses, and information collected through cookies.',
      p8: '• From third parties, for example, from our business partners.',
    },
  },
  informationYouProvide: {
    title: 'Information you provide to use',
    content: {
      p1: 'The information we collect on or through our Website may include:',
      p2: '• Information that you fill out by filling in forms on our Website. This includes information provided at the time of registering to use our Website, subscribing to our service, posting material, or requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us and when you report a problem with our Website.',
      p3: '• Records and copies of your correspondence (including email addresses), if you contact us.',
      p4: '• Your responses to surveys that we might ask you to complete for research purposes.',
      p5: '• Your search queries on the Website.',
      p6: 'You may also provide information to be published or displayed (hereinafter, "posted") on public areas of the Website or transmitted to other users of the Website or third parties (collectively, "User Contributions"). Your User Contributions are posted on and transmitted to others at your own risk. Although we may limit access to certain pages, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.',
    },
  },
  informationWeCollectThroughAutomaticData: {
    title:
      'Information we collect through automatic data collection technologies',
    content: {
      p1: 'As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:',
      p2: '• Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.',
      p3: '• Information about your computer and internet connection, including your IP address, operating system, and browser type',
      p4: 'The information we automatically collect may include personal data. We collect it to help us improve our Website and to deliver a better and more personalized service, including by enabling us to:',
      p5: '• Estimate our audience size and usage patterns.',
      p6: '• Store information about your preferences so we can customize our Website according to your individual interests.',
      p7: '• Speed up your searches.',
      p8: '• Recognize you when you return to our Website.',
      p9: 'The technologies we use for this automatic data collection may include:',
      p10: '• Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.',
      p11: '• Flash Cookies. Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies.',
    },
  },
  informationVoluntarily: {
    title: 'Information voluntarily provided for the use of services',
    content: {
      p1: "We collect information that you voluntarily provide to us in order to fulfill a request, such as when you sign up to use our services and website, request support from our support team, write to us via email, fill out a contact form, wish to use our services, or communicate with us in any other way. This information includes your first and last name, your company's name, physical address, email address, and certain types of business information of your company, such as customer payment information, revenue, website analytics information, customer churn, customer lifetime value, and other metrics and data, as well as any other information you provide to us regarding your company",
      p2: "Especially after you sign up to use our services as a startup, if you wish to display data from your accounts with third-party tools and applications such as Stripe, Google Analytics, ChartMogul, Baremetrics, and ProfitWell on your startup's information page for potential buyers by logging into your account on such third-party tools and applications on the authorization page or providing us with your API keys or view IDs on such third-party tools and applications, you authorize us and grant us read access to specific information in your accounts with such third parties. Below are the pieces of information we may collect with your authorization from such third parties:",
      p3: '• Stripe – Read only access of customer revenue data for the past 12 months',
      p4: '• Google Analytics – Read only access of website traffic data for the past 12 months',
    },
  },
  useOfCookies: {
    title: 'Use of cookies by third parties',
    content: {
      p1: 'Some content or applications on the website are provided by third parties, such as content providers and application providers like Google Analytics and Hubspot, as mentioned above. These third parties use cookies to collect information about you when you use our website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services.',
    },
  },
  howWeUseYourInformation: {
    title: 'How we use your information',
    content: {
      p1: 'We use information that we collect about you or that you provide to us, including any personal information:',
      p2: '• To present our Website and its contents to you.',
      p3: '• To facilitate the listing of your buyer, seller, or consultant profile on the website.',
      p4: '• To provide you with information, products, or services that you request from us, including but not limited to (for startup users) displaying authorized business information from startups to specific potential buyers.',
      p5: '• To fulfill any other purpose for which you provide it.',
      p6: '• To provide you with notices about your account, including expiration and renewal notices.',
      p7: '• To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.',
      p8: '• To notify you about changes to our Website or any products or services we offer or provide though it.',
      p9: '• To allow you to participate in interactive features on our Website.',
      p10: '• In any other way we may describe when you provide the information.',
      p11: '• For any other purpose with your consent.',
      p12: 'With your consent, we will use your information to inform you about our own and third-party goods and services that may be of interest to you. If you do not want us to use your information in this way, please adjust your user settings in your account profile. For more information, see "Your information rights and controls" below.',
    },
  },
  saleOfPersonalInformation: {
    title: 'Sale of personal information',
    content: {
      p1: 'Please note that we do not sell your personal information to third parties.',
    },
  },
  disclosureOfYourInformation: {
    title: 'Disclosure of your information',
    content: {
      p1: 'We may disclose aggregated information about our users and information that does not identify any individual or otherwise falls outside the definition of "Personal Information," "Personal Data," or any other similar definition under a regulation, without limitation.',
      p2: 'We may disclose personal information that we collect or you provide as described in this privacy policy:',
      p3: '• To our subsidiaries and affiliates to enable us to provide you with our services',
      p4: '• To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.',
      p5: "• To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of the company's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal data held by the company about users of our website is among the assets transferred.",
      p6: '• To fulfill the purpose for which you provide it.',
      p7: '• For any other purpose disclosed by us when you provide the information.',
      p8: '• With your consent.',
      p9: 'We may also disclose your personal information:',
      p10: '• To comply with any court order, law, or legal process, including responding to any government or regulatory request.',
      p11: '• To enforce or apply our terms of use and other agreements, including for billing and collection purposes.',
      p12: '• If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the company, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.',
    },
  },
  informationRights: {
    title: 'Your information rights and controls',
    content: {
      p1: 'The General Data Protection Regulation ("GDPR") grants individuals rights over their personal information. While the following rights are specific to members of the European Economic Area (EEA), we aim to provide all our users with the same rights as GDPR members of the EEA, whenever possible.',
      p2: 'Please familiarize yourself with your rights and their descriptions in the table below.',
      p3: 'It is your right :',
      p4: 'Access: Be informed about the personal data we process about you and request access to it.',
      p5: 'Rectification: Request that we update or correct inaccurate or incomplete data about you.',
      p6: 'Erasure: Request that we delete your data.',
      p7: 'Restriction of processing: Ask us to restrict our processing of your personal data.',
      p8: 'Data portability: Request that we provide you or a third party with a copy of your personal data in a structured, commonly used, machine-readable format.',
      p9: 'Object: Object to our processing of your personal data.',
      p10: 'Object to Marketing: Object to the processing of your personal data for marketing purposes.',
      p11: 'Not be subject to automated decision-making: Not be subject to a decision based solely on automated decision-making (decisions made without human involvement), including profiling, if the decision would have a legal effect on you or a similarly significant effect.',
    },
  },
  exerciseRights: {
    title: 'How to exercise your rights with us',
    content: {
      p1: 'Access: To make a verifiable request for information about the personal data we have collected about you, please send an email to support@gutgeregelt.ch. Please note that almost all, if not all, of the information we collect from you is included in your account profile on the platform.',
      p2: 'Rectification: You can edit your personal information in your account profile on our platform.',
      p3: 'Deletion: You can exercise this right by sending an email to support@gutgeregelt.ch. Please note that we will delete your personal data:',
      p4: '• We may retain your personal data as long as necessary for our legitimate business interests, such as preventing money laundering, detecting and preventing fraud, and enhancing security. For example, if we suspend a gut geregelt account for fraud or security reasons, we may retain information from that gut geregelt account to prevent that person from opening a new gut geregelt account in the future.',
      p5: '• We may retain and use your personal data to the extent necessary to fulfill our legal obligations. gut geregelt may retain your information, for example, for tax, legal reporting, and auditing obligations, or as a record of a dispute with you.',
      p6: '• Some copies of your information (e.g., emails) may remain in our database but will be separated from personal identifiers and kept only for record-keeping purposes.',
      p7: 'Restriction: You can exercise this right by sending an email to support@gutgeregelt.ch or by discontinuing the use of the platform.',
      p8: 'Objection: You can exercise this right by sending an email to support@gutgeregelt.ch.',
      p9: 'Data Portability: You can exercise this right by sending an email to support@gutgeregelt.ch.',
      p10: 'Automated Decision-Making: You can exercise this right by sending an email to support@gutgeregelt.ch. Please note, however, that we do not conduct automated decision-making. All decisions regarding your profile are made internally by our support personnel.',
      p11: 'You also have the right to contact your local data protection authority with any questions or concerns.',
    },
  },
  dataStorage: {
    title: 'Data storage and deletion',
    content: {
      p1: 'In accordance with the GDPR and other relevant regulations, we only store your personal data for as long as necessary to provide the services available on the gut geregelt platform and for our legitimate and essential business purposes.',
    },
  },
  deletion: {
    title: 'Deletion',
    content: {
      p1: 'When you close your account or request us to close your account, we will delete, de-identify, or anonymize your personal data so that it no longer identifies you, unless we are required to retain something or we still need to use it for a legally justified reason.',
      p2: 'Here are some examples of situations where we are legally entitled or obligated to retain some of your personal data:',
      p3: '• If there is an unresolved issue related to your account, such as an outstanding credit or an unresolved claim or dispute.',
      p4: '• For our legal, tax, auditing, and accounting obligations.',
      p5: '• For our legitimate business interests, such as fraud prevention or maintaining security. ',
      p6: 'For more information, please see the section on Deletion above.',
      p7: 'In some cases, we may de-identify or anonymize your personal data to prevent fraud or to determine if you are a former user when you register for the gut geregelt platform again to prevent fraud.',
      p8: 'We have implemented measures designed to protect your personal data from accidental loss and from unauthorized access, use, alteration, and disclosure.',
      p9: 'The security of your information also depends on you. If we have provided you (or if you have chosen) a password to access certain parts of our website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. Please be cautious when sharing information in public areas of the website, such as forums. The information you share in public areas can be viewed by any user of the website.',
      p10: 'Unfortunately, the transmission of information over the internet is not completely secure. Although we do our best to protect your personal data, we cannot guarantee the security of your personal data transmitted to our website. Any transmission of personal data is at your own risk. We are not responsible for circumvention of privacy settings or security measures contained on the website.',
    },
  },
  changes: {
    title: 'Changes to our privacy policy',
    content: {
      p1: "It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to the way we treat our users' personal data, we will notify you by notice on the homepage of the website. The date of the last revision of the privacy policy is indicated at the top of the page. You are responsible for ensuring that we have a current, active, and deliverable email address for you and for regularly visiting our website and this privacy policy to check for any changes.",
    },
  },
  contantInformation: {
    title: 'Contact information',
    content: {
      p1: 'If you have questions or comments about this privacy policy and our privacy practices, please contact us and our data protection officer at support@gutgeregelt.ch.',
      p2: 'To register a complaint or concern, please send an email to support@gutgeregelt.ch',
    },
  },
};

export default privacyPolicy;
