import {
  contractParties,
  estateQuestions,
  marriageQuestions,
} from './questions';
import outputDoc from './outputDoc';

const marriageContract = {
  mainHeaders: {
    maximiseBenefits: 'Maximieren Sie die Vorteile für Ihren Ehepartner',
    effectiveMarriageContract: 'Ihr wirksamer Ehevertrag',
  },

  stepperInfo: [
    {
      label: 'Ehepartner',
      title: 'Vertragsparteien',
    },
    {
      label: 'Ehe',
      title: 'Ehe',
    },
    {
      label: 'Vermögen',
      title: 'Vermögen',
    },
    {
      label: 'Ihr Vertrag',
      title: 'Hinterlegen Sie Ihren Ehevertrag',
    },
  ],

  propertyTypes: {
    apartment: 'Wohnung',
    house: 'Haus',
    chalet: 'Chalet',
    rustico: 'Rustico',
    building_plot: 'Baugrundstück',
    multi_family_house: 'Mehrfamilienhaus',
    commercial_building: 'Gewerbegebäude',
    residential_building: 'Wohngebäude',
    commercial_property: 'Gewerbeimmobilie',
  },

  stepOne: {
    questions: contractParties,
  },
  stepTwo: {
    questions: marriageQuestions,
    confirmAssetDelete: {
      title: 'Sind Sie sicher, dass Sie dieses Asset löschen möchten?',
      description: 'Sie können es später jederzeit wieder hinzufügen.',
      cancel: 'Abbrechen',
      delete: 'Löschen',
    },
  },
  stepThree: {
    questions: estateQuestions,
    tooltips: {
      id2: 'Wenn Sie "Ja" wählen, enthält der Vertrag einen Absatz, der bestätigt, dass der gesamte Rest Ihres Vermögens gemeinsam erwirtschaftet wurde, die sogenannte Errungenschaft. Das bedeutet, dass der überlebende Partner automatisch Eigentümer von 50% des Vermögens ist. Dies bietet viele Vorteile für Ihren Ehepartner.',
      id4: 'Diese Bedingung muss für unser Formular erfüllt sein. Sollten Sie andere Wünsche haben kontaktieren Sie bitte einen Anwalt. Wir helfen Ihnen gerne, einen passenden Anwalt zu finden.',
      id6: 'Hierbei ist der grössere Wert zum Todeszeitpunkt zu berücksichtigen',
      id7:
        'Falls Sie eine dritte Person zur Durchsetzung Ihres Nachlasses ermächtigen wollen fügen Sie sie hier hinzu. \n' +
        'Falls sie keine dritte Person hinzufügen ist automatisch der überlebende Ehepartner ermächtigt.\n' +
        'Bitte beachten Sie, dass eine dritte Person grundsätzlich das Recht hat die Aufgabe abzulehnen.\n' +
        'Falls die Person die Aufgabe annimmt ist Sie zu einer Aufwandsentschädigung berechtigt. Es gelten dabei die gesetzlichen Bestimmungen zum Todeszeitpunkt.',
    },
  },
  stepFour: {
    document: outputDoc,
    introParagraph: 'Gut gemacht!',
    nextStepsTitle: 'Die nächsten Schritte:',
    nextStep1:
      'Drucken Sie den Ehevertrag aus und lesen Sie ihn sorgfältig durch',
    nextStep2:
      'Kontaktieren Sie einen Notar, um Ihren Vertrag notariell beglaubigen zu lassen (klicken Sie hier, um einen zu finden)',
    nextStep3: 'Laden Sie unten den notariell beglaubigten Ehevertrag hoch',
  },
};

export default marriageContract;
