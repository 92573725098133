const validations = {
  user: {
    first_name: 'Le prénom est requis',
    last_name: 'Le nom de famille est requis',
    email: "L'email est requis",
    invalidEmail: "L'email est invalide",
    civil_status: "L'état civil est requis",
    gender: 'Veuillez sélectionner votre sexe',
    date_of_birth: {
      required: 'La date de naissance est requise',
      max: 'Le représentant doit avoir 18 ans ou plus',
    },
    mobile_phone: {
      required: 'Le numéro de téléphone portable est requis',
      min: 'Le numéro de téléphone portable est trop court',
      max: 'Le numéro de téléphone portable est trop long',
      matches: 'Le numéro de téléphone portable est invalide',
    },
    minPassword: 'Le mot de passe doit comporter au moins 6 caractères',
    password: 'Le mot de passe est requis',
    confirmPassword: 'La confirmation du mot de passe est requise',
    passwordMatch: 'Les mots de passe doivent correspondre',
  },
  adress: {
    street: 'La rue est requise',
    house_number: 'Le numéro de maison est requis',
    zip_code: 'Le code postal est requis',
    city: 'La ville est requise',
    country: 'Le pays est requis',
  },
  primary_representative: 'Le représentant principal est requis',
  secondary_representative: 'Le représentant secondaire est requis',
  rep_selection:
    'Veuillez sélectionner le représentant principal et secondaire',
  rep_inform_date: "Veuillez sélectionner la date d'information",
  selectAnOption: 'Sélectionnez une option',
  who_are_you: 'Veuillez me dire qui vous êtes',
  default_message: 'Ce champ est requis',
  successors: {
    first_name: 'Le prénom est requis',
    last_name: 'Le nom de famille est requis',
    email: "L'email est requis",
    invalidEmail: "L'email est invalide",
    agree: 'Veuillez accepter que ces personnes reçoivent un accès invité.',
    min: 'Veuillez sélectionner au moins deux personnes de confiance',
  },
  message: 'Le message est requis',
  role: 'Le rôle est requis',
};

export default validations;
