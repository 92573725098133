const adminDashboard = {
  main: {
    title: 'Tableau de bord',
    description:
      'Voyez combien de vos clients visitent bien réglés, créent une connexion, puis achètent également un forfait.',
  },
  analytics: {
    cards: {
      visitors: 'Visiteurs',
      loginsCreated: 'Connexions créées',
      conversionRate: 'Taux de conversion',
      packagesSold: 'Forfaits vendus',
    },
  },
  clientOverview: 'Aperçu des clients',
  generalOverview: 'Aperçu général',
  legalAdvisors: 'Conseillers juridiques',
  financialAdvisors: 'Conseillers financiers',
  platform: 'Plateforme',
  user: 'Utilisateur',
  mainInfoModal: {
    title: 'Veuillez remplir les informations manquantes',
    description:
      'Comme ce compte a été créé sur invitation, certaines informations nous manquent. Veuillez remplir les informations manquantes pour continuer à utiliser la plateforme.',
  },

  consulting: {
    unlockWidget: {
      title: 'Débloquer le widget',
      description:
        "Demandez un devis et activez des widgets pour vous et vos conseillers afin d'offrir une consultation de retraite simplifiée aux clients pendant les consultations.",
    },
  },
  teamTable: {
    tableHeads: {
      name: 'Nom',
      email: 'Email',
      role: 'Rôle',
      last_login: 'Dernière connexion',
    },
  },
  consultantTable: {
    tableHeads: {
      name: 'Nom',
      email: 'Email',
      location: 'Emplacement',
      added: 'Ajouté',
    },
  },
  deleteUser: "Supprimer l'utilisateur",
  deleteConsultant: {
    title:
      'Vous êtes sur le point de supprimer {{consultant}} de la liste. Êtes-vous sûr?',
    description: 'Veuillez confirmer la suppression.',
    onConfirm: 'Supprimer',
    onClose: 'Annuler',
  },
};

export default adminDashboard;
