const partnerWithUs = {
  mainBanner: {
    title: 'Bieten Sie Zugang zu digitalen Vorsorgelösungen',
    subtitle:
      'Pensionsfonds, Banken, Versicherungsgesellschaften, Verbände und Medienpartner arbeiten mit gut geregelt zusammen, um Menschen einen einfachen Zugang zu umfassender Altersvorsorge zu bieten.',
    buttonLabel: 'Sprechen Sie mit uns',
  },
  youtubeTutorial: 'Warum mit uns zusammenarbeiten?',
  aboutCards: {
    differentiate: {
      title: 'Differenzierung erreichen',
      description:
        'Generieren Sie mehr Traffic für Ihre Plattform mit zusätzlichen Dienstleistungen und relevantem Inhalt.',
    },
    efficiency: {
      title: 'Mehr Effizienz',
      description:
        'Mit zusätzlichen Dienstleistungen können Sie einfach mehr Umsatz generieren und gleichzeitig die Kosten reduzieren.',
    },
    simplyIntegrated: {
      title: 'Einfache Integration',
      description:
        'Bereit zur Verwendung mit der einfachen eigenständigen Cloud-Lösung in Ihrem Design.',
    },
  },
  subBanner: {
    title: 'Als Self-Service- oder als professionelle Beratungslösung',
    subtitle:
      'gut geregelt kann als Whitelabel-Self-Service-Lösung für Endkunden oder als Beratungslösung zur Unterstützung Ihres Beratungsprozesses verwendet werden. Eine API-Integration in eine bestehende Lösung ist ebenfalls möglich.',
  },
  talkWithUs: {
    title: 'Helfen Sie Menschen, ein gut geregeltes Leben zu führen',
    description:
      'Erweitern Sie Ihr Dienstleistungsangebot und stellen Sie sinnvolle Vorsorgedienstleistungen für Menschen zur Verfügung, die ihr Leben vereinfachen und einen positiven Einfluss auf ihre Familien haben.',
    talkAboutPossibilities: 'Lassen Sie uns über Möglichkeiten sprechen',
    iam: 'Ich bin',
    partnerOccupations: {
      bank: 'Bank',
      insurance: 'Versicherung',
      pensionFund: 'Pensionskasse',
      association: 'Verband',
      media: 'Medienpartner',
      trust_foundation: 'Stiftung',
      other: 'Andere',
    },
  },
  solutions: {
    pensionFund: {
      title: 'Pensionskasse',
      content:
        'Wir versuchen seit einiger Zeit, die Besucherzahlen auf unserem Versicherungsportal zu erhöhen. Doch jenseits der jährlichen Versicherungsausweise gibt es wenig gute Gründe. Mit gut geregelt erweitern wir die Möglichkeiten der Altersvorsorge und erhöhen gleichzeitig den Traffic mit einem innovativen Angebot.',
    },
    bank: {
      title: 'Bank',
      content:
        'Obwohl sich die Banken in der Vergangenheit auf die finanzielle Versorgung der Kunden konzentriert haben, sind wir der Meinung, dass wir als Bank auch neue Wege gehen sollten, um den Kunden zu helfen, ein gut geregeltes Leben zu führen. Mit einem Instrument wie gut geregelt wird genau das möglich.\n',
    },
    fintechPlatform: {
      title: 'FinTech-Plattform',
      content:
        'Als Lösung sind wir in der finanziellen Vorsorge bereits sehr nahe am Kunden. Die Ergänzung unseres Angebots durch erweiterte rechtssichere Vorsorgelösungen ist ein logischer nächster Schritt, um den Kunden eine umfassende Altersvorsorge zu ermöglichen.',
    },
  },
  faq: {
    questions: {
      question1: 'Welche Mehrwerte bietet gut geregelt meinen Kunden?',
      question2: 'Wo liegen die Daten?',
      question3: 'Ist gut geregelt DSG und EU-DSGVO-konform?',
      question4: 'Kann ich gut geregelt als Beratungslösung einsetzen?',
      question5: 'Ist gut geregelt als Whitelabel-Lösung verfügbar?',
      question6: 'Wie kann ich gut geregelt in meinem Unternehmen integrieren?',
      question7: 'Welche Sprachen sind unterstützt?',
      question8:
        'Kann ich Partnerorganisationen von mir ebenso mit involvieren?',
      question9: 'Welche Vorteile bietet mir gut geregelt als Stiftungsrat?',
      question10: 'Kann ich zusätzliche Funktionen anfordern?',
      question11: 'Wie sieht das kommerzielle Modell aus?',
    },
    answers: {
      answer1:
        'Mit gut geregelt erhalten Menschen zum ersten Mal einfachen Zugang zu digitalen Vorsorgedienstleistungen mit Rechtssicherheit. Wir unterstützen Sie dabei die ersten Schritte hin zu einem gut geregelten Leben zu nehmen. Dadurch verhelfen wir ihnen und ihren Familien zu mehr Kontrolle und weniger Stress im Ernstfall.',
      answer2:
        'Die Daten werden in der Schweiz gespeichert. Für Partnerorganisationen in Deutschland und Österreich sehen wir eine EU-Compliance-Zone vor.',
      answer3:
        'Ja. Die Anforderungen aus der DSG und EU-DSGVO dienten als Design-Anforderungen bei der Umsetzung von gut geregelt als Software und bilden somit die Basis der Produktarchitektur.',
      answer4:
        'Ja, gut geregelt bietet neben der Self-Service-Lösung für Endkunden auch ein Berater-Cockpit. Dies ermöglicht Ihren Beratern gemeinsam mit den Kunden Lösungen zu entwickeln, wobei der Kunde die Möglichkeit hat, zu einem späteren Zeitpunkt selbstständig Anpassungen oder Ergänzungen vorzunehmen.',
      answer5:
        'Ja. gut geregelt ist eine dedizierte Whitelabel-Lösung für Organisationen wie Banken, Versicherungen, Pensionskassen und andere Vorsorgeeinrichtungen sowie Finanzinstitutionen und Verbände. Die Lösung kann unterschiedlich integriert werden.',
      answer6:
        'Es sind unterschiedliche Szenarien möglich. Die einfachste Integration findet über einen einfachen Link auf gut geregelt statt. Wir bieten jedoch auch Integrationen via SSO und APIs an. Ebenso die Integration als iFrame ist denkbar.',
      answer7:
        'gut geregelt unterstützt Deutsch, Englisch, Französisch und Italienisch.',
      answer8:
        'gut geregelt hat unterschiedliche Partnermodelle und bietet Ihnen beispielsweise die Möglichkeit, Notare und Rechtsanwälte wie auch Finanz- und Vermögensberater als Fachexperten aufzuführen.',
      answer9:
        'Mit gut geregelt kann ich als Stiftungsrat/Stiftungsrätin aktiv mein Haftungsrisiko minimieren, indem ich Versicherungsnehmer aktiv zur Vorsorge animiere, ein Instrument zur Erhöhung von Traffic auf dem Portal der Vorsorgeeinrichtung bereitstelle, und zunehmend mehr Transparenz und Effizienz schaffe.',
      answer10:
        'Ja. Bei gut geregelt hören wir sehr stark auf das, was unsere Kunden und Partner sagen. Die Wünsche und Anforderungen prüfen wir in Einbezugnahme aller und priorisieren jene Funktionen, die einen Mehrwert für den Grossteil unserer Kunden und Partner bieten.',
      answer11:
        'Partner zahlen eine jährliche Gebühr für die gut geregelt Lösung. Von jedem Abschluss durch Endkunden erhält der Partner ein Kickback. Es gibt jedoch auch Partner, die die Kosten für Ihre Endkunden teilweise oder komplett übernehmen und den Service vergünstigt zur Verfügung stellen.',
    },
  },
};

export default partnerWithUs;
