import { alpha } from '@mui/material/styles';

type BgBlurProps = {
  blur?: number;
  opacity?: number;
  color?: string;
  imgUrl?: string;
};

export function bgBlur(props?: BgBlurProps) {
  const color = props?.color || '#000000';
  const blur = props?.blur || 6;
  const opacity = props?.opacity || 0.8;
  const imgUrl = props?.imgUrl;

  if (imgUrl) {
    return {
      position: 'relative',
      backgroundImage: `url(${imgUrl})`,
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9,
        content: '""',
        width: '100%',
        height: '100%',
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity),
      },
    } as const;
  }

  return {
    backdropFilter: `blur(${blur}px)`,
    WebkitBackdropFilter: `blur(${blur}px)`,
    backgroundColor: alpha(color, opacity),
  };
}

type BgGradientProps = {
  direction?: string;
  color?: string;
  startColor?: string;
  endColor?: string;
  imgUrl?: string;
};

export function bgGradient(props?: BgGradientProps) {
  const direction = props?.direction || 'to bottom';
  const startColor = props?.startColor;
  const endColor = props?.endColor;
  const imgUrl = props?.imgUrl;
  const color = props?.color;

  if (imgUrl) {
    return {
      background: `linear-gradient(${direction}, ${startColor || color}, ${
        endColor || color
      }), url(${imgUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    };
  }

  return {
    background: `linear-gradient(${direction}, ${startColor}, ${endColor})`,
  };
}

export function textGradient(value: string) {
  return {
    background: `-webkit-linear-gradient(${value})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };
}

export function filterStyles(value: string) {
  return {
    filter: value,
    WebkitFilter: value,
    MozFilter: value,
  };
}

export const hideScrollbarY = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

export const hideScrollbarX = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowX: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

/**
 * Converts HEX or RGB to RGBA format.
 *
 * @param {string} color - The color in HEX (#rrggbb or #rgb) or RGB (rgb(r, g, b)) format.
 * @param {number} opacity - The opacity level (0-1).
 * @returns {string} - The color in RGBA format.
 */

export function toRgba(color: string, opacity: number): string {
  let rgbaColor = '';

  if (color?.startsWith('#')) {
    const hex = color.replace('#', '');
    const r = parseInt(
      hex.length === 3 ? hex[0] + hex[0] : hex.substring(0, 2),
      16
    );
    const g = parseInt(
      hex.length === 3 ? hex[1] + hex[1] : hex.substring(2, 4),
      16
    );
    const b = parseInt(
      hex.length === 3 ? hex[2] + hex[2] : hex.substring(4, 6),
      16
    );

    rgbaColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
  } else if (color?.startsWith('rgb')) {
    rgbaColor = color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
  } else {
    console.warn('Unsupported color format. Please use HEX or RGB.');
    rgbaColor = color;
  }

  return rgbaColor;
}

export function adjustBrightness(
  hex: string | undefined,
  percent: number
): string {
  if (!hex) return '#3366FF';

  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  r = Math.min(255, Math.max(0, Math.round(r * (1 + percent))));
  g = Math.min(255, Math.max(0, Math.round(g * (1 + percent))));
  b = Math.min(255, Math.max(0, Math.round(b * (1 + percent))));

  const toHex = (c: number) => {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export interface ColorVariants {
  lighter: string;
  light: string;
  main: string;
  dark: string;
  darker: string;
}

export const getColorVariants = (
  hexColor: string | undefined
): ColorVariants => {
  const baseColor = hexColor || '#3366FF';

  return {
    lighter: adjustBrightness(baseColor, 0.4),
    light: adjustBrightness(baseColor, 0.2),
    main: baseColor,
    dark: adjustBrightness(baseColor, -0.2),
    darker: adjustBrightness(baseColor, -0.4),
  };
};

export function isColorTooDark(color: string | undefined): boolean {
  if (!color) return false;

  function hexToRgb(hex: string): [number, number, number] | null {
    let hexCopy = hex;
    if (hexCopy.startsWith('#')) {
      hexCopy = hexCopy.slice(1);
    }
    if (hexCopy.length === 3) {
      hexCopy = hexCopy
        .split('')
        .map((char) => char + char)
        .join('');
    }
    const r = parseInt(hexCopy.slice(0, 2), 16);
    const g = parseInt(hexCopy.slice(2, 4), 16);
    const b = parseInt(hexCopy.slice(4, 6), 16);
    return [r, g, b];
  }
  function parseRgba(rgba: string): [number, number, number, number] | null {
    const result = rgba.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*(\d*\.?\d*)?\)/
    );
    if (!result) return null;
    const alpha = result[4] !== undefined ? parseFloat(result[4]) : 1;
    return [
      parseInt(result[1], 10),
      parseInt(result[2], 10),
      parseInt(result[3], 10),
      alpha,
    ];
  }

  function luminance(r: number, g: number, b: number): number {
    const a = [r, g, b].map(function calculate(v) {
      const value = v / 255;
      return value <= 0.03928
        ? value / 12.92
        : ((value + 0.055) / 1.055) ** 2.4;
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  let rgb: [number, number, number] | null = null;
  let alpha: number = 1;

  if (color.startsWith('#')) {
    rgb = hexToRgb(color);
  } else if (color.startsWith('rgb')) {
    const rgba = parseRgba(color);
    if (rgba) {
      rgb = [rgba[0], rgba[1], rgba[2]];
      alpha = rgba[3];
    }
  } else {
    console.error('Invalid color format');
    return false;
  }

  if (!rgb) {
    console.error('Invalid color format');
    return false;
  }

  const lum = luminance(rgb[0], rgb[1], rgb[2]);

  const threshold = 0.5;
  const isDark = lum < threshold && alpha > 0.5;

  if (isDark) {
    console.log('Now the text should be white');
  } else {
    console.log('The color is not too dark');
  }

  return isDark;
}
