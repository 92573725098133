import { IServiceStats } from '#/types/globalTypes';

export interface ServiceStatsContextProps extends IServiceStats {
  isLoading: boolean;
}

export const initialState: ServiceStatsContextProps = {
  living_will: null,
  power_of_attorney: null,
  last_will: null,
  marriage_contract: null,
  important_information: null,
  cohabitation_agreement: null,
  isLoading: false,
  downloaded: [],
};
