const lastWillLanding = {
  mainBanner: {
    intro: 'Testament online erstellen:',
    title: 'Rechtssicher, in 10 Minuten erledigt',
    subtitle:
      'Die proaktive Festlegung dessen, was mit Ihrem Vermögen geschehen wird, hilft dabei, Ihren letzten Willen zu gewährleisten und den Prozess für Ihre Familie bequemer zu gestalten.',
    buttonLabel: 'Testament erstellen',
  },
  youtubeTutorial: 'Warum ein Testament wichtig ist?',
  aboutCards: {
    whoGetsWhat: {
      title: 'Wer bekommt was',
      description:
        'Das Testament regelt, wer wie viel von dem bekommt, was von der gesetzlichen Erbfolge abweichen darf.',
    },
    enforceWishes: {
      title: 'Wünsche durchsetzen',
      description:
        'Stellen Sie sicher, dass Sie Ihr Vermögen gemäss Ihren persönlichen Wünschen und Vorstellungen verteilen.',
    },
    clarificationForFamily: {
      title: 'Klarheit für die Familie',
      description:
        'Machen Sie unnötige Diskussionen überflüssig und sorgen Sie für Klarheit für Ihre Hinterbliebenen.',
    },
  },
  subBanner: {
    title: 'Gut geregelt mit einem rechtlich konformen Testament',
    subtitle:
      'Mit gut geregelt erstellen Sie schnell Ihr Testament, können rechtliche Beratung zu heiklen Themen oder offenen Fragen erhalten und tun Ihren Angehörigen einen Gefallen.',
    buttonLabel: 'Jetzt kostenlos erstellen',
  },
  newsletter: {
    title: 'Werden Sie gut geregelt mit Ihrem Testament',
    buttonLabel: 'Jetzt erstellen',
  },
  faq: {
    questions: {
      question1: 'Was bringt mir ein Testament?',
      question2:
        'Gibt es verschiedene Arten, wie ich mein Testament erstellen kann?',
      question3: 'Was fällt unter den gesetzlichen Erbanspruch?',
      question4:
        'Was hat sich mit der Revision des Erbrechts am 1. Januar 2023 geändert?',
      question5:
        'Über welche Teile meines Vermögens darf ich im Testament frei verfügen?',
      question6: 'Was geschieht, wenn ich kein Testament habe und sterbe?',
      question7:
        'Kann ich mein Testament zusammen mit meinem Ehepartner verfassen?',
      question8: 'Kann ich Menschen ausserhalb meiner Familie erben?',
      question9: 'Darf ich jemanden enterben?',
      question10: 'Muss ich einen Willensvollstrecker ernennen?',
      question11: 'Kann ich mein Testament jederzeit anpassen?',
      question12:
        'Gibt es neben dem Testament weitere Dokumente, die ich erstellen sollte?',
      question13: 'Wie hilft mir gut geregelt bei meinem Testament?',
    },
    answers: {
      answer1:
        'Mit einem Testament regeln Sie Ihren Nachlass. Sie legen fest, was mit Ihrem Vermögen nach Ihrem Tod geschehen soll. Zudem gilt: Wenn Sie im Konkubinat leben und ihren Partner beerben möchten, müssen Sie das unbedingt im Testament festhalten.',
      answer2:
        'Ja, es gibt das eigenhändige Testament, das öffentliche Testament und das mündliche Testament.',
      answer3:
        'Es gibt einen Pflichtteil, den gesetzlichen Erbanspruch, der sicherstellt, dass Ehepartner oder Kinder nicht komplett von Ihrem Erbe ausgeschlossen werden können.',
      answer4:
        'Die grösste Änderung ist, dass der Pflichtteil der Eltern komplett wegfällt und der Pflichtteil der Kinder für unverheiratete Erblasser von 75% auf 50% reduziert wurde.',
      answer5:
        'Neben den Pflichtteilen dürfen Sie frei über Ihren Nachlass entscheiden. Sie können also beispielsweise Ihren Ehepartner oder eingetragenen Partner mehr begünstigen.',
      answer6:
        'In diesem Fall tritt automatisch die gesetzliche Erbfolge in Kraft und Ihr Nachlass wird aufgeteilt, abhängig von Ihrer familiären Situation zum Todeszeitpunkt.',
      answer7:
        'Nein, Sie dürfen Ihr Testament nicht gemeinsam mit Ihrem Ehepartner erstellen.',
      answer8:
        'Ja, neben dem gesetzlich festgelegten Pflichtteil dürfen Sie selbst entscheiden, wen Sie als Erben einsetzen, auch Vereine oder Institutionen.',
      answer9:
        'Solange es sich nicht um Erben handelt, die Anrecht auf den Pflichtteil haben, können Sie enterben.',
      answer10:
        'Nein, das Bestimmen eines Willensvollstreckers ist freiwillig, kann aber vor Erbstreitigkeiten schützen.',
      answer11:
        'Ja, Sie können jederzeit Änderungen vornehmen, wichtig ist, dass Sie diese datieren und unterschreiben.',
      answer12:
        'Ja, der {{powerOfAttorney}} und die {{livingWill}} sind zwei Dokumente, die Sie möglichst zeitnah erstellen sollten.',
      answer13:
        'Bei gut geregelt helfen wir Ihnen mit dem reibungslosen und rechtsgültigen Erstellen Ihres Testaments.',
    },
    hyperlinks: {
      lastWill: 'Testament',
      livingWill: 'Patientenverfügung',
      powerOfAttorney: 'Vorsorgeauftrag',
    },
  },
};

export default lastWillLanding;
