const poaLanding = {
  mainBanner: {
    intro: 'Créer un mandat de prévoyance en ligne :',
    title: 'Désignez un mandataire pour les situations d`urgence',
    subtitle:
      'Sans procuration, votre conjoint ou votre famille ne peut pas prendre de décisions en votre nom en cas d`urgence. Désignez dès maintenant qui est légalement autorisé à agir en votre nom.',
    buttonLabel: 'Créer le document',
  },
  youtubeTutorial: "Qu'est-ce qu'un mandat de protection future?",
  aboutCards: {
    guaranteeForPower: {
      title: 'Garantie de pouvoir',
      description:
        'Permettez à vos proches de prendre des décisions dans votre meilleur intérêt lorsque vous ne pouvez pas le faire vous-même.',
    },
    mutualProtection: {
      title: 'Protection mutuelle',
      description:
        'Protégez l`intégrité de vos biens en vous assurant que des décisions importantes pour la famille ne peuvent être prises que par des membres de la famille.',
    },
    clarificationForFamily: {
      title: 'Clarté pour la famille',
      description:
        'Évitez les discussions inutiles et assurez la clarté pour les proches.',
    },
  },
  subBanner: {
    title:
      'Bien organisé avec un mandat de protection future juridiquement conforme',
    subtitle:
      'Avec bien organisé, vous créez rapidement votre mandat de protection future, pouvez obtenir des conseils juridiques sur des sujets délicats ou des questions en suspens, et faites plaisir à vos proches.',
    buttonLabel: 'Créer gratuitement maintenant',
  },
  newsletter: {
    title: 'Soyez bien organisé avec votre mandat de protection future',
    buttonLabel: 'Créer maintenant',
  },
  faq: {
    questions: {
      question1: 'Qu est-ce qu un mandat de prévoyance ?',
      question2: 'Quand une incapacité de jugement est-elle présente ?',
      question3:
        'Que se passe-t-il si je deviens incapable de juger et que je n ai pas de mandat de prévoyance ?',
      question4: 'Que dois-je préciser dans le mandat de prévoyance ?',
      question5: 'Comment créer mon mandat de prévoyance ?',
      question6: 'Comment choisir ma personne de représentation ?',
      question7: 'Qu est-ce que la KESB et quelle est sa fonction ?',
      question8: 'Puis-je modifier mon mandat de prévoyance à tout moment ?',
      question9: 'Puis-je annuler mon mandat de prévoyance ?',
      question10:
        'Puis-je également préciser dans le mandat de prévoyance mes souhaits de traitement en cas d urgence médicale ?',
      question11:
        'Y a-t-il d autres documents que je devrais créer en plus du mandat de prévoyance ?',
      question12:
        'Comment bien réglé m aide-t-il avec mon mandat de prévoyance ?',
    },
    answers: {
      answer1:
        'Dans le mandat de prévoyance, vous précisez qui doit représenter vos intérêts dans les affaires personnelles et financières en cas d incapacité de jugement. Avec le mandat de prévoyance, vous vous assurez que vos intérêts sont représentés et que votre volonté est mise en œuvre.',
      answer2:
        'Une incapacité de jugement peut survenir, par exemple, à la suite d une maladie grave comme la démence, après un accident vasculaire cérébral ou après un accident grave suivi d un coma.',
      answer3:
        'Cela dépend de votre état civil : en tant que personne célibataire, en tant que personne mariée ou en partenariat enregistré, et en tant que personne vivant en concubinage.',
      answer4:
        'Le mandat de prévoyance comprend trois domaines : les soins personnels, les soins patrimoniaux et les transactions juridiques. Vous pouvez attribuer ces trois domaines à la même personne représentative ou les diviser.',
      answer5:
        'Vous pouvez créer votre mandat de prévoyance vous-même, l important étant de ne pas oublier la date et la signature. Ou vous pouvez le créer avec un notaire et le faire authentifier publiquement.',
      answer6:
        'Vous pouvez désigner une seule personne pour les trois domaines ou plusieurs personnes. Il est important que vous ayez une confiance totale en cette personne.',
      answer7:
        'L autorité de protection de l enfant et de l adulte assure la protection des enfants et des adultes en danger. Dans le cas du mandat de prévoyance, la KESB intervient lorsqu elle est informée de l incapacité de jugement d une personne.',
      answer8:
        'Oui, à condition que vous soyez capable de juger au moment de la modification. Un nouveau mandat de prévoyance remplace la dernière version.',
      answer9:
        'Oui, vous pouvez annuler votre mandat de prévoyance à tout moment en détruisant le document.',
      answer10:
        'Oui, vous pouvez. Cependant, il est également judicieux de créer séparément une directive anticipée du patient.',
      answer11:
        'Oui, le {{lastWill}} et le {{livingWill}} sont deux autres documents importants que vous devriez rédiger rapidement.',
      answer12:
        'Chez bien réglé, nous vous aidons à créer votre mandat de prévoyance de manière juridiquement valide et sans heurts.',
    },
    hyperlinks: {
      lastWill: 'Testament',
      livingWill: 'Directive anticipée du patient',
      powerOfAttorney: 'Mandat de prévoyance',
    },
  },
};

export default poaLanding;
