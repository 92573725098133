const lastWillLanding = {
  mainBanner: {
    title: 'Clarify who gets what when you’re no longer here',
    subtitle:
      'Proactively determining what will happen to your assets helps guarantee your will and make the process more convenient for your family.',
    buttonLabel: 'Create document',
  },
  youtubeTutorial: 'What is a last will ?',
  aboutCards: {
    whoGetsWhat: {
      title: 'Who gets what',
      description:
        'It regulates who should get what, which can deviate from the legal succession.',
    },
    enforceWishes: {
      title: 'Enforce wishes',
      description:
        'Make sure that you distribute your assets according to your personal wishes and desires.',
    },
    clarificationForFamily: {
      title: 'Clarification for family',
      description:
        'Make unnecessary discussions obsolete and provide clarity for the bereaved.',
    },
  },
  subBanner: {
    title: 'Gut geregelt with a legally compliant last will',
    subtitle:
      'With gut geregelt, you quickly create your last will, can get legal consultation on delicate topics or open questions and do your relatives a favor.',
    buttonLabel: 'Create it for free',
  },
  newsletter: {
    title: 'Get gut geregelt with your last will',
    buttonLabel: 'Create it now',
  },
  faq: {
    questions: {
      question1: 'What does a last will do for me?',
      question2: 'Are there different ways to create my last will?',
      question3: 'What falls under the statutory inheritance claim?',
      question4:
        'What changed with the revision of the inheritance law on January 1, 2023?',
      question5: 'What parts of my assets am I free to dispose of in the will?',
      question6: 'What happens if I have no last will and die?',
      question7: 'Can I create my last will together with my spouse?',
      question8: 'Can I inherit people outside my family?',
      question9: 'Can I disinherit someone?',
      question10: 'Do I have to appoint an executor?',
      question11: 'Can I adjust my last will at any time?',
      question12:
        'Are there other documents I should create besides the last will?',
      question13: 'How does gut geregelt help me with my last will?',
    },
    answers: {
      answer1:
        'With a last will, you determine the distribution of your estate, i.e., what should happen to your assets after your death. Additionally, if you live in a non-marital cohabitation and want your partner to inherit, you must explicitly state it in the will.',
      answer2:
        'Yes, there are three ways to create your last will: handwritten will, public will, and oral will.',
      answer3:
        'There is a mandatory share, the statutory inheritance claim, to ensure that you cannot completely exclude your spouse or registered partner from your inheritance. The same applies if you have children.',
      answer4:
        'The most significant change is the complete elimination of the mandatory share of parents. Additionally, the mandatory share for unmarried deceased individuals with children was reduced from 75% to 50%.',
      answer5:
        'In addition to the mandatory shares, you are free to decide on your estate, including favoring your spouse or registered partner more than your children or favoring your partner if you live in a non-marital cohabitation.',
      answer6:
        'The statutory inheritance rules automatically come into effect, and your estate will be divided depending on your family situation at the time of death.',
      answer7:
        'No, you cannot create your will jointly with your spouse. Each person must do it individually.',
      answer8:
        'Yes, in addition to the legally defined mandatory share, you can decide for yourself whom to appoint as heirs, including associations or institutions.',
      answer9:
        'As long as they are not entitled to the mandatory share, you can disinherit them entirely.',
      answer10:
        'No, appointing an executor is optional but advisable if there is a risk of inheritance disputes.',
      answer11:
        'Yes, you can make changes at any time, but it is important to date and sign these changes.',
      answer12:
        'Yes, {{powerOfAttorney}} and {{livingWill}} are two documents you should create promptly.',
      answer13:
        'At gut geregelt, we assist you in the smooth and legally valid creation of your will.',
    },
    hyperlinks: {
      lastWill: 'the last will',
      livingWill: 'the living will',
      powerOfAttorney: 'the power of attorney',
    },
  },
};

export default lastWillLanding;
