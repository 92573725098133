import { Stack } from '@mui/material';
import React from 'react';
import { StyledRoot, StyledContent } from './styles';

import Header from '../compact/Header';

type Props = {
  children: React.ReactNode;
};

export default function Auth({ children }: Props) {
  return (
    <StyledRoot>
      <Header isOffset />
      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
