import {
  contractParties,
  estateQuestions,
  marriageQuestions,
} from './questions';
import outputDoc from './outputDoc';

const marriageContract = {
  mainHeaders: {
    maximiseBenefits: 'Maximize Benefits for Your Spouse',
    effectiveMarriageContract: 'Your Effective Marriage Contract',
  },

  stepperInfo: [
    {
      label: 'Spouse',
      title: 'Contract Parties',
    },
    {
      label: 'Marriage',
      title: 'Marriage',
    },
    {
      label: 'Assets',
      title: 'Assets',
    },
    {
      label: 'Your Contract',
      title: 'Deposit Your Marriage Contract',
    },
  ],

  propertyTypes: {
    apartment: 'Apartment',
    house: 'House',
    chalet: 'Chalet',
    rustico: 'Rustic House',
    building_plot: 'Building Plot',
    multi_family_house: 'Multi-family House',
    commercial_building: 'Commercial Building',
    residential_building: 'Residential Building',
    commercial_property: 'Commercial Property',
  },

  stepOne: {
    questions: contractParties,
  },
  stepTwo: {
    questions: marriageQuestions,
  },
  stepThree: {
    questions: estateQuestions,
  },
  stepFour: {
    document: outputDoc,
    introParagraph: 'Well done!',
    nextStepsTitle: 'Next Steps:',
    nextStep1: 'Print the marriage contract and read it carefully',
    nextStep2:
      'Contact a notary to have your contract notarized (click here to find one)',
    nextStep3: 'Upload the notarized marriage contract below',
  },
};

export default marriageContract;
