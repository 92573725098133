import { FileRejection } from 'react-dropzone';
import { alpha } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';
import { fData } from '#/utils/formatNumber';
import { fileData } from '../../file-thumbnail';
import useLocales from '#/hooks/useLocales';

type Props = {
  fileRejections: FileRejection[];
};

const formatWords = ['.pdf', '.docx', '.xlsx', '.png', '.jpg', '.txt']; // Add more format words as needed

export default function RejectionFiles({ fileRejections }: Props) {
  const { translate } = useLocales();
  if (!fileRejections.length) {
    return null;
  }

  const checkFormatWord = (str: string) =>
    formatWords.find((word) => str.includes(word)) || null;

  const handleErrorMessages = (error: any) => {
    const checkFormat = checkFormatWord(error.message);

    switch (error.code) {
      case 'file-invalid-type':
        return translate(`validations.acceptedFiles.type${checkFormat}`);
      default:
        return error.message;
    }
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        borderColor: (theme) => alpha(theme.palette.error.main, 0.24),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = fileData(file);

        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {size ? fData(size) : ''}
            </Typography>

            {errors.map((error) => {
              return (
                <Box
                  key={error.code}
                  component="span"
                  sx={{ typography: 'caption' }}
                >
                  - {handleErrorMessages(error)}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Paper>
  );
}
