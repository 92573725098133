import React, { FC } from 'react';
import MenuPopover from '../MenuPopover';

interface AccountPopoverProps {
  openMenu: HTMLElement | null;
  handleCloseMenu: () => void;
  actions: React.ReactNode;
}

const AccountPopover: FC<AccountPopoverProps> = ({
  openMenu,
  handleCloseMenu,
  actions,
}) => (
  <MenuPopover
    open={Boolean(openMenu)}
    anchorEl={openMenu}
    onClose={handleCloseMenu}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    arrow="top-right"
    disabledArrow
    sx={{
      mt: 2,
    }}
  >
    {actions}
  </MenuPopover>
);

export default AccountPopover;
