const outputDoc = {
  title: 'Patientenverfügung',
  from: 'von ',
  born: 'geboren am ',
  civilStatus: {
    single: 'Ledig',
    married: 'Verheiratet',
    widowed: 'Verwitwet',
    divorced: 'Geschieden',
    registered_partnership: 'Eingetragener partnerschaft',
  },
  civil_status: 'Familienstand: {{civil_status}}',
  address: 'Adresse: {{address}}',
  declare: 'Ich, {{first_name}} {{last_name}}, erkläre folgendes:  ',
  paragraph1:
    'Für den Fall, dass ich wegen Krankheit oder Unfall urteilsunfähig werde und nicht mehr in der Lage bin, regle ich mit dieser Patientenverfügung meinen rechtsverbindlichen Willen und meine Wünsche im Hinblick auf medizinische Behandlung, Betreuung, Sterben und Tod. Als vertretungsberechtigte Person(en) beauftrage ich folgende Person(en): ',
  paragraph2:
    'Die vertretungsberechtigte Person setzt im Fall meiner Urteilsunfähigkeit meinen festgelegten Willen durch und trifft für mich medizinische und pflegerische Entscheide. Sie muss über meinen gesundheitlichen Zustand wie auch über den voraussichtlichen weiteren Krankheitsverlauf informiert werden. Ich entbinde das Behandlungsteam ausdrücklich von der Schweigepflicht, soweit dies für eine anstehende Entscheidung erforderlich ist. Die vertretungsberechtigte Person muss – wenn immer zeitlich möglich – miteinbezogen werden, bevor medizinische Entscheidungen gefällt werden. Sofern diese Patientenverfügung eine Frage nicht regelt, entscheidet die vertretungsberechtigte Person gemäss meinem mutmasslichen Willen.',
  paragraph3:
    'Die genannte Person ist auch berechtigt, nach meinem Tod die Patientendokumentation wie auch einen allfälligen Obduktionsbefund einzusehen. ',
  repInformParagraph:
    'Ich habe den Auftrag mit der vertretungsberechtigten Person am {{representative_inform_date}} besprochen. ',
  secondaryRepParagraph:
    'Für den Fall, dass die beauftragte Person gemäss Ziff. 1 ihre Aufgabe nicht übernehmen kann, ernenne ich als ersatzbeauftragte Person:  ',
  motivation: {
    title: 'Motivation und Grundeinstellung',
    paragraph1: 'Ich erlasse diese Patientenverfügung ',
    takePrecautions: 'in guter Gesundheit, aber mit vorausschauender Planung.',
    makeProvisions:
      'mit einem gewissen Krankheitsbild, das mich dazu führt, Vorkehrungen zu treffen.',
    makeProvisionsForFuture:
      'nach Erreichen eines gewissen Alters, wo auch kurzfristige Vorkehrungen getroffen werden müssen.',
    paragraph2:
      'Ich möchte mit dieser Patientenverfügung vor allem erreichen, dass ',
    alleviateSuffering:
      'die medizinischen Möglichkeiten vor allem zur Linderung meines Leidens eingesetzt werden. Die Verlängerung meiner Lebenszeit um jeden Preis ist für mich nicht vorrangig. Dabei nehme ich in Kauf, dass der Verzicht auf medizinische Behandlung mein Leben verkürzen kann.',
    exhaustedMedicalPossibilities:
      'alle medizinischen Möglichkeiten zur Aufrechterhaltung meines Lebens ausgeschöpft werden. Mein Leiden soll so weit wie möglich gelindert werden. Dabei nehme ich in Kauf, dass dies mit Belastungen verbunden sein kann (z.B. getrübtes Bewusstsein).',
  },
  medical: {
    title: 'Medizinische Anordnungen / Behandlungsziel ',
    paragraph1:
      'Falls ich mich in einer der folgenden Situationen befinden sollte, erwarte ich vom Behandlungsteam folgendes Verhalten:',
    exhaustedMedicalPossibilities:
      'Ich möchte vor allem eine wirksame Behandlung von Schmerzen und anderen Symptomen wie Angst. Dabei nehme ich in Kauf, dass dies mit Belastungen verbunden sein kann (z.B. Eintrübung des Bewusstseins).',
    alleviateSuffering:
      'Ich möchte vor allem das Bewusstsein und meine Kommunikationsfähigkeit erhalten. Dabei nehme ich in Kauf, dass dies die optimale Linderung von Schmerzen und anderen Symptomen wie Angst möglicherweise beeinträchtigt.',
  },
  forego_measures: {
    yes: 'Ich verzichte auf alle Massnahmen, die lediglich eine Lebens- und Leidensverlängerung zur Folge haben, wenn ich durch ein unerwartetes, akutes Ereignis urteilsunfähig werde und wenn es nach eingehender medizinischer Beurteilung unmöglich oder unwahrscheinlich ist, dass ich meine Urteilsfähigkeit je wiedererlange. ',
    no: 'Ich will, dass alle möglichen Massnahmen umgesetzt werden, wenn ich durch ein unerwartetes, akutes Ereignis urteilsunfähig werde und auch wenn es nach eingehender medizinischer Beurteilung unmöglich oder unwahrscheinlich ist, dass ich meine Urteilsfähigkeit je wiedererlange. ',
  },
  revive_preference: {
    paragraph: 'Im Falle eines Herz-Kreislauf- oder Atemstillstandes soll',
    yes: 'eine',
    no: 'keine',
    paragraph1: 'Wiederbelebung (Reanimation) erfolgen.',
  },
  moreDetails: {
    treatment_goal_sudden_incapacity: {
      paragraph: 'Bei plötzlicher Urteilsunfähigkeit (Notfall) ',
      resuscitationAndTreatment:
        'wünsche eine Wiederbelebung (Reanimation) und eine Behandlung auf der Intensivstation (Be-handlungsziel Lebensverlängerung). ',
      treatedInIntensiveCareUnit:
        'wünsche keine Wiederbelebung (Reanimation), aber eine Behandlung auf der Intensivstation. ',
    },
    suffering_relief_sudden_incapacity: {},
    treatment_goal_prolonged_incapacity: {
      paragraph: 'Bei länger dauernder Urteilsunfähigkeit ',
      resuscitationAndTreatment:
        'wünsche eine Wiederbelebung (Reanimation) und eine Behandlung auf der Intensivstation (Be-handlungsziel Lebensverlängerung). ',
      treatedInIntensiveCareUnit:
        'wünsche keine Wiederbelebung (Reanimation), aber eine Behandlung auf der Intensivstation.',
    },
    treatment_goal_permanent_incapacity: {
      paragraph: 'Bei bleibender Urteilsunfähigkeit ',
      resuscitationAndTreatment:
        'wünsche eine Wiederbelebung (Reanimation) und eine Behandlung auf der Intensivstation (Be-handlungsziel Lebensverlängerung). ',
      treatedInIntensiveCareUnit:
        'wünsche keine Wiederbelebung (Reanimation), aber eine Behandlung auf der Intensivstation. ',
    },
  },
  revocation: {
    title: 'Widerruf',
    paragraph1:
      'Ich widerrufe hiermit sämtliche Patientenverfügungen früheren Datums.',
  },
  abilityToConsent: {
    title: 'Handlungsfähigkeit ',
    paragraph1:
      'Ich gebe diese Erklärung nach sorgfältiger Überlegung und in der vollen Verantwortung für mich selbst ab, sowie im Bewusstsein, dass bezüglich der medizinischen Massnahmen meine Ärzte, Betreuer oder Bevollmächtigten an den Entscheid der beauftragten Person gebunden sind.',
    informedRepresentative:
      'Ich habe diese Verfügung mit den, eingangs auf der ersten Seite, erwähnten Personen besprochen, welche bestätigen können, dass ich zu diesem Zeitpunkt, nach deren Wahrnehmung, urteilsfähig war und der Inhalt meinem Willen entspricht. ',
  },
};

export default outputDoc;
