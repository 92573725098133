const fr = {
  homeTitle: 'Bien organisé',
  homeDescription:
    'Nous sommes là pour vous aider, ainsi que vos proches. En seulement trente minutes, vous et votre famille aurez créé les documents de prévoyance les plus importants et serez bien organisés. Allons-y.',
  homeHighlight: 'discuter avec un expert',

  clear: 'Effacer',
  search: 'Rechercher',

  customerOpinion: 'Avis des clients',
  joinWaitlist: "Rejoindre la liste d'attente",

  civilStatus: {
    married: 'Marié',
    single: 'Célibataire',
    divorced: 'Divorcé',
    widowed: 'Veuf/Veuve',
    registered_partnership: 'Partenariat enregistré',
  },

  dashCardInfos: {
    livingWill: {
      title: 'Directive anticipée',
      description:
        '80% des gens rédigent leur directive anticipée en moins de 7 minutes',
    },
    powerOfAttorney: {
      title: 'Mandat de protection future',
      description: 'Nous estimons que cela prendra 5 minutes',
    },
    lastWill: {
      title: 'Testament',
      description:
        '80% des gens rédigent leur testament en moins de 10 minutes',
    },
    digitalFootprint: {
      title: 'Empreinte numérique',
      description: 'Laissez une empreinte numérique propre',
    },
    heirsRegister: {
      title: 'Registre des héritiers',
      description: 'Représentation numérique de vos biens.',
    },
    successionPlanning: {
      title: 'Planification successorale',
      description:
        'Vous possédez une entreprise ? Assurez-vous de sa durabilité.',
    },
    comingSoon: {
      title: 'Prochainement',
      description:
        'gut geregelt travaille constamment sur de nouveaux services',
    },
    continue: 'Continuer',
    getStarted: 'Commencer',
    summaryAndReview: 'Résumé et vérification',
    update: 'Mettre à jour',
    history: 'Historique',
    markAsDone: 'Marquer comme terminé',
    guest: {
      disabledButton: "L'hôte doit d'abord terminer le document.",
      getDocButton: 'Récupérer le document',
    },
  },

  loginInfo: {
    title: 'Faites plaisir à vos proches, soyez bien organisé',
    title1: 'Bonjour, bienvenue de nouveau',
    newUser: 'Nouvel utilisateur ?',
    createAccount: 'Créer un compte',
    forgotPassword: 'Mot de passe oublié ?',
    login: 'Se connecter',
    resetPasswordInfo:
      "Veuillez saisir l'adresse e-mail associée à votre compte, et nous vous enverrons un lien pour réinitialiser votre mot de passe par e-mail.",
    sendRequest: 'Envoyer la demande',
    returnToLogin: 'Retour à la connexion',
    setNewPassword: 'Définir un nouveau mot de passe',
    updatePassword: 'Mettre à jour le mot de passe',
    invalidLink: 'Le lien est invalide ou a expiré !',
    returnToResetPassword: 'Retour à la réinitialisation du mot de passe',
  },

  services: {
    forIndividuals: 'Pour les particuliers',
    forCompanies: 'Pour les entreprises',
    livingWill: 'Directive anticipée',
    powerOfAttorney: 'Mandat de protection future',
    lastWill: 'Testament',
    digitalFootprint: 'Emprunte digitale',
    registerAssets: 'Registre des biens et des actifs',
    pricing: 'Tarifs',
    login: 'Se connecter',
    makeProvisions: 'Provisions',
    partnerWithUs: 'Partenaire Whitelabel',
    blog: 'Blog',
    dashboard: 'Tableau de bord',
    consulting: 'Consultation',
  },

  settings: {
    changeAccount: 'Changer de compte',
    changeAccountContent: {
      title: 'Changer pour un autre compte',
    },
    settings: 'Paramètres',
    needHelp: "Besoin d'aide ?",
    logOut: 'Déconnexion',
  },

  roles: {
    executor: 'Exécuteur testamentaire',
    lawyer: 'Avocat',
    trustee: 'Fiduciaire',
    familyMember: 'Membre de la famille',
    friend: 'Ami',
  },

  account: 'Compte',
  main: 'Principal',
  guest: 'Invité',
  lastUpdated: 'Dernière mise à jour :',
  description: 'Description',
  imageHelper: 'Formats de fichier autorisés : *.jpeg, *.jpg, *.png, *.gif, ',
  imageMaxSize: 'taille maximale du fichier : ',
  storageLocation: 'Lieu de stockage',
  beneficiary: 'Bénéficiaire',
  beneficiarie: "Bénéficiaire à l'attention de :",
  addNewBeneficiary: 'Ajouter un nouveau bénéficiaire',
  primary: 'Contact principal',
  representative: 'Représentant',
  enoughForNow: "Pause s'il vous plaît !",
  finishForToday: "C'est fini pour aujourd'hui",
  currentPackage: 'Votre forfait actuel',
  freePackage: 'Paquet gratuit',
  starterPackage: 'Paquet de démarrage',
  standardPackage: 'Paquet standard',
  familyPack: 'Paquet familial',
  noPackage: 'Aucun paquet',
  needHelp: "Besoin d'aide ?",
  continueToLastWill: 'Continuer avec le testament',
  continueToPOA: 'Continuer avec la procuration',
  addAnother: 'Ajouter une autre personne de confiance',
  add: 'Ajouter',
  finish: 'Terminer',
  delete: 'Supprimer',
  edit: 'Modifier',
  save: 'Enregistrer',
  next: 'Suivant',
  back: 'Retour',
  or: 'ou',
  role: 'Rôle',
  current: 'Actuel',
  gender: 'Genre',
  male: 'Masculin',
  female: 'Féminin',
  cancel: 'Annuler',
  priority: 'Priorité',
  areYouSureYouWantToLeave: 'Êtes-vous sûr de vouloir faire une pause ?',
  enoughForNowDscp:
    'Pas de souci, tout ce que vous avez fait sera automatiquement enregistré, et vous serez rappelé de reprendre là où vous avez laissé.',
  enoughForNowCancel: "Oui, j'ai fini",
  enoughForNowContinue: 'Merci, je veux continuer',
  addPropertyOrAsset: 'Ajouter une propriété ou un actif',
  addItem: 'Ajouter un actif',
  good: 'avoir',
  adress: 'Adresse',
  download: 'Télécharger',
  upgradePlan:
    'Mettez à niveau votre plan pour télécharger deux documents ou plus',
  choosePlan: 'Choisissez un plan',
  switzerland: 'Suisse',
  fillInfoTitle:
    'Assurez-vous de fournir des informations importantes pour votre compte principal',
  switch: 'Changer',
  activatePersonalAccount: 'Activer votre compte personnel',
  howManyMembers: 'Nombre de membres de la famille',
  requiredBasicInfoTitle:
    'Veuillez entrer les informations de base requises avant de continuer',

  familyPackage: {
    title: 'Ma famille',
    description:
      'Avec le paquet familial, vous et les membres de votre famille pouvez régler votre prévoyance à un tarif réduit.',
    helperText:
      "Veuillez remplir soigneusement les informations de vos membres de la famille. Ces informations ne peuvent pas être mises à jour une fois que l'utilisateur a accepté l'invitation.",
  },
  deleteConfirmation: {
    question: 'Êtes-vous sûr de vouloir supprimer {{item}} ?',
    cancelDelete: 'Non, garder {{item}}',
    confirmDelete: 'Oui, supprimer {{item}}',
  },

  formLabels: {
    name: 'Nom',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    civilStatus: 'État civil',
    street: 'Rue',
    houseNumber: 'Numéro',
    postalCode: 'Code postal',
    city: 'Ville',
    country: 'Pays',
    uploadPhoto: 'Télécharger une photo',
    uploadLogo: 'Télécharger un logo',
    submit: 'Envoyer',
    invite: 'Inviter',
    emailAddress: 'Adresse e-mail',
    role: 'Rôle',
    search: 'Rechercher',
    mobilePhone: 'Numéro de portable',
    password: 'Mot de passe',
    oldPassword: 'Ancien mot de passe',
    newPassword: 'Nouveau mot de passe',
    confirmPassword: 'Confirmer le mot de passe',
    minimum6Characters: 'Au moins 6 caractères',
    primaryRepresentative: 'Représentant principal',
    secondaryRepresentative: 'Représentant secondaire',
    howManyChildren: "Combien d'enfants avez-vous ?",
    date_of_birth: 'Date de naissance',
    rep_inform_date: "Date d'information du représentant",
    trusted_person: 'Personne de confiance',
    message: 'Message',
    location: 'Emplacement',
    domain: 'Domaine',
    addWidget: 'Ajouter un widget',
    plusWidget: '+ Widget',
    organisation_name: "Nom de l'organisation",
    primary_color: 'Couleur primaire',
    secondary_color: 'Couleur secondaire',
    sendAutomatically:
      'Envoyer automatiquement lorsque je mets à jour la directive anticipée',
    deathReport: 'Rapport de décès',
    executorCertificate: "Certificat d'exécuteur",
    trustees_list: {
      husband: 'Mari',
      wife: 'Femme',
      partner: 'Partenaire',
      'close-friend': 'Ami proche',
      brother: 'Frère',
      sister: 'Sœur',
      brother_in_law: 'Beau-frère',
    },
    beneficiarieCategories: {
      selectCategory: 'Sélectionner une catégorie',
      individual: 'Personne physique',
      company: 'Entreprise',
      foundation: 'Fondation',
      association: 'Association',
    },
    ordinalNumbers: {
      first: 'premier',
      second: 'deuxième',
      third: 'troisième',
      fourth: 'quatrième',
      fifth: 'cinquième',
      sixth: 'sixième',
      seventh: 'septième',
      eighth: 'huitième',
      ninth: 'neuvième',
      tenth: 'dixième',
      information: 'Informations sur le {{ordinalNumber}} enfant',
    },
  },
  userSettings: {
    general: 'Général',
    billing: 'Facturation',
    my_family: 'Ma famille',
    successors: 'Successeurs',
    notifications: 'Notifications',
    changePassword: 'Changer le mot de passe',
    changeReminder:
      "Modifiez la fréquence des rappels en cas d'interruption d'un processus",
    reminder: 'Rappel',
    reminderFrequency: 'Fréquence des rappels',
    frequencyChoices: {
      weekly: 'Hebdomadaire',
      monthly: 'Mensuel',
      quarterly: 'Trimestriel',
      disabled: 'Désactiver',
    },
    successor: {
      title: 'Définir les successeurs',
      description:
        'Pour vous assurer que vos documents et informations sont disponibles en cas de décès, vous devez vous assurer que au moins deux personnes ont accès à bien organisé.',
      pleaseConfirm:
        "Veuillez confirmer que les personnes suivantes auront accès en tant qu'invités à partir d'aujourd'hui (avec accès limité - voir ce qui est inclus) et auront un accès illimité à toutes les données en cas de votre décès.",
      agree: "Oui, je suis d'accord",
      addSuccessor: 'Ajouter des successeurs',
    },
  },

  adminSettings: {
    general: 'Général',
    team: 'Équipe',
    consulting: 'Consultation',
    changePassword: 'Changer de mot de passe',
  },

  team: {
    tableHeads: {
      name: 'Nom',
      email: 'E-mail',
      role: 'Rôle',
      last_login: 'Dernière connexion',
    },
    resendInvitation: "Renvoyer l'invitation",
    cancelInvitation: "Annuler l'invitation",
    consultant: 'Consultant',
    consultantDescription: 'Accès au cockpit de consultant de bien organisé.',
    admin: 'Admin',
    adminDescription:
      "Gestion du cockpit de bien organisé, des personnes et d'autres paramètres.",
  },

  footer: {
    allRightsReserved: 'Tous droits réservés',
    provisionLinks: {
      title: 'Dispositions',
      pricing: 'Tarifs',
      livingWill: 'Directive anticipée',
      powerOfAttorney: 'Procuration',
      lastWill: 'Dernière volonté',
    },
    resourcesLinks: {
      title: 'Ressources',
      imprint: 'Mentions légales',
      termsAndConditions: "Conditions d'utilisation",
      privacyPolicy: 'Politique de confidentialité',
      contact: 'Contact',
    },
    contact: 'Contact',
  },

  contactUs: {
    getLegalSupport: 'Obtenez un soutien juridique',
    description:
      'Gut geregelt offre des conseils juridiques directs et personnalisés. Si vous le souhaitez, utilisez simplement notre formulaire de contact :',
    pleaseWriteMessage: 'Veuillez nous écrire un message',
  },

  civil_statusWarning: {
    title: 'Êtes-vous sûr de vouloir mettre à jour votre état civil ?',
    description:
      'Si vous modifiez votre état civil, toutes vos données testamentaires seront effacées, et vous devrez recommencer depuis le début.',
    cancel: 'Annuler',
    update: 'Mettre à jour',
  },

  hyperlinks: {
    lastWill: 'Testament',
    livingWill: 'Directive anticipée',
    powerOfAttorney: 'Mandat de prévoyance',
  },
  signedDoc: {
    placeholder: {
      headingText: 'Télécharger après signature',
      currentlySigned: 'Actuellement {{grammarFix}} {{docType}}',
      grammarFix: {
        livingWill: 'directive signée et active',
        powerOfAttorney: 'mandat signé et actif',
        lastWill: 'testament signé et actif',
      },
      doctype: {
        lastWill: 'Testament',
        livingWill: 'Directive anticipée',
        powerOfAttorney: 'Mandat de prévoyance',
      },
      helper: {
        dropFilesHere: 'Déposez le fichier ici ou parcourez votre appareil',
        browse: 'parcourir',
        thoroughYourDevice: 'sur votre appareil',
      },
    },
    reUpload: 'Glisser-déposer ou cliquer pour recharger',
    updatedAt: 'Mis à jour le {{date}}',
  },

  shareDocument: {
    button: 'Partager avec le médecin',
    title: 'Partagez-le avec votre médecin traitant',
    description:
      'Nous recommandons de partager la version signée de votre directive anticipée avec votre médecin traitant.',
    doAutomatically:
      'Automatiquement, lorsque je mets à jour la directive anticipée',
  },

  finfinder: {
    title: 'Choisissez votre conseiller financier sur FinFinder',
    button: 'Prendre rendez-vous pour une première consultation gratuite',
  },

  consulting: {
    title: 'Consultation',
    description: 'Aidez vos clients à préparer leur retraite.',
    dashCardInfos: {
      livingWill: {
        title: 'Directive anticipée',
        description:
          "Règle les traitements médicaux auxquels le client consent ou qu'il refuse, lorsqu'il ne peut plus décider par lui-même.",
      },
      powerOfAttorney: {
        title: 'Mandat de prévoyance',
        description:
          'Définit qui représente légalement le client en cas de pire scénario, assume ses droits et obligations envers la famille, et gère ses affaires financières.',
      },
      lastWill: {
        title: 'Testament',
        description:
          'Définit les souhaits du client concernant la transmission de ses biens.',
      },
      ageAppropriate: {
        title: "Investissement approprié à l'âge dans le 3a",
        description:
          "Vérifiez dans quelle mesure votre client investit ses actifs 3a de manière appropriée à l'âge et prenez les mesures appropriées si nécessaire.",
      },
      ahvCalculator: {
        title: 'Calculateur AVS',
        description:
          "Le calculateur AVS est une estimation de retraite basée sur une méthode de calcul simplifiée qui n'est pas contraignante.",
      },
      lifeExpectancy: {
        title: 'Espérance de vie',
        description:
          "Calcule l'espérance de vie théorique du client selon des tables actuarielles.",
      },
      getStarted: 'Commencer',
      unlock: 'Déverrouiller',
      createUser: 'Créer un utilisateur',
      createUserDescription:
        'Créez un utilisateur pour votre client qui pourra continuer à utiliser indépendamment de vous pour consulter des documents et apporter des modifications.',
    },
    pageTitle: 'Créer un login client',
    createUserForm: {
      description:
        'Votre client recevra un courriel séparé avec les informations de connexion. Demandez à votre client de changer le mot de passe communiqué lors de la première connexion.',
    },
  },
  consultantDashboard: {
    pageName: 'Tableau de bord du consultant',
    title: 'Tableau de bord',
    description:
      'Obtenez un aperçu de votre portefeuille client et de son état en matière de prévoyance retraite avec bien organisé.',
  },
};

export default fr;
