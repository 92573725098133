const grosseItern = {
  mainBanner: {
    title: 'Simply a good feeling well regulated provision',
    subtitle:
      'A difficult topic finally becomes simple: create a living will, advance care directive and will from the comfort of your own home - in just 20 minutes. Get started now with the Swiss provider gut geregelt.',
    buttonLabel: 'Get started',
    sale: {
      blueText: 'Save 50%',
      blackText: 'until 30.09.2024',
    },
  },
  aboutCards: {
    justDoIt: {
      title: 'Just do it before something happens',
      description:
        'The hope is never to have to make use of the living will and the advance care directive. However, strokes of fate do happen, which is why it is worth drawing them up at an early stage.',
    },
    security: {
      title: 'Security for spouse and family',
      description:
        'Drawing up these documents in good time allows you to maintain control over your wishes. At the same time, you can protect your family and avoid unnecessary stress.',
    },
    done: {
      title: 'Done in 20 minutes - from home',
      description:
        'With gut geregelt, you can create legally compliant documents in easy-to-understand language from the comfort of your own home. Legal experts are on hand to help you with more complex cases.',
    },
    swissProvider: {
      title: 'Swiss provider: Well regulated provision',
      description:
        'gut geregelt is your digital Swiss pension suite. Do yourself and your loved ones a favour and make sure you are well taken care of today.',
    },
  },
  angebot: {
    phrase:
      'Known from the media and the Grandparents Magazine (issue 30/2024)',
    highlightedPhrase: 'Grandparents Magazine',
  },
};

export default grosseItern;
