import { Box, Stack, Typography, Link, Button } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link as ScrollTo, scroller } from 'react-scroll';
import { useEffect } from 'react';
import { footerLinks } from '#/components/pages/Landing/landingData';
import useLocales from '#/hooks/useLocales';

const contactInfo = {
  title: 'contact',
  email: 'info@gutgeregelt.ch',
  address1: 'gut geregelt AG',
  address2: 'Gewerbestrasse 12',
  address3: 'CH-4450 Sissach',
  contact: '/kontakt',
};

const Footer = () => {
  const { translate } = useLocales();
  const { pathname, state } = useLocation();

  useEffect(() => {
    if (state?.pricing) {
      scroller.scrollTo('pricing', {
        smooth: true,
      });
    }
  }, [state]);

  return (
    <Box
      sx={{
        backgroundColor: '#F8F8F8',
        py: {
          xs: 5,
          md: 10,
        },
        px: {
          xs: 2,
          md: 15,
        },
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        justifyContent: 'center',
        gap: {
          xs: 5,
          md: 10,
        },
      }}
    >
      {footerLinks.map((link, i) => (
        <Stack
          key={i}
          spacing={{
            xs: 1,
            md: 4,
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
            pb: '50px',
          }}
        >
          <Typography variant="subtitle2" textTransform="uppercase">
            {String(translate(`global.footer.${link.i18nKey}.title`))}
          </Typography>
          <Stack spacing={1}>
            {link.links.map((item, i) => (
              <Link
                component={RouterLink}
                to={item.link}
                key={i}
                color="inherit"
                sx={{
                  textDecoration: 'none',
                }}
                state={
                  item.title === 'Pricing' && pathname !== '/main'
                    ? {
                        pricing: true,
                      }
                    : null
                }
              >
                <Button
                  variant="text"
                  color="inherit"
                  sx={{ textTransform: 'none' }}
                >
                  {item.title === 'Pricing' && pathname === '/main' ? (
                    <ScrollTo to={item.title} smooth duration={500}>
                      <Typography variant="body1">
                        {String(
                          translate(
                            `global.footer.${link.i18nKey}.${item.i18nKey}`
                          )
                        )}
                      </Typography>
                    </ScrollTo>
                  ) : (
                    <Typography variant="body1">
                      {String(
                        translate(
                          `global.footer.${link.i18nKey}.${item.i18nKey}`
                        )
                      )}
                    </Typography>
                  )}
                </Button>
              </Link>
            ))}
          </Stack>
        </Stack>
      ))}
      <Stack
        spacing={{
          xs: 1,
          md: 4,
        }}
        sx={{
          textAlign: {
            xs: 'center',
            md: 'left',
          },
        }}
      >
        <Typography variant="subtitle2" textTransform="uppercase">
          {String(translate(`global.footer.${contactInfo.title}`))}
        </Typography>
        <Stack spacing={1}>
          <Typography variant="body1">{contactInfo.email}</Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {contactInfo.address1}
          </Typography>
          <Typography variant="body1">{contactInfo.address2}</Typography>
          <Typography variant="body1">{contactInfo.address3}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer;
