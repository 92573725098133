import { IQuestion } from '#/types/globalTypes';

export const financialInformationQuestions: IQuestion[] = [
  {
    question: 'Bankdokumente',
  },
  {
    question: 'Versicherungspolicen',
  },
  {
    question: 'Staatliche Rente (AHV)',
  },
  {
    question: 'Pensionskasse',
  },
  {
    question: '3a',
  },
];

export const trustedPersonQuestions: IQuestion[] = [
  {
    question: 'Willensvollstrecker',
  },
  {
    question: 'Notar/Treuhänder',
  },
];
