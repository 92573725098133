function path(root: string, sublink: string): string {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  // register: path(ROOTS_AUTH, '/register'),
  logout: path(ROOTS_AUTH, '/logout'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-passwort'),
  passwordReset: (uidb64: string, token: string): string =>
    path(ROOTS_AUTH, `/passwort-reset-bestaetigen/${uidb64}/${token}`),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, '/home'),
  contact: path(ROOTS_DASHBOARD, '/kontakt'),
  livingWill: path(ROOTS_DASHBOARD, '/living-will'),
  powerOfAttorney: path(ROOTS_DASHBOARD, '/power-of-attorney'),
  lastWill: path(ROOTS_DASHBOARD, '/last-will'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  provisionFinal: path(ROOTS_DASHBOARD, '/provision-final'),
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
  maintenance: '/maintenance',
  comingSoon: '/coming-soon',
  contact: '/kontakt',
  pensionProvision: '/vorsorge',
  imprint: '/imprint',
  privacyPolicy: '/privacy-policy',
  termsConditions: '/terms-conditions',
  agb: '/agb',
  acceptInvitation: (token: string): string =>
    path(`/successor/accept-invitation`, token),
};
