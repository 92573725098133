import { IQuestion } from '#/types/globalTypes';

export const contractParties: IQuestion[] = [
  {
    question: 'Ihr Ehepartner',
  },
  {
    question: 'Sie',
  },
];

export const marriageQuestions: IQuestion[] = [
  {
    question: 'Wann haben Sie geheiratet?',
  },
  {
    question: 'Haben Sie Kinder?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  // {
  //   question: 'Wie viele?',
  // },
];

export const estateQuestions: IQuestion[] = [
  {
    question:
      'Welche Vermögenswerte haben Sie und Ihr Ehepartner bereits vor der Ehe besessen (z.B. Erbschaften, Gegenstände mit hohem emotionalen Wert, etc.)?',
  },
  {
    question:
      'Ist es korrekt, dass Sie alle anderen Vermögenswerte (nicht aufgeführt) nach der Ehe erworben haben?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question: 'Haben Sie nach der Ehe Immobilien-Eigentum erworben?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question:
      'Stimmen Sie zu, dass Sie hiermit den ordentlichen Güterstand der Errungeschaftsbeiteiligung beibehalten?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question:
      'Die Ehegatten bestätigen, dass der überlebende Ehegatte sein Eigengut (z. B. vor Eheschliessung erworbenes Auto oder Schenkung einer Tante) erhält und hinsichtlich des verbleibenden Nachlasses die rechtlich bestmögliche Wahl hat.',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question:
      'Ich möchte, dass mein Ehepartner das Recht hat, frei über einen bestimmten Teil des Nachlasses zu verfügen.',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  // {
  //   question:
  //     'Wer soll im Falle des gleichzeitigen Todes beider Ehegatten oder des Todes des letzten verbliebenen Ehegatten der Testamentsvollstrecker sein?',
  // },
  // {
  //   question:
  //     'Wenn die von Ihnen gewählte Person nicht als Testamentsvollstrecker fungieren kann, wird folgende Person ernannt:',
  // },
];
