const partnerWithUs = {
  mainBanner: {
    title: 'Offer access to digital pension solutions',
    subtitle:
      'Pension funds, banks, insurance companies, associations and media partners work together with gut geregelt to offer people easy access to comprehensive pension provision.',
    buttonLabel: 'Talk to us',
  },
  youtubeTutorial: 'Why partner with us?',
  aboutCards: {
    differentiate: {
      title: 'Differentiate yourself',
      description:
        'Generate more traffic for your platform with additional services and relevant content.',
    },
    efficiency: {
      title: 'More efficiency',
      description:
        'With additional services, you can easily generate more revenue and reduce costs at the same time.',
    },
    simplyIntegrate: {
      title: 'Simply integrate',
      description:
        'Ready for use with the simple standalone cloud solution in your design.',
    },
  },
  subBanner: {
    title: 'In self-service or as a professional consulting solution',
    subtitle:
      'gut geregelt can be used as a whitelabel self-service solution for end customers or as a consulting solution to support your consulting process. An API integration into an existing solution is also possible.',
  },
  talkWithUs: {
    title: 'Help people to live a well-regulated life',
    description:
      'Add to your range of services and make sensible pension services available to people that simplify their lives and have a positive impact on their families.',
    talkAboutPossibilities: "Let's talk about possibilities",
    iam: 'I am a',
    partnerOccupations: {
      bank: 'Bank',
      insurance: 'Insurance',
      pensionFund: 'Pension fund',
      association: 'Association',
      media: 'Media partner',
      trust_foundation: 'Trust foundation',
      other: 'Other',
    },
  },
  solutions: {
    pensionFund: {
      title: 'Pension fund',
      content:
        'We have been trying for some time to increase traffic to our pensions portal. However, there are few good reasons beyond the annual insurance statements. With gut geregelt, we expand the options for pension planning and increase traffic with an innovative offer at the same time.',
    },
    bank: {
      title: 'Bank',
      content:
        'We have been trying for some time to increase traffic to our pensions portal. However, there are few good reasons beyond the annual insurance statements. With gut geregelt, we expand the options for pension planning and increase traffic with an innovative offer at the same time.',
    },
    fintechPlatform: {
      title: 'Fintech platform',
      content:
        'As a solution, we are already very close to the customer when it comes to financial provision. Supplementing our offering with extended legally compliant pension solutions is a logical next step in making comprehensive pension provision available to customers.',
    },
  },
  faq: {
    questions: {
      question1: 'What benefits does gut geregelt offer to my customers?',
      question2: 'Where are the data located?',
      question3:
        'Is well-regulated compliant with Swiss data protection regulations (DSG) and EU General Data Protection Regulation (EU-DSGVO)?',
      question4: 'Can I use gut geregelt as a consulting solution?',
      question5: 'Is gut geregelt available as a white-label solution?',
      question6: 'How can I integrate gut geregelt into my company?',
      question7: 'Which languages are supported?',
      question8: 'Can I involve partner organizations as well?',
      question9:
        'What advantages does gut geregelt offer me as a foundation board member?',
      question10: 'Can I request additional features?',
      question11: 'What is the commercial model?',
    },
    answers: {
      answer1:
        'With gut geregelt, people gain simple access to digital precautionary services with legal certainty for the first time. For many, we support taking the very first steps towards a well-regulated life. This helps them and their families to have more control and less stress in case of emergencies.',
      answer2:
        'The data is stored in Switzerland. For partner organizations in Germany and Austria, we work on an EU compliance zone.',
      answer3:
        'Yes. The requirements of DSG and EU-DSGVO served as design requirements during the implementation of gut geregelt as software and thus form the basis of the product architecture.',
      answer4:
        'Yes. In addition to the self-service solution for end customers, gut geregelt offers a consultant cockpit. This allows processes in financial consulting or pension planning to be supported and expanded by the digital gut geregelt suite. This enables your consultants to develop retirement solutions together with customers, giving the customer the opportunity to make adjustments or additions independently at a later time.',
      answer5:
        'Yes. gut geregelt is a dedicated white-label solution for organizations such as banks, insurance companies, pension funds, and other pension institutions, as well as financial institutions and associations. The solution can be integrated in different ways.',
      answer6:
        'Various scenarios are possible. The simplest integration is through a direct link to gut geregelt. However, we also offer integrations via single sign-on (SSO) and APIs. Integration as an iFrame is also conceivable.',
      answer7: 'gut geregelt supports German, English, French, and Italian.',
      answer8:
        'gut geregelt has different partner models and offers you the opportunity to list notaries and lawyers as well as financial and asset advisors as experts.',
      answer9:
        "With gut geregelt, I can actively minimize my liability risk as a foundation board member by receiving a tool to increase traffic on the pension institution's portal, actively encouraging policyholders to take precautionary measures, and increasingly creating transparency and efficiency.",
      answer10:
        'Yes. At gut geregelt, we pay close attention to what our customers and partners say. We review and prioritize wishes and requirements, taking into account everyone, focusing on features that provide added value to the majority of our customers and partners.',
      answer11:
        'Partners pay an annual fee for the gut geregelt solution. The partner receives a kickback from each transaction by end customers. However, there are also partners who partially or completely cover the costs for their end customers and offer the service at a discounted rate.',
    },
  },
};

export default partnerWithUs;
