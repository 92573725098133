import outputDoc from './outputDoc';
import powerOfTrustQuestions from './questions';

const powerOfAttorney = {
  mainHeaders: {
    shortPainFree: 'Mandat de prévoyance: court et sans douleur',
    madeIt: 'Tu l as fait!',
  },

  stepperInfo: [
    {
      label: 'Mandat de confiance',
      title: 'De quoi s agit-il?',
    },
    {
      label: 'Votre mandat de prévoyance',
      title: 'Que faire maintenant?',
    },
  ],

  stepOne: {
    introParagraph:
      'Malheureusement, il y a des situations qui peuvent nous rendre incapables de juger. Des exemples de cela sont les accidents graves ou les maladies comme la démence',
    helperParagraph:
      'Nous vous recommandons donc de régler à l avance qui devrait prendre soin de vous et vous représenter légalement dans une telle situation à l aide d une soi-disant directive anticipée. Cela soulage vos proches dans une situation déjà difficile, car vos souhaits sont clairement formulés.',
    helperParagraph2:
      'Commençons: cette section ne prend que 5 minutes environ. Si vous avez besoin d une pause, vous pouvez arrêter et sauvegarder à tout moment.',
    questions: powerOfTrustQuestions,
  },

  stepTwo: {
    introParagraph: 'Bien joué et surtout bien réglé!',
    helperParagraph:
      'L étape la plus difficile est maintenant terminée. Téléchargez le document, imprimez-le et lisez-le attentivement pour voir s il correspond vraiment à vos souhaits. Si vous êtes satisfait, nous vous recommandons de discuter du mandat de prévoyance avec une personne de confiance et votre famille.',
    helperParagraph2:
      'Il est recommandé de déposer le mandat de prévoyance chez un avocat ou un notaire, qui peut enregistrer l emplacement de stockage à votre demande dans le registre suisse des testaments (https://www.ztr.ch).',
    contactForm: 'Formulaire de contact',
    helperParagraph3:
      'Si vous souhaitez que nous vous aidions, veuillez utiliser notre formulaire de contact.',
    obligation1:
      'Le mandat de prévoyance doit être établi de sa propre main ou être notarié. Le mandat de prévoyance écrit de sa propre main doit être écrit, daté et signé par la personne qui donne l ordre du début à la fin.',
    obligation2:
      'Si vous n êtes pas sûr, un avocat ou un notaire peut vous aider.',
    document: outputDoc,
  },
};

export default powerOfAttorney;
