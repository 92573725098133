const privacyPolicy = {
  title: 'Datenschutzerklärung',

  childrenUnderAge: {
    title: 'Kinder unter 18 Jahren',
    content: {
      p1: 'Unsere Website richtet sich nicht an Kinder unter 18 Jahren. Personen unter 18 Jahren dürfen keine Informationen auf oder über die Website bereitstellen. Wir sammeln wissentlich keine personenbezogenen Informationen von Kindern unter 18 Jahren. Wenn Sie unter 18 Jahren sind, nutzen Sie diese Website nicht und geben Sie keine Informationen auf dieser Website oder über ihre Funktionen an. Wenn wir feststellen, dass wir ohne Überprüfung der elterlichen Zustimmung personenbezogene Informationen von einem Kind unter 18 Jahren gesammelt oder erhalten haben, werden wir diese Informationen löschen. Wenn Sie glauben, dass wir Informationen von oder über ein Kind unter 18 Jahren haben könnten, kontaktieren Sie uns bitte unter support@gutgeregelt.ch.',
    },
  },
  informationWeCollect: {
    title: 'Informationen, die wir sammeln und wie wir sie sammeln',
    content: {
      p1: 'Wir sammeln verschiedene Arten von Informationen von Nutzern unserer Website, einschliesslich Informationen:',
      p2: '• Anhand derer Sie persönlich identifiziert werden können, wie Ihr Name oder Ihre E-Mail-Adresse oder ein anderer Identifikator, über den Sie online oder offline kontaktiert werden können ("personenbezogene Daten");',
      p3: '• Die über Sie, jedoch nicht einzeln identifizierbar sind; und/oder',
      p4: '• Über Ihre Internetverbindung, die Geräte, die Sie zur Nutzung unserer Website verwenden, und Details zu Ihrer Nutzung.',
      p5: 'Wir sammeln diese Informationen:',
      p6: '• Direkt von Ihnen, wenn Sie uns diese Informationen zur Verfügung stellen.',
      p7: '• Automatisch während Sie durch die Website navigieren. Automatisch gesammelte Informationen können Nutzungsdetails, IP-Adressen und über Cookies gesammelte Informationen umfassen.',
      p8: '• Von Dritten, zum Beispiel von unseren Geschäftspartnern.',
    },
  },
  informationYouProvide: {
    title: 'Von Ihnen bereitgestellte Informationen',
    content: {
      p1: 'Die Informationen, die wir auf oder durch unsere Website sammeln, können Folgendes umfassen:',
      p2: '• Informationen, die Sie durch das Ausfüllen von Formularen auf unserer Website bereitstellen. Dies umfasst Informationen, die Sie bei der Registrierung zur Nutzung unserer Website, beim Abonnieren unseres Dienstes, beim Veröffentlichen von Material oder beim Anfordern weiterer Dienstleistungen angeben. Wir können Sie auch um Informationen bitten, wenn Sie an einem von uns gesponserten Wettbewerb oder einer Promotion teilnehmen und wenn Sie ein Problem mit unserer Website melden.',
      p3: '• Aufzeichnungen und Kopien Ihrer Korrespondenz (einschliesslich E-Mail-Adressen), wenn Sie sich mit uns in Verbindung setzen.',
      p4: '• Ihre Antworten auf Umfragen, die wir möglicherweise zu Forschungszwecken durchführen.',
      p5: '• Ihre Suchanfragen auf der Website.',
      p6: 'Sie können auch Informationen bereitstellen, die in öffentlichen Bereichen der Website veröffentlicht oder angezeigt werden sollen (nachfolgend "veröffentlicht" genannt) und an andere Nutzer der Website oder Dritte übertragen werden (gemeinsam als "Nutzerbeiträge" bezeichnet). Ihre Nutzerbeiträge werden auf der Website veröffentlicht und an andere übertragen, auf eigene Gefahr. Obwohl wir den Zugriff auf bestimmte Seiten einschränken können, sollten Sie sich darüber im Klaren sein, dass keine Sicherheitsmassnahmen perfekt oder undurchdringlich sind. Darüber hinaus können wir die Handlungen anderer Nutzer der Website, mit denen Sie möglicherweise Ihre Nutzerbeiträge teilen, nicht kontrollieren. Daher können wir nicht garantieren, dass Ihre Nutzerbeiträge von unbefugten Personen nicht eingesehen werden.',
    },
  },
  informationWeCollectThroughAutomaticData: {
    title:
      'Informationen, die wir durch automatische Datenerfassungstechnologien sammeln',
    content: {
      p1: 'Während Sie durch unsere Website navigieren und mit ihr interagieren, können wir automatische Datenerfassungstechnologien verwenden, um bestimmte Informationen über Ihre Geräte, Browsing-Aktivitäten und Muster zu sammeln, einschliesslich:',
      p2: '• Details zu Ihren Besuchen auf unserer Website, einschliesslich Verkehrsdaten, Standortdaten, Protokolle und andere Kommunikationsdaten sowie die Ressourcen, auf die Sie auf der Website zugreifen und die Sie nutzen.',
      p3: '• Informationen über Ihren Computer und Ihre Internetverbindung, einschliesslich Ihrer IP-Adresse, Ihres Betriebssystems und des Browsertyps.',
      p4: 'Die von uns automatisch gesammelten Informationen können personenbezogene Daten enthalten. Wir sammeln diese, um unsere Website zu verbessern und einen besseren und personalisierteren Service zu bieten, indem wir beispielsweise:',
      p5: '• Die Grösse unseres Publikums und Nutzungsmuster schätzen.',
      p6: '• Informationen über Ihre Vorlieben speichern, um unsere Website gemäss Ihren individuellen Interessen anzupassen.',
      p7: '• Ihre Suchvorgänge beschleunigen.',
      p8: '• Sie bei Ihrer Rückkehr auf unsere Website erkennen.',
      p9: 'Die von uns für diese automatische Datenerfassung verwendeten Technologien können Folgendes umfassen:',
      p10: '• Cookies (oder Browser-Cookies). Ein Cookie ist eine kleine Datei, die auf der Festplatte Ihres Computers gespeichert wird. Sie können Browser-Cookies ablehnen, indem Sie die entsprechende Einstellung in Ihrem Browser aktivieren. Wenn Sie diese Einstellung auswählen, können Sie möglicherweise nicht auf bestimmte Teile unserer Website zugreifen. Sofern Sie Ihre Browsereinstellungen nicht so angepasst haben, dass Cookies abgelehnt werden, wird unser System Cookies ausgeben, wenn Sie Ihren Browser auf unsere Website lenken.',
      p11: '• Flash-Cookies. Bestimmte Funktionen unserer Website können lokale Speicherobjekte (oder Flash-Cookies) verwenden, um Informationen über Ihre Vorlieben und Navigation auf, von und auf unserer Website zu sammeln und zu speichern. Flash-Cookies werden nicht über die gleichen Browsereinstellungen verwaltet wie Browser-Cookies.',
    },
  },

  informationVoluntarily: {
    title:
      'Von Ihnen freiwillig bereitgestellte Informationen für die Nutzung von Dienstleistungen',
    content: {
      p1: 'Wir sammeln Informationen, die Sie uns freiwillig zur Verfügung stellen, um eine Anfrage zu erfüllen, z. B. wenn Sie sich anmelden, um unsere Dienste und Website zu nutzen, Unterstützung von unserem Support-Team anfordern, uns per E-Mail kontaktieren, ein Kontaktformular ausfüllen, unsere Dienste nutzen möchten oder auf andere Weise mit uns kommunizieren. Diese Informationen umfassen Ihren Vor- und Nachnamen, den Namen Ihres Unternehmens, die physische Adresse, die E-Mail-Adresse und bestimmte Arten von Geschäftsinformationen Ihres Unternehmens, wie Kundenzahlungsinformationen, Umsatz, Website-Analyseinformationen, Kundenabwanderung, Kundenwert während der Lebensdauer und andere Metriken und Daten sowie alle anderen Informationen, die Sie uns bezüglich Ihres Unternehmens zur Verfügung stellen.',
      p2: 'Besonders nach Ihrer Anmeldung zur Nutzung unserer Dienste als Start-up, wenn Sie Daten von Ihren Konten mit Tools und Anwendungen von Drittanbietern wie Stripe, Google Analytics, ChartMogul, Baremetrics und ProfitWell auf Ihrer Informationsseite für potenzielle Käufer anzeigen möchten, indem Sie sich auf der Autorisierungsseite in Ihr Konto bei solchen Tools und Anwendungen von Drittanbietern einloggen oder uns Ihre API-Schlüssel oder Ansichts-IDs auf solchen Tools und Anwendungen von Drittanbietern zur Verfügung stellen, autorisieren Sie uns und gewähren uns Lesezugriff auf spezifische Informationen in Ihren Konten bei solchen Drittanbietern. Unten sind die Informationen aufgeführt, die wir mit Ihrer Autorisierung von solchen Dritten erfassen können:',
      p3: '• Stripe – Schreibgeschützter Zugriff auf Kundenerlösdaten für die letzten 12 Monate',
      p4: '• Google Analytics – Schreibgeschützter Zugriff auf Website-Traffic-Daten für die letzten 12 Monate',
    },
  },
  useOfCookies: {
    title: 'Verwendung von Cookies durch Dritte',
    content: {
      p1: 'Einige Inhalte oder Anwendungen auf der Website werden von Dritten bereitgestellt, wie z. B. Inhaltsanbieter und Anbieter von Anwendungen wie Google Analytics und Hubspot, wie oben erwähnt. Diese Dritten verwenden Cookies, um Informationen über Sie zu sammeln, wenn Sie unsere Website nutzen. Die von ihnen gesammelten Informationen können mit Ihren persönlichen Informationen verknüpft werden oder sie können Informationen, einschliesslich personenbezogener Informationen, über Ihre Online-Aktivitäten im Laufe der Zeit und über verschiedene Websites und andere Online-Dienste hinweg sammeln.',
    },
  },
  howWeUseYourInformation: {
    title: 'Wie wir Ihre Informationen verwenden',
    content: {
      p1: 'Wir verwenden Informationen, die wir über Sie sammeln oder die Sie uns zur Verfügung stellen, einschliesslich aller personenbezogenen Informationen:',
      p2: '• Um Ihnen unsere Website und ihre Inhalte zu präsentieren.',
      p3: '• Um die Auflistung Ihres Käufer-, Verkäufer- oder Beraterprofils auf der Website zu erleichtern.',
      p4: '• Um Ihnen Informationen, Produkte oder Dienstleistungen bereitzustellen, die Sie von uns anfordern, einschliesslich aber nicht beschränkt auf (für Startup-Benutzer) die Anzeige von autorisierten Geschäftsinformationen von Start-ups für bestimmte potenzielle Käufer.',
      p5: '• Um jeden anderen Zweck zu erfüllen, für den Sie sie bereitgestellt haben.',
      p6: '• Um Ihnen Mitteilungen über Ihr Konto zuzusenden, einschliesslich Ablauf- und Erneuerungshinweisen.',
      p7: '• Um unsere Verpflichtungen aus Verträgen, die zwischen Ihnen und uns abgeschlossen wurden, durchzuführen und unsere Rechte durchzusetzen, einschliesslich Abrechnung und Inkasso.',
      p8: '• Um Sie über Änderungen unserer Website oder der von uns angebotenen Produkte oder Dienstleistungen zu informieren.',
      p9: '• Um Ihnen die Teilnahme an interaktiven Funktionen auf unserer Website zu ermöglichen.',
      p10: '• Auf jede andere Art und Weise, die wir beschreiben können, wenn Sie die Informationen bereitstellen.',
      p11: '• Für jeden anderen Zweck mit Ihrer Zustimmung.',
      p12: 'Mit Ihrer Zustimmung werden wir Ihre Informationen verwenden, um Sie über unsere eigenen Waren und Dienstleistungen sowie über Waren und Dienstleistungen von Drittanbietern zu informieren, die für Sie von Interesse sein könnten. Wenn Sie nicht möchten, dass wir Ihre Informationen auf diese Weise verwenden, passen Sie bitte Ihre Benutzereinstellungen in Ihrem Kontoprofil an. Weitere Informationen finden Sie unter "Ihre Rechte und Kontrollen in Bezug auf Informationen" unten.',
    },
  },
  saleOfPersonalInformation: {
    title: 'Verkauf von persönlichen Informationen',
    content: {
      p1: 'Bitte beachten Sie, dass wir Ihre persönlichen Informationen nicht an Dritte verkaufen.',
    },
  },

  disclosureOfYourInformation: {
    title: 'Offenlegung Ihrer Informationen',
    content: {
      p1: 'Wir können aggregierte Informationen über unsere Nutzer offenlegen sowie Informationen, die keine individuelle Identifizierung ermöglichen oder anderweitig ausserhalb der Definition von "Persönliche Informationen," "Personenbezogene Daten" oder einer ähnlichen Definition gemäss einer Verordnung fallen, ohne Einschränkung.',
      p2: 'Wir können personenbezogene Informationen offenlegen, die wir sammeln oder die Sie gemäss dieser Datenschutzrichtlinie bereitstellen:',
      p3: '• An unsere Tochtergesellschaften und verbundenen Unternehmen, um uns die Bereitstellung unserer Dienstleistungen zu ermöglichen.',
      p4: '• An Auftragnehmer, Dienstleister und andere Dritte, die wir zur Unterstützung unseres Geschäfts nutzen und die vertraglich verpflichtet sind, personenbezogene Informationen vertraulich zu behandeln und nur für die Zwecke zu verwenden, für die wir sie offenlegen.',
      p5: '• An einen Käufer oder einen anderen Rechtsnachfolger im Falle einer Fusion, Aufspaltung, Umstrukturierung, Neugestaltung, Auflösung oder eines anderen Verkaufs oder Transfers von Teilen oder des gesamten Unternehmensvermögens, sei es im laufenden Geschäftsbetrieb oder im Rahmen eines Insolvenz-, Liquidations- oder ähnlichen Verfahrens, bei dem personenbezogene Daten, die das Unternehmen über Nutzer unserer Website hat, zu den übertragenen Vermögenswerten gehören.',
      p6: '• Um den Zweck zu erfüllen, für den Sie die Informationen bereitstellen.',
      p7: '• Für jeden anderen Zweck, den wir angeben, wenn Sie die Informationen bereitstellen.',
      p8: '• Mit Ihrer Zustimmung.',
      p9: 'Wir können auch Ihre personenbezogenen Informationen offenlegen:',
      p10: '• Um einer gerichtlichen Anordnung, einem Gesetz oder rechtlichen Verfahren nachzukommen, einschliesslich der Beantwortung von Anfragen von Regierungsbehörden oder Aufsichtsbehörden.',
      p11: '• Um unsere Nutzungsbedingungen und andere Vereinbarungen durchzusetzen oder anzuwenden, einschliesslich Abrechnungs- und Inkassozwecken.',
      p12: '• Wenn wir der Meinung sind, dass eine Offenlegung notwendig oder angemessen ist, um die Rechte, das Eigentum oder die Sicherheit des Unternehmens, unserer Kunden oder anderer Personen zu schützen. Dies umfasst den Austausch von Informationen mit anderen Unternehmen und Organisationen zum Zwecke des Schutzes vor Betrug und der Reduzierung von Kreditrisiken.',
    },
  },
  informationRights: {
    title: 'Ihre Informationsrechte und Kontrollen',
    content: {
      p1: 'Die Datenschutz-Grundverordnung ("DSGVO") gewährt Einzelpersonen Rechte über ihre personenbezogenen Informationen. Während die folgenden Rechte spezifisch für Mitglieder des Europäischen Wirtschaftsraums (EWR) sind, bemühen wir uns, allen unseren Nutzern dieselben Rechte wie den Mitgliedern der EWR gemäss der DSGVO zu gewähren, wann immer möglich.',
      p2: 'Bitte machen Sie sich mit Ihren Rechten und deren Beschreibungen in der folgenden Tabelle vertraut.',
      p3: 'Es ist Ihr Recht:',
      p4: 'Zugang: Informieren Sie sich über die personenbezogenen Daten, die wir über Sie verarbeiten, und beantragen Sie den Zugang dazu.',
      p5: 'Berichtigung: Fordern Sie uns auf, ungenaue oder unvollständige Daten über Sie zu aktualisieren oder zu korrigieren.',
      p6: 'Löschung: Fordern Sie uns auf, Ihre Daten zu löschen.',
      p7: 'Einschränkung der Verarbeitung: Bitten Sie uns, die Verarbeitung Ihrer personenbezogenen Daten einzuschränken.',
      p8: 'Datenübertragbarkeit: Fordern Sie uns auf, Ihnen oder einem Dritten eine Kopie Ihrer personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format bereitzustellen.',
      p9: 'Widerspruch: Legen Sie Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten ein.',
      p10: 'Widerspruch gegen Marketing: Legen Sie Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten zu Marketingzwecken ein.',
      p11: 'Keine automatisierte Entscheidungsfindung: Seien Sie nicht Gegenstand einer Entscheidung, die ausschliesslich auf automatisierter Entscheidungsfindung beruht (Entscheidungen ohne menschliche Beteiligung), einschliesslich Profilbildung, wenn die Entscheidung rechtliche Auswirkungen auf Sie oder eine ähnlich signifikante Auswirkung auf Sie haben würde.',
    },
  },
  exerciseRights: {
    title: 'Wie Sie Ihre Rechte bei uns ausüben können',
    content: {
      p1: 'Zugang: Um eine verifizierbare Anfrage nach Informationen über die personenbezogenen Daten, die wir über Sie gesammelt haben, zu stellen, senden Sie bitte eine E-Mail an support@gutgeregelt.ch. Bitte beachten Sie, dass nahezu alle von uns von Ihnen gesammelten Informationen in Ihrem Kontoprofil auf der Plattform enthalten sind.',
      p2: 'Berichtigung: Sie können Ihre persönlichen Informationen in Ihrem Kontoprofil auf unserer Plattform bearbeiten.',
      p3: 'Löschung: Sie können dieses Recht ausüben, indem Sie eine E-Mail an support@gutgeregelt.ch senden. Bitte beachten Sie, dass wir Ihre personenbezogenen Daten löschen werden:',
      p4: '• Wir können Ihre personenbezogenen Daten so lange aufbewahren, wie es für unsere berechtigten Geschäftsinteressen erforderlich ist, wie z. B. zur Verhinderung von Geldwäsche, Erkennung und Verhinderung von Betrug und Erhöhung der Sicherheit. Wenn wir beispielsweise ein gut geregelt-Konto aus Gründen des Betrugs oder der Sicherheit sperren, können wir Informationen aus diesem gut geregelt-Konto speichern, um zu verhindern, dass die Person in Zukunft ein neues gut geregelt-Konto eröffnet.',
      p5: '• Wir können Ihre personenbezogenen Daten in dem Umfang aufbewahren und verwenden, wie es zur Erfüllung unserer gesetzlichen Verpflichtungen erforderlich ist. Gut geregelt kann Ihre Informationen beispielsweise aus steuerlichen, rechtlichen Berichts- und Prüfungspflichten oder als Aufzeichnung über eine Auseinandersetzung mit Ihnen aufbewahren.',
      p6: '• Einige Kopien Ihrer Informationen (z. B. E-Mails) können in unserer Datenbank verbleiben, werden jedoch von persönlichen Identifikatoren getrennt und nur zu Dokumentationszwecken aufbewahrt.',
      p7: 'Einschränkung: Sie können dieses Recht ausüben, indem Sie eine E-Mail an support@gutgeregelt.ch senden oder die Nutzung der Plattform einstellen.',
      p8: 'Widerspruch: Sie können dieses Recht ausüben, indem Sie eine E-Mail an support@gutgeregelt.ch senden.',
      p9: 'Datenübertragbarkeit: Sie können dieses Recht ausüben, indem Sie eine E-Mail an support@gutgeregelt.ch senden.',
      p10: 'Automatisierte Entscheidungsfindung: Sie können dieses Recht ausüben, indem Sie eine E-Mail an support@gutgeregelt.ch senden. Bitte beachten Sie jedoch, dass wir keine automatisierten Entscheidungsfindungen durchführen. Alle Entscheidungen in Bezug auf Ihr Profil werden intern von unserem Support-Personal getroffen.',
      p11: 'Sie haben auch das Recht, sich mit Fragen oder Bedenken an Ihre örtliche Datenschutzbehörde zu wenden.',
    },
  },
  dataStorage: {
    title: 'Datenspeicherung und Löschung',
    content: {
      p1: 'Gemäss der DSGVO und anderen relevanten Vorschriften speichern wir Ihre personenbezogenen Daten nur so lange, wie es erforderlich ist, um die auf der gut geregelt-Plattform verfügbaren Dienste bereitzustellen, und für unsere legitimen und wesentlichen Geschäftszwecke.',
    },
  },
  deletion: {
    title: 'Löschung',
    content: {
      p1: 'Wenn Sie Ihr Konto schliessen oder uns bitten, Ihr Konto zu schliessen, werden wir Ihre personenbezogenen Daten löschen, anonymisieren oder so bearbeiten, dass sie Sie nicht mehr identifizieren. Es sei denn, wir sind gesetzlich verpflichtet, etwas aufzubewahren, oder wir müssen es aus rechtlich gerechtfertigten Gründen weiterhin verwenden.',
      p2: 'Hier sind einige Beispiele für Situationen, in denen wir gesetzlich berechtigt oder verpflichtet sind, einige Ihrer personenbezogenen Daten aufzubewahren:',
      p3: '• Wenn es ein ungelöstes Problem im Zusammenhang mit Ihrem Konto gibt, wie z. B. eine ausstehende Zahlung oder eine ungelöste Beschwerde oder Streitigkeit.',
      p4: '• Für unsere rechtlichen, steuerlichen, prüferischen und buchhalterischen Verpflichtungen.',
      p5: '• Für unsere legitimen Geschäftsinteressen, wie die Verhinderung von Betrug oder die Aufrechterhaltung der Sicherheit.',
      p6: 'Weitere Informationen finden Sie im Abschnitt zur Löschung oben.',
      p7: 'In einigen Fällen können wir Ihre personenbezogenen Daten anonymisieren oder so bearbeiten, dass sie nicht mehr identifizierbar sind, um Betrug zu verhindern oder festzustellen, ob Sie ein früherer Nutzer sind, wenn Sie sich erneut für die gut geregelt-Plattform registrieren, um Betrug zu verhindern.',
      p8: 'Wir haben Massnahmen ergriffen, um Ihre personenbezogenen Daten vor unbeabsichtigtem Verlust und unbefugtem Zugriff, Verwendung, Änderung und Offenlegung zu schützen.',
      p9: 'Die Sicherheit Ihrer Informationen hängt auch von Ihnen ab. Wenn wir Ihnen (oder wenn Sie dies gewählt haben) ein Passwort zur Nutzung bestimmter Teile unserer Website bereitgestellt haben, sind Sie dafür verantwortlich, dieses Passwort vertraulich zu behandeln. Wir bitten Sie, Ihr Passwort nicht mit anderen zu teilen. Bitte seien Sie vorsichtig, wenn Sie Informationen in öffentlichen Bereichen der Website teilen, wie Foren. Die Informationen, die Sie in öffentlichen Bereichen teilen, können von jedem Benutzer der Website eingesehen werden.',
      p10: 'Leider ist die Übertragung von Informationen über das Internet nicht vollständig sicher. Obwohl wir unser Bestes tun, um Ihre personenbezogenen Daten zu schützen, können wir die Sicherheit Ihrer über das Internet an unsere Website übermittelten personenbezogenen Daten nicht garantieren. Jede Übertragung von personenbezogenen Daten erfolgt auf Ihr eigenes Risiko. Wir sind nicht verantwortlich für die Umgehung von Datenschutzeinstellungen oder Sicherheitsmassnahmen auf der Website.',
    },
  },
  changes: {
    title: 'Änderungen an unserer Datenschutzrichtlinie',
    content: {
      p1: 'Es ist unsere Politik, alle Änderungen, die wir an unserer Datenschutzrichtlinie vornehmen, auf dieser Seite zu veröffentlichen. Wenn wir wesentliche Änderungen an der Art und Weise vornehmen, wie wir die personenbezogenen Daten unserer Nutzer behandeln, werden wir Sie durch eine Benachrichtigung auf der Startseite der Website informieren. Das Datum der letzten Überarbeitung der Datenschutzrichtlinie wird oben auf der Seite angezeigt. Sie sind dafür verantwortlich sicherzustellen, dass wir eine aktuelle, aktive und zustellbare E-Mail-Adresse von Ihnen haben, und regelmässig unsere Website und diese Datenschutzrichtlinie zu besuchen, um nach Änderungen zu suchen.',
    },
  },
  contantInformation: {
    title: 'Kontaktinformationen',
    content: {
      p1: 'Wenn Sie Fragen oder Kommentare zu dieser Datenschutzrichtlinie und unseren Datenschutzpraktiken haben, kontaktieren Sie uns und unseren Datenschutzbeauftragten unter support@gutgeregelt.ch.',
      p2: 'Um eine Beschwerde oder Bedenken zu registrieren, senden Sie bitte eine E-Mail an support@gutgeregelt.ch.',
    },
  },
};

export default privacyPolicy;
