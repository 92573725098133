import Page from '#/components/shared/ui/Page';
import LoginForm from '../../components/pages/Login/LoginForm';

const Login = () => (
  <Page title="Login">
    <LoginForm />
  </Page>
);

export default Login;
