const outputDoc = {
  title: 'Will',
  from: 'from',
  born: 'born on ',
  civilStatus: {
    single: 'Single',
    married: 'Married',
    widowed: 'Widowed',
    divorced: 'Divorced',
    registered_partnership: 'Registered partnership',
  },
  civil_status: 'Marital Status: {{civil_status}}',
  address: 'Address: {{address}}',
  declare: 'I, {{first_name}} {{last_name}}, declare the following:  ',

  paragraph1: 'I revoke all wills I have previously written.',

  paragraph2:
    'Outstanding debts and funeral expenses are to be paid from the estate.',

  paragraph3: '{{civil_status}}',

  receivesNothing: 'receives nothing.',
  and: ' and ',

  p4MarriedSpouse: {
    receives: 'receives',
    marriedStatus: {
      male: 'My wife',
      female: 'My husband',
    },
    livingPartnershipStatus: {
      male: 'My registered partner (female)',
      female: 'My registered partner (male)',
    },
    partnershipContract: {
      yes: 'maritally, all community property, and inheritance-wise, all assets within the freely disposable',
      no: 'maritally, the entire proposal and inheritance-wise, all assets within the freely disposable',
    },
    quota: 'Share',
    noContractButChildren: {
      main: 'only for usufruct, while the compulsory portion, which is on {{children}}',
      oneChild: 'the common child',
      multipleChildren: 'the common children, is omitted.',
    },

    propertyContractAndChildren: {
      main: 'only for usufruct, while the compulsory portion, which is on {{children}}',
      oneChild: 'the child',
      multipleChildren: 'the children, is omitted.',
    },
  },

  p4UnmarriedPartner: {
    receives: 'receives all assets within the freely disposable share.',
    receivesProperty: 'receives especially',
    theProperty: 'the property',
    atTheAddress: 'at the address',
    assignedToProperty: 'assigned in ownership.',
    genderStatus: {
      male: 'My life partner (male)',
      female: 'My life partner (female)',
      male1: 'my life partner (female)',
      female1: 'my life partner (male)',
      registeredPartnerMale: 'My registered partner (female)',
      registeredPartnerFemale: 'My registered partner (male)',
    },
  },

  p5UnmarriedPartnerWithChildren: {
    commonPartnership: {
      oneChild: {
        main: 'Our common child, ',
        born: ' born on {{birthday}}, ',
        receive: 'or its descendants receive the compulsory portion.',
      },
      multipleChildren: {
        main: 'Our common children,',
        born: ' born on {{birthday}}, ',
        receive: 'or all their descendants receive the compulsory portion.',
      },
      and: 'and',
    },
    otherPartnership: {
      oneChild: {
        main: 'My non-common child, ',
        born: ' born on {{birthday}}, ',
        receive: 'or its descendants receive the compulsory portion.',
      },
      multipleChildren: {
        main: 'My non-common children,',
        born: ' born on {{birthday}}, ',
        receive: 'or all their descendants receive the compulsory portion.',
      },
    },
    deathCase:
      'If I should die simultaneously with {{partner}}, then {{childrenCase}}',
    childrenCase: {
      oneChild: {
        fromCommonMarriage: 'receives our common child',
        fromOtherMarriage: 'receives our non-common child',
      },
      onesDescendants: 'or its descendants receive all assets.',
      multipleChildren: 'our common children receive,',
      allDescendants: 'or all their descendants each receive all assets.',
    },
  },

  p4Child: {
    commonMarriage: {
      oneChild: {
        main: 'Our common child, ',
        born: ' born on {{birthday}}, ',
        receive:
          'or its descendants each receive all assets within the freely disposable share. ',
      },
      multipleChildren: {
        main: 'Our common children, ',
        born: ' born on {{birthday}}, ',
        receive:
          'or all their descendants each receive all assets within the freely disposable share. ',
      },
    },
    otherMarriage: {
      oneChild: {
        main: 'My non-common child, ',
        born: ' born on {{birthday}}, ',
        receive:
          'or its descendants each receive all assets within the freely disposable share. ',
      },
      multipleChildren: {
        main: 'My non-common children,',
        born: ' born on {{birthday}}, ',
        receive:
          'or all their descendants each receive all assets within the freely disposable share. ',
      },
      and: 'and',
    },
  },

  unmarriedChildrenChoice: {
    commonPartnership: {
      oneChild: {
        main: 'Our common child, ',
        born: ' born on {{birthday}}, ',
        receives: 'or its descendants receive',
      },
      multipleChildren: {
        main: 'Our common children, ',
        born: ' born on {{birthday}}, ',
        receives: 'or all their descendants receive each all assets',
      },
      quota: 'within the freely disposable share.',
      and: 'and',
    },
    otherPartnership: {
      oneChild: {
        main: 'My non-common child, ',
        born: ' born on {{birthday}}, ',
        receives: 'or its descendants receive.',
      },
      multipleChildren: {
        main: 'My non-common children, ',
        born: ' born on {{birthday}}, ',
        receives: 'or all their descendants receive each all assets',
      },
      quota: 'within the freely disposable share.',
      and: 'and',
    },
  },

  unmarriedInstitutionChoice: {
    commonPartnership: {
      oneChild: {
        main: 'Our common child, ',
        born: ' born on {{birthday}}, ',
        receive: 'or its descendants receive the compulsory portion.',
      },
      multipleChildren: {
        main: 'Our common children, ',
        born: ' born on {{birthday}}, ',
        receive:
          'or all their descendants each receive the compulsory portion.',
      },
      and: 'and',
    },
    otherPartnership: {
      oneChild: {
        main: 'My non-common child, ',
        born: ' born on {{birthday}}, ',
        receive: 'or its descendants receive the compulsory portion.',
      },
      multipleChildren: {
        main: 'My non-common children, ',
        born: ' born on {{birthday}}, ',
        receive:
          'or all their descendants each receive the compulsory portion.',
      },
      and: 'and',
    },
  },

  p4Institution: {
    oneInstitution: {
      main: 'The institution',
      receives: 'receives ',
    },
    multipleInstitutions: {
      main: 'The institutions',
      receives: 'receive ',
    },
    finals:
      ' each receive equal shares of all assets within the freely available quota. ',
    final: 'each equally all assets within the freely disposable share. ',
    partnerReceives: 'receives the compulsory portion.',
    and: 'and',
  },

  p5MarriedSpouse: {
    marriedStatus: {
      male: 'My wife',
      female: 'My husband',
    },
    livingPartnershipStatus: {
      male: 'My registered partner (female)',
      female: 'My registered partner (male)',
    },
    particularReceives: 'receives especially also',
    theProperty: 'the property',
    atTheAddress: 'at the address',
    assignedToProperty: 'assigned in ownership.',
  },

  p5Institution: {
    commonMarriage: {
      oneChild: {
        main: 'Our common child, ',
        born: ' born on {{birthday}}, ',
        receive: 'or its descendants receive the compulsory portion.',
      },
      multipleChildren: {
        main: 'Our common children, ',
        born: ' born on {{birthday}}, ',
        receive:
          'or all their descendants each receive the compulsory portion.  ',
      },
      and: 'and',
    },
    otherMarriage: {
      oneChild: {
        main: 'My non-common child, ',
        born: ' born on {{birthday}}, ',
        receive: 'or its descendants receive the compulsory portion',
      },
      multipleChildren: {
        main: 'My non-common children, ',
        born: ' born on {{birthday}}, ',
        receive:
          'or all their descendants each receive the compulsory portion.',
      },
      and: 'and',
    },
  },

  p6MarriedSpouse: {
    oneChild: {
      commonMarriage: 'Our common child, ',
      otherMarriage: 'My non-common child, ',
      born: ' born on {{birthday}}, ',
      receives: 'or its descendants receive.',
    },
    multipleChildren: {
      commonMarriage: 'Our common children,',
      otherMarriage: 'My non-common children, ',
      born: ' born on {{birthday}}, ',
      receives: 'or all their descendants each receive',
    },
    final:
      'the compulsory portion, burdened with the usufruct mentioned in the first paragraph. ',
  },

  willExecutor:
    'As executor for my entire estate, I appoint {{executor1}}. As alternate executor, I appoint {{executor2}}.',

  forFollowing: {
    main: 'However, different regulations apply to the following assets:',
    asset: '{{asset}}, {{address}} goes to: {{benefiter}}. ',
    devisionProvision:
      'This is a division provision in favor of {{benefiter}}, not an additional benefit.',
  },
};

export default outputDoc;
