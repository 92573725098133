const outputDoc = {
  title: 'Living Will',
  from: 'from ',
  born: 'born on ',
  civilStatus: {
    single: 'Single',
    married: 'Married',
    widowed: 'Widowed',
    divorced: 'Divorced',
    registered_partnership: 'Registered partnership',
  },
  civil_status: 'Marital Status: {{civil_status}}',
  address: 'Address: {{address}}',
  declare: 'I, {{first_name}} {{last_name}}, declare the following:  ',
  paragraph1:
    'In the event that I become incapacitated due to illness or accident and am no longer able to make decisions, I hereby establish my legally binding wishes and preferences regarding medical treatment, care, dying, and death through this living will. I appoint the following person(s) as my authorized representative(s): ',
  paragraph2:
    'In the event of my incapacity, the authorized representative will enforce my specified wishes and make medical and caregiving decisions on my behalf. They must be informed about my health condition and the expected course of illness. I explicitly release the medical team from confidentiality to the extent necessary for an impending decision. The authorized representative should be involved, whenever possible, before medical decisions are made. If this living will does not address a specific question, the authorized representative shall decide according to my presumed wishes.',
  paragraph3:
    'The named person is also authorized to access my patient documentation and any autopsy findings after my death. ',
  repInformParagraph:
    'I have discussed the assignment with the authorized representative on {{representative_inform_date}}. ',
  secondaryRepParagraph:
    'In the event that the appointed person under point 1 is unable to fulfill their duties, I appoint the following person as an alternative representative:  ',
  motivation: {
    title: 'Motivation and Attitude',
    paragraph1: 'I issue this living will ',
    takePrecautions: 'in good health, but with forward-looking planning.',
    makeProvisions:
      'with a certain medical condition that prompts me to make arrangements.',
    makeProvisionsForFuture:
      'after reaching a certain age, where short-term arrangements may be necessary.',
    paragraph2: 'With this living will, my main objective is to ensure that ',
    alleviateSuffering:
      'medical interventions are primarily used to alleviate my suffering. Prolonging my life at any cost is not a priority for me. I accept that foregoing medical treatment may shorten my life.',
    exhaustedMedicalPossibilities:
      'all medical possibilities to sustain my life have been exhausted. My suffering should be alleviated as much as possible, even if it entails certain burdens (e.g., impaired consciousness).',
  },
  medical: {
    title: 'Medical Directives / Treatment Goal ',
    paragraph1:
      'If I find myself in any of the following situations, I expect the treatment team to act as follows:',
    exhaustedMedicalPossibilities:
      'I primarily want effective treatment for pain and other symptoms, such as anxiety. I accept that this may be associated with burdens (e.g., clouded consciousness).',
    alleviateSuffering:
      'I primarily want to preserve consciousness and my ability to communicate. I accept that this may compromise the optimal relief of pain and other symptoms, such as anxiety.',
  },
  forego_measures: {
    yes: 'I waive all measures that only result in prolonging life and suffering if I become incapacitated due to an unexpected, acute event, and if, after a thorough medical assessment, it is impossible or unlikely that I will regain my capacity for judgment.',
    no: 'I want all possible measures to be implemented if I become incapacitated due to an unexpected, acute event, even if, after a thorough medical assessment, it is impossible or unlikely that I will regain my capacity for judgment.',
  },
  revive_preference: {
    paragraph: 'In the case of cardiac or respiratory arrest, ',
    yes: 'resuscitation',
    no: 'no resuscitation',
    paragraph1: 'should be performed.',
  },
  moreDetails: {
    treatment_goal_sudden_incapacity: {
      paragraph: 'In the event of sudden incapacity (emergency), ',
      resuscitationAndTreatment:
        'I wish for resuscitation and treatment in the intensive care unit (treatment goal: life extension). ',
      treatedInIntensiveCareUnit:
        'I do not wish for resuscitation but prefer treatment in the intensive care unit. ',
    },
    suffering_relief_sudden_incapacity: {},
    treatment_goal_prolonged_incapacity: {
      paragraph: 'In the case of prolonged incapacity, ',
      resuscitationAndTreatment:
        'I wish for resuscitation and treatment in the intensive care unit (treatment goal: life extension). ',
      treatedInIntensiveCareUnit:
        'I do not wish for resuscitation but prefer treatment in the intensive care unit.',
    },
    treatment_goal_permanent_incapacity: {
      paragraph: 'In the case of permanent incapacity, ',
      resuscitationAndTreatment:
        'I wish for resuscitation and treatment in the intensive care unit (treatment goal: life extension). ',
      treatedInIntensiveCareUnit:
        'I do not wish for resuscitation but prefer treatment in the intensive care unit. ',
    },
  },
  revocation: {
    title: 'Revocation',
    paragraph1: 'I hereby revoke all living wills of earlier dates.',
  },
  abilityToConsent: {
    title: 'Capacity to Act ',
    paragraph1:
      'I make this statement after careful consideration and in full responsibility for myself, and aware that regarding medical measures, my doctors, caregivers, or authorized persons are bound by the decision of the appointed person.',
    informedRepresentative:
      'I have discussed this directive with the individuals mentioned at the beginning of the first page, who can confirm that, at that time, according to their perception, I was capable of judgment, and the content reflects my will. ',
  },
};

export default outputDoc;
