const outputDoc = {
  title: 'Ehevertrag',
  from: 'von ',
  born: 'geboren am ',
  civilStatus: {
    single: 'Ledig',
    married: 'Verheiratet',
    widowed: 'Verwitwet',
    divorced: 'Geschieden',
    registered_partnership: 'Eingetragener partnerschaft',
  },
  civil_status: 'Familienstand: {{civil_status}}',
  address: 'Adresse: {{address}}',
  male: 'Ehefrau',
  female: 'Ehemann',
  and: 'und',
  spousePresentation:
    '{{spouseLastName}} {{spouseName}}, {{relation}}, geboren am {{spouseBirthDate}}, von {{spousePlaceOfCitizenship}}, {{spouseCivilStatus}}, wohnhaft {{spouseAddress}}.',
  personalPresentation:
    '{{firstName}} {{lastName}}, {{relation}}, geboren am {{birthDate}}, von {{placeOfCitizenship}}, {{civilStatus}}, wohnhaft {{address}}.',
  determinations: {
    title: 'Bestimmungen',
    section1: {
      firstParagraphWithChildren:
        'Die Vertragsparteien haben am {{weddingDate}} in {{weddingLocation}} geheiratet.',
      oneCommonChildParagraph: 'Aus ihrer Ehe ist ein gemeinsames Kind: ',
      multipleCommonChildrenParagraph:
        'Aus ihrer Ehe sind {{numberOfChildren}} gemeinsame Kinder: ',
      childBirthday: 'geboren am {{birthday}}',
      firstParagraphWithoutChildren:
        'Die Vertragsparteien haben am {{weddingDate}} in {{weddingLocation}} geheiratet.Aus ihrer Ehe sind keine gemeinsamen Kinder hervorgegangen.',
      noChildrenOutsideMarriage:
        'Die Vertragsparteien haben keine nicht-gemeinsamen Kinder.',
      oneChildOutsideMarriage:
        'Die Vertragsparteien haben ein nicht-gemeinsamen Kind: ',
      multipleChildrenOutsideMarriage:
        'Die Vertragsparteien haben {{numberOfChildren}} nicht-gemeinsamen Kinder: ',
    },
    section2: {
      noMarriageContract:
        'Bis heute haben die Vertragsparteien noch nie einen Ehevertrag abgeschlossen. Es ist weder eine gerichtliche noch eine gesetzliche Gütertrennung eingetreten.',
    },
    section3: {
      participationAgreement:
        'Die Errungenschaftsbeteiligung umfasst die Errungenschaft und das Eigengut eines jeden Ehegatten. Innerhalb der gesetzlichen Schranken verwaltet und nutzt jeder Ehegatte seine Errungenschaft und sein Eigengut und verfügt darüber.',
    },
    section4: {
      personalAssets:
        'Neben denjenigen Gegenständen, die jedem Ehegatten ausschliesslich zum persönlichen Gebrauch dienen, besitzen die Vertragsparteien die folgenden Eigengüter:',
      pointA: 'a.) Ehemann: ',
      pointB: 'b.) Ehefrau: ',
    },
    section5: {
      otherAssets: 'Sämtliche weiteren Vermögenswerte',
      evenEstates: 'inkl. ',
      otherAssetsContinued:
        'haben die Vertragsparteien nach der Eheschliessung gemeinsam erwirtschaftet; diese bilden Errungenschaft im Sinne von Art. 197 ZGB.',
      apartment: 'der der Wohnung',
      house: 'dem Haus',
      chalet: 'dem Chalet',
      rustico: 'dem Rustico',
      building_plot: 'dem Baugrundstück',
      multi_family_house: 'dem Mehrfamilienhaus',
      commercial_building: 'dem Gewerbegebäude',
      residential_building: 'dem Wohngebäude',
      commercial_property: 'der Gewerbeimmobilie',
      nonAcquiredAfterMarriage:
        'Folgende {{assetsQuantity}} haben die Vertragsparteien nach der Eheschliessung gemeinsam erwirtschaftet; diese bilden Errungenschaft im Sinne von Art. 197 ZGB: ',
      plural: 'Immobilien',
      singular: 'Immobilie',
    },
  },
  marriageContractAgreements: {
    title: 'Ehevertragliche Vereinbarungen',
    section1: {
      firstParagraph:
        'Die Vertragsparteien vereinbaren ausdrücklich, den ordentlichen Güterstand der Errungenschaftsbeteiligung beizubehalten.',
    },
    section2: {
      firstParagraph:
        'Gestützt auf Art. 216 Abs. 1 ZGB und in Abänderung der in Art. 215 ZGB vorgesehenen Vorschlagsbeteiligung vereinbaren die Vertragsparteien, dass bei Auflösung der Ehe durch den Tod eines Ehegatten dem Überlebenden die Gesamtsumme der Vorschläge beider Ehegatten vollumfänglich zu unbeschwertem Eigentum zufällt.',
    },
    section3: {
      firstParagraph:
        'In Anwendung von Art. 206 Abs. 3 ZGB schliessen die Ehegatten bei Auflösung der Ehe im Todesfall eine gegenseitige Beteiligung am Mehrwertanteil aus.',
    },
  },
  jointProvisions: {
    title: 'Gemeinsame Bestimmungen',
    section1: {
      firstParagraph:
        'Die Vertragsparteien widerrufen mit Errichtung dieses Vertrages sämtliche früheren Verfügungen von Todes wegen. Ausgenommen hiervon sind allfällige Begünstigungserklärungen gegenüber Versicherungen und Vorsorgeeinrichtungen.',
    },
    section2: {
      firstParagraph:
        'Im Sinne einer Teilungsvorschrift wird bestimmt, dass der überlebende Ehegatte nach freier Wahl jene Sach- und Kapitalwerte übernehmen kann, die ihm zusagen, in Anrechnung an die güter- und erbrechtlichen Ansprüche. Diese Teilungsvorschrift bezieht sich ausdrücklich auch auf Grundstücke, die im entsprechenden Grundbuch ins Alleineigentum des überlebenden Ehegatten einzutragen sind. Als Anrechnungswert gilt der amtliche Verkehrswert (Steuerwert); Stand Todestag des Erstversterbenden.',
      secondParagraph:
        'Es steht dem überlebenden Ehegatten auch zu, die erbrechtlichen Ansprüche der übrigen Erben nach seiner Wahl in bar oder durch Zuweisung von anderen Vermögenswerten abzugelten. Der ganze Hausrat steht dem überlebenden Ehegatten ohne Anrechnung an den Erbteil zu uneingeschränktem Eigentum zu.',
    },
    section3: {
      firstParagraph:
        'Sollte sich der überlebende Ehegatte wieder verheiraten, hat er den Nachkommen denjenigen Betrag auszurichten, den diese beim Tode des erstverstorbenen Ehegatten bei einer güter- und erbrechtlichen Auseinandersetzung gemäss den gesetzlichen Bestimmungen und ohne diesen Vertrag erhalten hätten. Dabei wird angerechnet, was die Nachkommen aus dem Nachlass des erstverstorbenen Ehegatten trotz dieses Vertrages bereits erhalten haben und was ihnen seither vom überlebenden Ehegatten durch Schenkung zugewendet worden ist. Massgebend für den Bestand des Nachlassvermögens ist das steuerrechtliche Nachlassinventar, welches durch die kantonalen Behörden oder die Erben selbst beim Tod des erstverstorbenen Elternteils/Ehegatten aufgenommen wurde. Die Ansprüche der Nachkommen werden fällig mit dem Tag des Eintritts der Bedingung und sind bis zu diesem Tag weder zu verzinsen noch sicherzustellen. Es ist dem überlebenden Elternteil/Ehegatten freigestellt, in welcher Form die Auszahlung erfolgt. ',
    },
    section4: {
      firstParagraph:
        'Dieser Ehevertrag ist vorbehältlich anderslautender zwingender gesetzlicher Bestimmungen dem schweizerischen Recht unterstellt. Soweit zulässig, sind für die ehegüter- und erbrechtlichen Verhältnisse der Vertragsparteien zudem die schweizerischen Behörden zuständig.',
    },
    section5: {
      firstParagraph:
        'Dieser Ehevertrag verliert seine Gültigkeit, wenn beim Tod des erstversterbenden Ehegatten die Ehe der Vertragsparteien rechtskräftig geschieden ist, im Sinne von Art. 117 ZGB gerichtlich oder faktisch getrennt wurde oder ein Ehescheidungs- beziehungsweise Eheschutzbegehren anhängig ist.',
    },
  },
  contractualAgreements: {
    title: 'Erbvertragliche Vereinbarungen',
    section1: {
      firstParagraph:
        'Beim Tod des erstversterbenden Ehegatten behält der Überlebende sein Eigengut und erhält aufgrund der vorstehenden ehevertraglichen Vereinbarung die Vorschläge beider Ehegatten zu Eigentum.',
    },
    section2: {
      firstParagraph:
        'Ferner begünstigen sich die Vertragsparteien auch erbrechtlich, soweit dies gesetzlich zulässig ist.',
      nonCommonChildren:
        'Der überlebende Ehegatte kann somit aus dem verbleibenden Nachlass wählen zwischen:',
      pointA:
        'a.) dem höchstmöglichen Erbanspruch (von derzeit 5/8) zu Eigentum und Ausrichtung des Pflichtteils an die Nachkommen',
      pointB:
        'b.) der freien Quote (von derzeit 1/4) zu Eigentum sowie vom restlichen, den Nachkommen zustehenden Nachlass, die unentgeltliche Nutzniessung nach Art. 473 ZGB, ohne hierfür Sicherstellung leisten zu müssen.',
    },
    section4: {
      firstParagraph:
        'Die Ehegatten räumen sich gegenseitig das Recht ein, mittels letztwilliger Verfügung über den grösseren Betrag aus CHF {{disposal_fixed_amount}} bzw. {{disposal_percentage}}% ihres Netto-Nachlasses frei verfügen zu können.',
    },
  },
  signatures: {
    title: 'Unterschrift {{gender}}',
    female: 'Ehefrau',
    male: 'Ehemann',
  },
  notarization: {
    title: 'Beurkundung',
    firstParagraph: 'Der unterzeichnete Notar bescheinigt hiermit, dass',
    firstCondition:
      'er die vorliegende Urkunde den Urkundsparteien vorgelesen hat,',
    secondCondition:
      'die Urkunde dem ihm von den Urkundsparteien mitgeteilten Willen entspricht,',
    thirdCondition:
      'die Urkunde von den Urkundsparteien in seiner Gegenwart eigenhändig unterzeichnet worden ist,',
    noterTitle: 'Unterschrift Notar',
  },
};

export default outputDoc;
