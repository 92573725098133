const livingWillLanding = {
  mainBanner: {
    intro: 'Créez votre directive anticipée en ligne :',
    title: 'Gardez le contrôle dans les situations d`urgence médicale',
    subtitle:
      'Dans la directive anticipée, vous consignez par écrit les traitements médicaux auxquels vous consentez ou que vous refusez, au cas où vous seriez incapable de prendre des décisions par vous-même.',
    buttonLabel: 'Créer le document',
  },
  youtubeTutorial: "Qu'est-ce qu'une directive anticipée ?",
  aboutCards: {
    proactiveMeasures: {
      title: 'Mesures proactives',
      description:
        "En cas d'incapacité à agir, la directive anticipée veille à ce que vous soyez traité selon vos souhaits.",
    },
    wishesYouCanAdapt: {
      title: 'Souhaits ajustables',
      description:
        'Toute personne en capacité de décider peut rédiger une directive anticipée et la modifier ou la révoquer à tout moment.',
    },
    legalSecurity: {
      title: 'Alléger la charge pour les proches',
      description:
        "En cas d'urgence médicale, vos proches auront moins de stress parce que vous avez pris des dispositions.",
    },
  },
  subBanner: {
    title: 'Bien organisé avec une directive anticipée juridiquement conforme',
    subtitle:
      'Avec bien organisé, vous créez rapidement votre directive anticipée, pouvez obtenir des conseils juridiques sur des sujets délicats ou des questions en suspens, et faites plaisir à vos proches.',
    buttonLabel: 'Créer gratuitement maintenant',
  },
  newsletter: {
    title: 'Soyez bien organisé avec votre directive anticipée',
    buttonLabel: 'Créer maintenant',
  },
  faq: {
    questions: {
      question1: 'Qu est-ce qu une directive anticipée et à quoi sert-elle ?',
      question2:
        'Quelles sont les conditions pour la création d une directive anticipée ?',
      question3: 'Quand une directive anticipée entre-t-elle en vigueur ?',
      question4: 'Que peut empêcher la directive anticipée ?',
      question5:
        'Comment fonctionnent les personnes représentatives définies dans la directive anticipée ?',
      question6:
        'Une copie de la directive anticipée est-elle valable en cas d application ?',
      question7:
        'Dois-je faire authentifier ma directive anticipée par un notaire ?',
      question8:
        'Une tierce personne peut-elle remplir la directive anticipée pour moi ?',
      question9:
        'Que dois-je faire si je ne peux pas signer moi-même (aveuglement, sclérose en plaques ou Parkinson) ?',
      question10:
        'Ma directive anticipée est-elle également valable à l étranger ?',
      question11:
        'Puis-je également disposer que je ne sois pas réanimé en cas d urgence ?',
      question12:
        'Dois-je indiquer une personne représentative ? Que se passe-t-il si je ne peux / ne veux pas indiquer de personne représentative ?',
      question13: 'Dois-je informer ma personne représentative à l avance ?',
      question14:
        'Mon conjoint est-il automatiquement ma personne représentative ?',
      question15:
        'Puis-je désigner mon enfant mineur comme personne représentative ?',
      question16:
        'Si je déménage ou me marie, dois-je adapter ma directive anticipée ?',
      question17:
        'Dois-je donner une copie de ma directive anticipée à mon médecin traitant ?',
      question18:
        'Ma directive anticipée en ligne est-elle publiquement accessible ?',
      question19:
        'Que se passe-t-il si ma directive anticipée n est pas mise en œuvre ?',
      question20: 'Comment puis-je assurer la validité ?',
      question21:
        'Y a-t-il d autres documents que je devrais créer en plus de la directive anticipée ?',
      question22:
        'Comment bien réglé m aide-t-il avec ma directive anticipée ?',
    },
    answers: {
      answer1:
        'Une directive anticipée est une expression de volonté unilatérale, écrite en état de jugement. Vous donnez aux médecins et aux soignants des instructions médicales pour le cas où vous ne seriez plus capable de juger ou de vous exprimer à un moment ultérieur.',
      answer2: 'Capacité de jugement en matière de décisions médicales.',
      answer3:
        'Une directive anticipée entre en vigueur lorsque deux facteurs se rencontrent : incapacité de jugement et pronostic infauste. Une telle situation peut se produire, par exemple, en cas d accident vasculaire cérébral grave, de maladie démentielle avancée, de tumeur cérébrale en phase avancée, de coma ou de coma éveillé après un accident, une opération ou une réanimation.',
      answer4:
        'que des mesures médicales retardent la mort naturelle, que des tentatives de réanimation soient entreprises, que des machines maintiennent la vie sans espoir d amélioration, l alimentation artificielle.',
      answer5:
        'Toutes les personnes représentatives mentionnées dans la directive anticipée deviennent des mandataires dans l ordre de leur nomination. Ils sont autorisés à prendre des décisions médicales au nom de la personne disposante si une situation se présente qui n est pas réglée dans la directive anticipée. En cas de conflit, la loi prévoit que toute personne proche de la personne incapable de juger peut s adresser à l autorité de protection des adultes.',
      answer6:
        'Oui, une copie peut être utilisée en cas d application. Nous recommandons néanmoins qu au moins une personne représentative connaisse l emplacement de stockage des documents originaux.',
      answer7:
        'Non. Depuis l entrée en vigueur du nouveau droit de protection des adultes en 2013, une directive anticipée créée en état de jugement est juridiquement contraignante.',
      answer8:
        'Oui, à condition que vous compreniez le contenu et que vous ajoutiez à la fin la date et la signature manuscrites de manière autonome, ou que vous puissiez signer électroniquement la directive anticipée de manière autonome et qualifiée.',
      answer9: 'Faites authentifier votre directive anticipée par un notaire.',
      answer10:
        'bien réglé ne peut intervenir pour le respect d une directive anticipée qu en Suisse. Il faut vérifier dans chaque pays si une directive anticipée est contraignante.',
      answer11:
        'En principe, en cas d urgence, il s agit de sauver et de stabiliser la vie, de sorte qu il n y a souvent pas le temps de chercher une directive anticipée éventuellement existante. Le personnel de secours est également tenu de déterminer la volonté présumée d une personne non communicative et d agir en conséquence.',
      answer12:
        'Une directive anticipée est également juridiquement valable si aucune personne représentative n est nommée, mais dans ce cas, il est avantageux de mettre à jour la directive anticipée chaque année.',
      answer13:
        'Nous recommandons de les informer pour des raisons pratiques. Ainsi, votre représentant sait ce qu on attend de lui.',
      answer14:
        'Non. Le conjoint doit être explicitement mentionné dans la directive anticipée si cela est souhaité.',
      answer15:
        'Oui, selon la loi, une personne représentative doit être capable de juger, mais pas nécessairement majeure. Nous recommandons d impliquer également une personne adulte pour le soutien.',
      answer16:
        'Une directive anticipée devrait être adaptée autant que possible aux circonstances actuelles de la vie. Nous recommandons donc de prendre en compte tout changement.',
      answer17: 'Nous recommandons de remettre une copie au médecin traitant.',
      answer18: 'Non, l accès est protégé par un mot de passe.',
      answer19:
        'Vous ou des personnes proches de vous pouvez contacter par écrit ou par téléphone l autorité de protection des adultes et faire valoir que la directive anticipée n est pas respectée ou que les intérêts de la personne incapable de juger sont menacés ou ne sont plus respectés.',
      answer20:
        'bien réglé vous aide à assurer la validité. Chaque directive anticipée doit contenir la date de création et la signature personnelle de la personne disposante.',
      answer21:
        'Oui, le {{powerOfAttorney}} et le {{lastWill}} sont deux documents que vous devriez créer le plus tôt possible.',
      answer22:
        'Chez bien réglé, nous vous aidons à créer votre directive anticipée de manière fluide et juridiquement valide. Pour que vous puissiez regarder l avenir avec sérénité.',
    },
  },
};

export default livingWillLanding;
