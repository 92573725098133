import { IQuestion } from '#/types/globalTypes';

export const treatmentQuestions: IQuestion[] = [
  {
    question:
      'Warum erstellen Sie diese Patientenverfügung? Was beschreibt Ihre aktuelle Situation am besten?',
    options: [
      {
        label: 'Ich bin bei guter Gesundheit, aber ich möchte vorsorgen',
      },
      {
        label: 'Ich habe Beschwerden und möchte vorsorgen',
      },
      {
        label:
          'Ich habe ein bestimmtes Alter erreicht und möchte für die Zukunft vorsorgen',
      },
    ],
  },
  {
    question:
      'Was ist Ihnen bei der Schmerz- und Symptombehandlung wichtiger? Bitte kreuzen Sie die treffende Aussage an.',
    options: [
      {
        label:
          'Ich möchte, dass alle medizinischen Möglichkeiten zur Erhaltung meines Lebens ausgeschöpft werden, auch wenn dies mit Belastungen (Leiden) verbunden ist',
      },
      {
        label:
          'Ich möchte, dass die medizinische Behandlung in erster Linie der Linderung von Leiden dient, auch wenn sie mein Leben verkürzen kann',
      },
    ],
  },
  {
    question:
      'Möchten Sie auf alle lebens- und leidensverlängernden Massnahmen verzichten, wenn Sie aufgrund eines unerwarteten, akuten Ereignisses urteilsunfähig werden und es nach eingehender ärztlicher Beurteilung unmöglich oder unwahrscheinlich ist, dass Sie Ihre Urteilsfähigkeit wiedererlangen?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question: 'Möchten Sie wiederbelebt werden?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
];

export const detailedQuestions: IQuestion[] = [
  {
    question:
      'In einem Szenario, in dem Sie plötzlich urteilsunfähig werden, z. B. in einem Notfall, was wäre Ihr Behandlungsziel zur Lebensverlängerung?',
    options: [
      {
        label: 'Ich möchte Wiederbelebung und Behandlung erhalten',
      },
      {
        label:
          'Ich möchte nicht wiederbelebt, sondern auf der Intensivstation behandelt werden',
      },
    ],
  },
  {
    question:
      'Was wäre Ihr Behandlungsziel zur Linderung des Leidens im Falle einer plötzlichen Arbeitsunfähigkeit?',
    options: [
      {
        label:
          'Ich möchte leidenslindernde Medikamente (Morphin, etc.) und Behandlungstherapien erhalten',
      },
      {
        label:
          'Ich möchte keine leidenslindernden Medikamente (Morphin usw.) und Behandlungstherapien erhalten',
      },
    ],
  },
  {
    question:
      'In einem Szenario, in dem Sie dauerhaft arbeitsunfähig sind, z. B. bei Demenz, was wären Ihre Behandlungsziele zur Lebensverlängerung?',
    options: [
      {
        label:
          'Ich möchte auf der Intensivstation wiederbelebt und behandelt werden',
      },
      {
        label:
          'Ich möchte nicht wiederbelebt werden, sondern auf der Intensivstation behandelt werden',
      },
    ],
  },
  {
    question:
      'Welche Ziele würden Sie im Falle einer plötzlichen dauerhaften Arbeitsunfähigkeit verfolgen?',
    options: [
      {
        label:
          'Ich möchte leidenslindernde Medikamente (Morophin, etc.) und Behandlungstherapien erhalten',
      },
      {
        label:
          'Ich möchte keine leidenslindernden Medikamente (Morophin, etc.) und Behandlungstherapien erhalten',
      },
    ],
  },
  {
    question:
      'Was wären Ihre Behandlungsziele für eine Lebensverlängerung in einem Szenario, in dem Sie dauerhaft arbeitsunfähig sind, beispielsweise aufgrund einer Demenz?',
    options: [
      {
        label:
          'Ich möchte eine Wiederbelebung und Behandlung auf der Intensivstation erhalten',
      },
      {
        label:
          'Ich möchte nicht wiederbelebt, sondern auf der Intensivstation behandelt werden',
      },
    ],
  },
  {
    question:
      'Was wären Ihre Ziele zur Linderung des Leidens im Falle einer plötzlichen dauerhaften Arbeitsunfähigkeit?',
    options: [
      {
        label:
          'Ich möchte leidenslindernde Medikamente (Morophin, etc.) und Behandlungstherapien erhalten',
      },
      {
        label:
          'Ich möchte und möchte keine leidlindernden Medikamente (Morophin etc.) und Behandlungstherapien erhalten',
      },
    ],
  },
];
