const livingWillLanding = {
  mainBanner: {
    intro: 'Patientenverfügung online erstellen:',
    title: 'Rechtssicher, in 5 Minuten erledigt',
    subtitle:
      'In der Patientenverfügung halten Sie schriftlich fest, welchen medizinischen Behandlungen Sie zustimmen oder ablehnen, falls Sie nicht mehr in der Lage sind, Entscheidungen für sich zu treffen.',
    buttonLabel: 'Patientenverfügung erstellen',
  },
  youtubeTutorial: 'Warum eine Patientenverfügung wichtig ist?',
  aboutCards: {
    proactiveMeasures: {
      title: 'Vorsorgemassnahmen',
      description:
        'Wenn Sie handlungsunfähig sind, sorgt die Patientenverfügung dafür, dass Sie gemäss Ihren Wünschen behandelt werden.',
    },
    wishesYouCanAdapt: {
      title: 'Anpassbare Wünsche',
      description:
        'Jede Person mit Entscheidungsfähigkeit kann eine Patientenverfügung erstellen und sie jederzeit ändern oder widerrufen.',
    },
    legalSecurity: {
      title: 'Angehörige entlasten',
      description:
        'Ihre Vorsorge schenkt Ihren Angehörigen im Ernstfall Ruhe und entlastet sie von schwierigen Entscheidungen.',
    },
  },
  subBanner: {
    title: 'Gut geregelt mit einer rechtlich konformen Patientenverfügung',
    subtitle:
      'Mit gut geregelt erstellen Sie schnell Ihre Patientenverfügung, können rechtliche Beratung zu heiklen Themen oder offenen Fragen erhalten und tun Ihren Angehörigen einen Gefallen.',
    buttonLabel: 'Jetzt kostenlos erstellen',
  },
  newsletter: {
    title: 'Werden Sie gut geregelt mit Ihrer Patientenverfügung',
    buttonLabel: 'Jetzt erstellen',
  },
  faq: {
    questions: {
      question1: 'Was ist eine Patientenverfügung und wozu dient sie?',
      question2:
        'Was sind Voraussetzungen für die Erstellung einer Patientenverfügung?',
      question3: 'Wann tritt eine Patientenverfügung in Kraft?',
      question4: 'Was kann die Patientenverfügung verhindern?',
      question5:
        'Wie fungieren die in der Patientenverfügung definierten Vertretungspersonen?',
      question6:
        'Ist eine Kopie der Patientenverfügung gültig im Anwendungsfall?',
      question7:
        'Muss ich meine Patientenverfügung notariell beglaubigen lassen?',
      question8:
        'Darf eine Drittperson für mich die Patientenverfügung ausfüllen?',
      question9:
        'Was tue ich, wenn ich nicht selber unterschreiben kann (Blindheit, MS oder Parkinson)?',
      question10: 'Ist meine Patientenverfügung im Ausland auch gültig?',
      question11:
        'Kann ich auch verfügen, dass ich im Notfall nicht reanimiert werde?',
      question12:
        'Muss ich eine Vertretungsperson angeben? Was passiert, wenn ich keine Vertretungssperson angeben kann / will?',
      question13: 'Muss ich meine Vertretungsperson im Vorfeld informieren?',
      question14: 'Ist mein Ehepartner automatisch meine Vertretungsperson?',
      question15:
        'Kann ich mein minderjähriges Kind als Vertretungsperson einsetzen?',
      question16:
        'Wenn ich umziehe oder heirate, muss ich meine Patientenverfügung anpassen?',
      question17:
        'Soll ich meinem Hausarzt eine Kopie der Patientenverfügung abgeben?',
      question18: 'Ist meine Online-Patientenverfügung öffentlich einsehbar?',
      question19: 'Was wenn meine Patientenverfügung nicht umgesetzt wird?',
      question20: 'Wie kann ich die Gültigkeit sicherstellen?',
      question21:
        'Gibt es neben der Patientenverfügung weitere Dokumente, die ich erstellen sollte?',
      question22: 'Wie hilft mir gut geregelt bei meiner Patientenverfügung?',
    },
    answers: {
      answer1:
        'Eine Patientenverfügung ist eine einseitige, schriftliche Willensäusserung im Zustand der Urteilsfähigkeit. Darin geben Sie Ärzten und Pflegenden medizinische Handlungsanweisungen für den Fall, dass Sie zu einem späteren Zeitpunkt nicht mehr urteils- oder äusserungsfähig sind.',
      answer2: 'Urteilsfähigkeit in Bezug auf medizinische Entscheidungen.',
      answer3:
        'Eine Patientenverfügung tritt in Kraft, wenn zwei Faktoren zusammentreffen: Urteilsunfähigkeit und infauste Prognose. Eine solche Situation kann z.B. vorliegen bei schwerem Hirnschlag, fortgeschrittener Demenzerkrankung, Hirntumor in fortgeschrittenem Stadium, Koma oder Wachkoma nach Unfall, Operation oder Reanimation.',
      answer4:
        'dass durch medizinische Massnahmen der natürliche Tod hinausgezögert wird, dass Wiederbelebungsversuche unternommen werden, dass Maschinen ohne Aussicht auf Besserung Leben erhalten, künstliche Ernährung.',
      answer5:
        'Alle in der Patientenverfügung genannten Vertretungspersonen werden in der Reihenfolge ihrer Nennung zu Bevollmächtigten. Sie sind befugt, im Namen der verfügenden Person medizinische Entscheidungen zu treffen, wenn eine Situation eintritt, die in der Patientenverfügung nicht geregelt ist. Im Konfliktfall sieht das Gesetz vor, dass sich jede der urteilsunfähigen Person nahe stehende Person an die Erwachsenenschutzbehörde wenden kann.',
      answer6:
        'Ja, eine Kopie kann im Anwendungsfall zum Gebrauch kommen. Wir empfehlen dennoch, dass zumindest eine Vertretungsperson den Aufbewahrungsort der Originaldokumente kennt.',
      answer7:
        'Nein. Seit Inkrafttreten des neuen Erwachsenenschutzrechts im Jahr 2013 ist eine Patientenverfügung, die im Zustand der Urteilsfähigkeit erstellt wurde, rechtlich verbindlich.',
      answer8:
        'Ja, sofern Sie den Inhalt verstehen und am Ende eigenständig Datum und Unterschrift handschriftlich hinzufügen oder die Patientenverfügung eigenständig qualifiziert elektronisch signieren können.',
      answer9: 'Lassen Sie Ihre Patientenverfügung notariell beglaubigen.',
      answer10:
        'gut geregelt kann sich nur innerhalb der Schweiz für die Einhaltung einer Patientenverfügung einsetzen. Inwiefern eine Patientenverfügung im Ausland verbindlich ist, muss im jeweiligen Land abgeklärt werden.',
      answer11:
        'Grundsätzlich geht es im Notfall darum, Leben zu retten und zu stabilisieren, so dass oft keine Zeit bleibt, nach einer eventuell vorhandenen Patientenverfügung zu suchen. Das Rettungspersonal ist aber auch verpflichtet, den mutmasslichen Willen einer nicht ansprechbaren Person zu ermitteln und danach zu handeln.',
      answer12:
        'Eine Patientenverfügung ist auch dann rechtsgültig, wenn keine Vertretungsperson genannt ist, allerdings ist es in diesem Fall von Vorteil, wenn die Patientenverfügung jährlich aktualisiert wird.',
      answer13:
        'Wir empfehlen diese auf aus praktischen Gründen zu informieren. So weiss Ihr Vertreter, was von ihm erwartet wird.',
      answer14:
        'Nein. Der Ehepartner muss explizit auf der Patientenverfügung aufgeführt werden, wenn dies gewünscht ist.',
      answer15:
        'Ja, von Gesetzes wegen muss eine Vertretungsperson urteilsfähig, aber nicht unbedingt volljährig sein. Wir empfehlen, zusätzlich eine erwachsene Person zur Unterstützung beizuziehen.',
      answer16:
        'Eine Patientenverfügung sollte möglichst den aktuellen Lebensumständen angepasst sein. Wir empfehlen daher, jede Änderung zu berücksichtigen.',
      answer17: 'Wir empfehlen, dem behandelnden Arzt eine Kopie zuzustellen.',
      answer18: 'Nein, der Zugang ist passwortgeschützt.',
      answer19:
        'Sie oder Ihnen nahestehende Personen können schriftlich die Erwachsenenschutzbehörde kontaktieren oder anrufen und geltend machen, dass der Patientenverfügung nicht entsprochen wird oder die Interessen der urteilsunfähigen Person gefährdet oder nicht mehr gewahrt sind.',
      answer20:
        'gut geregelt hilft Ihnen bei der Sicherstellung der Rechtsgültigkeit. Jede Patientenverfügung muss das Erstellungsdatum und die persönliche Unterschrift der verfügenden Person enthalten.',
      answer21:
        'Ja, den {{powerOfAttorney}} und das {{lastWill}} sind zwei Dokumente, die Sie möglichst zeitnah erstellen sollten.',
      answer22:
        'Bei gut geregelt helfen wir Ihnen mit dem reibungslosen und rechtsgültigen Erstellen Ihrer Patientenverfügung. Damit Sie gut geregelt in die Zukunft blicken können.',
    },
  },
};

export default livingWillLanding;
