const tt = {
  title: 'Conditions Générales',
  scope: {
    title: 'Portée',
    content:
      'Ces Conditions Générales (CG) régissent l’utilisation des services de betascale AG (ci-après « bien réglé »). Bien réglé met à disposition du client les prestations pendant la durée du contrat dans leur version actuelle en contrepartie d’un paiement. La relation contractuelle entre bien réglé et le client est établie par l’acceptation de l’offre. En acceptant l’offre, le client reconnaît expressément ces CG, même si elles contredisent en tout ou partie ses propres conditions commerciales. Le client s’engage à fournir des informations correctes, complètes et actuelles à tout moment. Le non-respect de cette obligation constitue une violation des conditions d’utilisation et peut entraîner la résiliation immédiate du contrat.',
  },
  userRights: {
    title: 'Droits de l’utilisateur',
    content:
      'Bien réglé accorde au client, pour la durée du contrat, un droit non exclusif et non transférable d’utiliser les services conformément à leur destination. Le client n’est pas autorisé à mettre les services à disposition de tiers à titre onéreux ou gratuit. Toute forme de prestation de service à des tiers est expressément interdite. Le client doit s’assurer que tous les accords contractuels avec des tiers excluent l’utilisation gratuite du service.',
  },
  customerResponsibilities: {
    title: 'Obligations du client',
    content:
      'En plus des services fournis par bien réglé, le client est entièrement responsable de l’exploitation, de la sécurité et de l’état de ses supports électroniques et numériques utilisés pour les services de bien réglé (matériel, logiciel, exploitation, sécurité, etc.). Tous les coûts associés à ces obligations sont à la charge du client.',
  },
  systemAvailability: {
    title: 'Disponibilité du système',
    content:
      'Les services de bien réglé sont basés sur le principe du « meilleur effort ». Bien réglé prend des mesures appropriées pour assurer une utilisation aussi ininterrompue que possible des services. Toutefois, le client est conscient que le service et d’autres composants de tiers, dont la fonctionnalité est hors du contrôle de bien réglé, constituent un système techniquement complexe. Par conséquent, bien réglé ne peut garantir une disponibilité constante et complète des composants de plus de 99,5 %. Les interruptions dues à la maintenance du système, mises à jour, etc. seront annoncées à l’avance avec un préavis de deux jours ouvrables pour les travaux de maintenance planifiés. Les travaux de maintenance urgents, qui entraînent une interruption de la disponibilité, peuvent être effectués sans préavis préalable afin de résoudre immédiatement les problèmes ou de prévenir les menaces potentielles pour le système. Si bien réglé identifie un risque pour le fonctionnement correct en raison de l’action négligente ou intentionnelle de tiers, elle est autorisée à prendre immédiatement toutes les mesures nécessaires pour protéger sa propre infrastructure et logiciel contre les dommages.',
  },
  support: {
    title: 'Support',
    content:
      'Le support de bien réglé est disponible pour le client par e-mail à support@biengeregle.ch du lundi au vendredi de 8h00 à 12h00 et de 13h00 à 17h00, avec un temps de réponse moyen d’une heure.',
  },
  renumerations: {
    title: 'Rémunérations',
    content:
      'Le montant des rémunérations pour les services convenus est fixé séparément dans les listes de prix. Il s’agit généralement de frais récurrents. Les frais récurrents peuvent être ajustés après préavis. Ils sont dus dans les 20 jours ou peuvent être prélevés par carte de crédit. Les services additionnellement convenus sont facturés séparément.',
  },
  intellectualPropertyRights: {
    title: 'Droits de propriété intellectuelle',
    content:
      'Le client reconnaît les droits de propriété intellectuelle, en particulier le droit d’auteur, de bien réglé sur les logiciels, services et documentation. Il n’est pas permis au client de mettre ces logiciels à la disposition de tiers à titre onéreux ou gratuit, de les louer en dehors de la relation contractuelle avec bien réglé et/ou de les utiliser ou de contester les droits de bien réglé de quelque manière que ce soit.',
  },
  dataProtection: {
    title: 'Protection des données et sécurité des données',
    content:
      'Bien réglé traitera les données du client avec la plus grande prudence et les protégera contre les abus et la perte. Bien réglé ne stocke aucune donnée personnelle de ses clients. Néanmoins, bien réglé prend des mesures techniques et organisationnelles qui répondent aux exigences applicables du RGPD. Des accords particuliers concernant le stockage des données peuvent être établis dans certains pays dans le cadre des possibilités techniques. Le client est responsable de la légalité du transfert des données ou de leur utilisation. Toutes les données du client stockées et traitées par bien réglé sont la propriété exclusive du client et sont utilisées par bien réglé uniquement aux fins de l’exécution du contrat.',
  },
  disclaimerLiability: {
    title: 'Exclusion de responsabilité',
    content:
      'Bien réglé garantit que les services sont fonctionnels et prêts à fonctionner conformément aux dispositions légales de ce contrat. Le client s’engage à dégager bien Réglé de toute revendication de tiers en raison des données stockées et à rembourser bien réglé les coûts qui résultent de violations légales éventuelles. Bien réglé est autorisé à bloquer immédiatement l’espace de stockage si il existe un soupçon fondé que les données stockées sont illégales et/ou enfreignent les droits de tiers. Un soupçon fondé d’illégalité et/ou de violation est présent lorsque des tribunaux, autorités et/ou tiers informent bien Réglé à ce sujet. Bien réglé informera immédiatement le client de la suppression et des raisons de celle-ci. L’interdiction doit être levée dès que le soupçon est complètement écarté. Bien Réglé exclut toute responsabilité envers le client (ou tiers), en particulier pour l’exécution de ses obligations contractuelles et extracontractuelles, ainsi que pour la perte de données et le manque à gagner (même en cas de négligence grave), dans le cadre des dispositions légales. Cette exclusion de responsabilité s’applique également aux dommages qui surviennent directement ou indirectement par l’utilisation de nos services. La responsabilité mutuelle des parties, quel que soit le motif de responsabilité, est en tout cas limitée au montant des honoraires de service mensuels des douze derniers mois avant l’occurrence du dommage.',
  },
  communication: {
    title: 'Communication',
    content:
      'Bien réglé est autorisé à mentionner le client comme référence et à utiliser de manière appropriée des informations générales sur le contrat convenu à des fins de marketing et de vente.',
  },
  amendments: {
    title: 'Modifications',
    content:
      'Bien réglé se réserve le droit de modifier ou de remplacer ces Conditions Générales à tout moment. En cas de modification, bien réglé essaiera d’informer le client au moins 30 jours avant l’entrée en vigueur des nouvelles conditions.',
  },
  severabilityClause: {
    title: 'Clause de sauvegarde',
    content:
      'En cas d’invalidité totale ou partielle de certaines dispositions de ce contrat, les dispositions invalides ou inapplicables seront mises en œuvre ou complétées de manière à atteindre le but économique poursuivi par la disposition invalide. Il en va de même en cas de lacunes dans ce contrat.',
  },
  jurisdiction: {
    title: 'Juridiction',
    content:
      'Les parties conviennent que le droit suisse s’applique à toutes les relations juridiques découlant de ce contrat. Le lieu de juridiction exclusif pour tous les litiges découlant de ce contrat est Liestal BL (Suisse).',
  },
};

export default tt;
