import powerOfTrustQuestions from './questions';
import outputDoc from './outputDoc';

const powerOfAttorney = {
  mainHeaders: {
    shortPainFree: 'Vorsorgeauftrag: kurz und schmerzfrei',
    madeIt: 'Ihr wirksamer Vorsorgeauftrag',
  },

  stepperInfo: [
    {
      label: 'Vertrauensvollmacht',
      title: 'Worum geht es?',
    },
    {
      label: 'Ihre Vorsorgeauftrag',
      title: 'Hinterlegen Sie Ihr Vorsorgeauftrag',
    },
  ],

  stepOne: {
    introParagraph:
      'Leider gibt es Situationen, die zu unserer Urteilsunfähigkeit führen können. Beispiele dafür sind schwere Unfälle oder Krankheiten wie Demenz',
    helperParagraph:
      'Wir empfehlen Ihnen daher, frühzeitig durch eine so genannte Patientenverfügung zu regeln, wer sich in einer solchen Situation wie um Sie kümmern und Sie auch rechtlich vertreten soll. Dies entlastet Ihre Angehörigen in einer ohnehin schwierigen Situation, da Ihre Wünsche klar formuliert sind.',
    helperParagraph2:
      'Fangen wir an: Dieser Abschnitt dauert nur etwa 5 Minuten. Wenn Sie eine Pause brauchen, können Sie jederzeit anhalten und zwischenspeichern..',
    questions: powerOfTrustQuestions,
  },

  stepTwo: {
    introParagraph: 'Herzlichen Glückwunsch! Sie haben es geschafft.\n',
    helperParagraph:
      'Der schwierigste Schritt ist nun getan. Laden Sie das Dokument runter, drucken Sie es aus und lesen Sie es sorgfältig durch, um zu sehen, ob es wirklich Ihren Wünschen entspricht. Wenn Sie zufrieden sind, empfehlen wir Ihnen, den Vorsorgeauftrag mit einer Person Ihres Vertrauens und Ihrer Familie zu besprechen.',
    helperParagraph2:
      'Es empfiehlt sich, den Vorsorgeauftrag bei einem Anwalt oder Notar zu hinterlegen, dieser kann den Aufbewahrungsort auf Ihren Wunsch beim Schweizerischen Testamentsregister (https://www.ztr.ch) registrieren.',
    contactForm: 'Kontaktformular',
    helperParagraph3:
      'Wenn Sie möchten, dass wir Ihnen helfen, nutzen Sie bitte unser Kontaktformular.',
    obligation1:
      'Der Vorsorgeauftrag ist eigenhändig zu errichten oder öffentlich zu beurkunden. Der eigenhändige Vorsorgeauftrag ist von der auftraggebenden Person von Anfang bis Ende von Hand niederzuschreiben, zu datieren und zu unterzeichnen.',
    obligation2:
      'Wenn Sie unsicher sind, kann Ihnen ein Anwalt oder ein Notar dabei helfen.',
    document: outputDoc,
    nextStepsTitle: 'Die nächsten Schritte:',
    nextStep1:
      'Entweder die Vorsorgeauftrag komplett von Hand abschreiben, datieren und unterschreiben oder bei einem Anwalt beglaubigen lassen und hier hochladen',
    nextStep2:
      'Sorgen Sie dafür, dass Vertrauenspersonen einen Gastzugang für gut geregelt haben, damit diese im Notfall auf die Vollmacht zugreifen können (hier klicken)',
    nextStep3: 'Besprechen Sie den Vorsorgeauftrag mit der Familie',
    highlightedText: {
      noterizeWithLawyer: 'Anwalt beglaubigen lassen',
    },
  },
};

export default powerOfAttorney;
