import { Outlet } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import React, { useState } from 'react';
import Header from '#/components/shared/ui/header';
import FooterLinks from '#/components/pages/Landing/Footer';
import Footer from '#/components/shared/ui/footer/Footer';

export default function MainLayout() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 1,
      }}
    >
      <Header onOpenNav={handleOpen} openNav={open} onCloseNav={handleClose} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: '100vh',
          position: 'relative',
          pt: { xs: 8, md: 11 },
        }}
      >
        <Stack spacing={18}>
          <Outlet />
          <div>
            <FooterLinks />
            <Footer isLanding />
          </div>
        </Stack>
      </Box>
    </Box>
  );
}
