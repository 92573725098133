const adminDashboard = {
  main: {
    title: 'Dashboard',
    description:
      'See how many of your customers visit gut geregelt, create a login and then also buy a package.',
  },
  analytics: {
    cards: {
      visitors: 'Visitors',
      loginsCreated: 'Logins created',
      conversionRate: 'Conversion rate',
      packagesSold: 'Packages sold',
    },
  },
  legalAdvisors: 'Legal Advisors',
  financialAdvisors: 'Financial Advisors',
  platform: 'Platform',
  user: 'User',
  mainInfoModal: {
    title: 'Please fill in missing information',
    description:
      'Since this account was created by an invitation, we are missing some information. Please fill in the missing information to continue using the platform.',
  },

  consulting: {
    unlockWidget: {
      title: 'Unlock widget',
      description:
        'Request an offer and activate widgets for you and your advisors to provide customers with simplified pension advice during client meetings.',
    },
  },
  teamTable: {
    tableHeads: {
      name: 'Name',
      email: 'Email',
      role: 'Role',
      last_login: 'Last Login',
    },
  },
  consultantTable: {
    tableHeads: {
      name: 'Name',
      email: 'Email',
      location: 'Location',
      added: 'Added',
    },
  },
  deleteUser: 'Delete user',
  deleteConsultant: {
    title:
      'You are about to remove {{consultant}} from the list. Are you sure?',
    description: 'Please confirm the deletion.',
    onConfirm: 'Delete',
    onClose: 'Cancel',
  },
};
export default adminDashboard;
