import smartPalette from './smartpurse-theme/palette';
import finFinderPalette from './finfinder-theme/palette';
import typography from '../typography';
import breakpoints from '../breakpoints';
import shadows, { customShadows } from '../shadows';
import palette from './random-theme/palette';

// eslint-disable-next-line import/prefer-default-export
export const generatePartnerTheme = (
  partner: string,
  isLight: boolean,
  primaryColor: string,
  secondaryColor: string
) => {
  switch (partner) {
    case 'smartpurse':
      return {
        palette: isLight ? smartPalette.light : smartPalette.dark,
        typography,
        breakpoints,
        shape: { borderRadius: 8 },
        direction: 'ltr',
        shadows: isLight ? shadows.light : shadows.dark,
        customShadows: isLight ? customShadows.light : customShadows.dark,
      };
    case 'finfinder':
      return {
        palette: isLight ? finFinderPalette.light : finFinderPalette.dark,
        typography,
        breakpoints,
        shape: { borderRadius: 8 },
        direction: 'ltr',
        shadows: isLight ? shadows.light : shadows.dark,
        customShadows: isLight ? customShadows.light : customShadows.dark,
      };
    default:
      return {
        palette: isLight
          ? palette(primaryColor, secondaryColor).light
          : palette(primaryColor, secondaryColor).dark,
        typography,
        breakpoints,
        shape: { borderRadius: 8 },
        direction: 'ltr',
        shadows: isLight ? shadows.light : shadows.dark,
        customShadows: isLight ? customShadows.light : customShadows.dark,
      };
  }
};
