const digitalFootprint = {
  mainBanner: {
    title:
      'Régulez et sécurisez votre héritage numérique dans un coffre-fort sécurisé',
    subtitle:
      'Assurez-vous que toutes vos données ou actifs électroniques, appareils et assurances sont sécurisés et peuvent être accessibles aux personnes autorisées en cas de décès.',
    buttonLabel: "Sécuriser l'héritage",
  },
  youtubeTutorial: 'Comment préserver votre héritage numérique?',
  aboutCards: {
    documentAccounts: {
      title: 'Documentez vos comptes',
      description:
        'Transférez facilement tous les comptes de votre gestionnaire de mots de passe à bien réglé. Ne vous inquiétez pas. Nous ne voulons explicitement aucun mot de passe!',
    },
    connectDigitalValues: {
      title: 'Représentez les valeurs numériques',
      description:
        'Listez tous vos portefeuilles numériques, domaines et autres valeurs et décrivez où et comment votre famille peut trouver les mots de passe pour y accéder.',
    },
    preserveDigitalData: {
      title: 'Préservez les données numériques',
      description:
        "Organisez et conservez des données telles que des images ou des vidéos, de la musique, des documents importants et d'autres données dans un coffre-fort sécurisé.",
    },
  },
  subBanner: {
    title: 'Assurez-vous de laisser tout propre et bien rangé',
    subtitle:
      'Sauf disposition expresse dans votre dernier testament ou contrat de testament, vos actifs numériques seront transmis à vos héritiers légaux en cas de décès. Vous devriez donc décider de votre vivant ce qui doit être supprimé ou transféré après votre décès.',
    buttonLabel: "Régler l'empreinte numérique",
  },
  testimonials: {
    testimonial1: {
      name: 'Patrick Zesiger',
      text: "L'héritage numérique de bien réglé m'a aidé à garder mes affaires numériques en parfait ordre. Un must pour quiconque fait des choses dans le monde numérique!",
    },
    testimonial2: {
      name: 'Sara Becker',
      text: "Savoir que ma famille n'a pas à se soucier du nettoyage et que tous mes canaux de médias sociaux peuvent être fermés me donne une bonne sensation. Super facile à mettre en place. Je le recommande vivement.",
    },
    testimonial3: {
      name: 'Luisa Stauffer',
      text: "J'ai récemment mis en place l'héritage numérique avec et pour mon père et ma mère. C'était très rapide et simple. Même mon père était content que nous ayons enfin terminé cela.",
    },
  },
  newsletter: {
    title: "Devenez bien réglé dans l'héritage numérique",
    buttonLabel: 'Organiser maintenant',
  },
  faq: {
    questions: {
      question1:
        "Qu'est-ce qu'un héritage numérique et pourquoi est-il important?",
      question2:
        "Quels types d'actifs numériques peuvent être inclus dans l'héritage numérique?",
      question3: "Comment fonctionne le service d'héritage numérique?",
      question4:
        "Comment puis-je m'assurer que mes proches ont accès à mon héritage numérique?",
      question5:
        'Que se passe-t-il avec mes actifs numériques lorsque je meurs?',
      question6:
        'Quelle est la sécurité de mes informations personnelles avec votre service?',
      question7: 'Puis-je mettre à jour ou modifier mon héritage numérique?',
      question8:
        "Quels aspects juridiques doivent être pris en compte lors de la gestion d'un héritage numérique?",
      question9:
        'Que se passe-t-il avec mes abonnements en ligne et mes comptes après ma mort?',
      question10:
        "Comment votre service soutient-il la gestion des documents d'assurance et de banque?",
      question11:
        "Est-ce que bien réglé stocke des mots de passe ou d'autres données d'accès?",
    },
    answers: {
      answer1:
        "L'héritage numérique comprend tous les actifs numériques et les informations stockées en ligne d'une personne. Cela peut aller des comptes de médias sociaux aux abonnements en ligne en passant par les portefeuilles de crypto-monnaies. Il est important de gérer l'héritage numérique pour s'assurer que vos affaires numériques sont correctement gérées après votre décès et pour faciliter l'accès à des informations importantes pour vos proches.",
      answer2:
        "Notre service vous permet de documenter une variété d'actifs numériques, y compris les comptes de médias sociaux, les abonnements en ligne, les portefeuilles de crypto-monnaies, les domaines, les gestionnaires de mots de passe, les appareils électroniques et bien plus encore. Vous pouvez documenter tous les actifs numériques pertinents et laisser des instructions sur ce qui doit se passer avec eux après votre décès et comment y accéder.",
      answer3:
        'Notre service vous offre une plateforme conviviale où vous pouvez documenter, gérer et laisser des instructions pour la gestion de vos actifs numériques après votre décès. Vous pouvez définir qui doit avoir accès à quelles informations et définir ce qui doit se passer avec vos actifs numériques.',
      answer4:
        'Avec notre service, vous pouvez désigner des personnes spécifiques qui auront accès à votre héritage numérique. Vous pouvez également laisser des instructions détaillées sur la façon dont vos actifs numériques doivent être gérés pour vous assurer que vos proches ont accès à des informations importantes.',
      answer5:
        "Après votre décès, les personnes que vous avez désignées auront accès à votre compte bien réglé en présentant le certificat de décès et le pouvoir du testamentaire. Cela garantit qu'ils peuvent gérer vos actifs numériques selon vos instructions. Vous pouvez définir si certains comptes doivent être supprimés, continués ou transférés à certaines personnes. Notre service assure que vos affaires numériques sont gérées selon vos souhaits.",
      answer6:
        "Nous prenons la sécurité de vos informations personnelles très au sérieux. Notre service utilise des mesures de sécurité standard de l'industrie pour protéger vos données, y compris des technologies de cryptage et des restrictions d'accès. De plus, nous ne stockons pas de données sensibles comme les mots de passe, les soldes de compte et autres.",
      answer7:
        "Oui, vous pouvez mettre à jour ou modifier votre héritage numérique à tout moment. Notre plateforme vous permet d'ajouter de nouveaux actifs, de supprimer ou de modifier des informations existantes et de laisser de nouvelles instructions.",
      answer8:
        "Lors de la gestion d'un héritage numérique, des aspects juridiques tels que la rédaction d'un testament et la détermination des héritiers doivent être pris en compte. Il est conseillé de consulter un conseiller juridique pour s'assurer que vos affaires numériques sont gérées conformément aux lois en vigueur.",
      answer9:
        'Après votre décès, vos abonnements en ligne et vos comptes peuvent être gérés selon vos instructions. Vous pouvez définir si certains abonnements doivent être annulés, les comptes supprimés ou transférés à certaines personnes.',
      answer10:
        "Notre service offre des fonctionnalités pour organiser vos relations d'assurance et bancaires. Vous pouvez indiquer où trouver quels documents. Nous soutenons également la création automatique de lettres de résiliation pour les polices d'assurance après votre décès, une fois que le certificat de décès et le pouvoir du testamentaire sont présentés.",
      answer11:
        "Non. Nous le soulignons explicitement et offrons même un soutien pour que vous, en tant que client, NE stockiez ou n'affichiez AUCUN mot de passe dans bien réglé. Au lieu de cela, nous vous encourageons à stocker séparément les données d'accès pour les comptes pertinents et à les conserver dans un endroit sûr auquel votre famille a accès. Vous documentez cet endroit sûr pour vos proches dans bien réglé.",
    },
  },
};

export default digitalFootprint;
