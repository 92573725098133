const de = {
  homeTitle: 'Gut geregelt werden',
  homeDescription:
    'Treffen Sie jetzt Ihre persönliche und familiäre Vorsorge oder sprechen Sie mit einem Finanz- oder Rechtsberater.',
  homeHighlight: 'Finanz',
  homeHighlight2: 'Rechtsberater',

  clear: 'Löschen',
  search: 'Suchen',
  done: 'Fertig',

  ch: 'Schweiz',
  noData: 'Keine Daten',
  beneficiaries: 'Begünstigte',
  representatives: 'Vertreter',

  service: 'Service',
  accessLevel: 'Zugriffsrecht',
  addServiceAccess: 'Artefakt-Zugriff hinzufügen',
  afterDeath: 'Nach dem Tod',
  whileAlive: 'Während Lebzeiten',

  customerOpinion: 'Kundenstimmen',
  joinWaitlist: 'Warteliste beitreten',

  civilStatus: {
    married: 'Verheiratet',
    single: 'Ledig',
    divorced: 'Geschieden',
    widowed: 'Verwitwet',
    registered_partnership: 'Eingetragener partnerschaft',
  },

  contractParties: {
    self: 'Ich',
    spouse: 'Ehepartner',
  },

  dashCardInfos: {
    livingWill: {
      title: 'Patientenverfügung',
      description:
        'Erledigen Sie Ihre Patientenverfügung in weniger als 7 Minuten.',
    },
    powerOfAttorney: {
      title: 'Vorsorgeauftrag',
      description: 'Wir gehen davon aus, dass dies 5 Minuten dauern wird.',
    },
    lastWill: {
      title: 'Testament',
      description:
        '80% der Menschen haben ihren letzten Willen innerhalb von 10 Minuten.',
    },
    marriageContract: {
      title: 'Ehevertrag',
      description:
        'Bringen Sie Ihren Ehepartner in eine bessere finanzielle Lage.',
    },
    digitalFootprint: {
      title: 'Digitaler Nachlass',
      description: 'Hinterlassen Sie einen sauberen digitalen Nachlass.',
    },
    assetsRegister: {
      title: 'Vermögens- und Sachregister',
      description: 'Digitale Abbildung Ihrer Vermögenswerte.',
    },
    cohabitationAgreement: {
      title: 'Konkubinatsvertrag',
      description: 'Regeln Sie Ihr Leben gemeinsam',
    },
    successionPlanning: {
      title: 'Nachfolgeplanung',
      description:
        'Sie besitzen ein Unternehmen?! Stellen Sie seine Nachhaltigkeit sicher.',
    },
    comingSoon: {
      title: 'Demnächst',
      description: 'gut geregelt arbeitet stetig an neuen Services.',
    },
    continue: 'Weitermachen',
    getStarted: 'Loslegen',
    summaryAndReview: 'Dokument ansehen',
    uploadDocument: 'Dokument hochladen',
    update: 'Aktualisieren',
    history: 'Historie',
    markAsDone: 'Als erledigt markieren',
    startOver: 'Von vorne beginnen',
    viewData: 'Daten ansehen',
    guest: {
      disabledButton:
        'Der Host hat zusätzliche Einschränkungen für den Zugriff auf diesen Dienst hinzugefügt.',
      getDocButton: 'Dokument abrufen',
      noDocumentUploaded:
        '{{hostName}} hat noch kein {{documentType}} hochgeladen.',
    },
  },

  loginInfo: {
    title: 'Tun Sie Ihren Liebsten einen Gefallen, werden Sie gut geregelt',
    title1: 'Hallo, willkommen zurück',
    newUser: 'Neuer Benutzer?',
    createAccount: 'Benutzerkonto erstellen',
    forgotPassword: 'Passwort vergessen?',
    login: 'Anmelden',
    resetPasswordInfo:
      'Bitte geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein und wir senden Ihnen per E-Mail einen Link zum Zurücksetzen Ihres Passworts.',
    sendRequest: 'Anfrage senden',
    returnToLogin: 'Zurück zur Anmeldung',
    setNewPassword: 'Neues Passwort festlegen',
    updatePassword: 'Passwort aktualisieren',
    invalidLink: 'Link ist ungültig oder abgelaufen!',
    returnToResetPassword: 'Zurück zum Zurücksetzen des Passworts',
    welcomeOnboard: 'Willkommen an Bord',
    welcomeOnboardDescription:
      'Um zum Gutgeregelt dashboard weiterzugehen, setzen Sie bitte ein neues Passwort.',
  },

  services: {
    forIndividuals: 'Für Privatpersonen',
    forCompanies: 'Für Unternehmen',
    gutGeregelt: 'gut geregelt',
    livingWill: 'Patientenverfügung',
    powerOfAttorney: 'Vorsorgeauftrag',
    lastWill: 'Testament',
    marriageContract: 'Ehevertrag',
    digitalFootprint: 'Digitaler Nachlass',
    registerAssets: 'Vermögens- und Sachregister',
    cohabitationAgreement: 'Konkubinatsvertrag',
    pricing: 'Preise',
    login: 'Anmelden',
    makeProvisions: 'Jetzt vorsorgen',
    pensionCheck: 'Vorsorgecheck',
    partnerWithUs: 'Whitelabel-Partner',
    blog: 'Blog',
    dashboard: 'Dashboard',
    consulting: 'Beratung',
    aboutUs: 'Über uns',
  },

  settings: {
    changeAccount: 'Konto ändern',
    changeAccountContent: {
      title: 'Zu einem anderen Konto wechseln',
    },
    settings: 'Einstellungen',
    needHelp: 'Brauchen Sie Hilfe?',
    logOut: 'Abmelden',
  },

  roles: {
    executor: 'Vollstrecker',
    lawyer: 'Anwalt',
    trustee: 'Treuhänder',
    familyMember: 'Familienmitglied',
    friend: 'Freund',
  },

  editAccessRights: 'Zugriffsrechte bearbeiten',
  editAccessRightsDscp:
    'Legen Sie fest, welche Daten die Institution einsehen darf. Sie können jederzeit Anpassungen vornehmen oder den Zugriff komplett verweigern.',
  shareSelected: 'Nur ausgewählte Daten freigeben',
  acceptingInvitation: 'Einladung annehmen',
  acceptingInvitationFail: 'Einladung abgelaufen oder ungültig...',
  removeAll: 'Alle entfernen',
  account: 'Konto',
  main: 'Haupt',
  guest: 'Gast',
  manage: 'Verwalten',
  trackHistoryTitle:
    'Unten können Sie die hochgeladenen Dokumente und die Geschichte des {{documentType}} verfolgen',
  getStarted: 'Loslegen',
  resetToDefault: 'Zurücksetzen',
  lastUpdated: 'Letze Aktualisierung:',
  description: 'Beschreibung',
  imageHelper: 'Erlaubte Dateiformate: *.jpeg, *.jpg, *.png, *.gif, ',
  imageMaxSize: 'maximale Dateigrösse: ',
  storageLocation: 'Aufbewahrungsort',
  beneficiary: 'Begünstigte Person',
  continue: 'Weiter',
  beneficiarie: 'Begünstigung zu Handen',
  removeFilter: 'Filter entfernen',
  addNewBeneficiary: 'Neuer Begünstigter hinzufügen',
  primary: 'Hauptkontakt',
  representative: 'Stellvertreter',
  addNewAsset: 'Neues Gut hinzufügen',
  addNewProperty: 'Neue Immobilie hinzufügen',
  uploadNew: 'Neu hochladen',
  addMore: 'Weitere hinzufügen',
  saveDetails: 'Details speichern',
  saving: 'Speichern...',
  enoughForNow: 'Pause bitte!',
  finishForToday: 'Für heute reicht es',
  currentPackage: 'Sie haben das folgende Vorsorgepaket gewählt:',
  freePackage: 'Kostenloses Paket',
  userDataAccess: {
    request: 'Datenzugriff anfordern',
    awaiting: 'Warten auf Zugriffsgenehmigung',
    approved: 'Kundendaten anzeigen',
  },
  starterPackage: 'Starter Paket',
  standardPackage: 'Standard Paket',
  familyPack: 'Familien Paket',
  noPackage: 'Kein Paket',
  needHelp: 'Brauchen Sie Hilfe?',
  continueToLastWill: 'Weitermachen mit Testament',
  continueToPOA: 'Weitermachen mit Vorsorgeauftrag',
  addAnother: 'Vertrauensperson hinzufügen',
  add: 'Hinzufügen',
  finish: 'Fertig',
  delete: 'Löschen',
  edit: 'Bearbeiten',
  save: 'Speichern',
  close: 'Schliessen',
  next: 'Weiter',
  back: 'Zurück',
  or: 'oder',
  role: 'Rolle',
  current: 'Aktuell',
  gender: 'Geschlecht',
  male: 'Männlich',
  servicesToShare: 'Dienstleistungen, die ich gerne teilen möchte',
  howMany: 'Wie viele',
  female: 'Weiblich',
  cancel: 'Abbrechen',
  priority: 'Priorität',
  areYouSureYouWantToLeave: 'Möchten Sie eine Pause einlegen?',
  enoughForNowDscp:
    'Keine Sorge, wir speichern Ihre Eingaben und erinnern Sie regelmässig daran, dass dieses Dokument noch nicht rechtsgültig abgeschlossen ist. Sie können jederzeit fortfahren, wo sie aufgehört haben',
  enoughForNowCancel: 'Ja, ich möchte eine Pause',
  enoughForNowContinue: 'Nein, danke',
  addPropertyOrAsset: 'Eigenschaft oder Vermögenswert hinzufügen',
  addItem: 'Vermögenswert hinzufügen',
  good: 'vermögen',
  addNew: 'Neu hinzufügen',
  owner: 'Besitzer',
  adress: 'Adresse',
  download: 'Herunterladen',
  upgradePlan:
    'Erweitern Sie Ihren Plan, um zwei oder mehr Dokumente herunterzuladen',
  choosePlan: 'Wählen Sie einen Plan',
  switzerland: 'Schweiz',
  fillInfoTitle:
    'Bitte stellen Sie sicher, dass Sie wichtige Informationen für Ihr Hauptkonto angeben',
  switch: 'Wechseln',
  activatePersonalAccount: 'Aktivieren Sie Ihr persönliches Konto',
  howManyMembers: 'Familienmitglieder',
  requiredBasicInfoTitle:
    'Bitte geben Sie die erforderlichen Basisinformationen ein, bevor Sie fortfahren',

  familyPackage: {
    title: 'Meine Familie',
    description:
      'Mit dem Familienpaket können Sie und Ihre Familienmitglieder vergünstigt Ihre Vorsorge regeln.',
    helperText:
      'Bitte füllen Sie die Informationen Ihrer Familienmitglieder sorgfältig aus. Diese Informationen können nicht aktualisiert werden, sobald der Benutzer die Einladung akzeptiert hat.',
  },
  deleteConfirmation: {
    question: 'Sind Sie sicher, dass Sie {{item}} löschen möchten?',
    cancelDelete: 'Nein, {{item}} behalten',
    confirmDelete: 'Ja, {{item}} löschen',
  },

  formLabels: {
    name: 'Name',
    firstName: 'Vorname',
    lastName: 'Nachname',
    civilStatus: 'Zivilstand',
    place_of_citinzenship: 'Heimatort',
    place_of_marriage: 'Ort der Eheschliessung',
    date_of_marriage: 'Datum der Eheschliessung',
    children_count: 'Anzahl Kinder',
    street: 'Strasse',
    houseNumber: 'Nummer',
    postalCode: 'PLZ',
    city: 'Ort',
    country: 'Land',
    uploadPhoto: 'Foto hochladen',
    twoFactorAuth: 'Zwei-Faktor-Authentifizierung',
    verify: 'Verifizieren',
    uploadLogo: 'Logo hochladen',
    shareForFemalePartner: 'Anteil für Ihre Partnerin (in %)',
    shareForMalePartner: 'Anteil für Ihr Partner (in %)',
    submit: 'Absenden',
    invite: 'Einladen',
    emailAddress: 'E-Mail-Adresse',
    role: 'Rolle',
    twoFactorAuthHelp:
      'Wenn Sie 2FA aktivieren, wird ein Code an Ihr Telefon gesendet, den Sie eingeben müssen, um sich anzumelden.',
    region: 'Region',
    artifact: 'Artefakt',
    search: 'Suchen',
    mobilePhone: 'Handynummer',
    description: 'Beschreibung',
    storageLocation: 'Aufbewahrungsort',
    designatedBeneficiary: 'Begünstigte Person',
    benefiterAfterSeparation:
      'Im Falle einer Trennung wird das Eigentum alleiniges Eigentum von',
    password: 'Passwort',
    oldPassword: 'Altes Passwort',
    newPassword: 'Neues Passwort',
    confirmPassword: 'Passwort bestätigen',
    minimum6Characters: 'Mindestens 6 Zeichen',
    primaryRepresentative: 'Hauptvertreter',
    secondaryRepresentative: 'Stellvertreter',
    howManyChildren: 'Wie viele Kinder haben Sie?',
    assetType: 'Art des Vermögenswerts',
    date_of_birth: 'Geburtsdatum',
    rep_inform_date: 'Informationsdatum',
    trusted_person: 'Willensvollstrecker',
    billing_email: 'Rechnungs-E-Mail',
    asset: 'Vermögenswert',
    newConsultant: 'Neuer Berater',
    institutionName: 'Finanzinstitut',
    message: 'Nachricht',
    location: 'Standort',
    topics: 'Themen',
    type: 'Typ',
    plusTopic: '+ Thema',
    propertyType: 'Art der Immobilie',
    disposal_fixed_amount: 'Fester Betrag',
    amount: 'Betrag',
    passportLocation: 'Passort Aufbewahrungsort',
    date_of_first_payment: 'Datum der ersten Zahlung',
    meet_year: 'Jahr',
    date_of_moving_in: 'Einzugsdatum',
    idCardLocation: 'Identitätskarte Aufbewahrungsort',
    residencePermitLocation: 'Aufenthaltsbewilligung Aufbewahrungsort',
    familyBookLocation: 'Familienbüchlein Aufbewahrungsort',
    birthCertificateLocation: 'Geburtsurkunde Aufbewahrungsort',
    disposal_percentage: 'Prozentsatz',
    joint_household_account_bank: 'Gemeinschaftskonto Bank',
    plusUser: '+ Benutzer',
    domain: 'Domain',
    addWidget: 'Widget hinzufügen',
    plusWidget: '+ Widget',
    organisation_name: 'Organisationsname',
    primary_color: 'Primärfarbe',
    secondary_color: 'Sekundärfarbe',
    sendAutomatically:
      'Automatisch teilen, wenn ich die Patientenverfügung aktualisiere',
    deathReport: 'Todesfall melden',
    executorCertificate: 'Vollstreckerzeugnis',
    insuranceCompany: 'Versicherungsgesellschaft',
    insurancePolicyNumber: 'Policen-Nummer',
    ahvFund: 'AHV-Fonds',
    ahvNumber: 'AHV-Nummer',
    pensionFundOrganization: 'Pensionskassenorganisation',
    financialInstitution: 'Finanzinstitut',
    trustedPerson: 'Willensvollstrecker',
    select: 'Auswählen',
    cadenceSelect: 'Kadenz auswählen',
    expenses_to_be_covered: 'Zu deckende Kosten',
    workload: 'Arbeitspensum (in %)',
    shareYouPay: 'Anteil, den Sie zahlen (in %)',
    afterDeath: 'Nach dem Tod',
    access: 'Zugang',
    executor: 'Testamentvollstrecker',
    trustees_list: {
      husband_wife: 'Ehemann, Ehefrau',
      partner: 'Partner, Partnerin',
      'close-friend': 'Freund, Freundin',
      'brother-sister': 'Bruder, Schwester',
      'brother-sister-in-law': 'Schwager, Schwägerin',
    },
    beneficiarieCategories: {
      selectCategory: 'Kategorie auswählen',
      individual: 'Einzelne Person',
      company: 'Unternehmen',
      foundation: 'Stiftung',
      association: 'Verein',
    },
    ordinalNumbers: {
      first: 'ersten',
      second: 'zweiten',
      third: 'dritten',
      fourth: 'vierten',
      fifth: 'fünften',
      sixth: 'sechsten',
      seventh: 'siebten',
      eighth: 'achten',
      ninth: 'neunten',
      tenth: 'zehnten',
      information: 'Informationen zum {{ordinalNumber}} Kind',
    },
    good_description: 'Beschreibung des Gutes',
    approximate_value: 'Ungefährer Wert',
    storage_location: 'Aufbewahrungsort',
    previous_owner: 'Vorheriger Besitzer',
    font_settings: 'Schrifteinstellungen',
    title_font: 'Überschrift Schrift',
    subtitle_font: 'Untertitel Schrift',
    label_font: 'Label Schrift',
    prose_font: 'Text Schrift',
    link_font: 'Link Schrift',
  },

  userSettings: {
    general: 'Allgemein',
    billing: 'Abrechnung',
    my_family: 'Meine Familie',
    successors: 'Vertrauenspersonen',
    notifications: 'Benachrichtigungen',
    dataSharing: 'Daten',
    changePassword: 'Passwort ändern',
    changeReminder:
      'Ändern Sie die Häufigkeit der Erinnerungen, wenn ein Prozess unterbrochen wird',
    reminder: 'Erinnerung',
    reminderFrequency: 'Erinnerungsfrequenz',
    frequencyChoices: {
      weekly: 'Wöchentlich',
      monthly: 'Monatlich',
      quarterly: 'Vierteljährlich',
      disabled: 'Deaktivieren',
    },
    successor: {
      title: 'Vertrauenspersonen definieren',
      description:
        'Um sicherzustellen, dass Ihre Dokumente und Informationen im Falle Ihres Todes verfügbar sind, sollten Sie sicherstellen, dass mindestens zwei Personen Zugang zu gut geregelt haben.',
      pleaseConfirm:
        'Bitte bestätigen Sie, dass die folgenden Personen ab heute Gastzugang erhalten (mit eingeschränktem Zugang - siehe, was enthalten ist) und im Falle Ihres Todes uneingeschränkten Zugang zu allen Daten haben werden.',
      agree: 'Ja, ich stimme zu',
      addSuccessor: 'Vertrauenspersonen hinzufügen',
      access: {
        modal: {
          title: 'Definieren Sie individuelle Zugriffsberechtigungen',
        },
      },
    },

    dataSharingPage: {
      noSharingTitle: 'Daten teilen',
      toggleLabel: 'Ich möchte meine Daten teilen',
      currentlySharing: 'Datenzugriff für {{partnerName}}',
      partnerListLabel: 'Partnerorganisation',
      changedOrganisation: 'Organisation geändert?',
      info: 'Wenn Sie das Teilen von Daten mit einer Partnerorganisation von gut geregelt aktiviert haben, stimmen Sie zu, folgende Daten mit Ihren Bankberater zu teilen:',
      sharingName: 'Name',
      sharingEmail: 'E-Mail',
      sharingLogin: 'Letzter Login',
      sharingServiceProgress:
        'Fortschritt in Prozent zu den einzelnen Vorsorgedokumente (es werden jedoch keine eigentlichen Dokumente oder Informationen geteilt)',
    },
  },

  adminSettings: {
    general: 'Allgemein',
    team: 'Team',
    consulting: 'Beratung',
    changePassword: 'Passwort ändern',
  },

  team: {
    tableHeads: {
      name: 'Name',
      email: 'E-Mail',
      role: 'Rolle',
      last_login: 'Letzter Login',
    },
    resendInvitation: 'Einladung erneut senden',
    cancelInvitation: 'Einladung abbrechen',
    consultant: 'Berater',
    consultantDescription: 'Zugang zum gut geregelt Berater-Cockpit.',
    admin: 'Admin',
    adminDescription:
      'Verwaltung des gut geregelt Cockpits, Personen und anderer Einstellungen.',
  },

  footer: {
    allRightsReserved: 'Alle Rechte vorbehalten',
    provisionLinks: {
      title: 'Lösungen',
      pricing: 'Preise',
      livingWill: 'Patientenverfügung',
      powerOfAttorney: 'Vorsorgeauftrag',
      lastWill: 'Testament',
      digitalFootprint: 'Digitaler Nachlass',
      registerAssets: 'Vermögens- und Sachregister',
    },
    resourcesLinks: {
      title: 'Ressourcen',
      imprint: 'Impressum',
      termsAndConditions: 'Nutzungsbedingungen',
      privacyPolicy: 'Datenschutzrichtlinien',
      contact: 'Kontakt',
    },
    contact: 'Kontakt',
  },

  contactUs: {
    getLegalSupport: 'Holen Sie sich rechtliche Unterstützung',
    partnerTitle: 'Wenden Sie sich an das gut geregelt Team',
    description:
      'gut geregelt verbindet Sie bei Bedarf gerne mit erfahrenen Rechtsberatern in Ihrer Region.',
    pleaseWriteMessage: 'Bitte schreiben Sie uns eine Nachricht',
  },

  civil_statusWarning: {
    title:
      'Sind Sie sicher, dass Sie Ihren Personenstand aktualisieren möchten?',
    description:
      'Wenn Sie Ihren Personenstand ändern, werden alle Ihre Testamentsdaten gelöscht und Sie müssen von vorne beginnen.',
    cancel: 'Abbrechen',
    update: 'Aktualisieren',
  },

  hyperlinks: {
    lastWill: 'Testament',
    livingWill: 'Patientenverfügung',
    powerOfAttorney: 'Vorsorgeauftrag',
  },
  signedDoc: {
    placeholder: {
      headingText: 'Nach dem Signieren hochladen',
      currentlySigned: 'Derzeit {{grammarFix}} {{docType}}',
      grammarFix: {
        livingWill: 'signierte und aktive',
        powerOfAttorney: 'signierter und aktiver',
        lastWill: 'signiertes und aktives',
        marriageContract: 'signierter und aktiver',
        cohabitationAgreement: 'signierte und aktive',
      },
      doctype: {
        lastWill: 'Testament',
        livingWill: 'Patientenverfügung',
        powerOfAttorney: 'Vorsorgeauftrag',
        marriageContract: 'Ehevertrag',
        cohabitationAgreement: 'Konkubinatsvertrag',
      },
      helper: {
        dropFilesHere: 'Datei hier ablegen oder Ihr Gerät',
        browse: 'durchsuchen',
        thoroughYourDevice: 'auf Ihrem Gerät',
      },
    },
    reUpload: 'Ziehen und ablegen oder klicken, um erneut hochzuladen',
    updatedAt: 'Aktualisiert am {{date}}',
  },

  shareDocument: {
    button: 'Mit dem Arzt teilen',
    title: 'Teilen Sie es mit Ihrem Hausarzt',
    description:
      'Wir empfehlen, dass Sie die unterschriebene Version Ihrer Patientenverfügung mit Ihrem Hausarzt teilen.',
    doAutomatically:
      'Automatisch teilen, wenn ich die Patientenverfügung aktualisiere',
  },

  finfinder: {
    title: 'Wählen Sie Ihren Finanzberater auf FinFinder',
    button: 'Kostenloses Erstgespräch vereinbaren',
  },

  consulting: {
    title: 'Beratung',
    description: 'Unterstützen Sie Ihre Kunden bei der Altersvorsorge.',
    dashCardInfos: {
      livingWill: {
        title: 'Patientenverfügung',
        description:
          'Regelt, welchen medizinischen Behandlungen der Kunde zustimmt oder welche er ablehnt, wenn er nicht mehr selbst entscheiden kann.',
      },
      powerOfAttorney: {
        title: 'Vorsorgeauftrag',
        description:
          'Definiert, wer den Kunden im schlimmsten Fall rechtlich vertritt, seine Rechte und Pflichten gegenüber der Familie wahrnimmt und sich um seine finanziellen Angelegenheiten kümmert.',
      },
      lastWill: {
        title: 'Testament',
        description:
          'Definiert die Wünsche des Kunden bezüglich der Vererbung von Vermögenswerten.',
      },
      ageAppropriate: {
        title: 'Altersgerechtes 3a-Anlegen',
        description:
          'Überprüfen Sie, inwieweit Ihr Kunde die 3a-Vermögenswerte altersgerecht anlegt und ergreifen Sie gegebenenfalls geeignete Massnahmen.',
      },
      ahvCalculator: {
        title: 'AHV-Rechner',
        description:
          'Der AHV-Rechner ist eine Rentenschätzung und basiert auf einer vereinfachten Berechnungsmethode, die nicht verbindlich ist.',
      },
      lifeExpectancy: {
        title: 'Lebenserwartung',
        description:
          'Berechnet die theoretische Lebenserwartung des Kunden anhand von versicherungsmathematischen Tabellen.',
      },
      getStarted: 'Loslegen',
      unlock: 'Entsperren',
      createUser: 'Benutzer erstellen',
      createUserDescription:
        'Legen Sie für Ihren Kunden einen Benutzer an, den dieser weiterhin unabhängig von Ihnen nutzen kann, um Dokumente einzusehen und Anpassungen vorzunehmen.',
    },
    pageTitle: 'Kundenlogin erstellen',
    createUserForm: {
      description:
        'Ihr Kunde erhält eine separate E-Mail mit den Zugangsdaten. Bitten Sie Ihren Kunden, beim ersten Login das mitgeteilte Passwort zu ändern.',
    },
  },
  consultantDashboard: {
    pageName: 'Berater-Dashboard',
    title: 'Kundenübersicht',
    description:
      'Erhalten Sie einen Überblick über Ihr Kundenportfolio und dessen Status in Bezug auf die Altersvorsorge mit gut geregelt.',
  },

  teamRoles: {
    owner: 'Besitzer',
    consultant: 'Berater',
    admin: 'Admin',
  },

  financialAdvisorsModal: {
    title: 'Sprechen Sie mit einem Finanzberater in Ihrer Nähe',
    description:
      'Um Ihnen einen einfachen Zugang zu unabhängigen Finanzexperten in Ihrer Nähe zu ermöglichen, arbeiten wir mit FinFinder zusammen.',
  },
  legalAdvisorsModal: {
    title: 'Sprechen Sie mit einem Notar in Ihrer Nähe',
    description:
      'Um Ihnen einen einfachen Zugang zu Rechtsexperten in Ihrer Nähe zu ermöglichen, arbeiten wir mit GetYouLawyer zusammen.',
  },

  complexFormModal: {
    title: 'Wir empfehlen Ihnen, mit einem Notar zu sprechen',
    description:
      'Ihr Fall ist komplexer und daher empfehlen wir Ihnen dringend, mit einem Rechtsexperten zu sprechen, um den Sachverhalt zu klären und die beste Lösung für Ihren Fall zu erarbeiten.',
    searchLabel: 'Notar suchen',
    financialSearchLabel: 'Finanzberater suchen',
    continueLabel: 'Weiter',
    requestConsultation: 'Beratung anfordern',
  },

  financialConsultingTopics: {
    financialPlanning: 'Finanzplanung',
    pensionPlanning: 'Pensionsplanung',
    taxPlanning: 'Steuerberatung',
    realEstate: 'Immobilienberatung',
    successionPlanning: 'Nachfolgeberatung',
  },

  legalConsultingTopics: {
    livingWill: 'Patientenverfügung',
    powerOfAttorney: 'Vorsorgeauftrag',
    lastWill: 'Testament',
    marriageContract: 'Ehevertrag',
  },

  markAsDoneView: {
    intro: 'Ihr wirksames {{service}}',
    title: 'Hinterlegen Sie Ihr rechtskräftiges {{service}}',
    helperOne:
      'gut geregelt informiert Sie über Änderungen der Rechtsgrundlagen und gibt auch von Zeit zu Zeit Impulse, damit das geltende Rechtsdokument noch immer Ihren Bedürfnissen und Wünschen entspricht.',
    helperTwo:
      'Sollten Sie zu einem späteren Zeitpunkt eine andere Regelung wünschen, können Sie das Rechtsdokument jederzeit aktualisieren und Ihre Angelegenheiten über „gut geregelt“ regeln.',
    service: {
      livingWill: 'Patientenverfügung',
      powerOfAttorney: 'Vorsorgeauftrag',
      lastWill: 'Testament',
      marriageContract: 'Ehevertrag',
      cohabitationAgreement: 'Konkubinatsvertrag',
    },
  },

  otp: {
    pageTitle: 'E-Mail-Verifizierung',
    title: 'Bestätigungscode an Ihrer E-Mail versandt',
    subtitle:
      'Wir haben Ihnen einen 6-stelligen Bestätigungscode per E-Mail an {{email}} gesendet. Bitte geben Sie den Code in das unten stehende Feld ein, um Ihre E-Mail zu bestätigen.',
    dontHaveCode: 'Haben Sie keinen Code erhalten?',
    resendCode: 'Code erneut senden',
    returnToLogin: 'Zurück zum Login',
    waitforResend: 'Bitte warten Sie, bevor Sie den Code erneut anfordern.',
  },
};

export default de;
