import { IQuestion } from '#/types/globalTypes';

export const inheritorsMarriedVersion: IQuestion[] = [
  {
    question:
      'We understand you are married. Can you please provide us with the details of your spouse?',
  },
  {
    question: 'Have you concluded a marriage contract or a property contract?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Do you have a property/apartment to inherit to your partner?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'Do you have divorce proceedings pending?',
  //   options: [
  //     {
  //       label: 'Yes',
  //     },
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
  {
    question: 'Do you have children from the common marriage?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'Would you like to benefit other persons or institutions?',
  //   options: [
  //     {
  //       label: 'Yes',
  //     },
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
];

export const inheritorsSingleVersion: IQuestion[] = [
  {
    question: 'Are you living in a registered partnership ?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Have you concluded a marriage contract or a property contract?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Do you have a property/apartment to inherit to your partner?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Do you have children ?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'Would you like to benefit other persons or institutions ?',
  //   options: [
  //     {
  //       label: 'Yes',
  //     },
  //
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
];

export const inheritorsDivorcedVersion: IQuestion[] = [
  {
    question:
      'We understand that you are divorced. Do you have children from the common marriage?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },

  // {
  //   question: 'Would you like to benefit other persons or institutions?',
  //   options: [
  //     {
  //       label: 'Yes',
  //     },
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
];

export const distributionQuestions: IQuestion[] = [
  {
    question:
      'To the extent permitted by law, I would like as much of my estate as possible to:',
    options: [
      {
        label: 'My spouse/registered partner/my life partner',
      },
      {
        label: 'My children',
      },
      {
        label: 'An institution of my choice',
      },
    ],
  },
  {
    question:
      'Do you have assets that you would like to donate to specific individuals?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question:
  //     'Do you have different inheritance goods that you would like to donate to different people?',
  //   options: [
  //     {
  //       label: 'Yes',
  //     },
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
];

export const governanceQuestions: IQuestion[] = [
  {
    question: 'Who should be the executor of your will?',
  },
];
