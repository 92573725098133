import {
  distributionQuestions,
  governanceQuestions,
  inheritorsDivorcedVersion,
  inheritorsMarriedVersion,
  inheritorsSingleVersion,
} from './questions';
import outputDoc from './outputDoc';

const lastWill = {
  mainHeaders: {
    shortPainFree: 'Votre testament simple et efficace',
    madeIt: 'Félicitations ! Vous avez réussi !',
  },

  yes: 'Oui',
  no: 'Non',

  stepperInfo: [
    {
      label: 'Bénéficiaires',
      title: "De quoi s'agit-il et pourquoi est-ce si important ?",
    },

    {
      label: 'Répartition',
      title: 'Définissez la répartition',
    },

    {
      label: 'Gouvernance',
      title: 'Définissez la gouvernance',
    },

    {
      label: 'Votre dernier testament',
      title: 'Terminons la rédaction du testament',
    },
  ],

  stepOne: {
    introParagraph:
      "Un testament vous offre la possibilité de donner des instructions claires à vos descendants en période difficile. Il vous permet également (dans les limites de la législation en vigueur) de vous assurer que vos souhaits concernant la gestion de votre patrimoine seront respectés. De plus, le testament vous offre la possibilité d'éviter des discussions difficiles et émotionnelles au sein du système familial en désignant un exécuteur testamentaire.",
    helperParagraph:
      "En même temps, la rédaction d'un testament n'est pas une tâche facile pour vous personnellement, alors prenez votre temps et ne vous sentez pas stressé. Avec notre service de rappel, vous pouvez également faire une pause à tout moment et reprendre plus tard. Cliquez simplement sur le bouton 'J'ai terminé'.",
    questions: {
      inheritorsMarriedVersion,
      inheritorsSingleVersion,
      inheritorsDivorcedVersion,
    },
    addAnotherBenefiter: 'Ajouter un bénéficiaire',
    isChildFromCommonMarriage: "Cet enfant est issu d'un mariage commun?",
  },

  stepTwo: {
    introParagraph: "Quel est l'objectif principal de ce testament?",
    mainly: 'principalement',
    yesIHaveProperty:
      "Oui, j'ai des biens ou un (des) logement(s) que je souhaite donner à:",
    followingDistribution:
      "Oui, je souhaite que les éléments suivants de la succession / la somme d'argent mentionnée ci-dessus soit transférée à la personne mentionnée ci-dessus:",
    questions: distributionQuestions,
  },
  stepThree: {
    questions: governanceQuestions,
    orAddSomeoneNew: "ou ajouter quelqu'un de nouveau",
    addAnotherExecutor: 'Exécuteur testamentaire',
  },
  stepFour: {
    document: outputDoc,
    introParagraph:
      "Félicitations ! Vous avez réussi. Imprimez maintenant votre dernier testament et vérifiez s'il correspond vraiment à vos souhaits.",
    helperParagraph:
      "En Suisse, la forme écrite est requise, c'est-à-dire que pour rendre votre testament juridiquement valable, vous avez le choix :",
    helperParagraph2:
      "Il est également conseillé de déposer le testament chez un avocat et de l'enregistrer auprès du registre officiel suisse des testaments (https://www.ztr.ch/notariatsinfo/).",
    contactForm: 'Formulaire de contact',
    helperParagraph3:
      'Si vous souhaitez que nous vous aidions, veuillez utiliser notre formulaire de contact',
    obligation1:
      'Une copie manuscrite du testament avec lieu, date et votre signature',
    obligation2:
      "Un testament public (procédure de lecture personnelle, art. 500 et suivants du Code civil suisse ou procédure de lecture, art. 502 du Code civil suisse), pour cela vous avez besoin d'un notaire et de deux témoins",
    stopProcess: {
      title:
        'Si vous avez signé un contrat de mariage, nous ne pouvons pas continuer ce processus.',
      description:
        'Nous vous recommandons de consulter un avocat pour un testament prenant en compte un contrat de mariage. Vous trouverez ici un avocat pour vous aider.',
      changeAnswerBtn: 'Changer la réponse',
      continueBtn: 'Terminer et continuer avec un avocat',
    },
  },
  alternativeGoverner:
    "Si la personne que vous avez choisie ne peut pas agir en tant qu'exécuteur testamentaire, la personne suivante est désignée :",
};

export default lastWill;
