const grosseItern = {
  mainBanner: {
    intro: 'Einfach ein gutes Gefühl:',
    title: 'Vorsorge gut geregelt',
    subtitle:
      'Ein schwieriges Thema wird endlich einfach: Erstellen Sie eine Patientenverfügung, Vorsorgevollmacht und ein Testament bequem von zu Hause aus - in nur 20 Minuten. Starten Sie jetzt mit dem Schweizer Anbieter gut geregelt.',
    buttonLabel: 'Jetzt starten',
    sale: {
      blueText: 'Sparen Sie 50%',
      blackText: 'bis zum 31.12.2024',
    },
  },
  aboutCards: {
    justDoIt: {
      title: 'Einfach erledigen, bevor etwas passiert',
      description:
        'Die Hoffnung ist, nie von der Patientenverfügung und dem Vorsorgeauftrag Gebrauch machen zu müssen. Schicksalsschläge passieren jedoch, weshalb es sich lohnt, sie frühzeitig zu erstellen.',
    },
    security: {
      title: 'Sicherheit für Ehepartner und Familie',
      description:
        'Indem Sie diese Dokumente rechtzeitig erstellen, behalten Sie die Kontrolle über Ihre Wünsche. Gleichzeitig schützen Sie Ihre gesamte Familie und vermeiden unnötige Stresssituationen.',
    },
    done: {
      title: 'In 20 Minuten erledigt - von zu Hause aus',
      description:
        'Mit gut geregelt erstellen Sie rechtssichere Dokumente und Verträge in einer leicht verständlichen Sprache, ganz bequem von zuhause aus. Und keine Sorge: bei komplexeren Fällen helfen Ihnen Rechtsexperten.',
    },
    swissProvider: {
      title: 'Schweizer Anbieter: Vorsorge gut regeln',
      description:
        'gut geregelt ist Ihre digitale Schweizer Vorsorge-Suite. Tun Sie Ihnen und Ihren Liebsten einen Gefallen und lassen Sie sich bereits heute gut regeln.',
    },
  },
  angebot: {
    phrase:
      'Bekannt aus den Medien und dem Grosseltern Magazin (Ausgabe 30/2024)',
    highlightedPhrase: 'Grosseltern Magazin',
  },
};

export default grosseItern;
