import { Box, CssBaseline } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { useState } from 'react';
import Header from '#/components/shared/ui/header';
import Footer from '#/components/shared/ui/footer/Footer';

const DashboardLayout = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isRegisterPortal = pathname === '/dashboard/provision-final';

  return (
    <Box>
      <CssBaseline />
      <Header onOpenNav={handleOpen} openNav={open} onCloseNav={handleClose} />
      <Box
        component="main"
        sx={{
          minHeight: '100vh',
          position: 'relative',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          pt: { xs: 8, md: 11 },
          px: {
            xs: 0.5,
            md: 6,
          },
        }}
      >
        <Outlet />
        <Footer isRegisterPortal={isRegisterPortal} />
      </Box>
    </Box>
  );
};

export default DashboardLayout;
