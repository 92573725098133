import powerOfTrustQuestions from './questions';
import outputDoc from './outputDoc';

const powerOfAttorney = {
  mainHeaders: {
    shortPainFree: 'Short and painfree power of attorney',
    madeIt: 'You made it!',
  },

  stepperInfo: [
    {
      label: 'Power of trust',
      title: 'What is it about?',
    },
    {
      label: 'Your power of attorney',
      title: 'Now what?',
    },
  ],

  stepOne: {
    introParagraph:
      'Unfortunately, there are situations in which your independence may be limited. For example, after a serious accident, in the event of a serious illness or dementia. ',
    helperParagraph:
      'We therefore recommend that, as long as you are capable of making decisions and acting, you arrange who is to look after you in such a situation and how, and also represent you legally, by means of a so-called advance directive. This will relieve your relatives in an already difficult situation, as your wishes are clearly stated.',
    helperParagraph2:
      "So let's get started, this section takes about 5 minutes, and remember if you need a break you can always stop for the moment. Our reminder services has got your back.",
    questions: powerOfTrustQuestions,
  },

  stepTwo: {
    introParagraph:
      'Congratulations! You have made it. Now print out our Power of Attorney and check whether it really corresponds to your exact wishes.',
    helperParagraph:
      'In Switzerland, the written form obligation applies, that is, to make your will legally valid you have the choice: ',
    helperParagraph2:
      'It is also advisable to deposit the will with a lawyer under registration with the official Swiss register of wills ( https://www.ztr.ch/notariatsinfo/ ).',
    contactForm: 'contact form',
    helperParagraph3:
      'If you want us to help you, please use our contact form.',
    obligation1:
      'A handwritten copy of the will with place, date and your signature',
    obligation2:
      'A public will (self-reading procedure, Art. 500 f. ZGB or reading procedure, Art. 502 ZGB) for this you need a notary and two witnesses',
    document: outputDoc,
  },
};

export default powerOfAttorney;
