/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { QueryFunctionContext } from 'react-query/types/core/types';
import { AxiosError, AxiosResponse } from 'axios';
import requestProvider from './requestProvider';

type IBaseType = {
  id: number;
};

type QueryKeyT = [string, object | undefined];

export const fetcher = async <T>({
  queryKey,
  pageParam,
}: QueryFunctionContext<QueryKeyT>): Promise<T> => {
  const [url, params] = queryKey;
  const res = await requestProvider.get<T>(url, {
    params: { ...params, pageParam },
  });
  return res.data;
};

export const usePrefetch = <T>(url: string | null, params?: object) => {
  const queryClient = useQueryClient();

  return () => {
    if (!url) {
      return;
    }

    queryClient
      .prefetchQuery<T, Error, T, QueryKeyT>([url!, params], ({ queryKey }) =>
        fetcher({ queryKey } as QueryFunctionContext<QueryKeyT>)
      )
      .then((r) => r as unknown as T);
  };
};

export const useGet = <T>(
  url: string | null,
  params?: object,
  config?: UseQueryOptions<T, Error, T, QueryKeyT>
) =>
  useQuery<T, Error, T, QueryKeyT>(
    [url!, params],
    ({ queryKey }) => fetcher({ queryKey } as QueryFunctionContext<QueryKeyT>),
    {
      enabled: !!url,
      ...config,
    }
  );

const useGenericMutation = <T, S>(
  func: (data: S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined
) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, S>(func, {
    onMutate: async (data: any) => {
      await queryClient.cancelQueries([url!]);

      const previousData = queryClient.getQueryData([url!]);

      if (updater) {
        queryClient.setQueryData<T>([url], (oldData) =>
          updater(oldData!, data)
        );
      } else {
        removeLastUrlIdSegment();

        queryClient.setQueryData<S>([url!], () => data);
      }

      return previousData;

      function removeLastUrlIdSegment() {
        if (data && typeof data === 'object') {
          const dataBaseType = data as unknown as IBaseType;
          const urlSplit = url.split('/');
          const lastSegment = urlSplit.pop() || urlSplit.pop(); // handle potential trailing slash
          if (
            lastSegment &&
            dataBaseType.id &&
            lastSegment === dataBaseType.id.toString()
          ) {
            // eslint-disable-next-line no-param-reassign
            url = url.slice(0, url.lastIndexOf('/'));
          }
        }
      }
    },
    onError: (err: any, _: any, context: any) => {
      queryClient.setQueryData([url!], context);
    },
    onSettled: () => {
      queryClient.invalidateQueries([url!]).then((r) => r as unknown as T);
    },
  });
};

export const useDelete = <T>(
  url: string,
  params?: object,
  updater?: (oldData: T, id: string | number) => T
) =>
  useGenericMutation<T, string | number>(
    (id) => requestProvider.delete(`${url}`),
    url,
    params,
    updater
  );

export const usePost = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T
) =>
  useGenericMutation<T, S>(
    (data) => requestProvider.post<S>(url, data),
    url,
    params,
    updater
  );

export const usePut = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
  headers?: object
) =>
  useGenericMutation<T, S>(
    (data) =>
      requestProvider.put<S>(`${url}`, data, {
        headers,
      }),
    url,
    params,
    updater
  );

export const usePatch = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T
) =>
  useGenericMutation<T, S>(
    (data) => requestProvider.patch<S>(url, data),
    url,
    params,
    updater
  );
