import { IQuestion } from '#/types/globalTypes';

export const treatmentQuestions: IQuestion[] = [
  {
    question:
      'Why are you creating this patient directive, what best describes your current situation?',
    options: [
      {
        label: 'I am in good health but I would like to take precautions',
      },
      {
        label: 'I have ailments and would like to make provisions',
      },
      {
        label:
          'I have reached a certain age and would like to make provisions for the future',
      },
    ],
  },
  {
    question: 'What is more important to you in pain and symptom management?',
    options: [
      {
        label:
          'I want that all medical possibilities for the preservation of my life are exhausted, even if this is connected with burdens (suffering)',
      },
      {
        label:
          'I want that the medical treatment is intended first to alleviate suffering, even if it may shorten my life',
      },
    ],
  },
  {
    question:
      'Do you wish to forego all measures that merely prolong life and suffering if you become incapacitated due to an unexpected, acute event and if, after thorough medical assessment, it is impossible or unlikely that you will regain your capacity to judge?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Do you want to be revived ?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
];

export const detailedQuestions: IQuestion[] = [
  {
    question:
      'In a scenario where you are suddenly incapacitated, for example, emergency, what would be your treatment goal for life extension?',
    options: [
      {
        label:
          'I would like to receive resuscitation and treatment in the intensive care unit',
      },
      {
        label:
          'I do not wish to be resuscitated but to be treated in the intensive care unit',
      },
    ],
  },
  {
    question:
      'What would be your treatment goal for suffering relief in the sudden incapacitation scenario?',
    options: [
      {
        label:
          'I would like to receive suffering-relieving medications (Morophin, etc.) and treatment therapies',
      },
      {
        label:
          'I not nor want to receive suffering-relieving medications (Morophin, etc.) and treatment therapies',
      },
    ],
  },
  {
    question:
      'In a scenario where you have prolonged incapacity, for example, a coma, what would be your treatment goals for life extension?',
    options: [
      {
        label:
          'I would like to receive resuscitation and treatment in the intensive care unit',
      },
      {
        label:
          'I do not wish to be resuscitated but to be treated in the intensive care unit',
      },
    ],
  },
  {
    question:
      'What would be your treatment goal for suffering relief in the sudden incapacitation scenario?',
    options: [
      {
        label:
          'I would like to receive suffering-relieving medications (Morophin, etc.) and treatment therapies',
      },
      {
        label:
          'I not nor want to receive suffering-relieving medications (Morophin, etc.) and treatment therapies',
      },
    ],
  },
  {
    question:
      'In a scenario where you have permanent incapacity, for example, dementia, what would be your treatment goals for life extension?',
    options: [
      {
        label:
          'I would like to receive resuscitation and treatment in the intensive care unit',
      },
      {
        label:
          'I do not wish to be resuscitated but to be treated in the intensive care unit',
      },
    ],
  },
  {
    question:
      'What would be your goals on suffering relief in the sudden permanent incapacity scenario?',
    options: [
      {
        label:
          'I would like to receive suffering-relieving medications (Morophin, etc.) and treatment therapies',
      },
      {
        label:
          'I not nor want to receive suffering-relieving medications (Morophin, etc.) and treatment therapies',
      },
    ],
  },
];
