const outputDoc = {
  title: 'Mandat de prévoyance',
  from: 'De',
  born: 'Né(e) le ',
  civilStatus: {
    single: 'Célibataire',
    married: 'Marié(e)',
    widowed: 'Veuf(ve)',
    divorced: 'Divorcé(e)',
    registered_partnership: 'Partenariat enregistré',
  },
  primary: 'Personne principale',
  deputy: 'Suppléant',
  civil_status: 'État civil: {{civil_status}}',
  address: 'Adresse: {{address}}',
  declare: 'Je, {{first_name}} {{last_name}}, déclare ce qui suit: ',
  paragraph1:
    'Dans le cas où je deviendrais incapable de jugement et ne pourrais plus former ou communiquer clairement ma volonté de manière permanente ou temporaire, je charge la(les) personne(s) suivante(s) de prendre en charge les tâches de prévoyance et de gestion de mes biens, et de me représenter dans toutes les affaires juridiques: ',
  firstParagraph: {
    sameTrustees: 'Soin des personnes et des biens, même personne ainsi que',
    differentTrustees: 'Soin des personnes et des biens, personnes différentes',
  },
  assetCare: 'Gestion des biens',
  personCare: 'Soin des personnes',
  mainCharacter: 'Personne principale',
  alternate: 'Suppléant(e)',
  compensationDeclare: 'Reçoit une compensation selon la définition ci-dessous',
  nonCompensationDeclare: 'Ne reçoit pas de compensation',
  paragraph2: 'En tant que personne suppléante, je nomme',
  paragraph3:
    'Dans le cas où je deviendrais incapable de jugement et ne pourrais plus former ou communiquer clairement ma volonté de manière permanente ou temporaire, je charge la(les) personne(s) suppléante(s) de prendre en charge les tâches de prévoyance et de gestion de mes biens, et de me représenter dans toutes les affaires juridiques: ',
  personCareDeclare1:
    'La personne mandatée détermine les mesures à prendre en ce qui concerne mes soins et ma prise en charge médicale, et veille à assurer une vie quotidienne ordonnée et une participation adéquate à la vie sociale.',
  personCareDeclare2:
    'Sauf indication contraire dans votre testament, vos actifs numériques seront transmis à vos héritiers légaux à votre décès. Il est donc conseillé de décider de leur sort après votre décès de votre vivant.',
  assetCare1:
    'La personne mandatée gère mes revenus et mon patrimoine, en dispose et prend toutes les mesures liées. Elle donne ou émet les instructions et demandes nécessaires à des tiers, banques et autorités pour la protection de mes intérêts. Cette personne s’occupe de payer mes factures par poste ou banque et reçoit les paiements en mon nom. Elle est autorisée à recevoir et ouvrir tout courrier qui m’est adressé.',
  assetCare2:
    'La gestion des avoirs bancaires et des valeurs mobilières doit se conformer aux accords et usages existants. La gestion des biens n est expressément pas soumise aux dispositions de l ordonnance sur la gestion des biens dans le cadre d une curatelle ou d une tutelle.',
  assetCare3:
    'La personne mandatée ne peut pas faire de dons avec mes biens ni aliéner des biens gratuitement, à l exception des cadeaux occasionnels ou des donations pour remplir un devoir moral.',
  repLegal: 'Représentation dans les affaires juridiques',
  repLegal1:
    'La personne mandatée est autorisée à initier toutes les actions juridiques et éventuellement judiciaires nécessaires dans le cadre de ce mandat, notamment à conclure ou résilier les contrats nécessaires, à soumettre les déclarations fiscales, et à me représenter devant les autorités et tribunaux ou vis-à-vis de tiers. Cette personne est responsable de tous les aspects administratifs.',
  repLegal2:
    'La personne mandatée est autorisée à vendre ou grever ma propriété immobilière, à créer, modifier ou supprimer des annotations, réserves et servitudes inscrites en ma faveur ou à ma charge dans le registre foncier, à faire authentifier de telles transactions et à initier les inscriptions correspondantes dans le registre foncier.',
  custodyOrder: 'Dispositions relatives à la garde',
  childrenTrustee:
    'Si mes enfants ne sont pas encore majeurs, je souhaite que la personne suivante soit nommée leur tuteur et que les enfants soient confiés à sa garde:',
  childrenTrusteeRelationship: 'Relation avec moi: {{relation}}',
  relation: {
    husband: 'Époux',
    wife: 'Épouse',
    partner: 'Partenaire',
    friend: 'Ami(e)',
    brother: 'Frère',
    sister: 'Sœur',
    brother_in_law: 'Beau-frère',
  },
  alternateChildrenTrustee: 'En tant que personne suppléante, je nomme',
  childrenTrusteeDeclare:
    'Je suis conscient(e) que cette disposition relative à la garde n’est pas juridiquement partie intégrante du mandat de prévoyance, qu’il s’agit d’un souhait et que l’autorité de protection de l’enfant n’y est pas liée et ne peut prendre en compte ce souhait que s’il est conforme à l’intérêt de l’enfant. Aucun droit légal n existe pour que l’autorité de protection de l’enfant confie la tutelle à la(les) personne(s) mentionnée(s).',
  releaseFromConfidentiality: 'Levée de la confidentialité',
  releaseFromConfidentialityDeclare:
    'Je délie toutes les personnes et autorités soumises à un secret professionnel ou officiel vis-à-vis de la personne mandatée du secret professionnel et du secret de fonction (en particulier banques, médecins et fonctionnaires).',
  involvementOfAidsAndAssistants: 'Recours à des aides et assistants',
  involvementOfAidsAndAssistantsDeclare:
    'Pour l’exécution du mandat, la personne mandatée est autorisée à acquérir à mes frais des aides et à faire appel à des assistants et substituts (comme des aides ménagères, des services de soins, des conseillers fiscaux, des avocats, des gestionnaires de patrimoine, etc.) et à conclure, modifier ou résilier des contrats avec eux.',
  expensesAndCompensation: 'Frais et compensations',
  expenses: 'Frais',
  expensesDeclare:
    'Les dépenses nécessaires et prouvées doivent être remboursées à la personne chargée de la gestion des personnes ou des biens.',
  indemnities: 'Indemnités',
  indemnitiesPositive:
    'La personne chargée de la gestion des personnes ou des biens a droit à une compensation appropriée, qui doit être calculée selon les tarifs habituels de l autorité de protection des adultes pour la compensation des tuteurs.',
  indemnitiesNegative:
    'La(les) personne(s) chargée(s) de la garde des enfants, des personnes ou des biens, qui, selon le souhait du créateur du document, doit recevoir une compensation, recevra une telle compensation conformément aux tarifs habituels de l autorité de protection des adultes pour les tuteurs.',
  storageRegistration: 'Conservation et enregistrement du mandat de prévoyance',
  storage: 'Conservation',
  storageRegistrationDeclare:
    'Je suis conscient(e) que les mandats de prévoyance peuvent être déposés pour conservation auprès de l autorité de protection des enfants et des adultes compétente pour la commune de résidence. Il est de ma libre décision de profiter de cette possibilité.',
  storageRegistrationDeclare2:
    'La personne chargée de la prévoyance est mandatée et autorisée à s adresser à l autorité de protection des adultes de mon lieu de résidence avec ce mandat de prévoyance en cas de besoin, afin que l autorité puisse lui délivrer le document nécessaire à ma représentation au sens de l art. 363 CC.',
  registration: 'Enregistrement',
  registrationDeclare:
    'Je suis conscient(e) de la possibilité d informer l office de l état civil compétent de la création et du lieu de dépôt du présent mandat de prévoyance en vue de son enregistrement dans la base de données centrale conformément à l art. 361 al. 3 CC. Il est de ma libre décision de profiter de cette possibilité.',
  applicableLaw: 'Droit applicable et juridiction compétente',
  applicableLawDeclare:
    'Ce mandat de prévoyance est soumis – indépendamment de ma nationalité ou de mon domicile – au droit suisse, à l exclusion des normes de droit international privé (LDIP). Le for juridique est :',
  revocation: 'Révocation',
  revocationDeclare:
    'Je révoque par la présente tous les mandats de prévoyance de date antérieure.',
  abilityToAct: 'Capacité d’agir',
  abilityToActDeclare:
    'Je fais cette déclaration après mûre réflexion et en pleine responsabilité de moi-même, conscient que mes médecins, soignants ou mandataires sont liés par la décision de la personne mandatée concernant les mesures médicales. J ai discuté cette disposition avec les personnes mentionnées au début de la première page, qui peuvent confirmer que j étais capable de jugement à ce moment-là et que le contenu correspond à ma volonté.',
};

export default outputDoc;
