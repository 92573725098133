const guest = {
  title: 'Provision of {{first_name}} {{last_name}}',
  description:
    'As long as your host is alive, you have limited access to the data and information',
  seeWhatsIncluded: 'see what is included',
  includedTooltip:
    'With guest access, these persons only have access to the living will and the power of attorney.',
  reportDeath: 'Report death',
  reportDeathDescription:
    'Unfortunately, in order for you to have full access to all of {{first_name}}’s records and information, we must first confirm the death and need the official death and executor certificate (you can obtain both from the municipality).',
  alreadyReported: 'Already reported',
};

export default guest;
