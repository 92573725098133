const toast_notifications = {
  success: {
    register: 'Registered successfully',
    login: 'Login successfully',
    logout: 'Logout successfully',
    representative_add: 'Representative added successfully',
    representative_update: 'Representative updated successfully',
    representative_delete: 'Representative deleted successfully',
    message_sent: 'Message sent successfully',
    package_update: 'Package updated successfully',
    good_update: 'Good updated successfully',
    good_create: 'Good added successfully',
    profile_update: 'Profile updated successfully',
    password_update: 'Password updated successfully',
    password_reset: 'Reset password link sent to your email',
    asset_delete: 'Asset deleted successfully',
    property_update: 'Property updated successfully',
    property_create: 'Property added successfully',
    beneficiary_update: 'Beneficiary updated successfully',
    beneficiary_create: 'Beneficiary added successfully',
    beneficiary_delete: 'Beneficiary deleted successfully',
    reminder_service: 'Reminder service updated successfully',
    partner_message: 'Message sent successfully',
    invitation_sent: 'Invitation sent successfully',
    document_submit: 'Document submitted successfully',
    deathReport: 'Death report submitted successfully',
    teamInvite: 'Team member invited successfully',
    advisorAdd: 'Advisor added successfully',
    platform_update: 'Platform settings updated',
    cancel_invitation: 'Invitation canceled successfully',
    resend_invitation: 'Invitation resent successfully',
    delete_team_member: 'Team member deleted successfully',
    joinWaitList: 'Waitlist joined successfully',
  },
  error: {
    register: 'Failed to register',
    login: 'Login failed, check credentials',
    logout: 'Logout failed',
    package_failed: 'Package failed to update',
    representative_add: 'Representative failed to add',
    representative_update: 'Representative failed to update',
    representative_delete: 'Representative failed to delete',
    good_update: 'Good failed to update',
    good_create: 'Failed to add good',
    profile_update: 'Profile failed to update',
    password_update: 'Password failed to update',
    password_reset: 'Failed to send reset password link',
    asset_delete: 'Asset failed to delete',
    property_update: 'Property failed to update',
    property_create: 'Failed to add property',
    beneficiary_update: 'Beneficiary failed to update',
    beneficiary_create: 'Failed to add beneficiary',
    beneficiary_delete: 'Beneficiary failed to delete',
    reminder_service: 'Reminder service failed to update',
    partner_message: 'Message failed to send',
    invitation_sent: 'Invitation failed to send',
    document_submit: 'Document failed to submit',
    deathReport:
      'Death report failed to submit, maybe you have already submitted it',
    teamInvite: 'Team member failed to invite',
    advisorAdd: 'Advisor failed to add',
    platform_update: 'Platform settings failed to update',
    cancel_invitation: 'Failed to cancel invitation',
    resend_invitation: 'Failed to resend invitation',
    delete_team_member: 'Failed to delete team member',
    joinWaitList: 'Failed to join waitlist',
  },
};

export default toast_notifications;
