const poaLanding = {
  mainBanner: {
    intro: 'Vorsorgeauftrag online erstellen:',
    title: 'Rechtssicher, in 5 Minuten erledigt',
    subtitle:
      'Ohne eine Vorsorgeauftrag können Ihr Ehepartner, Ihre Ehepartnerin oder Ihre Familie selbst in einem Notfall keine Entscheidungen für Sie treffen. Bestimmen jetzt, wer gesetzlich berechtigt ist, in Ihrem Namen zu handeln.',
    buttonLabel: 'Vorsorgeauftrag erstellen',
  },
  youtubeTutorial: 'Warum ein Vorsorgeauftrag wichtig ist?',
  aboutCards: {
    guaranteeForPower: {
      title: 'Garantie für Befugnis',
      description:
        'Ermöglichen Sie Ihren geliebten Menschen, Entscheidungen in Ihrem besten Interesse für Sie zu treffen, wenn Sie sie selbst nicht treffen können.',
    },
    mutualProtection: {
      title: 'Gegenseitiger Schutz',
      description:
        'Schützen Sie die Integrität Ihrer Vermögenswerte, indem Sie sicherstellen, dass wichtige Entscheidungen für die Familie nur von Familienmitgliedern getroffen werden können.',
    },
    clarificationForFamily: {
      title: 'Klarheit für die Familie',
      description:
        'Machen Sie unnötige Diskussionen überflüssig und sorgen Sie für Klarheit für die Hinterbliebenen.',
    },
  },
  subBanner: {
    title: 'Gut geregelt mit einer rechtlich konformen Vorsorgeauftrag',
    subtitle:
      'Mit gut geregelt erstellen Sie schnell Ihren Vorsorgeauftrag, können rechtliche Beratung zu heiklen Themen oder offenen Fragen erhalten und tun Ihren Angehörigen einen Gefallen.',
    buttonLabel: 'Jetzt kostenlos erstellen',
  },
  newsletter: {
    title: 'Werden Sie gut geregelt mit Ihrem Vorsorgeauftrag',
    buttonLabel: 'Jetzt erstellen',
  },
  faq: {
    questions: {
      question1: 'Was ist ein Vorsorgeauftrag?',
      question2: 'Wann liegt eine Urteilsunfähigkeit vor?',
      question3:
        'Was passiert, wenn ich urteilsunfähig werde und keinen Vorsorgeauftrag habe?',
      question4: 'Was muss ich alles im Vorsorgeauftrag festhalten?',
      question5: 'Wie erstelle ich meinen Vorsorgeauftrag?',
      question6: 'Wie wähle ich meine Vertretungsperson aus?',
      question7: 'Was ist die KESB und was ist ihre Funktion?',
      question8:
        'Kann ich meinen Vorsorgeauftrag anpassen, wann immer ich will?',
      question9: 'Kann ich meinen Vorsorgeauftrag annullieren?',
      question10:
        'Kann ich im Vorsorgeauftrag auch meine Behandlungswünsche für den Fall eines medizinischen Notfalls festhalten?',
      question11:
        'Gibt es neben dem Vorsorgeauftrag weitere Dokumente, die ich erstellen sollte?',
      question12: 'Wie hilft mir gut geregelt mit meinem Vorsorgeauftrag?',
    },
    answers: {
      answer1:
        'Im Vorsorgeauftrag halten Sie fest, wer Ihre Interessen in persönlichen und finanziellen Angelegenheiten im Falle einer Urteilsunfähigkeit vertreten soll. Mit dem Vorsorgeauftrag stellen Sie sicher, dass Ihre Interessen vertreten sind und Ihr Wille auch umgesetzt wird.',
      answer2:
        'Eine Urteilsunfähigkeit kann zum Beispiel infolge einer schweren Erkrankung wie Demenz, nach einem Schlaganfall oder auch nach einem schweren Unfall mit anschliessendem Koma eintreten.',
      answer3:
        'Das hängt davon ab, was Ihr Zivilstand ist: Als alleinstehende Person, als verheiratete Person oder in einer eingetragenen Partnerschaft, und als im Konkubinat lebende Person.',
      answer4:
        'Der Vorsorgeauftrag beinhaltet drei Bereiche: Personensorge, Vermögenssorge und Rechtsverkehr. Diese können Sie entweder alle der selben Vertretungsperson zusichern oder auch unterteilen.',
      answer5:
        'Sie können Ihren Vorsorgeauftrag selbst erstellen, wichtig dabei: Datum und Unterschrift nicht vergessen. Oder Sie erstellen ihn gemeinsam mit einem Notar und lassen ihn öffentlich beglaubigen.',
      answer6:
        'Sie können entweder eine Person für alle drei Bereiche oder auch mehrere Personen bestimmen. Wichtig ist, dass Sie dieser Person zu 100% vertrauen.',
      answer7:
        'Die Kindes- und Erwachsenenschutzbehörde (KESB) stellt den Schutz von gefährdeten Kindern und Erwachsenen sicher. Im Fall des Vorsorgeauftrages wird die KESB tätig, wenn sie über die Urteilsunfähigkeit einer Person informiert wird.',
      answer8:
        'Ja, mit der einzigen Voraussetzung, dass Sie zum Zeitpunkt der Anpassung urteilsfähig sein müssen. Ein neuer Vorsorgeauftrag ersetzt die letzte Version.',
      answer9:
        'Ja, Sie können Ihren Vorsorgeauftrag jederzeit annullieren, indem Sie das Dokument vernichten.',
      answer10:
        'Ja, das können Sie. Es macht allerdings zusätzlich Sinn, separat eine Patientenverfügung zu erstellen.',
      answer11:
        'Ja, das {{lastWill}} und die {{livingWill}} sind zwei weitere wichtige Dokumente, die Sie zeitnah verfassen sollten.',
      answer12:
        'Bei gut geregelt helfen wir Ihnen mit dem rechtsgültigen und reibungslosen Erstellen Ihres Vorsorgeauftrags.',
    },
    hyperlinks: {
      lastWill: 'Testament',
      livingWill: 'Patientenverfügung',
      powerOfAttorney: 'Vorsorgeauftrag',
    },
  },
};

export default poaLanding;
