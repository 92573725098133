import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material';
import { CustomFile } from '../types';
import Image from '../../ui/Image';

type Props = {
  file: CustomFile | string | null;
  sx?: SxProps<Theme>;
};

export default function AvatarPreview({ file, sx }: Props) {
  if (!file) {
    return null;
  }

  const imgUrl = typeof file === 'string' ? file : file.preview;

  return (
    <Image
      alt="avatar"
      src={imgUrl}
      sx={{
        zIndex: 8,
        overflow: 'hidden',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 16px)`,
        height: `calc(100% - 16px)`,
        ...sx,
      }}
    />
  );
}
