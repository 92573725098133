import { IQuestion } from '#/types/globalTypes';

export const treatmentQuestions: IQuestion[] = [
  {
    question:
      'Pourquoi créez-vous cette directive anticipée? Quelle est la meilleure description de votre situation actuelle?',
    options: [
      {
        label: 'Je suis en bonne santé, mais je veux prendre des précautions',
      },
      {
        label: "J'ai des plaintes et je veux prendre des précautions",
      },
      {
        label:
          "J'ai atteint un certain âge et je veux prendre des précautions pour l'avenir",
      },
    ],
  },
  {
    question:
      "Qu'est-ce qui est le plus important pour vous dans le traitement de la douleur et des symptômes? Veuillez cocher la déclaration appropriée.",
    options: [
      {
        label:
          'Je souhaite que toutes les possibilités médicales pour préserver ma vie soient épuisées, même si cela implique des souffrances',
      },
      {
        label:
          'Je souhaite que le traitement médical vise principalement à soulager la souffrance, même si cela peut raccourcir ma vie',
      },
    ],
  },
  {
    question:
      "Souhaitez-vous renoncer à toutes les mesures prolongeant la vie et la souffrance si vous devenez incapable de juger à la suite d'un événement aigu inattendu et qu'il est impossible ou improbable que vous retrouviez votre capacité de jugement après une évaluation médicale approfondie?",
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question: 'Voulez-vous être réanimé?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
];

export const detailedQuestions: IQuestion[] = [
  {
    question:
      "Dans un scénario où vous devenez soudainement incapable de juger, par exemple en cas d'urgence, quel serait votre objectif de traitement pour prolonger la vie?",
    options: [
      {
        label: 'Je souhaite recevoir une réanimation et un traitement',
      },
      {
        label:
          'Je ne souhaite pas être réanimé, mais être traité en soins intensifs',
      },
    ],
  },
  {
    question:
      "Quel serait votre objectif de traitement pour soulager la souffrance en cas d'incapacité de travail soudaine?",
    options: [
      {
        label:
          'Je souhaite recevoir des médicaments pour soulager la douleur (morphine, etc.) et des thérapies de traitement',
      },
      {
        label:
          'Je ne souhaite pas recevoir de médicaments pour soulager la douleur (morphine, etc.) et des thérapies de traitement',
      },
    ],
  },
  {
    question:
      'Dans un scénario où vous êtes définitivement incapable de travailler, par exemple en cas de démence, quels seraient vos objectifs de traitement pour prolonger la vie?',
    options: [
      {
        label: 'Je souhaite être réanimé et traité en soins intensifs',
      },
      {
        label:
          'Je ne souhaite pas être réanimé, mais être traité en soins intensifs',
      },
    ],
  },
  {
    question:
      "Quels objectifs poursuivriez-vous en cas d'incapacité de travail soudaine et permanente?",
    options: [
      {
        label:
          'Je souhaite recevoir des médicaments pour soulager la douleur (morphine, etc.) et des thérapies de traitement',
      },
      {
        label:
          'Je ne souhaite pas recevoir de médicaments pour soulager la douleur (morphine, etc.) et des thérapies de traitement',
      },
    ],
  },
  {
    question:
      'Quels seraient vos objectifs de traitement pour prolonger la vie dans un scénario où vous êtes définitivement incapable de travailler, par exemple en raison de la démence?',
    options: [
      {
        label:
          'Je souhaite recevoir une réanimation et un traitement en soins intensifs',
      },
      {
        label:
          'Je ne souhaite pas être réanimé, mais être traité en soins intensifs',
      },
    ],
  },
  {
    question:
      "Quels seraient vos objectifs pour soulager la souffrance en cas d'incapacité de travail soudaine et permanente?",
    options: [
      {
        label:
          'Je souhaite recevoir des médicaments pour soulager la douleur (morphine, etc.) et des thérapies de traitement',
      },
      {
        label:
          'Je ne souhaite pas recevoir de médicaments pour soulager la douleur (morphine, etc.) et des thérapies de traitement',
      },
    ],
  },
];
