const partnerWithUs = {
  mainBanner: {
    title: 'Offrez un accès à des solutions de prévoyance numériques',
    subtitle:
      'Les fonds de pension, les banques, les compagnies d assurance, les associations et les partenaires médias travaillent avec nous pour offrir aux gens un accès facile à une prévoyance complète.',
    buttonLabel: 'Parlez avec nous',
  },
  youtubeTutorial: 'Pourquoi travailler avec nous?',
  aboutCards: {
    differentiate: {
      title: 'Atteindre la différenciation',
      description:
        'Générez plus de trafic pour votre plateforme avec des services supplémentaires et du contenu pertinent.',
    },
    efficiency: {
      title: "Plus d'efficacité",
      description:
        'Avec des services supplémentaires, vous pouvez simplement générer plus de revenus tout en réduisant les coûts.',
    },
    simplyIntegrated: {
      title: 'Intégration facile',
      description:
        "Prêt à l'emploi avec la solution cloud autonome simple dans votre design.",
    },
  },
  subBanner: {
    title: 'En tant que solution de self-service ou de conseil professionnel',
    subtitle:
      'Nous pouvons être utilisés comme une solution de self-service en marque blanche pour les clients finaux ou comme une solution de conseil pour soutenir votre processus de conseil. Une intégration API dans une solution existante est également possible.',
  },
  talkWithUs: {
    title: 'Aidez les gens à mener une vie bien réglée',
    description:
      'Élargissez votre offre de services et fournissez des services de prévoyance significatifs pour les gens qui simplifient leur vie et ont un impact positif sur leurs familles.',
    talkAboutPossibilities: 'Parlons des possibilités',
    iam: 'Je suis',
    partnerOccupations: {
      bank: 'Banque',
      insurance: 'Assurance',
      pensionFund: 'Fonds de pension',
      association: 'Association',
      media: 'Partenaire média',
      trust_foundation: 'Fondation',
      other: 'Autre',
    },
  },
  solutions: {
    pensionFund: {
      title: 'Fonds de pension',
      content:
        "Nous essayons depuis un certain temps d'augmenter le nombre de visiteurs sur notre portail d'assurance. Mais au-delà des certificats d'assurance annuels, il y a peu de bonnes raisons. Avec nous, nous élargissons les possibilités de prévoyance et augmentons en même temps le trafic avec une offre innovante.",
    },
    bank: {
      title: 'Banque',
      content:
        'Bien que les banques se soient concentrées sur la provision financière des clients dans le passé, nous pensons que nous devrions également en tant que banque prendre de nouvelles voies pour aider les clients à mener une vie bien réglée. Avec un outil comme le nôtre, cela devient possible.',
    },
    fintechPlatform: {
      title: 'Plateforme FinTech',
      content:
        "En tant que solution, nous sommes très proches du client en matière de prévoyance financière. L'extension de notre offre par des solutions de prévoyance juridiquement sûres est une prochaine étape logique pour permettre au client une prévoyance complète.",
    },
  },
  faq: {
    questions: {
      question1: 'Quelle valeur ajoutée offre-t-on à mes clients?',
      question2: 'Où sont stockées les données?',
      question3: "Sommes-nous conformes à la DSG et au RGPD de l'UE?",
      question4: 'Puis-je utiliser notre solution comme solution de conseil?',
      question5: 'Est-ce que notre solution est disponible en marque blanche?',
      question6: "Comment puis-je intégrer notre solution dans l'entreprise?",
      question7: 'Quelles langues sont prises en charge?',
      question8: 'Puis-je impliquer également mes organisations partenaires?',
      question9:
        "Quels avantages m'offre-t-on en tant que membre du conseil de fondation?",
      question10: 'Puis-je demander des fonctionnalités supplémentaires?',
      question11: 'À quoi ressemble le modèle commercial?',
    },
    answers: {
      answer1:
        "Avec nous, les gens ont pour la première fois un accès facile à des services de prévoyance numériques avec une sécurité juridique. Pour beaucoup, nous soutenons les premiers pas vers une vie bien réglée. Cela leur donne ainsi qu'à leurs familles plus de contrôle et moins de stress en cas d'urgence.",
      answer2:
        "Les données sont stockées en Suisse. Pour les organisations partenaires en Allemagne et en Autriche, nous prévoyons une zone de conformité de l'UE.",
      answer3:
        "Oui. Les exigences de la DSG et du RGPD de l'UE ont servi de spécifications de conception pour la mise en œuvre de notre solution en tant que logiciel et constituent donc la base de l'architecture du produit.",
      answer4:
        'Oui. En plus de la solution de self-service pour les clients finaux, nous offrons un cockpit de conseiller. Ainsi, les processus de conseil financier ou de planification de la retraite peuvent être soutenus et développés par nous. Cela permet à vos conseillers de développer des solutions avec les clients, le client ayant la possibilité de faire des ajustements ou des ajouts de manière autonome à une date ultérieure.',
      answer5:
        'Oui. Nous sommes une solution de marque blanche dédiée pour des organisations telles que les banques, les assurances, les fonds de pension et autres institutions de prévoyance ainsi que les institutions financières et les associations. La solution peut être intégrée de différentes manières.',
      answer6:
        "Différents scénarios sont possibles. L'intégration la plus simple se fait via un simple lien vers notre solution. Cependant, nous offrons également des intégrations via SSO et API. L'intégration en tant qu'iFrame est également envisageable.",
      answer7:
        "Nous supportons le français, l'anglais, l'allemand et l'italien.",
      answer8:
        "Nous avons différents modèles de partenariat et vous offrons par exemple la possibilité de lister des notaires et des avocats ainsi que des conseillers financiers et patrimoniaux en tant qu'experts.",
      answer9:
        "Avec nous, en tant que membre du conseil de fondation, je peux activement minimiser mon risque de responsabilité en obtenant un outil pour augmenter le trafic sur le portail de l'institution de prévoyance, en encourageant activement les assurés à prévoir et en créant de plus en plus de transparence et d'efficacité.",
      answer10:
        'Oui. Chez nous, nous écoutons très attentivement ce que nos clients et partenaires disent. Nous examinons les souhaits et les exigences en tenant compte de tous et priorisons les fonctions qui offrent une valeur ajoutée pour la majorité de nos clients et partenaires.',
      answer11:
        'Les partenaires paient une redevance annuelle pour notre solution. Pour chaque conclusion par les clients finaux, le partenaire reçoit un kickback. Cependant, il y a aussi des partenaires qui prennent en charge les coûts pour leurs clients finaux en partie ou en totalité et offrent le service à un prix réduit.',
    },
  },
};

export default partnerWithUs;
