const lastWillLanding = {
  mainBanner: {
    title: 'Déterminez qui recevra quoi en votre absence',
    subtitle:
      'La fixation proactive de ce qui arrivera à votre patrimoine contribue à assurer votre dernière volonté et à rendre le processus plus confortable pour votre famille.',
    buttonLabel: 'Créer le document',
  },
  youtubeTutorial: "Qu'est-ce qu'un testament?",
  aboutCards: {
    whoGetsWhat: {
      title: 'Qui reçoit quoi',
      description:
        'Le testament règle qui devrait recevoir quoi, ce qui peut déroger à la succession légale.',
    },
    enforceWishes: {
      title: 'Faire respecter les souhaits',
      description:
        'Assurez-vous de répartir votre patrimoine selon vos souhaits et préférences personnels.',
    },
    clarificationForFamily: {
      title: 'Clarté pour la famille',
      description:
        'Évitez les discussions inutiles et assurez la clarté pour les proches.',
    },
  },
  subBanner: {
    title: 'Bien organisé avec un testament juridiquement conforme',
    subtitle:
      'Avec bien organisé, vous créez rapidement votre testament, pouvez obtenir des conseils juridiques sur des sujets délicats ou des questions en suspens, et faites plaisir à vos proches.',
    buttonLabel: 'Créer gratuitement maintenant',
  },
  newsletter: {
    title: 'Soyez bien organisé avec votre testament',
    buttonLabel: 'Créer maintenant',
  },
  faq: {
    questions: {
      question1: 'Quels sont les avantages dun testament?',
      question2:
        'Existe-t-il différents types de testaments que je peux créer?',
      question3: 'Quels sont les droits de succession légaux?',
      question4:
        'Quels ont été les changements suite à la révision du droit de succession le 1er janvier 2023?',
      question5:
        'Quels éléments de mon patrimoine puis-je léguer librement dans mon testament?',
      question6:
        'Que se passe-t-il si je décède sans avoir rédigé de testament?',
      question7: 'Puis-je rédiger mon testament avec mon conjoint?',
      question8: 'Puis-je léguer à des personnes hors de ma famille?',
      question9: 'Est-il possible de déshériter quelquun?',
      question10: 'Dois-je nommer un exécuteur testamentaire?',
      question11: 'Puis-je modifier mon testament à tout moment?',
      question12:
        'Y a-t-il dautres documents que je devrais créer en plus du testament?',
      question13: 'Comment gut geregelt peut-il maider avec mon testament?',
    },
    answers: {
      answer1:
        'Un testament vous permet de régler votre succession, cest-à-dire ce qui doit arriver à votre patrimoine après votre mort. De plus, si vous vivez en concubinage et souhaitez que votre partenaire hérite, vous devez absolument le stipuler dans votre testament.',
      answer2:
        'Oui, il existe le testament olographe, le testament public et le testament oral.',
      answer3:
        'Il existe une part réservataire, un droit d héritage légal, qui garantit que votre conjoint ou vos enfants ne peuvent pas être complètement exclus de votre héritage.',
      answer4:
        'Le changement le plus important est que la part réservataire des parents a été complètement supprimée et que la part réservataire pour les héritiers non mariés avec des enfants a été réduite de 75% à 50%.',
      answer5:
        'Outre les parts réservataires, vous pouvez disposer librement de votre succession, par exemple en favorisant davantage votre conjoint ou votre partenaire enregistré.',
      answer6:
        'Dans ce cas, la succession légale s applique automatiquement et votre succession est divisée en fonction de votre situation familiale au moment du décès.',
      answer7:
        'Non, vous ne pouvez pas rédiger votre testament avec votre conjoint.',
      answer8:
        'Oui, en plus de la part réservataire légalement définie, vous pouvez décider qui vous voulez nommer comme héritier, y compris des associations ou des institutions.',
      answer9:
        'Tant qu il ne s agit pas d héritiers qui ont droit à la part réservataire, vous pouvez déshériter.',
      answer10:
        'Non, la nomination d un exécuteur testamentaire est facultative, mais peut protéger contre les litiges successoraux.',
      answer11:
        'Oui, vous pouvez apporter des modifications à tout moment, il est important que vous les datiez et les signiez.',
      answer12:
        'Oui, le {{powerOfAttorney}} et le {{livingWill}} sont deux documents que vous devriez créer dès que possible.',
      answer13:
        'Chez gut geregelt, nous vous aidons à créer votre testament de manière fluide et juridiquement valide.',
    },
    hyperlinks: {
      lastWill: 'Testament',
      livingWill: 'Directive anticipée',
      powerOfAttorney: 'Mandat de prévoyance',
    },
  },
};

export default lastWillLanding;
