import { treatmentQuestions, detailedQuestions } from './questions';
import outputDoc from './outputDoc';

const livingWill = {
  mainHeaders: {
    shortPainFree: 'Votre directive anticipée simple & efficace',
    madeIt: 'Vous avez réussi !',
    detailedLivingWill: 'Bien joué, vous avez réussi',
  },

  stepperInfo: [
    {
      label: 'Grundlagen',
      title: 'Was ist das Thema?',
    },
    {
      label: '',
      title: 'Die Menschen, die sich um mich kümmern',
    },
    {
      label: 'Behandlungen',
      title: 'Wann soll es wirksam werden?',
    },
    {
      label: 'Ihre Patientenverfügung',
      title: 'Was nun?',
    },
  ],

  stepOne: {
    introParagraph:
      'Avec une directive anticipée, vous déterminez comment vous voulez être traité médicalement et soigné si vous devenez incapable de juger.\n' +
      '\n' +
      'Toute personne capable de juger peut créer, modifier ou révoquer une directive anticipée. La directive anticipée indique également qui la mettra en œuvre en cas d urgence. Vous pouvez désigner une personne représentative autorisée à prendre des décisions pour vous et qui servira de point de contact pour le personnel médical. Idéalement, vous devriez désigner une personne principale et une personne de remplacement',
    helperParagraph: 'Pour commencer, veuillez remplir ce qui suit :',
  },

  stepTwo: {
    introParagraph: 'Personne représentative',
    helperParagraph: 'Ma personne de confiance est :',
    optionalRepresentative:
      'Si la personne mentionnée ci-dessus est empêchée, je désigne la personne de remplacement suivante :',

    contactConfirmation:
      "Je confirme que j'ai informé la/les personne(s) représentative(s) de mes souhaits.",
  },

  stepThree: {
    introParagraph:
      'Pour vous assurer que la directive anticipée correspond à vos souhaits, vous devez maintenant répondre à certaines questions exigeantes et personnelles.',
    helperParagraph:
      'Vous devez réfléchir à la façon dont vous définissez la qualité de vie et dans quelles situations vous renonceriez à des interventions médicales. Prenez tout le temps nécessaire. Avec notre service de rappel, vous pouvez interrompre et sauvegarder à tout moment.',
    questions: treatmentQuestions,
  },

  stepFour: {
    introParagraph:
      'Veuillez lire attentivement les scénarios suivants et cochez pour chaque maladie ce qui vous correspond le mieux. Pour chaque état de santé, nous voulons savoir quel traitement vous souhaitez pour prolonger votre vie et/ou soulager votre souffrance.',
    questions: detailedQuestions,
    highlightedText: {
      coma: 'Coma',
      dementia: 'Démence',
      emergency: 'Urgence',
      goalOnSufferingRelief: 'Objectif de soulagement de la souffrance',
      prolongedIncapacity: 'Incapacité de jugement prolongée',
      permanentIncapacity: 'Incapacité de jugement permanente',
      suddenlyIncacitated: 'Incapacité de jugement soudaine',
      sufferingRelief: 'Soulagement de la souffrance',
      suddenIncapacitation: 'Mise sous tutelle soudaine',
      treatmentGoalForLifeExtension:
        'Objectif de traitement pour prolonger la vie',
      treatmentGoalsForLifeExtension:
        'Objectifs de traitement pour prolonger la vie',
      treatmentGoalForSufferingRelief:
        'Objectif de traitement pour soulager la souffrance',
      vegetativeState: 'État végétatif',
    },
  },

  stepFive: {
    introParagraph: 'Bien joué, vous êtes maintenant bien réglé !',
    helperParagraph:
      'L étape la plus difficile est maintenant terminée. Il vous suffit maintenant d imprimer, de dater et de signer la directive anticipée et de remettre une copie à votre/vos personne(s) représentative(s) (de préférence en personne) et à votre médecin traitant. Veuillez également discuter de la directive anticipée avec votre famille en temps voulu.',
    here: 'ici',
    eightMinutes: '8 minutes',
    helperParagraph2:
      'Si vous souhaitez une version plus détaillée de la directive anticipée, vous pouvez continuer ici, le temps nécessaire est d environ 8 minutes.',
    document: outputDoc,
  },
};

export default livingWill;
