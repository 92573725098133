const digitalFootprint = {
  mainBanner: {
    title: 'Gather all your digital legacy and assets in a secure safe',
    subtitle:
      'Make sure that all your electronic data or assets, devices, and insurances are secured and accessible to authorized individuals in the event of your passing.',
    buttonLabel: 'Preserve your legacy',
  },
  youtubeTutorial: 'How to preserve your digital legacy?',
  aboutCards: {
    documentAccounts: {
      title: 'Document accounts',
      description:
        'Easily feed all accounts from your password manager into gut geregelt. Don’t worry. We explicitly don’t want passwords!',
    },
    connectDigitalValues: {
      title: 'Connect digital values',
      description:
        'List all your digital wallets, domains, and other values and where and how your family can find password to get access.',
    },
    preserveDigitalData: {
      title: 'Preserve digital data',
      description:
        'Organize and preserve data such as pictures or videos, music, important documents and other data in a secure safe.',
    },
  },
  subBanner: {
    title: 'Make sure you leave everything clean and tidy',
    subtitle:
      'Unless specifically stated it in your last will or testamentary contract, your digital assets will pass to your legal heirs on your death. You should therefore decide during your lifetime what should be deleted or transferred on your death.',
    buttonLabel: 'Take care of your digital footprint',
  },
  testimonials: {
    testimonial1: {
      name: 'Patrick Zesiger',
      text: 'The digital estate from gut geregelt has helped me keep my digital affairs in perfect order. An absolute must for anyone who does things in the digital world!',
    },
    testimonial2: {
      name: 'Sara Becker',
      text: 'The certainty that my family does not have to worry about cleaning up and that all my social media channels can be closed gives me a good feeling. Super easy to set up. Can only recommend it.',
    },
    testimonial3: {
      name: 'Luisa Stauffer',
      text: 'I recently set up the digital estate with and for my father and mother. It was very quick and uncomplicated. Even my father was glad that we finally got it over with.',
    },
  },
  newsletter: {
    title: 'Get your digital footprint gut geregelt',
    buttonLabel: 'Organize it now',
  },
  faq: {
    questions: {
      question1: 'What is a digital estate and why is it important?',
      question2:
        'What types of digital assets can be included in the digital estate?',
      question3: 'How does the digital estate service work?',
      question4:
        'How can I ensure that my loved ones have access to my digital estate?',
      question5: 'What happens to my digital assets when I die?',
      question6: 'How secure are my personal information in your service?',
      question7: 'Can I update or change my digital estate?',
      question8:
        'What legal aspects should be considered when managing a digital estate?',
      question9:
        'What happens to my online subscriptions and accounts after my death?',
      question10:
        'How does your service support the management of insurance and banking documents?',
      question11:
        'Does well-regulated store passwords or other access credentials?',
    },
    answers: {
      answer1:
        "A digital estate encompasses all digital assets and online stored information of an individual. This can range from social media accounts to online subscriptions to cryptocurrency wallets. It is important to manage a digital estate to ensure that your digital affairs are properly handled after your death and to facilitate your loved ones' access to important information.",
      answer2:
        'Our service allows you to capture a variety of digital assets, including social media accounts, online subscriptions, crypto wallets, domains, password managers, electronic devices, and much more. You can document all relevant digital assets and leave instructions on what should happen to them after your death and how access can be obtained.',
      answer3:
        'Our service provides you with a user-friendly platform where you can capture, manage, and set instructions for the management of your digital assets after your death. You can specify who should have access to which information and define what should happen to your digital assets.',
      answer4:
        'With our service, you can designate specific individuals who will have access to your digital estate. This way you ensure that your loved ones have access to important information.',
      answer5:
        "After your death, the individuals you have designated will gain access to your well-regulated account by submitting the death certificate and executor's certificate. This ensures that they handle your digital assets according to your instructions. You can specify whether certain accounts should be deleted, continued, or transferred to specific individuals. Our service ensures that your digital affairs are handled according to your wishes.",
      answer6:
        'We take the security of your personal information very seriously. Our service uses industry-standard security measures to protect your data, including encryption technologies and access restrictions. Additionally, we do not store sensitive data such as passwords, account balances, and the like.',
      answer7:
        'Yes, you can update or change your digital estate at any time. Our platform allows you to add new assets, delete existing information, edit existing information, and leave new instructions.',
      answer8:
        'When managing a digital estate, legal aspects such as creating a will and determining the succession should be considered. It is advisable to seek legal advice to ensure that your digital affairs are handled in accordance with applicable laws',
      answer9:
        'After your death, your online subscriptions and accounts can be managed according to your instructions. You can specify whether certain subscriptions should be canceled, accounts deleted, or transferred to specific individuals.',
      answer10:
        "Our service offers features to organize your insurance and banking relationships. You can specify where to find which documents. We also assist in automatically generating termination letters for insurance policies after your passing, once a death certificate and executor's certificate are available.",
      answer11:
        'No. We explicitly point this out and even provide assistance so that as a customer, you DO NOT store or display passwords in well-regulated. Instead, we encourage you to store the access credentials for relevant accounts separately and keep them in a secure place where your family can access them. You document this secure location for your loved ones in well-regulated.',
    },
  },
};

export default digitalFootprint;
