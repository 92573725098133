import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../lib/hooks/useAuth';
import { PATH_AFTER_LOGIN } from '#/config';
import LoadingScreen from '#/components/shared/ui/LoadingScreen';

type GuestGuardProps = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={PATH_AFTER_LOGIN} />;
  }
  if (!isInitialized) {
    return <LoadingScreen />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
