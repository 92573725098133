const poaLanding = {
  mainBanner: {
    intro: 'Create a precautionary mandate online:',
    title: 'Define a decision maker for the emergency',
    subtitle:
      'Without a power of attorney, your spouse or family cannot make decisions for you, even in an emergency.  Determine now who is legally authorized to act on your behalf.',
    buttonLabel: 'Create document',
  },
  youtubeTutorial: 'What is a power of attorney?',
  aboutCards: {
    guaranteeForPower: {
      title: 'Guarantee for power',
      description:
        'Allow your loved one to make decisions for you, but in your best interest, if you cannot make them yourself.',
    },
    mutualProtection: {
      title: 'Mutual protection',
      description:
        'Protect the integrity of your assets by ensuring that important decisions for the family can be made only by family members.',
    },
    clarificationForFamily: {
      title: 'Clarification for family',
      description:
        'Make unnecessary discussions obsolete and provide clarity for the bereaved.',
    },
  },
  subBanner: {
    title: 'Gut geregelt with a legally compliant power of attorney',
    subtitle:
      'With gut geregelt, you quickly create your power of attorney, can get legal consultation on delicate topics or open questions and do your relatives a favor.',
    buttonLabel: 'Create it for free',
  },
  newsletter: {
    title: 'Get gut geregelt with your power of attorney',
    buttonLabel: 'Create it now',
  },
  faq: {
    questions: {
      question1: 'What is a power of attorney?',
      question2: 'When is incapacity to judge present?',
      question3:
        'What happens if I become incapacitated and have no power of attorney?',
      question4: 'What should I include in the power of attorney?',
      question5: 'How do I create my power of attorney?',
      question6: 'How do I choose my representative?',
      question7: 'What is KESB, and what is its function?',
      question8: 'Can I adjust my power of attorney whenever I want?',
      question9: 'Can I cancel my power of attorney?',
      question10:
        'Can I also include my treatment wishes for a medical emergency in the power of attorney?',
      question11:
        'Are there other documents I should create besides the power of attorney?',
      question12: 'How does gut geregelt help me with my power of attorney?',
    },
    answers: {
      answer1:
        'In a power of attorney, you specify who should represent your interests in personal and financial matters in the event of incapacity to judge. With the power of attorney, you ensure that your interests are represented, and your will is implemented.',
      answer2:
        'Incapacity to judge can occur, for example, due to a severe illness such as dementia, after a stroke, or after a serious accident followed by a coma.',
      answer3:
        'It depends on your marital status: As a single person, as a married person or in a registered partnership, and as a person living in non-marital cohabitation.',
      answer4:
        'The power of attorney includes three areas: personal care, financial care, and legal affairs. You can either assign all three areas to the same representative or divide them.',
      answer5:
        'You can create your power of attorney yourself. Important: do not forget the date and signature. Or you can create it together with a notary and have it publicly certified.',
      answer6:
        'You can either choose one person for all three areas or multiple individuals. It is essential that you trust this person 100%.',
      answer7:
        "The Child and Adult Protection Authority (KESB) ensures the protection of vulnerable children and adults. In the case of a power of attorney, the KESB becomes active when informed about a person's incapacity.",
      answer8:
        'Yes, with the only condition that you must be of sound mind at the time of the adjustment. A new power of attorney replaces the previous version.',
      answer9:
        'Yes, you can cancel your power of attorney at any time by destroying the document. This extinguishes its validity.',
      answer10:
        'Yes, you can. However, it also makes sense to create a separate living will. This can then be part of your power of attorney.',
      answer11:
        'Yes, {{lastWill}} and {{livingWill}} are two other important documents you should create promptly.',
      answer12:
        'At gut geregelt, we assist you in the legally valid and smooth creation of your power of attorney. So you and your loved ones can look into the future well-regulated.',
    },
    hyperlinks: {
      lastWill: 'the last will',
      livingWill: 'the living will',
      powerOfAttorney: 'the power of attorney',
    },
  },
};

export default poaLanding;
