import { treatmentQuestions, detailedQuestions } from './questions';
import outputDoc from './outputDoc';

const livingWill = {
  mainHeaders: {
    shortPainFree: 'Your simple & effective living will',
    madeIt: "You've done it!",
    detailedLivingWill: 'Great job, you did it',
  },

  stepperInfo: [
    {
      label: 'Basics',
      title: 'What is the topic?',
    },
    {
      label: '',
      title: 'The people who take care',
    },
    {
      label: 'Treatments',
      title: 'When should it take effect?',
    },
    // {
    //   label: '',
    //   title: "Let's clarify the details",
    // },
    {
      label: 'Your living will',
      title: 'Now what?',
    },
  ],

  stepOne: {
    introParagraph:
      'The living will specifies how you will be medically cared for in the event of a serious accident or illness if you become unresponsive. Every person of sound body and mind can create, change or revoke a living will. The living will also states who will enforce it in the worst case.',
    helperParagraph: 'To get started, please fill out the following:',
  },

  stepTwo: {
    introParagraph: 'Representative',
    helperParagraph:
      'My confidant and representative in medical matters in the event of an accident or serious illness after which I am incapacitated is:',
    optionalRepresentative:
      'If person above is unable to attend, I nominate the following substitute person: ( optional )',
    contactConfirmation:
      'I confirm that I have informed the representative(s) for my descision',
  },

  stepThree: {
    introParagraph:
      'To ensure that the living will corresponds to your wishes, you must now answer a few difficult questions.',
    helperParagraph:
      "You have to deal with how you define quality of life and in which situations you would forego medical interventions. Take all the time in the world here, and rest assured that the reminder service has your back, because there's a good chance you'll be tempted to stop the process for a moment.",
    questions: treatmentQuestions,
  },

  stepFour: {
    introParagraph:
      'Please read the scenarios below carefully and check off the one that best applies to you for each condition. For each health condition, we want to know what treament you want in prolonging your life and what to reduce your suffering.',
    questions: detailedQuestions,
    highlightedText: {
      coma: 'coma',
      dementia: 'dementia',
      emergency: 'emergency,',
      goalOnSufferingRelief: 'goal on suffering relief',
      prolongedIncapacity: 'prolonged incapacity',
      permanentIncapacity: 'permanent incapacity',
      suddenlyIncacitated: 'suddenly incapacitated,',
      sufferingRelief: 'suffering relief',
      suddenIncapacitation: 'sudden incapacitation',
      treatmentGoalForLifeExtension: 'treatment goal for life extension',
      treatmentGoalsForLifeExtension: 'treatment goals for life extension',
      treatmentGoalForSufferingRelief: 'treatment goal for suffering relief',
      vegetativeState: 'vegetative state',
    },
  },

  stepFive: {
    introParagraph: 'Well done, you are now gut geregelt!',
    helperParagraph:
      'The most difficult step has now been completed. Now all you have to do is print out, date and sign the living will and give a copy to the person you trust (preferably in person) and to your family doctor. Also, please discuss the living will with your family at an appropriate time. Trust us - it will relieve them and you.',
    here: 'here,',
    eightMinutes: '8 minutes',
    helperParagraph2:
      'If you want a more detailed version of the living will you can continue here, time needed is about 8 minutes.',
    document: outputDoc,
  },
};

export default livingWill;
