const agb = {
  title: 'Terms and Conditions',
  scopeOfApp: {
    title: 'Scope of Application',
    content: {
      p1: 'The subject of these General Terms and Conditions (GTC) is the use of the services of Gut Geregelt AG (hereinafter referred to as Gut Geregelt). Gut Geregelt provides the customer with the services for the duration of the contract in the current version for a fee.',
      p2: 'The contractual relationship between Gut Geregelt and the customer is established with the acceptance of the offer. By accepting the offer, the customer expressly acknowledges these GTC, even if they contradict his terms and conditions in whole or in part.',
      p3: 'The customer is obliged to provide accurate, complete and up-to-date information at all times. If this requirement is not met, it constitutes a violation of the terms of use, which can lead to immediate termination of the contract.',
    },
  },
  rightOfUse: {
    title: 'Rights of Use',
    content: {
      p1: 'Gut Geregelt grants the customer the non-exclusive and non-transferable right to use the services of Gut Geregelt for the duration of the contract as intended.',
      p2: 'The customer is not entitled to make the service available to third parties for a fee or free of charge. Any form of provision of the service to third parties is expressly prohibited.',
      p3: 'The customer is obliged to design all contractual relationships with third parties in such a way that third parties are prevented from using the service free of charge.',
    },
  },
  support: {
    title: 'Support',
    content: {
      p1: 'Gut Geregelt support is available to the customer from Monday to Friday from 8.00 - 12.00 and 13.00 - 17.00 by email at support@gutgeregelt.ch.',
    },
  },
  intellectualPropertyRights: {
    title: 'Protection Rights',
    content: {
      p1: 'The customer acknowledges the protection rights, in particular the copyright of Gut Geregelt to software services and documentation. The customer is not entitled to make this software available to third parties for a fee or free of charge, or to use it outside the framework of the contractual relationship with Gut Geregelt or to dispute Gut Geregelt in any form.',
    },
  },
  confidentiality: {
    title: 'Confidentiality',
    content: {
      p1: 'Gut Geregelt may not disclose confidential information and/or materials to third parties or make them directly or indirectly accessible to third parties without the express written consent of the customer.',
      p2: 'Gut Geregelt undertakes not to use and/or make available to third parties the confidential information/materials that have become known to it without the express written consent of the customer.',
      p3: 'The confidential information is to be used by Gut Geregelt exclusively in connection with and for the provision of the service. Gut Geregelt may only pass on confidential information to its employees if this is necessary for the processing of the aforementioned project. Employees are to be obliged to maintain confidentiality in accordance with this agreement. The obligation of confidentiality also applies to companies associated with Gut Geregelt. Gut Geregelt will also oblige them to maintain confidentiality accordingly.',
      p4: 'Gut Geregelt can disclose confidential information if this is required by law or other government regulations. In these cases, Gut Geregelt will inform the customer immediately and take the necessary protective measures at the request of the customer, insofar as these are appropriate. Gut Geregelt takes appropriate precautions to protect confidential information and materials from unauthorized access by third parties.',
    },
  },
  dataProtectionAndSecurity: {
    title: 'Data Protection and Data Security',
    content: {
      p1: "Gut Geregelt will treat the customer's data with the utmost care and protect it from misuse and loss. For this purpose, Gut Geregelt takes technical and organizational measures that meet the valid requirements of the GDPR. Special agreements for storing data in certain countries can be made within the framework of technical possibilities.",
      p2: 'The customer is responsible for the legality of the data transfer or its use. All data of the customer stored and processed by Gut Geregelt is the exclusive property of the customer and is used by Gut Geregelt exclusively for the purpose of fulfilling the contract.',
    },
  },
  disclaimer: {
    title: 'Disclaimer',
    content: {
      p1: 'Gut Geregelt does not offer legal advice but provides coordinated templates. In complicated matters, the involvement of a lawyer is essential. Gut Geregelt does not replace legal advice.',
    },
  },
};

export default agb;
