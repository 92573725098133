const validations = {
  user: {
    first_name: 'First name is required',
    last_name: 'Last name is required',
    email: 'Email is required',
    invalidEmail: 'Email is not valid',
    civil_status: 'Civil status is required',
    gender: 'Please select your gender',
    date_of_birth: {
      required: 'Date of birth is required',
      max: 'Must be 18 or older',
    },
    mobile_phone: {
      required: 'Mobile phone is required',
      min: 'Mobile phone is too short',
      max: 'Mobile phone is too long',
      matches: 'Mobile phone number is not valid',
    },
    minPassword: 'Password must be at least 6 characters',
    password: 'Password is required',
    confirmPassword: 'Confirm password is required',
    passwordMatch: 'Passwords must match',
  },
  adress: {
    street: 'Street is required',
    house_number: 'House number is required',
    zip_code: 'Zip code is required',
    city: 'City is required',
    country: 'Country is required',
  },
  primary_representative: 'Primary representative is required',
  secondary_representative: 'Secondary representative is required',
  rep_selection: 'Please select primary and secondary representative',
  rep_inform_date: 'Please select the inform date',
  selectAnOption: 'Please select an option',
  who_are_you: 'Please tell me who you are',
  default_message: 'This field is required',
  successors: {
    first_name: 'First name is required',
    last_name: 'Last name is required',
    email: 'Email is required',
    invalidEmail: 'Email is not valid',
    agree: 'Please agree that these persons receive guest access.',
    min: 'Please select at least two successors',
  },
  message: 'Message is required',
  role: 'Role is required',
};

export default validations;
