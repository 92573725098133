/* eslint-disable react/no-unstable-nested-components */
import { AnimatePresence } from 'framer-motion';
import { Dialog, DialogProps } from '@mui/material';

export interface Props extends DialogProps {
  variants?: Record<string, unknown>;
  onClose?: VoidFunction;
}

export default function DialogAnimate({
  open = false,
  variants,
  onClose,
  children,
  sx,
  ...other
}: Props) {
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={open}
          onClose={onClose}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}
