const tt = {
  title: 'Geschäftsbedingungen',
  scope: {
    title: 'Umfang',
    content:
      'Diese Allgemeinen Geschäftsbedingungen (AGB) regeln die Nutzung der Dienste der betascale AG (nachfolgend „gut geregelt“ genannt). Gut geregelt stellt dem Kunden die Leistungen für die Dauer des Vertrages in der jeweils aktuellen Fassung gegen Bezahlung zur Verfügung. Das Vertragsverhältnis zwischen gut geregelt und dem Kunden kommt mit der Annahme des Angebots zustande. Mit der Annahme des Angebots erkennt der Kunde diese AGB ausdrücklich an, auch wenn diese seinen eigenen Geschäftsbedingungen ganz oder teilweise widersprechen. Der Kunde ist verpflichtet, jederzeit korrekte, vollständige und aktuelle Informationen bereitzustellen. Die Nichteinhaltung dieser Verpflichtung stellt einen Verstoss gegen die Nutzungsbedingungen dar und kann zur sofortigen Kündigung des Vertrages führen.',
  },
  userRights: {
    title: 'Nutzerrechte',
    content:
      'gut geregelt räumt dem Kunden für die Dauer des Vertrages ein nicht ausschliessliches und nicht übertragbares Recht ein, die Leistungen bestimmungsgemäss zu nutzen. Der Kunde ist nicht berechtigt, die Leistungen Dritten gegen Entgelt oder unentgeltlich zur Verfügung zu stellen. Jegliche Form der Erbringung der Leistung an Dritte ist ausdrücklich untersagt. Der Kunde hat sicherzustellen, dass sämtliche Vertragsverhältnisse mit Dritten die unentgeltliche Nutzung des Dienstes ausschliessen.',
  },
  customerResponsibilities: {
    title: 'Kundenpflichten',
    content:
      'Über die von gut geregelt erbrachten Leistungen hinaus trägt der Kunde die volle Verantwortung für den Betrieb, die Sicherheit und den Zustand seiner elektronischen und digitalen Medien, auf denen die Leistungen von gut geregelt genutzt werden (Hardware, Software, Betrieb, Sicherheit etc.). Sämtliche mit der Erfüllung dieser Pflichten verbundenen Kosten trägt der Kunde.',
  },
  systemAvailability: {
    title: 'Systemverfügbarkeit',
    content:
      'Die Leistungen von gut geregelt basieren auf dem „Best-Effort“-Prinzip. Gut geregelt ergreift angemessene Massnahmen, um eine möglichst unterbrechungsfreie Nutzung der Dienste sicherzustellen. Dem Kunden ist jedoch bewusst, dass es sich bei dem Dienst sowie weiteren Komponenten von Drittanbietern, deren Funktionalität ausserhalb des Einflussbereichs von gut geregelt liegt, um ein technisch komplexes System handelt. Daher kann gut geregelt keine ständige und vollständige Verfügbarkeit der Komponenten von mehr als 99,5 % garantieren. Unterbrechungen aufgrund von Systemwartungen, Updates etc. werden im Voraus mit einer Ankündigungsfrist von zwei Werktagen für geplante Wartungsarbeiten bekannt gegeben. Dringende Wartungsarbeiten, die zu einer Unterbrechung der Verfügbarkeit führen, können ohne vorherige Ankündigung durchgeführt werden, um Probleme umgehend zu beheben oder potenzielle Bedrohungen für das System abzuwenden. Erkennt gut geregelt eine Gefährdung des ordnungsgemässen Betriebs durch fahrlässiges oder vorsätzliches Handeln Dritter, ist sie befugt, unverzüglich alle notwendigen Massnahmen zu ergreifen, um die eigene Infrastruktur und Software vor Schäden zu schützen.',
  },
  support: {
    title: 'Support',
    content:
      'Der Support von gut geregelt steht dem Kunden per E-Mail unter support@gutgeregelt.ch von Montag bis Freitag, 8:00 – 12:00 Uhr und 13:00 – 17:00 Uhr zur Verfügung, mit einer durchschnittlichen Reaktionszeit von 1 Stunde.',
  },
  renumerations: {
    title: 'Vergütungen',
    content:
      'Die Höhe der Vergütung für die vereinbarten Leistungen wird in den jeweiligen Preislisten gesondert festgelegt. Es handelt sich in der Regel um wiederkehrende Gebühren. Wiederkehrende Gebühren können nach vorheriger Ankündigung angepasst werden. Sie sind innerhalb von 20 Tagen fällig oder können per Kreditkarte eingezogen werden. Zusätzlich vereinbarte Leistungen werden gesondert in Rechnung gestellt.',
  },
  intellectualPropertyRights: {
    title: 'Geistige Eigentumsrechte',
    content:
      'Der Kunde erkennt die geistigen Eigentumsrechte, insbesondere das Urheberrecht, von gut geregelt an Software, Dienstleistungen und Dokumentation an. Dem Kunden ist es nicht gestattet, diese Software Dritten entgeltlich oder unentgeltlich zur Verfügung zu stellen, sie ausserhalb des Vertragsverhältnisses mit gut geregelt weiterzuvermieten und/oder zu nutzen oder die Rechte von gut geregelt in irgendeiner Weise anzufechten.',
  },
  dataProtection: {
    title: 'Datenschutz und Datensicherheit',
    content:
      'gut geregelt wird die Daten des Kunden mit grösster Sorgfalt behandeln und vor Missbrauch und Verlust schützen. gut geregelt speichert keine personenbezogenen Daten seiner Kunden. Dennoch ergreift gut geregelt technische und organisatorische Massnahmen, die den geltenden Anforderungen der DSGVO entsprechen. Im Rahmen der technischen Möglichkeiten können in bestimmten Ländern besondere Vereinbarungen zur Datenspeicherung getroffen werden. Der Kunde ist für die Rechtmässigkeit der Datenweitergabe bzw. deren Verwendung verantwortlich. Sämtliche von gut geregelt gespeicherten und verarbeiteten Kundendaten sind ausschliessliches Eigentum des Kunden und werden von gut geregelt ausschliesslich zum Zwecke der Vertragsabwicklung genutzt.',
  },
  disclaimerLiability: {
    title: 'Haftungsausschluss',
    content:
      'gut geregelt gewährleistet, dass die Leistungen gemäss den gesetzlichen Bestimmungen dieses Vertrages funktionsfähig und betriebsbereit sind. Der Kunde verpflichtet sich, gut Regelung von sämtlichen Ansprüchen Dritter aufgrund der gespeicherten Daten freizustellen und gut geregelt etwaige Kosten zu erstatten, die aus etwaigen Rechtsverstössen entstehen. gut geregelt ist berechtigt, den Speicherplatz sofort zu sperren, wenn der begründete Verdacht besteht, dass die gespeicherten Daten rechtswidrig sind und/oder Rechte Dritter verletzen. Ein begründeter Verdacht einer Rechtswidrigkeit und/oder eines Verstosses liegt dann vor, wenn Gerichte, Behörden und/oder Dritte gut Geregelt darüber informieren. Gut geregelt hat den Kunden unverzüglich über die Entfernung und die Gründe dafür zu informieren. Das Verbot muss aufgehoben werden, sobald der Verdacht vollständig ausgeräumt ist. Gut Geregelt schliesst im Rahmen der gesetzlichen Bestimmungen jegliche Haftung gegenüber dem Kunden (oder Dritten), insbesondere für die Erfüllung seiner vertraglichen und ausservertraglichen Pflichten, sowie für Datenverlust und entgangenen Gewinn (auch bei grober Fahrlässigkeit) aus. Dieser Haftungsausschluss gilt auch für Schäden, die direkt oder indirekt durch die Nutzung unserer Dienste entstehen. Die gegenseitige Haftung der Parteien, gleich aus welchem Haftungsgrund, ist in jedem Fall auf die Höhe der monatlichen Serviceentgelte der letzten zwölf Monate vor Schadenseintritt beschränkt.',
  },
  communication: {
    title: 'Kommunikation',
    content:
      'gut geregelt ist berechtigt, den Kunden als Referenz zu nennen und allgemeine Informationen über den vereinbarten Vertrag für Marketing- und Vertriebszwecke angemessen zu nutzen.',
  },
  amendments: {
    title: 'Änderungen',
    content:
      'gut geregelt behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen jederzeit zu ändern oder zu ersetzen. Im Falle einer Änderung wird gut geregelt versuchen, den Kunden mindestens 30 Tage vor Inkrafttreten der neuen Bedingungen zu informieren.',
  },
  severabilityClause: {
    title: 'Salvatorische Klausel',
    content:
      'Im Falle der vollständigen oder teilweisen Unwirksamkeit einzelner Bestimmungen dieses Vertrages werden unwirksame oder undurchführbare Bestimmungen so umgesetzt oder ergänzt, dass der mit der unwirksamen Bestimmung verfolgte wirtschaftliche Zweck erreicht wird. Dasselbe gilt im Falle von Lücken in diesem Vertrag.',
  },
  jurisdiction: {
    title: 'Gerichtsstand',
    content:
      'Die Parteien vereinbaren, dass für alle Rechtsbeziehungen aus diesem Vertrag Schweizer Recht gilt. Als ausschliesslicher Gerichtsstand für sämtliche Streitigkeiten aus der Abwicklung dieses Vertragsverhältnisses wird Liestal BL (Schweiz) vereinbart.',
  },
};

export default tt;
