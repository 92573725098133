import {
  inheritorsDivorcedVersion,
  inheritorsMarriedVersion,
  inheritorsSingleVersion,
  distributionQuestions,
  governanceQuestions,
} from './questions';
import outputDoc from './outputDoc';

const lastWill = {
  mainHeaders: {
    shortPainFree: 'Your simple & effective last will',
    madeIt: 'You made it!',
  },

  yes: 'Yes',
  no: 'No',

  addAnotherChildren: 'Add more children',
  areYouSureYouWantToDeleteChild: 'Are you sure you want to delete the child?',
  deleteInformerChild:
    'If you delete a child from the list, all of their data will be deleted',
  cancelBtn: 'Cancel',
  deleteBtn: 'Delete',
  childBirthDate: 'Date of birth',

  noChildrenModal: {
    title: 'Are you sure you want to reset the number of children?',
    description:
      'If you reset the number of children, all information about your children will be deleted.',
    resetNumberBtn: 'Reset number',
    cancelBtn: 'Cancel',
  },

  stepperInfo: [
    {
      label: 'Beneficiaries',
      title: "What's the topic?",
    },
    {
      label: 'Distribution',
      title: 'Define the distribution',
    },
    {
      label: 'Governance',
      title: 'Define the governance',
    },

    {
      label: 'Your last will',
      title: 'Now what?',
    },
  ],

  stepOne: {
    introParagraph:
      'A last will offers you the possibility to provide clear instructions about your wishes at a difficult time for your beneficiaries. It also offers you the opportunity to ensure (within the framework of the law) that your wishes as to how your estate should be dealt with are taken into account. Moreover, the last will offers you the chance to avoid difficult and emotional discussions within the family system by naming an executor. ',

    helperParagraph:
      'At the same time, writing a will is not an easy subject for you personally, so please take your time and do not feel stressed. With our reminder service, you can also pause at any time and resume later. Just hit the “I`m done” button.',
    questions: {
      inheritorsMarriedVersion,
      inheritorsSingleVersion,
      inheritorsDivorcedVersion,
    },
    addAnotherBenefiter: 'Add another benefiter',
    isChildFromCommonMarriage: 'Is the child from the common marriage?',
  },

  stepTwo: {
    introParagraph: 'What do you mainly want to achieve with this will?',
    mainly: 'mainly',
    yesIHaveProperty:
      'Yes, I have property or (an) apartment(s) that I want to give to:',
    followingDistribution:
      'Yes, I want that the following inheritance items / the aforementioned sum of money is assigned to the aforementioned person:',
    questions: distributionQuestions,
  },

  stepThree: {
    questions: governanceQuestions,
    orAddSomeoneNew: 'or add someone new',
    addAnotherExecutor: 'Executor',
  },

  stepFour: {
    document: outputDoc,
    introParagraph:
      'Congratulations! You have made it. Now print out our last will and check whether it really corresponds to your exact wishes.',
    helperParagraph:
      'In Switzerland, the written form obligation applies, that is, to make your will legally valid you have the choice: ',
    helperParagraph2:
      'It is also advisable to deposit the will with a lawyer under registration with the official Swiss register of wills https://www.ztr.ch/notariatsinfo/.',
    contactForm: 'contact form',
    helperParagraph3:
      'If you want us to help you, please use our contact form.',
    obligation1:
      'A handwritten copy of the will with place, date and your signature',
    obligation2:
      'A public will (self-reading procedure, Art. 500 f. ZGB or reading procedure, Art. 502 ZGB) for this you need a notary and two witnesses',
    stopProcess: {
      title:
        'Unfortunately, this process cannot continue if you have a marriage contract.',
      title1: 'If your answer is no, we cannot continue the process here.',
      description:
        'We recommend you to continue the process with a lawyer. You can find a lawyer in your area here:',
      changeAnswerBtn: 'Change answer',
      continueBtn: 'Exit and continue with a lawyer',
    },
  },
  updateCivilStatusModal: {
    title: 'Would you like to update your marital status?',
    description:
      'Changing your answer will update your marital status and reset your document. Are you sure you want to proceed?',
    updateButton: 'Update',
    cancelButton: 'Cancel',
  },
  alternativeGoverner:
    'If the person you have chosen is unable to act as executor, the following person will be appointed:',
};

export default lastWill;
