const metadata = {
  main: {
    title:
      'Bien prévoir : directives anticipées, mandat de protection & testament en 20 minutes',
    description:
      'Nous aidons les gens à mener une vie bien réglée en simplifiant radicalement les choses essentielles dans la prévoyance.',
    keywords: {
      livingWill: 'Directives anticipées',
      powerOfAttorney: 'Mandat de protection',
      lastWill: 'Testament',
      personalPreferences: 'Prévoyance personnelle',
      retirementPlanning: 'Planification de la retraite',
      retirementProvision: 'Prévoyance vieillesse',
    },
  },
  living_will: {
    title:
      'Créez une directive anticipée en ligne en 5 minutes et en toute sécurité juridique',
    description:
      "Créez une directive anticipée et épargnez à vos proches du stress supplémentaire en cas d'urgence.",
    keywords: {
      livingWill: 'Directives anticipées',
      printLivingWill: 'Imprimer les directives anticipées',
      directive: 'Directive anticipée',
      printDirective: 'Directives anticipées à imprimer',
    },
  },
  power_of_attorney: {
    title:
      'Créez un mandat de protection en ligne en 5 minutes et en toute sécurité juridique',
    description:
      "Avec le mandat de protection, vous déterminez de manière proactive qui vous représentera et vous soutiendra en cas d'incapacité d'agir.",
    keywords: {
      powerOfAttorney: 'Mandat de protection',
      powerOfAttorneyTemplate: 'Modèle de mandat de protection',
      powerOfAttorneyTemplate2: 'Modèle pour mandat de protection',
      powerOfAttorneyTemplate3: 'Modèle de mandat de protection',
      powerOfAttorney2: 'Mandat de protection',
    },
  },
  last_will: {
    title:
      'Créez un testament en ligne en 10 minutes et en toute sécurité juridique',
    description:
      'Dans votre testament, précisez ce qui arrivera à votre héritage et qui héritera quoi. Prévoyez simplement maintenant.',
    keywords: {
      lastWill: 'Testament',
      share: "Part obligatoire de l'héritage",
      share2: 'Héritage obligatoire',
      estate: 'Héritage',
      succession: 'Succession légale',
    },
  },
  partner_with_us: {
    title: 'Étendez votre offre de prévoyance avec bien réglé',
    description:
      "Utilisez la solution en marque blanche de bien réglé pour rendre la prévoyance complète accessible à vos clients. S'intègre également dans votre processus de consultation.",
    keywords: {
      pensionFoundation: 'Fondation de prévoyance',
      solution: 'Solution de prévoyance',
      trustees: 'Conseil de fondation',
      advice: 'Conseil en prévoyance',
      advisor: 'Conseiller en prévoyance',
    },
  },
  digital_footprint: {
    title: "Régler l'héritage numérique",
    description:
      'Régulez votre héritage numérique et définissez ce qui arrivera à vos abonnements, comptes de médias sociaux, cryptomonnaies, etc. après votre mort.',
  },
  register_assets: {
    title: 'Maintenir un registre des biens et des actifs',
    description:
      "Documentez votre patrimoine physique et financier et épargnez à vos proches des dizaines d'heures de rangement.",
  },
  grosseItern: {
    title: 'Offre spéciale du magazine GrosseItern',
  },
};
export default metadata;
