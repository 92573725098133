const grosseItern = {
  mainBanner: {
    intro: 'Juste un bon sentiment :',
    title: 'Prévoyance bien réglée',
    subtitle:
      'Un sujet difficile devient enfin simple : Créez une directive anticipée, une procuration de prévoyance et un testament confortablement depuis chez vous - en seulement 20 minutes. Commencez maintenant avec le fournisseur suisse bien réglé.',
    buttonLabel: 'Commencer maintenant',
    sale: {
      blueText: 'Économisez 50%',
      blackText: "jusqu'au 30/09/2024",
    },
  },
  aboutCards: {
    justDoIt: {
      title: "Il suffit de le faire avant que quelque chose n'arrive",
      description:
        "L'espoir est de ne jamais avoir à utiliser la directive anticipée et l'ordre de prévoyance. Cependant, les coups du sort se produisent, c'est pourquoi il vaut la peine de les créer à l'avance.",
    },
    security: {
      title: 'Sécurité pour le conjoint et la famille',
      description:
        'En créant ces documents à temps, vous gardez le contrôle de vos souhaits. En même temps, vous protégez toute votre famille et évitez des situations de stress inutiles.',
    },
    done: {
      title: 'Terminé en 20 minutes - à la maison',
      description:
        'Avec bien réglé, vous créez des documents et des contrats juridiquement sûrs dans un langage facile à comprendre, confortablement depuis chez vous. Et ne vous inquiétez pas : en cas de cas plus complexes, des experts juridiques vous aideront.',
    },
    swissProvider: {
      title: 'Fournisseur suisse : bien régler la prévoyance',
      description:
        "bien réglé est votre suite de prévoyance numérique suisse. Faites-vous et à vos proches une faveur et laissez-vous bien régler dès aujourd'hui.",
    },
  },
  angebot: {
    phrase:
      'Connu des médias et du magazine des grands-parents (édition 30/2024)',
    highlightedPhrase: 'Magazine des grands-parents',
  },
};

export default grosseItern;
