import { useDropzone } from 'react-dropzone';
import { Box, Stack, Button, Typography, StackProps } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { UploadProps } from './types';
import RejectionFiles from './errors/RejectionFiles';
import MultiFilePreview from './preview/MultiFilePreview';
import useLocales from '#/hooks/useLocales';

const StyledDropZone = styled('div')(({ theme }) => ({
  outline: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
}));

export default function UploadButton({
  disabled,
  multiple = false,
  error,
  helperText,
  file,
  onDelete,
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  sx,
  placeholderHeading,
  isUploading,
  ...other
}: UploadProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple,
    disabled,
    ...other,
  });

  const isCustomFile = typeof file !== 'string';

  const hasFile = !!file && !multiple;

  const hasFiles = files && multiple && files.length > 0;
  const mutableFileRjections = [...fileRejections];

  const isError = isDragReject || !!error;
  // const isImageFile = isCustomFile ? file?.type.includes('image') : false;

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(isCustomFile && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          }),
          ...(isError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
        }}
      >
        <input {...getInputProps()} />

        <Placeholder
          heading={placeholderHeading}
          sx={{
            ...(hasFile &&
              isCustomFile && {
                opacity: 0,
                display: 'none',
              }),
          }}
          isUploading={isUploading}
        />

        {hasFile && isCustomFile && (
          <LoadingButton loading={isUploading}>
            <Typography>{file.name}</Typography>
          </LoadingButton>
        )}

        {onUpload && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onUpload}
          >
            Upload
          </Button>
        )}
      </StyledDropZone>

      {helperText && helperText}

      <RejectionFiles fileRejections={mutableFileRjections} />

      {hasFiles && (
        <>
          <Box sx={{ my: 3 }}>
            <MultiFilePreview
              files={files}
              thumbnail={thumbnail}
              onRemove={onRemove}
            />
          </Box>

          <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            {onRemoveAll && (
              <Button
                color="inherit"
                variant="outlined"
                size="small"
                onClick={onRemoveAll}
              >
                Remove all
              </Button>
            )}

            {onUpload && (
              <Button size="small" variant="contained" onClick={onUpload}>
                Upload files
              </Button>
            )}
          </Stack>
        </>
      )}
    </Box>
  );
}

interface PlaceholderProps extends StackProps {
  heading?: string;
  fileName?: string;
  isUploading?: boolean;
}

function Placeholder({
  sx,
  heading,
  fileName,
  isUploading,
  ...other
}: PlaceholderProps) {
  const { translate } = useLocales();

  return (
    <Stack
      spacing={5}
      alignItems="center"
      justifyContent="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        width: 1,
        textAlign: {
          xs: 'center',
          md: 'left',
        },
        ...sx,
      }}
      {...other}
    >
      <Button variant="contained" size="small">
        <Typography variant="subtitle1" fontWeight="light" noWrap>
          {fileName || String(translate('global.uploadNew'))}
        </Typography>
      </Button>
    </Stack>
  );
}
