import { Stack } from '@mui/material';
import { CustomFile } from '../types';
import Image from '../../ui/Image';

type Props = {
  file: CustomFile | string | null;
  helperText?: string;
};

export default function SingleFilePreview({ file, helperText }: Props) {
  if (!file) {
    return null;
  }

  const isCustomFile = typeof file !== 'string';

  const imgUrl = isCustomFile ? file.preview : file;
  const imageName = isCustomFile ? file.name : 'File';
  const isImageFile = isCustomFile ? file.type.includes('image') : false;

  if (!isImageFile) {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center">
        {imageName}
        <p>{helperText}</p>
      </Stack>
    );
  }

  return (
    <Image
      alt="file preview"
      src={imgUrl}
      sx={{
        top: 8,
        left: 8,
        zIndex: 8,
        borderRadius: 1,
        position: 'absolute',
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
    />
  );
}
