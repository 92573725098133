import { IQuestion } from '#/types/globalTypes';

export const inheritorsMarriedVersion: IQuestion[] = [
  {
    question:
      'Sie haben angegeben, dass Sie verheiratet sind. Können Sie uns bitte die Angaben zu Ihrem Ehepartner mitteilen?',
  },
  {
    question:
      'Haben Sie einen Ehevertrag oder einen Immobilienvertrag abgeschlossen?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question:
      'Haben Sie eine Immobilie/Wohnung, die Sie Ihrem Partner vererben möchten?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  // {
  //   question: 'Ist bei Ihnen ein Scheidungsverfahren hängig?',
  //   options: [
  //     {
  //       label: 'Ja',
  //     },
  //     {
  //       label: 'Nein',
  //     },
  //   ],
  // },
  {
    question: 'Haben Sie Kinder aus einer früheren oder aktuellen Ehe?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  // {
  //   question: 'Möchten Sie andere Personen oder Institutionen begünstigen?',
  //   options: [
  //     {
  //       label: 'Ja',
  //     },
  //     {
  //       label: 'Nein',
  //     },
  //   ],
  // },
];

export const inheritorsSingleVersion: IQuestion[] = [
  {
    question: 'Leben Sie aktuell in einer festen Beziehung?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question:
      'Haben Sie einen Immobilienvertrag abgeschlossen (Eingetragener Partnerschaft)?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question:
      'Haben Sie eine Immobilie/Wohnung, die Sie Ihrem Partner vererben möchten?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question: 'Haben Sie Kinder?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  // {
  //   question: 'Möchten Sie andere Personen oder Institutionen begünstigen?',
  //   options: [
  //     {
  //       label: 'Ja',
  //     },
  //
  //     {
  //       label: 'Nein',
  //     },
  //   ],
  // },
];

export const inheritorsDivorcedVersion: IQuestion[] = [
  {
    question: 'Leben Sie aktuell in einer festen Beziehung?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question:
      'Sie haben angegeben, dass Sie geschieden sind. Haben Sie Kinder?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  // {
  //   question: 'Möchten Sie andere Personen oder Institutionen begünstigen?',
  //   options: [
  //     {
  //       label: 'Ja',
  //     },
  //     {
  //       label: 'Nein',
  //     },
  //   ],
  // },
];

export const distributionQuestions: IQuestion[] = [
  {
    question:
      'Soweit gesetzlich zulässig, möchte ich, dass so viel wie möglich von meinem Nachlass verteilt wird an:',
    options: [
      {
        label: 'Ehepartner/eingetragener Partner/Lebenspartner',
      },
      {
        label: 'Kinder',
      },
      {
        label: 'Eine Einrichtung meiner Wahl',
      },
    ],
  },
  {
    question:
      'Möchten Sie bestimmte Gegenstände oder eine bestimmte Geldsumme einem Familienmitglied, einer bestimmten Person, auch ausserhalb ihrer Familie oder einer Institution, schenken?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  // {
  //   question:
  //     'Haben Sie verschiedene Erbschaften, die Sie an verschiedene Personen verschenken möchten?',
  //   options: [
  //     {
  //       label: 'Ja',
  //     },
  //     {
  //       label: 'Nein',
  //     },
  //   ],
  // },
];

export const governanceQuestions: IQuestion[] = [
  {
    question: 'Wer soll der Testamentsvollstrecker sein?',
  },
];
