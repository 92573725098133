const termsConditions = {
  title: 'Terms & Conditions',
  scope: {
    title: 'Scope',
    content:
      'These General Terms and Conditions (GTC) govern the use of the services of betascale AG (hereinafter referred to as "gut geregelt"). Gut geregelt provides the customer with the services for the duration of the contract in the current version, subject to payment. The contractual relationship between gut geregelt and the customer is established upon the acceptance of the offer. By accepting the offer, the customer expressly acknowledges these GTC, even if they partially or completely contradict their own terms and conditions. The customer is obligated to provide accurate, complete, and up-to-date information at all times. Failure to comply with this requirement constitutes a violation of the terms of use and may lead to immediate termination of the contract.',
  },
  userRights: {
    title: 'User rights',
    content:
      'gut geregelt grants the customer a non-exclusive and non-transferable right to use the services for the duration of the contract as intended. The customer is not entitled to provide the services to third parties for remuneration or free of charge. Any form of providing the service to third parties is expressly prohibited. The customer must ensure that all contractual relationships with third parties prevent them from using the service free of charge.',
  },
  customerResponsibilities: {
    title: 'Customer responsibilities',
    content:
      "Apart from the services provided by gut geregelt, the customer is fully responsible for the operation, security, and condition of their electronic and digital media on which gut geregelt's services are used (hardware, software, operation, security, etc.). The customer bears all costs associated with fulfilling these responsibilities.",
  },
  systemAvailability: {
    title: 'System availability',
    content:
      'The services provided by gut geregelt are based on a "best effort" principle. Gut geregelt takes reasonable measures to ensure the most uninterrupted use of the services. However, the customer is aware that the service, as well as other components from third-party providers, whose functionality is beyond gut geregelt\'s control, constitute a technically complex system. Therefore, gut geregelt cannot guarantee constant and complete availability of the components exceeding 99.5%. Interruptions due to system maintenance, updates, etc., will be announced in advance, with a notice period of two working days for scheduled maintenance. Urgent maintenance causing an interruption in availability can be performed without prior notice to promptly resolve issues or prevent potential threats to the system. If gut geregelt detects a threat to proper operation due to negligent or intentional activities by external parties, it has the authority to take all necessary steps immediately to protect its own infrastructure and software from harm.',
  },
  support: {
    title: 'Support',
    content:
      "gut geregelt's support is available to the customer via email at support@gutgeregelt.ch from Monday to Friday, 8:00 - 12:00, and 13:00 - 17:00, with an average response time of 1 hour.",
  },
  renumerations: {
    title: 'Remunerations',
    content:
      'The amount of compensation for the agreed-upon services is separately defined in the respective price lists. It generally consists of recurring fees. Recurring fees may be adjusted with prior notice. They are due within 20 days or can be collected via credit card. Additional agreed-upon services will be billed separately.',
  },
  intellectualPropertyRights: {
    title: 'Intellectual property rights',
    content:
      "The customer acknowledges the intellectual property rights, particularly the copyright, of gut geregelt for software, services, and documentation. The customer is not allowed to provide this software to third parties for remuneration or free of charge, or to sublet and/or use it outside the scope of the contractual relationship with gut geregelt or dispute gut geregelt's rights in any way.",
  },
  dataProtection: {
    title: 'Data protection and data security',
    content:
      "gut geregelt will handle the customer's data with the utmost care and protect it from misuse and loss. gut geregelt does not retain personally identifiable data of its customers. Nevertheless, gut geregelt takes technical and organizational measures that comply with the valid requirements of the GDPR. Specific agreements regarding data storage in certain countries can be made within the scope of technical possibilities. The customer is responsible for the legality of data disclosure or its use. All customer data stored and processed by gut geregelt are the exclusive property of the customer and will be used by gut geregelt solely for the purpose of fulfilling the contract.",
  },
  disclaimerLiability: {
    title: 'Disclaimer of liability',
    content:
      'gut geregelt guarantees that the services are functional and operational according to the legal provisions of this contract. The customer agrees to indemnify gut geregelt against all claims of third parties based on stored data and reimburse gut geregelt for any costs resulting from potential legal infringements. gut geregelt is entitled to immediately block storage space if there is a reasonable suspicion that the stored data is unlawful and/or infringing on the rights of third parties. A reasonable suspicion of unlawfulness and/or infringement exists when courts, authorities, and/or third parties inform gut geregelt about it. Gut geregelt must promptly inform the customer about the removal and the reasons behind it. The ban must be lifted as soon as the suspicion is completely dispelled. Within the legal provisions, gut geregelt excludes any liability towards the customer (or third parties), especially for fulfilling its contractual and non-contractual obligations, as well as for data loss and lost profits (even in cases of negligence). This disclaimer of liability also applies to damages directly or indirectly caused by the use of our services. The mutual liability of the parties, regardless of the basis of liability, is in any case limited to the amount of the monthly service fees for the last twelve months prior to the occurrence of the damage.',
  },
  communication: {
    title: 'Communication',
    content:
      'gut geregelt is authorized to mention the customer as a reference and to use general information about the agreed-upon contract appropriately for marketing and sales purposes.',
  },
  amandments: {
    title: 'Amendments',
    content:
      'gut geregelt reserves the right to change or replace these terms and conditions at any time. In the event of a revision, gut geregelt will attempt to inform the customer at least 30 days before the new conditions take effect.',
  },
  severabilityClause: {
    title: 'Severability clause',
    content:
      'In the event of complete or partial invalidity of individual provisions of this contract, invalid or unenforceable provisions shall be implemented or supplemented so that the economic purpose pursued with the invalid provision is achieved. The same applies in the case of gaps in this contract.',
  },
  jurisdiction: {
    title: 'Jurisdiction',
    content:
      'The parties agree that Swiss law applies to all legal relationships arising from this contract. Liestal BL (Switzerland) is agreed upon as the exclusive place of jurisdiction for all disputes arising in the course of the execution of this contractual relationship.',
  },
};

export default termsConditions;
