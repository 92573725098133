const outputDoc = {
  title: 'Testament',
  from: 'von',
  born: ' geboren am ',
  civilStatus: {
    single: 'Ledig',
    married: 'Verheiratet',
    widowed: 'Verwitwet',
    divorced: 'Geschieden',
    registered_partnership: 'Eingetragener partnerschaft',
  },
  civil_status: 'Familienstand: {{civil_status}}',
  address: 'Adresse: {{address}}',
  declare: 'Ich, {{first_name}} {{last_name}}, erkläre Folgendes:  ',

  paragraph1: 'Ich widerrufe alle Testamente, die ich zuvor verfasst habe.',

  paragraph2:
    'Die laufenden Verbindlichkeiten und Todesfallkosten sind aus dem Nachlass zu bezahlen.',

  paragraph3: '{{civil_status}}',

  receivesNothing: 'erhält nichts.',

  and: 'und',

  p4MarriedSpouse: {
    receives: 'erhält',
    marriedStatus: {
      male: 'Meine Ehegattin',
      female: 'Mein Ehegatte',
    },
    livingPartnershipStatus: {
      male: 'Meine eingetragene Partnerin',
      female: 'Mein eingetragener Partner',
    },
    partnershipContract: {
      yes: 'eherechtlich sämtliches Gesamtgut und erbrechtlich sämtliche Vermögenswerte im Rahmen der frei verfügbaren',
      no: 'eherechtlich den ganzen Vorschlag und erbrechtlich sämtliche Vermögenswerte im Rahmen der frei verfügbaren',
    },
    quota: 'Quote',
    noContractButChildren: {
      main: 'nur zur Nutzniessung indes den Pflichtteil, der auf {{children}}',
      oneChild: 'das Kind',
      multipleChildren: 'die Kinder',
      receive: ' entfällt.',
    },
    propertyContractAndChildren: {
      main: 'nur zur Nutzniessung indes den Pflichtteil, der auf {{children}}',
      oneChild: 'das Kind erhält',
      multipleChildren: 'die Kinder entfällt.',
    },
    born: ', geboren am {{birthday}}, ',
  },

  p4UnmarriedPartner: {
    receives:
      'erhält sämtliche Vermögenswerte im Rahmen der frei verfügbaren Quote.',
    receivesProperty: 'erhält insbesondere auch',
    theProperty: 'die Liegenschaft',
    atTheAddress: 'an der Adresse',
    assignedToProperty: 'zu Eigentum zugewiesen.',
    born: ', geboren am {{birthday}}, ',
    genderStatus: {
      male: 'Meine Lebenspartnerin',
      female: 'Mein Lebenspartner',
      male1: 'meiner Lebenspartnerin',
      female1: 'meinem Lebenspartner',
      registeredPartnerMale: 'Meine Lebenspartnerin',
      registeredPartnerFemale: 'Mein Lebenspartner',
    },
  },

  p5UnmarriedPartnerWithChildren: {
    commonPartnership: {
      oneChild: {
        main: 'Unser gemeinsams Kind, ',
        born: ', geboren am {{birthday}}, ',
        receive: 'bzw. dessen Nachkommen erhalten den Pflichtteil.',
      },
      multipleChildren: {
        main: 'Unsere gemeinsamen Kinder, ',
        born: ', geboren am {{birthday}}, ',
        receive: 'bzw. all deren Nachkommen erhalten je den Pflichtteil.',
      },
      and: 'und',
    },
    otherPartnership: {
      oneChild: {
        main: 'Mein nicht-gemeinsames Kind, ',
        born: ', geboren am {{birthday}}, ',
        receive: 'bzw. dessen Nachkommen erhalten den Pflichtteil.',
      },
      multipleChildren: {
        main: 'Meine nicht-gemeinsamen Kinder, ',
        born: ', geboren am {{birthday}}, ',
        receive:
          'bzw. all deren Nachkommen erhalten je zu gleichen Teilen den Pflichtteil.',
      },
    },
    noPartnership: {
      oneChild: {
        main: 'Mein Kind, ',
        born: ', geboren am {{birthday}}, ',
        receive: ' bzw. dessen Nachkommen erhalten den Pflichtteil.',
      },
      multipleChildren: {
        main: 'Meine Kinder, ',
        born: ', geboren am {{birthday}}, ',
        receive: ' bzw. all deren Nachkommen erhalten je den Pflichtteil.',
      },
    },
    deathCase:
      'Sollte ich mit {{partner}} gleichzeitig versterben, so {{childrenCase}}',
    born: ', geboren am {{birthday}}, ',
    childrenCase: {
      oneChild: {
        fromCommonMarriage: 'erhält unser gemeinsames Kind, ',
        fromOtherMarriage: 'erhält unser nicht-gemeinsames Kind, ',
        fromUnknownMarriage: 'erhält mein Kind,',
      },
      onesDescendants: 'bzw. dessen Nachkommen sämtliche Vermögenswerte.',
      multipleChildren: {
        fromCommonMarriage: 'erhalten unsere gemeinsamen Kinder, ',
        fromOtherMarriage: 'erhalten unsere nicht-gemeinsamen Kinder, ',
        fromUnknownMarriage: 'erhalten meine Kinder, ',
      },
      allDescendants: 'bzw. all deren Nachkommen je sämtliche Vermögenswerte.',
    },
  },

  p4Child: {
    commonMarriage: {
      oneChild: {
        main: 'Unser gemeinsames Kind, ',
        born: ', geboren am {{birthday}}, ',
        receive:
          'bzw. all dessen Nachkommen erhalten je zu gleichen Teilen alle Vermögenswerte im Rahmen der frei verfügbaren Quote. ',
      },
      multipleChildren: {
        main: 'Unsere gemeinsamen Kinder, ',
        born: ', geboren am {{birthday}}, ',
        receive:
          'bzw. all deren Nachkommen erhalten je zu gleichen Teilen alle Vermögenswerte im Rahmen der frei verfügbaren Quote. ',
      },
    },
    otherMarriage: {
      oneChild: {
        main: 'Mein nicht-gemeinsames Kind, ',
        born: ', geboren am {{birthday}}, ',
        receive:
          'bzw. all dessen Nachkommen erhalten je zu gleichen Teilen alle Vermögenswerte im Rahmen der frei verfügbaren Quote. ',
      },
      multipleChildren: {
        main: 'Meine nicht-gemeinsamen Kinder, ',
        born: ', geboren am {{birthday}}, ',
        receive:
          'bzw. all deren Nachkommen erhalten je zu gleichen Teilen alle Vermögenswerte im Rahmen der frei verfügbaren Quote. ',
      },
      and: 'und',
    },
    partnerCase: {
      male: 'Meine Ehegattin',
      female: 'Mein Ehegatte',
      born: ', geboren am {{birthday}}, ',
      receivesShare: 'erhält den Pflichtteil.',
    },
  },

  unmarriedChildrenChoice: {
    commonPartnership: {
      oneChild: {
        main: 'Unser gemeinsames Kind, ',
        born: ', geboren am {{birthday}}, ',
        receives:
          'bzw. all dessen Nachkommen erhalten je zu gleichen Teilen alle Vermögenswerte',
      },
      multipleChildren: {
        main: 'Unsere gemeinsamen Kinder, ',
        born: ', geboren am {{birthday}}, ',
        receives:
          'bzw. all deren Nachkommen erhalten je zu gleichen Teilen alle Vermögenswerte',
      },
      quota: 'im Rahmen der frei verfügbaren Quote.',
      and: 'und',
    },
    otherPartnership: {
      oneChild: {
        main: 'Mein nicht-gemeinsames Kind, ',
        born: ', geboren am {{birthday}}, ',
        receives:
          'bzw. all dessen Nachkommen erhalten je zu gleichen Teilen alle Vermögenswerte',
      },
      multipleChildren: {
        main: 'Meine nicht-gemeinsamen Kinder, ',
        born: ', geboren am {{birthday}}, ',
        receives:
          'bzw. all deren Nachkommen erhalten je zu gleichen Teilen alle Vermögenswerte',
      },
      quota: 'im Rahmen der frei verfügbaren Quote.',
      and: 'und',
    },
    noPartnership: {
      oneChild: {
        main: 'Mein Kind, ',
        born: ', geboren am {{birthday}}, ',
        receives: 'bzw. dessen Nachkommen erhalten',
      },
      multipleChildren: {
        main: 'Meine Kinder, ',
        born: ', geboren am {{birthday}}, ',
        receives: 'bzw. all deren Nachkommen erhalten je',
      },
      quota:
        'je zu gleichen Teilen alle Vermögenswerte im Rahmen der frei verfügbaren Quote.',
      and: 'und',
    },
  },

  unmarriedInstitutionChoice: {
    commonPartnership: {
      oneChild: {
        main: 'Unser gemeinsams Kind, ',
        born: ', geboren am {{birthday}}, ',
        receive: 'bzw. dessen Nachkommen erhalten den Pflichtteil.',
      },
      multipleChildren: {
        main: 'Unsere gemeinsamen Kinder, ',
        born: ', geboren am {{birthday}}, ',
        receive: 'bzw. all deren Nachkommen erhalten je den Pflichtteil.',
      },
      and: 'und',
    },
    otherPartnership: {
      oneChild: {
        main: 'Mein nicht-gemeinsames Kind, ',
        born: ', geboren am {{birthday}}, ',
        receive: 'bzw. dessen Nachkommen erhalten den Pflichtteil.',
      },
      multipleChildren: {
        main: 'Meine nicht-gemeinsamen Kinder, ',
        born: ', geboren am {{birthday}}, ',
        receive: 'bzw. all deren Nachkommen erhalten je den Pflichtteil.',
      },
      and: 'und',
    },
    noPartnership: {
      oneChild: {
        main: 'Mein Kind, ',
        born: ', geboren am {{birthday}}, ',
        receive: 'bzw. dessen Nachkommen erhalten den Pflichtteil.',
      },
      multipleChildren: {
        main: 'Meine Kinder, ',
        born: ', geboren am {{birthday}}, ',
        receive: 'bzw. all deren Nachkommen erhalten je den Pflichtteil.',
      },
      and: 'und',
    },
  },

  p4Institution: {
    oneInstitution: {
      main: 'Die Institution',
      receives: 'erhält',
    },
    multipleInstitutions: {
      main: 'Die Institutionen',
      receives: 'erhalten',
    },
    finals:
      ' je zu gleichen Teilen alle Vermögenswerte im Rahmen der frei verfügbaren Quote. ',
    final: ' alle Vermögenswerte im Rahmen der frei verfügbaren Quote. ',
    partnerReceives: 'erhält den Pflichtteil.',
    born: ', geboren am {{birthday}}, ',
    and: ' und ',
  },

  p5MarriedSpouse: {
    marriedStatus: {
      male: 'Meine Ehegattin',
      female: 'Mein Ehegatte',
    },
    livingPartnershipStatus: {
      male: 'Meine eingetragene Partnerin',
      female: 'Mein eingetragener Partner',
    },
    particularReceives: 'erhält insbesondere auch',
    theProperty: 'die Liegenschaft',
    atTheAddress: 'an der Adresse',
    assignedToProperty: 'zu Eigentum zugewiesen.',
  },

  p5Institution: {
    commonMarriage: {
      oneChild: {
        main: 'Unser gemeinsames Kind, ',
        born: ' geboren am {{birthday}}, ',
        receive: 'bzw. dessen Nachkommen erhalten den Pflichtteil.',
      },
      multipleChildren: {
        main: 'Unsere gemeinsamen Kinder, ',
        born: ' geboren am {{birthday}}, ',
        receive: 'bzw. all deren Nachkommen erhalten je den Pflichtteil.',
      },
      and: 'und',
    },
    otherMarriage: {
      oneChild: {
        main: 'Mein nicht-gemeinsames Kind, ',
        born: ' geboren am {{birthday}}, ',
        receive: 'bzw. dessen Nachkommen erhalten den Pflichtteil.',
      },
      multipleChildren: {
        main: 'Meine nicht-gemeinsamen Kinder, ',
        born: ' geboren am {{birthday}}, ',
        receive: 'bzw. all deren Nachkommen erhalten je den Pflichtteil.',
      },
      and: 'und',
    },
  },

  p6MarriedSpouse: {
    oneChild: {
      commonMarriage: 'Unser gemeinsames Kind, ',
      otherMarriage: 'Mein nicht-gemeinsames Kind, ',
      born: ', geboren am {{birthday}}, ',
      receives: 'bzw. dessen Nachkommen erhalten ',
    },
    multipleChildren: {
      commonMarriage: 'Unsere gemeinsamen Kinder, ',
      otherMarriage: 'Meine nicht-gemeinsamen Kinder, ',
      born: ', geboren am {{birthday}}, ',
      receives: 'bzw. all deren Nachkommen erhalten je',
    },
    final:
      'den Pflichtteil, belastet indes mit der im ersten Absatz erwähnten Nutzniessung. ',
  },

  willExecutor:
    'Als Willensvollstrecker für meinen gesamten Nachlass setze ich {{executor1}} ein. Als Ersatz-Willensvollstrecker setze ich {{executor2}} ein.',

  forFollowing: {
    main: 'Für folgende Vermögenswerte gilt jedoch eine abweichende Regelung:',
    asset: '{{asset}}, {{address}} geht an: {{benefiter}}. ',
    devisionProvision:
      'Dies ist eine Teilungsvorschrift zugunsten {{benefiter}}, keine zusätzlich Begünstigung.',
  },
};

export default outputDoc;
