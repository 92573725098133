import { IQuestion } from '#/types/globalTypes';

export const inheritorsMarriedVersion: IQuestion[] = [
  {
    question:
      'Vous avez indiqué que vous êtes marié(e). Pouvez-vous nous fournir des informations sur votre conjoint(e)?',
  },
  {
    question:
      'Avez-vous conclu un contrat de mariage ou un contrat immobilier?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question:
      'Possédez-vous un bien immobilier/appartement que vous souhaitez léguer à votre conjoint(e)?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question: 'Un processus de divorce est-il en cours pour vous?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question: "Avez-vous des enfants d'un mariage précédent ou actuel?",
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  // {
  //   question: 'Souhaitez-vous favoriser d\'autres personnes ou institutions?',
  //   options: [
  //     {
  //       label: 'Oui',
  //     },
  //     {
  //       label: 'Non',
  //     },
  //   ],
  // },
];

export const inheritorsSingleVersion: IQuestion[] = [
  {
    question: 'Vivez-vous en union civile enregistrée?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question:
      'Avez-vous conclu un contrat immobilier (union civile enregistrée)?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question:
      'Possédez-vous un bien immobilier/appartement que vous souhaitez léguer à votre partenaire?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question: 'Avez-vous des enfants?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  // {
  //   question: 'Souhaitez-vous favoriser d\'autres personnes ou institutions?',
  //   options: [
  //     {
  //       label: 'Oui',
  //     },
  //
  //     {
  //       label: 'Non',
  //     },
  //   ],
  // },
];

export const inheritorsDivorcedVersion: IQuestion[] = [
  {
    question:
      'Vous avez indiqué que vous êtes divorcé(e). Avez-vous des enfants?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  // {
  //   question: 'Souhaitez-vous favoriser d\'autres personnes ou institutions?',
  //   options: [
  //     {
  //       label: 'Oui',
  //     },
  //     {
  //       label: 'Non',
  //     },
  //   ],
  // },
];

export const distributionQuestions: IQuestion[] = [
  {
    question:
      'Dans la mesure où la loi le permet, je souhaite que le plus possible de ma succession soit distribué à:',
    options: [
      {
        label: 'Conjoint(e)/partenaire enregistré(e)/partenaire de vie',
      },
      {
        label: 'Enfants',
      },
      {
        label: 'Une institution de mon choix',
      },
    ],
  },
  {
    question:
      "Souhaitez-vous donner des biens spécifiques ou une somme d'argent spécifique à un membre de la famille, à une personne spécifique, même en dehors de votre famille, ou à une institution?",
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  // {
  //   question:
  //     'Possédez-vous plusieurs héritages que vous souhaitez donner à différentes personnes?',
  //   options: [
  //     {
  //       label: 'Oui',
  //     },
  //     {
  //       label: 'Non',
  //     },
  //   ],
  // },
];

export const governanceQuestions: IQuestion[] = [
  {
    question: "Qui devrait être l'exécuteur testamentaire?",
  },
];
