const cohabitationAgreementLanding = {
  mainBanner: {
    intro: 'Regeln Sie Ihre Beziehung rechtlich mit einem Konkubinatsvertrag',
    title:
      'Viele Aspekte der sozialen und rechtlichen Absicherung bei unverheirateten Lebensgemeinschaften sind gesetzlich nicht geregelt. Mit einem Konkubinatsvertrag profitieren Sie von den steuerlichen Vorteilen und klären viele ungeklärte Fragen.',
    buttonLabel: 'Jetzt kostenlos erstellen',
  },
  youtubeTutorial: 'Was ist ein Konkubinatsvertrag?',
};

export default cohabitationAgreementLanding;
