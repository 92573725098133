const privacyPolicy = {
  title: 'Politique de Confidentialité',

  childrenUnderAge: {
    title: 'Enfants de moins de 18 ans',
    content: {
      p1: 'Notre site Web n est pas destiné aux enfants de moins de 18 ans. Les personnes de moins de 18 ans ne doivent pas fournir d informations sur ou via le site Web. Nous ne collectons pas sciemment des informations personnelles d enfants de moins de 18 ans. Si vous avez moins de 18 ans, ne pas utiliser ce site et ne fournissez aucune information sur ce site ou via ses fonctionnalités. Si nous découvrons que nous avons collecté ou reçu des informations personnelles d un enfant de moins de 18 ans sans vérification du consentement parental, nous supprimerons ces informations. Si vous croyez que nous pourrions avoir des informations d un enfant de moins de 18 ans, veuillez nous contacter à support@gutgeregelt.ch.',
    },
  },
  informationWeCollect: {
    title: 'Informations que nous collectons et comment nous les collectons',
    content: {
      p1: 'Nous collectons différents types d informations auprès des utilisateurs de notre site Web, y compris des informations:',
      p2: '• Qui peuvent vous identifier personnellement, telles que votre nom ou votre adresse e-mail ou tout autre identifiant par lequel vous pouvez être contacté en ligne ou hors ligne ("données personnelles");',
      p3: '• Qui vous concernent, mais qui ne sont pas identifiées individuellement; et/ou',
      p4: '• Sur votre connexion Internet, les appareils que vous utilisez pour accéder à notre site Web, et les détails de votre utilisation.',
      p5: 'Nous collectons ces informations:',
      p6: '• Directement de votre part lorsque vous nous les fournissez.',
      p7: '• Automatiquement alors que vous naviguez sur le site. Les informations collectées automatiquement peuvent inclure les détails de l utilisation, les adresses IP et les informations collectées via les cookies.',
      p8: '• De tiers, par exemple nos partenaires commerciaux.',
    },
  },
  informationYouProvide: {
    title: 'Informations que vous fournissez',
    content: {
      p1: 'Les informations que nous collectons sur ou via notre site Web peuvent inclure:',
      p2: '• Des informations que vous fournissez en remplissant des formulaires sur notre site Web. Cela inclut les informations fournies lors de l inscription pour utiliser notre site Web, lors de l abonnement à notre service, lorsque vous postez du matériel, ou lorsque vous demandez des services supplémentaires. Nous pouvons également vous demander des informations lorsque vous participez à un concours ou une promotion sponsorisée par nous, et lorsque vous signalez un problème avec notre site Web.',
      p3: '• Des enregistrements et copies de votre correspondance (y compris les adresses e-mail), si vous nous contactez.',
      p4: '• Vos réponses aux sondages que nous pourrions vous demander de compléter à des fins de recherche.',
      p5: '• Vos recherches effectuées sur le site.',
      p6: 'Vous pouvez également fournir des informations à publier ou à afficher (ci-après, "publier") sur des zones publiques du site Web, ou transmises à d autres utilisateurs du site Web ou à des tiers (collectivement, "contributions des utilisateurs"). Vos contributions utilisateurs sont publiées sur le site Web et transmises à d autres à vos propres risques. Bien que nous puissions limiter l accès à certaines pages, veuillez être conscient que aucune mesure de sécurité n est parfaite ou impénétrable. De plus, nous ne pouvons pas contrôler les actions d autres utilisateurs du site Web avec lesquels vous pouvez choisir de partager vos contributions utilisateurs. Par conséquent, nous ne pouvons pas et ne garantissons pas que vos contributions utilisateurs ne seront pas vues par des personnes non autorisées.',
    },
  },
  informationWeCollectThroughAutomaticData: {
    title:
      'Informations que nous collectons par des technologies de collecte de données automatiques',
    content: {
      p1: 'Alors que vous naviguez et interagissez avec notre site Web, nous pouvons utiliser des technologies de collecte de données automatiques pour collecter certaines informations sur vos équipements, actions de navigation et modèles, y compris:',
      p2: '• Des détails de vos visites sur notre site Web, y compris les données de trafic, les données de localisation, les journaux et autres données de communication, ainsi que les ressources auxquelles vous accédez et que vous utilisez sur le site Web.',
      p3: '• Des informations sur votre ordinateur et votre connexion Internet, y compris votre adresse IP, votre système d exploitation et votre type de navigateur.',
      p4: 'Les informations que nous collectons automatiquement peuvent inclure des données personnelles, ou nous pouvons les conserver ou les associer à des informations personnelles que nous collectons de différentes manières ou recevons de tiers. Nous faisons cela pour améliorer notre site Web et pour vous offrir une meilleure expérience et plus personnalisée, en vous permettant notamment de:',
      p5: '• Estimer la taille de notre audience et les modèles d utilisation.',
      p6: '• Stocker des informations sur vos préférences, nous permettant de personnaliser notre site Web selon vos intérêts individuels.',
      p7: '• Accélérer vos recherches.',
      p8: '• Vous reconnaître lorsque vous revenez sur notre site Web.',
      p9: 'Les technologies que nous utilisons pour cette collecte automatique de données peuvent inclure:',
      p10: '• Les cookies (ou cookies navigateur). Un cookie est un petit fichier placé sur le disque dur de votre ordinateur. Vous pouvez refuser d accepter les cookies navigateur en activant le paramètre approprié sur votre navigateur. Toutefois, si vous sélectionnez ce paramètre, vous pourriez ne pas être en mesure d accéder à certaines parties de notre site Web. À moins que vous n ayez ajusté les paramètres de votre navigateur afin qu il refuse les cookies, notre système émettra des cookies lorsque vous dirigerez votre navigateur vers notre site Web.',
      p11: '• Les cookies Flash. Certaines fonctionnalités de notre site Web peuvent utiliser des objets de stockage local (ou cookies Flash) pour collecter et stocker des informations sur vos préférences et votre navigation sur, vers et depuis notre site Web. Les cookies Flash ne sont pas gérés par les mêmes paramètres de navigateur que ceux utilisés pour les cookies navigateur.',
    },
  },

  informationVoluntarily: {
    title:
      'Informations que vous fournissez volontairement pour l utilisation de services',
    content: {
      p1: 'Nous collectons des informations que vous nous fournissez volontairement pour répondre à une demande, par exemple lorsque vous vous inscrivez pour utiliser nos services et notre site Web, demandez de l aide à notre équipe de support, nous contactez par e-mail, remplissez un formulaire de contact, souhaitez utiliser nos services ou communiquez avec nous de toute autre manière. Ces informations incluent votre prénom et nom, le nom de votre entreprise, l adresse physique, l adresse e-mail et certains types d informations commerciales de votre entreprise, telles que les informations de paiement des clients, les revenus, les informations d analyse du site Web, le taux de perte de clients, la valeur des clients pendant la durée de vie et d autres métriques et données, ainsi que toutes autres informations que vous nous fournissez concernant votre entreprise.',
      p2: 'Particulièrement après votre inscription pour utiliser nos services en tant que startup, lorsque vous souhaitez afficher des données de vos comptes avec des outils et applications tiers tels que Stripe, Google Analytics, ChartMogul, Baremetrics et ProfitWell sur votre page d information pour les acheteurs potentiels, en vous connectant à votre compte sur la page d autorisation pour de tels outils et applications tiers ou en nous fournissant vos clés API ou identifiants de vue sur de tels outils et applications tiers, vous nous autorisez et nous accordez un accès en lecture à des informations spécifiques dans vos comptes avec ces tiers. Voici les informations que nous pouvons collecter avec votre autorisation de ces tiers:',
      p3: '• Stripe – Accès en lecture aux données de revenus des clients pour les derniers 12 mois',
      p4: '• Google Analytics – Accès en lecture aux données de trafic du site Web pour les derniers 12 mois',
    },
  },
  useOfCookies: {
    title: 'Utilisation de Cookies par des Tiers',
    content: {
      p1: 'Certains contenus ou applications sur le site Web sont fournis par des tiers, tels que les fournisseurs de contenu et les fournisseurs d applications comme Google Analytics et Hubspot, comme mentionné ci-dessus. Ces tiers utilisent des cookies pour collecter des informations vous concernant lorsque vous utilisez notre site Web. Les informations qu ils collectent peuvent être associées à vos informations personnelles ou ils peuvent collecter des informations, y compris des informations personnelles, sur vos activités en ligne au fil du temps et à travers différents sites Web et autres services en ligne.',
    },
  },
  howWeUseYourInformation: {
    title: 'Comment nous utilisons vos informations',
    content: {
      p1: 'Nous utilisons les informations que nous collectons sur vous ou que vous nous fournissez, y compris toutes les informations personnelles:',
      p2: '• Pour vous présenter notre site Web et son contenu.',
      p3: '• Pour faciliter la liste de votre profil d acheteur, de vendeur ou de conseiller sur le site Web.',
      p4: '• Pour vous fournir des informations, produits ou services que vous nous demandez, y compris mais non limité à (pour les utilisateurs de startups) l affichage des informations commerciales autorisées des startups pour certains acheteurs potentiels.',
      p5: '• Pour remplir tout autre objectif pour lequel vous les avez fournies.',
      p6: '• Pour vous envoyer des communications concernant votre compte, y compris des avis d expiration et de renouvellement.',
      p7: '• Pour remplir nos obligations découlant de tout contrat passé entre vous et nous, et pour faire respecter nos droits, y compris pour la facturation et la collecte.',
      p8: '• Pour vous informer des modifications de notre site Web ou des produits ou services que nous offrons ou fournissons via celui-ci.',
      p9: '• Pour vous permettre de participer à des fonctionnalités interactives sur notre site Web.',
      p10: '• De toute autre manière que nous pourrions décrire lorsque vous fournissez les informations.',
      p11: '• Pour tout autre objectif avec votre consentement.',
      p12: 'Avec votre consentement, nous utiliserons vos informations pour vous informer de nos propres biens et services ainsi que ceux de tiers susceptibles de vous intéresser. Si vous ne souhaitez pas que nous utilisions vos informations de cette manière, veuillez ajuster vos paramètres utilisateur dans votre profil de compte. Pour plus d informations, voir "Vos droits et contrôles concernant les informations" ci-dessous.',
    },
  },
  saleOfPersonalInformation: {
    title: 'Vente d informations personnelles',
    content: {
      p1: 'Veuillez noter que nous ne vendons pas vos informations personnelles à des tiers.',
    },
  },

  disclosureOfYourInformation: {
    title: 'Divulgation de vos informations',
    content: {
      p1: 'Nous pouvons divulguer des informations agrégées sur nos utilisateurs, et des informations qui ne permettent pas l identification individuelle, ou qui ne tombent pas sous la définition de "Informations Personnelles", "Données Personnelles" ou une définition similaire selon un règlement, sans restriction.',
      p2: 'Nous pouvons divulguer des informations personnelles que nous collectons ou que vous fournissez comme décrit dans cette politique de confidentialité:',
      p3: '• À nos filiales et affiliés, pour nous permettre de fournir nos services.',
      p4: '• À des entrepreneurs, prestataires de services et autres tiers que nous utilisons pour soutenir notre entreprise et qui sont contractuellement tenus de garder les informations personnelles confidentielles et de les utiliser uniquement pour les fins pour lesquelles nous les divulguons.',
      p5: '• À un acheteur ou autre successeur en cas de fusion, cession, restructuration, réorganisation, dissolution ou autre vente ou transfert de tout ou partie des actifs de l entreprise, que ce soit dans le cadre de l exploitation en cours ou dans le cadre d une faillite, liquidation ou procédure similaire, dans laquelle les informations personnelles détenues par l entreprise sur les utilisateurs de notre site Web font partie des actifs transférés.',
      p6: '• Pour remplir l objectif pour lequel vous les fournissez.',
      p7: '• Pour tout autre objectif que nous précisons lorsque vous fournissez les informations.',
      p8: '• Avec votre consentement.',
      p9: 'Nous pouvons également divulguer vos informations personnelles:',
      p10: '• Pour respecter toute ordonnance judiciaire, loi ou procédure légale, y compris pour répondre à toute demande gouvernementale ou réglementaire.',
      p11: '• Pour appliquer ou faire respecter nos conditions d utilisation et autres accords, y compris pour les opérations de facturation et de recouvrement.',
      p12: '• Si nous croyons que la divulgation est nécessaire ou appropriée pour protéger les droits, la propriété ou la sécurité de l entreprise, de nos clients ou d autres personnes. Cela comprend l échange d informations avec d autres entreprises et organisations pour la protection contre la fraude et la réduction des risques de crédit.',
    },
  },
  informationRights: {
    title: 'Vos droits d information et contrôles',
    content: {
      p1: 'Le Règlement Général sur la Protection des Données ("RGPD") accorde aux individus des droits sur leurs informations personnelles. Bien que les droits suivants soient spécifiques aux membres de l Espace Économique Européen (EEE), nous nous efforçons de fournir à tous nos utilisateurs les mêmes droits que ceux accordés aux membres de l EEE conformément au RGPD, chaque fois que possible.',
      p2: 'Veuillez vous familiariser avec vos droits et leurs descriptions dans le tableau suivant.',
      p3: 'Il est votre droit:',
      p4: 'Accès: Informez-vous sur les données personnelles que nous traitons à votre sujet et demandez-y accès.',
      p5: 'Rectification: Demandez-nous de mettre à jour ou de corriger des données inexactes ou incomplètes vous concernant.',
      p6: 'Suppression: Demandez-nous de supprimer vos données.',
      p7: 'Restriction de traitement: Demandez-nous de restreindre le traitement de vos données personnelles.',
      p8: 'Portabilité des données: Demandez-nous de vous fournir ou de fournir à un tiers une copie de vos données personnelles dans un format structuré, couramment utilisé et lisible par machine.',
      p9: 'Opposition: Opposez-vous au traitement de vos données personnelles.',
      p10: 'Opposition au marketing: Opposez-vous au traitement de vos données personnelles à des fins de marketing.',
      p11: 'Pas de prise de décision automatisée: Ne soyez pas soumis à une décision basée uniquement sur le traitement automatisé, y compris le profilage, qui produit des effets juridiques vous concernant ou vous affectant de manière similairement significative.',
    },
  },
  exerciseRights: {
    title: 'Comment vous pouvez exercer vos droits chez nous',
    content: {
      p1: 'Accès: Pour faire une demande vérifiable concernant les données personnelles que nous avons collectées à votre sujet, veuillez envoyer un e-mail à support@gutgeregelt.ch. Veuillez noter que presque toutes les informations que nous collectons à votre sujet sont contenues dans votre profil de compte sur la plateforme.',
      p2: 'Rectification: Vous pouvez modifier vos informations personnelles dans votre profil de compte sur notre plateforme.',
      p3: 'Suppression: Vous pouvez exercer ce droit en envoyant un e-mail à support@gutgeregelt.ch. Veuillez noter que nous supprimerons vos données personnelles:',
      p4: '• Nous pouvons conserver vos données personnelles aussi longtemps que nécessaire pour nos intérêts commerciaux légitimes, tels que la prévention du blanchiment d argent, la détection et la prévention de la fraude, et l augmentation de la sécurité. Par exemple, si nous bloquons un compte gut geregelt pour des raisons de fraude ou de sécurité, nous pouvons conserver des informations de ce compte gut geregelt pour empêcher la personne d ouvrir un nouveau compte à l avenir.',
      p5: '• Nous pouvons conserver et utiliser vos données personnelles dans la mesure nécessaire pour respecter nos obligations légales. Par exemple, gut geregelt peut conserver vos informations pour des obligations fiscales, juridiques, d audit et de comptabilité, ou comme enregistrement d une dispute avec vous.',
      p6: '• Certaines copies de vos informations (par exemple, les e-mails) peuvent rester dans notre base de données mais seront dépersonnalisées et conservées uniquement à des fins de documentation.',
      p7: 'Restriction: Vous pouvez exercer ce droit en envoyant un e-mail à support@gutgeregelt.ch ou en cessant d utiliser la plateforme.',
      p8: 'Opposition: Vous pouvez exercer ce droit en envoyant un e-mail à support@gutgeregelt.ch.',
      p9: 'Portabilité des données: Vous pouvez exercer ce droit en envoyant un e-mail à support@gutgeregelt.ch.',
      p10: 'Décision automatisée: Vous pouvez exercer ce droit en envoyant un e-mail à support@gutgeregelt.ch. Veuillez noter cependant que nous ne procédons à aucune décision automatisée. Toutes les décisions concernant votre profil sont prises en interne par notre personnel de support.',
      p11: 'Vous avez également le droit de contacter votre autorité de protection des données locale pour toute question ou préoccupation.',
    },
  },
  dataStorage: {
    title: 'Stockage et suppression des données',
    content: {
      p1: 'Conformément au RGPD et à d autres réglementations pertinentes, nous ne stockons vos données personnelles que aussi longtemps que nécessaire pour fournir les services disponibles sur la plateforme gut geregelt, et pour nos intérêts commerciaux légitimes et essentiels.',
    },
  },
  deletion: {
    title: 'Suppression',
    content: {
      p1: 'Si vous fermez votre compte ou nous demandez de fermer votre compte, nous supprimerons, anonymiserons ou modifierons vos données personnelles pour qu elles ne puissent plus vous identifier. Sauf si nous sommes légalement tenus de conserver certaines données, ou nous devons continuer à les utiliser pour des raisons légalement justifiées.',
      p2: 'Voici quelques exemples de situations où nous sommes légalement autorisés ou obligés de conserver certaines de vos données personnelles:',
      p3: '• S il existe un problème non résolu lié à votre compte, tel qu un paiement en attente ou une plainte ou un litige non résolu.',
      p4: '• Pour nos obligations légales, fiscales, d audit et de comptabilité.',
      p5: '• Pour nos intérêts commerciaux légitimes, tels que la prévention de la fraude ou le maintien de la sécurité.',
      p6: 'Pour plus d informations, voir la section sur la suppression ci-dessus.',
      p7: 'Dans certains cas, nous pouvons anonymiser vos données personnelles ou les modifier de manière à ce qu elles ne soient plus identifiables pour prévenir ou détecter des fraudes, ou pour déterminer si vous êtes un utilisateur précédent si vous vous réinscrivez sur la plateforme gut geregelt pour prévenir des fraudes.',
      p8: 'Nous avons pris des mesures pour protéger vos données personnelles contre la perte accidentelle et l accès, l utilisation, la modification et la divulgation non autorisés.',
      p9: 'La sécurité de vos informations dépend également de vous. Si nous vous avons fourni (ou si vous avez choisi) un mot de passe pour accéder à certaines parties de notre site Web, vous êtes responsable de garder ce mot de passe confidentiel. Nous vous demandons de ne partager votre mot de passe avec personne. Veuillez être prudent lorsque vous partagez des informations dans des zones publiques de notre site Web comme les forums. Les informations que vous partagez dans les zones publiques peuvent être vues par n importe quel utilisateur du site Web.',
      p10: 'Malheureusement, la transmission d informations via internet n est pas complètement sécurisée. Bien que nous fassions de notre mieux pour protéger vos données personnelles, nous ne pouvons pas garantir la sécurité de vos données personnelles transmises à notre site Web. Toute transmission de données personnelles se fait à vos propres risques. Nous ne sommes pas responsables de la contournement des paramètres de confidentialité ou des mesures de sécurité sur notre site Web.',
    },
  },
  changes: {
    title: 'Modifications de notre politique de confidentialité',
    content: {
      p1: 'Il est de notre politique de poster toute modification que nous apportons à notre politique de confidentialité sur cette page. Si nous apportons des modifications substantielles à la manière dont nous traitons les données personnelles de nos utilisateurs, nous vous en informerons par une notification sur la page d accueil de notre site Web. La date de la dernière révision de la politique de confidentialité est indiquée en haut de la page. Vous êtes responsable de vous assurer que nous avons une adresse e-mail actuelle, active et livrable pour vous, et de visiter régulièrement notre site Web et cette politique de confidentialité pour vérifier les modifications.',
    },
  },
  contantInformation: {
    title: 'Informations de contact',
    content: {
      p1: 'Si vous avez des questions ou des commentaires sur cette politique de confidentialité et nos pratiques de confidentialité, veuillez nous contacter et notre délégué à la protection des données à support@gutgeregelt.ch.',
      p2: 'Pour enregistrer une plainte ou des préoccupations, veuillez envoyer un courriel à support@gutgeregelt.ch.',
    },
  },
};

export default privacyPolicy;
