import { IQuestion } from '#/types/globalTypes';

const powerOfTrustQuestions: IQuestion[] = [
  {
    question: 'Ma personne de confiance pour moi en tant que personne est :',
  },
  {
    question:
      "L'assumption de responsabilité pour une autre personne est souvent associée à un effort considérable. Souhaitez-vous payer un honoraire à la personne de votre confiance ?",
    options: [
      {
        label:
          'Oui, ' +
          " indemnisation selon les normes locales et habituelles de l'industrie",
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question:
      "Si la personne mentionnée ci-dessus n'est pas disponible, je souhaite nommer la personne suivante comme ma personne de confiance :",
  },
  {
    question:
      "L'assumption de responsabilité pour une autre personne est souvent associée à un effort considérable. Souhaitez-vous payer un honoraire à votre personne de confiance alternative ?",
    options: [
      {
        label:
          'Oui, ' +
          "indemnisation selon les normes locales et habituelles de l'industrie",
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question: 'Avez-vous des enfants de moins de 18 ans ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question: 'Qui devrait être la personne de confiance pour vos enfants ?',
  },
  {
    question: 'Ma relation avec la personne de confiance : ',
  },
  {
    question:
      'Souhaitez-vous payer un honoraire à votre personne de confiance pour être responsable de vos enfants ?',
    options: [
      {
        label:
          'Oui, ' +
          "indemnisation selon les normes locales et habituelles de l'industrie",
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question:
      "Si cette personne n'est pas disponible, je souhaite que la personne suivante soit responsable de mes enfants :",
  },
  {
    question:
      'Souhaitez-vous payer un honoraire à votre personne de confiance alternative pour la prise en charge de vos enfants ?',
    options: [
      {
        label:
          'Oui, ' +
          "indemnisation selon les normes locales et habituelles de l'industrie",
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question: 'Je confie la gestion de mon patrimoine à la personne suivante :',
  },
  {
    question:
      'Souhaitez-vous payer un honoraire à votre personne de confiance pour la gestion de votre patrimoine ?',
    options: [
      {
        label:
          'Oui, ' +
          "indemnisation selon les normes locales et habituelles de l'industrie",
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question:
      "Si cette personne n'est pas disponible, je souhaite que la personne suivante soit responsable de mon patrimoine :",
  },
  {
    question:
      'Souhaitez-vous payer un honoraire à votre personne de confiance alternative pour la gestion de votre patrimoine ?',
    options: [
      {
        label: `Oui,
                indemnisation en accord avec les normes locales et habituelles de l'industrie`,
      },
      {
        label: 'Non',
      },
    ],
  },
];

export default powerOfTrustQuestions;
