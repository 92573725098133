import React, { useEffect, useState } from 'react';
import { MenuItem, Stack } from '@mui/material';
import MenuPopover from '../MenuPopover';
import useLocales from '#/hooks/useLocales';
import IconButtonAnimate from '../IconButtonAnimate';
import Image from '../Image';
import { useSetLanguage } from '#/api/userQueries';
import useAuth from '#/hooks/useAuth';

export default function LanguagePopover() {
  const { user, refetch, isAuthenticated } = useAuth();
  const { mutateAsync: setLangToServer } = useSetLanguage();

  const { allLangs, currentLang, onChangeLang } = useLocales();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  // const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setOpen(event.currentTarget);
  // };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = async (lang: string) => {
    onChangeLang(lang);
    handleClose();
    if (isAuthenticated) {
      await setLangToServer({
        language: lang,
      }).then(() => refetch());
    }
  };

  useEffect(() => {
    if (user?.language !== null || user?.language !== '') {
      onChangeLang(user?.language);
    }
    // eslint-disable-next-line
  }, [user?.language]);

  return (
    <>
      <IconButtonAnimate
        // onClick={handleOpen}
        disabled
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Image
          disabledEffect
          src={currentLang?.flag}
          alt={currentLang?.label}
        />
      </IconButtonAnimate>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': {
            px: 1,
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Image
                disabledEffect
                alt={option.label}
                src={option.flag}
                sx={{ width: 28, mr: 2 }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
