const livingWillLanding = {
  mainBanner: {
    intro: 'Create a living will online:',
    title: 'Be in control of what happens in case of emergency ',
    subtitle:
      'In the living will, you can record in writing which medical treatments you consent to or which you refuse in the event that you are no longer able to make decisions for yourself.',
    buttonLabel: 'Create document',
  },
  youtubeTutorial: 'What is a living will?',
  aboutCards: {
    proactiveMeasures: {
      title: 'Proactive measures',
      description:
        'If you are incapable of acting, the living will ensures that you are treated according to your wishes.',
    },
    wishesYouCanAdapt: {
      title: 'Wishes you can adapt',
      description:
        'Any person with capacity can create a living will and change or revoke it at any time.',
    },
    legalSecurity: {
      title: 'Relieve your relatives',
      description:
        'In case of emergency, your relatives have less stress because you made provisions.',
    },
  },
  subBanner: {
    title: 'Gut geregelt with a legally compliant living will',
    subtitle:
      'With gut geregelt, you quickly create your living will, can get legal consultation on delicate topics or open questions and do your relatives a favor.',
    buttonLabel: 'Create it for free',
  },
  newsletter: {
    title: 'Get gut geregelt with your living will',
    buttonLabel: 'Create it now',
  },
  faq: {
    questions: {
      question1: 'What is a living will, and what is its purpose?',
      question2: 'What are the requirements for creating a living will?',
      question3: 'When does a living will come into force?',
      question4: 'What can a living will prevent?',
      question5:
        'How do the representatives defined in the living will function?',
      question6: 'Is a copy of the living will valid in case of application?',
      question7: 'Do I need to have my living will notarized?',
      question8: 'Can a third party fill out the living will for me?',
      question9:
        'What if I cannot sign it myself (due to blindness, MS, or Parkinson’s)?',
      question10: 'Is my living will valid abroad?',
      question11:
        'Can I specify in the directive that I do not want to be resuscitated in an emergency?',
      question12:
        'Do I have to specify a designated representative? What happens if I cannot or do not want to specify a designated representative?',
      question13:
        'Do I need to inform my designated representative in advance?',
      question14: 'Is my spouse automatically my designated representative?',
      question15:
        'Can I appoint my minor child as a designated representative?',
      question16:
        'If I move or get married, do I need to update my living will?',
      question17:
        'Should I give a copy of my living will to my primary care physician?',
      question18: 'Is my online living will publicly accessible?',
      question19: 'What if my living will is not implemented?',
      question20: 'How can I ensure the validity?',
      question21:
        'Are there other documents I should create besides the living will?',
      question22: 'How does well-regulated help with my living will?',
    },
    answers: {
      answer1:
        "A living will is a written, unilateral expression of one's will made while in a state of capacity to judge. In it, you provide doctors and caregivers with medical instructions for the situation where you are no longer able to make judgments or express yourself at a later time.",
      answer2: 'Capacity to make medical decisions.',
      answer3:
        'A living will takes effect when two factors coincide: incapacity to judge and a grim prognosis. This situation may occur in cases such as a severe stroke, advanced dementia, advanced-stage brain tumor, coma, or persistent vegetative state after an accident, surgery, or resuscitation.',
      answer4:
        'It can prevent the prolongation of natural death through medical measures, attempts at resuscitation, maintaining life through machines without the prospect of improvement, and artificial nutrition.',
      answer5:
        'All designated representatives mentioned in the living will become agents in the order of their mention. They are authorized to make medical decisions on behalf of the person making the directive if a situation arises that is not covered in the living will.',
      answer6:
        'Yes, a copy can be used in case of application. However, it is recommended that at least one designated representative knows the location of the original documents.',
      answer7:
        'No. Since the implementation of the new adult protection law in 2013, a living will created in a state of capacity is legally binding.',
      answer8:
        'Yes, as long as you understand the content and independently add the date and signature by hand or electronically sign it.',
      answer9: 'Have your living will notarized.',
      answer10:
        'gut geregelt can only enforce a living will in Switzerland. The binding nature of a living will in a foreign country must be clarified in that respective country.',
      answer11:
        'In principle, emergencies aim to save and stabilize life, often leaving no time to search for a possibly existing living will. However, emergency personnel are also obligated to determine the presumed will of an unresponsive person and act accordingly.',
      answer12:
        'A living will is still legally valid even if no designated representative is named. However, in this case, it is advantageous to update the living will annually.',
      answer13:
        'We recommend informing them for practical reasons. This way, your representative knows what is expected of them.',
      answer14:
        'No. The spouse must be explicitly listed on the living will if desired.',
      answer15:
        'Yes, by law, a designated representative must be capable of judgment but not necessarily of legal age.',
      answer16:
        'A living will should ideally be adapted to current circumstances. Therefore, it is recommended to consider every change.',
      answer17: 'We recommend sending a copy to the treating/family doctor.',
      answer18: 'No, access is password-protected.',
      answer19:
        'You or individuals close to you can contact the adult protection authority in writing or by phone and assert that the living will is not being followed, or the interests of the incapacitated person are endangered or no longer safeguarded.',
      answer20:
        'gut geregelt helps ensure the legal validity. Every living will must include the creation date and the personal signature of the person making the directive.',
      answer21:
        'Yes, {{powerOfAttorney}} and {{lastWill}} are two documents you should create as soon as possible.',
      answer22:
        'gut geregelt assists you in smoothly and legally creating your living will, allowing you to look into the future well-regulated.',
    },
  },
};

export default livingWillLanding;
