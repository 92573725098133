import {
  financialInformationQuestions,
  trustedPersonQuestions,
} from './questions';

const importantInformation = {
  mainTitle: 'Einfache Bereitstellung wichtiger Informationen',
  mainDescription:
    'Lassen Sie Ihre Angehörigen oder Testamentsvollstrecker wissen, wo wichtige Informationen in schwierigen Zeiten zu finden sind.​',

  insuranceTypes: {
    life_insurance: 'Lebensversicherung',
    private_liability_insurance: 'Private Haftpflichtversicherung',
    household_insurance: 'Hausratversicherung',
    vehicle_insurance: 'Fahrzeugversicherung',
    building_insurance: 'Gebäudeversicherung',
    legal_expenses_insurance: 'Rechtsschutzversicherung',
    occupational_disability_insurance: 'Berufsunfähigkeitsversicherung',
    health_insurance: 'Krankenversicherung',
    supplementary_health_insurance: 'Zusatzkrankenversicherung',
    accident_insurance: 'Unfallversicherung',
  },

  threeATypes: {
    policy: 'Police',
    account: 'Konto',
    fund: 'Fonds',
  },

  stepperInfo: [
    {
      label: 'Identifikationsdokumente',
      title: '',
    },
    {
      label: 'Finanzinformationen',
      title: '',
    },
    {
      label: 'Vertrauenspersonen',
      title: '',
    },
  ],

  stepTwo: {
    questions: financialInformationQuestions,
  },

  delete3Amodal: {
    title:
      'Möchten Sie {{financialInstitution}} wirklich aus der Liste löschen?',
    description: 'Sie können es später jederzeit wieder hinzufügen',
    onClose: 'Schliessen',
    onConfirm: 'Löschen',
  },

  deleteInsurancePolicyModal: {
    title: 'Möchten Sie die {{policy}} wirklich löschen?',
    description: 'Sie können es später jederzeit wieder hinzufügen',
    onClose: 'Schliessen',
    onConfirm: 'Löschen',
  },

  stepThree: {
    questions: trustedPersonQuestions,
    addAnotherTrustedPerson: 'Weitere Willensvollstrecker hinzufügen',
    addNotary: 'Notar oder Treuhänder hinzufügen',
  },
};

export default importantInformation;
