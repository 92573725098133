const outputDoc = {
  title: 'Vorsorgeauftrag',
  from: 'von',
  born: 'geboren am ',
  civilStatus: {
    single: 'Ledig',
    married: 'Verheiratet',
    widowed: 'Verwitwet',
    divorced: 'Geschieden',
    registered_partnership: 'Eingetragener partnerschaft',
  },
  primary: 'Hauptperson',
  deputy: 'Ersatzbeauftragter',
  civil_status: 'Familienstand: {{civil_status}}',
  address: 'Adresse: {{address}}',
  declare: 'Ich, {{first_name}} {{last_name}}, erkläre folgendes:  ',
  paragraph1:
    'Für den Fall, dass ich urteilsunfähig werde und nicht mehr in der Lage bin, meinen Willen dauernd oder vorübergehend zu bilden oder verständlich mitzuteilen, beauftrage ich folgende Person(en), für mich als Vorsorge-  und Vermögensbeauftrage die Aufgaben zu übernehmen und mich im Rechtsverkehr in allen Angelegenheiten zu vertreten: ',
  firstParagraph: {
    sameTrustees: 'Personen- und Vermögenssorge, gleiche Person sowie',
    differentTrustees: 'Personen und Vermögenssorge, verschiedene Person',
  },
  assetCare: 'Vermögenssorge',
  personCare: 'Personensorge',
  mainCharacter: 'Hauptperson',
  alternate: 'Ersatzbeauftragte',
  compensationDeclare: 'Erhält Entschädigung gemäss unten stehender Definition',
  nonCompensationDeclare: 'Erhält keine Entschädigung',
  paragraph2: 'Als ersatz beauftragte Person ernenne ich',
  paragraph3:
    'Für den Fall, dass ich urteilsunfähig werde und nicht mehr in der Lage bin, meinen Willen dauernd oder vorübergehend zu bilden oder verständlich mitzuteilen, beauftrage ich folgende Ersatz-Person(en), für mich als Vorsorge-  und Vermögensbeauftrage die Aufgaben zu übernehmen und mich im Rechtsverkehr in allen Angelegenheiten zu vertreten: ',
  personCareDeclare1:
    'Die beauftragte Person bestimmt, welche Massnahmen im Hinblick auf meine Pflege und medizinische Versorgung zu treffen sind, und sorgt für die Sicherstellung eines geordneten Alltags und eine angemessene Teilnahme am gesellschaftlichen Leben.',
  personCareDeclare2:
    'Sofern nicht ausdrücklich in Ihrem Testament festgelegt, gehen Ihre digitalen Vermögenswerte im Falle Ihres Todes an Ihre gesetzlichen Erben über. Sie sollten daher zu Lebzeiten entscheiden, was nach Ihrem Tod gelöscht oder wem übertragen werden soll. ',
  assetCare1:
    'Die beauftragte Person verwaltet mein Einkommen und Vermögen, verfügt darüber und trifft alle damit zusammenhängenden Massnahmen. Sie erteilt bzw. stellt gegenüber Dritten, Banken und Behörden, die für die Wahrung meiner Interessen notwendigen Instruktionen und Anträge. Die beauftragte Person sorgt für die Bezahlung meiner Rechnungen via Post oder Bank und nimmt Zahlungen für mich entgegen. Sie ist berechtigt, alle an mich adressierte Post entgegenzunehmen und zu öffnen.',
  assetCare2:
    'Die Verwaltung der Bankguthaben und des Wertschriftenvermögens soll sich an den bestehenden Vereinbarungen und Gepflogenheiten orientieren. Die Vermögensverwaltung unterliegt ausdrücklich nicht den Bestimmungen gemäss Verordnung über die Vermögensverwaltung im Rahmen einer Beistandschaft oder Vormundschaft.',
  assetCare3:
    'Die beauftragte Person darf aus meinem Vermögen keine Schenkungen ausrichten und keine Vermögenswerte unentgeltlich veräussern, mit Ausnahme von Gelegenheitsgeschenken oder Zuwendungen zur Erfüllung einer sittlichen Pflicht.',
  repLegal: 'Vertretung im Rechtsverkehr',
  repLegal1:
    'Die beauftragte Person ist ermächtigt, alle im Rahmen dieses Vorsorgeauftrages erforderlichen Rechts- und allenfalls Prozesshandlungen zu veranlassen, insbesondere die dafür nötigen Verträge abzuschliessen oder zu kündigen, Steuererklärungen einzureichen und mich vor Behörden und Gerichten oder gegenüber Dritten zu vertreten. Die beauftragte Person ist zuständig für alle administrativen Belange.',
  repLegal2:
    'Die beauftragte Person ist ermächtigt, mein Grundeigentum zu veräussern oder zu belasten, zu meinen Gunsten oder Lasten im Grundbuch eingetragenen Anmerkungen, Vormerkungen und Dienstbarkeiten zu begründen, zu ändern und zu löschen, solche Geschäfte beurkunden zu lassen sowie entsprechende Einschreibungen im Grundbuch zu veranlassen.',
  custodyOrder: 'Sorgerechtsverfügung',
  childrenTrustee:
    'Sofern meine Kinder noch nicht volljährig sind so wünsche ich, dass folgende Person als ihr Vormund eingesetzt wird und die Kinder in dessen Obhut gestellt werden:',
  childrenTrusteeRelationship: 'Beziehung zu mir: {{relation}}',
  relation: {
    husband: 'Ehemann',
    wife: 'Ehefrau',
    husband_wife: 'Ehemann/Ehefrau',
    partner: 'Partner, Partnerin',
    friend: 'Freund',
    'brother-sister': 'Bruder, Schwester',
    'brother-sister-in-law': 'Schwager, Schwägerin',
  },
  alternateChildrenTrustee: 'Als ersatzbeauftragte Person ernenne ich',
  childrenTrusteeDeclare:
    'Es ist mir bekannt, dass diese Sorgerechtsverfügung rechtlich nicht Teil des Vorsorgeauftrages ist, dass es sich dabei um einen Wunsch handelt bzw. dass die Kindesschutzbehörde daran nicht gebunden ist und dass die entsprechende Behörde den Wunsch nur dann berücksichtigen kann, wenn sie dem Kindeswohl entsprechen. Ein gesetzlicher Anspruch, dass die Kindesschutzbehörde die obgenannte(n) Person(en) mit der Vormundschaft betraut, besteht demnach nicht. ',
  releaseFromConfidentiality: 'Entbindung von der Schweigepflicht',
  releaseFromConfidentialityDeclare:
    'Ich entbinde alle einer beruflichen Schweigepflicht unterstehenden Personen und Behörden gegenüber der beauftragten Person vom Berufs- und Amtsgeheimnis (insbesondere Banken, Ärzte und Amtspersonen).',
  involvementOfAidsAndAssistants: 'Beizug von Hilfsmitteln und Hilfspersonen',
  involvementOfAidsAndAssistantsDeclare:
    'Zur Erfüllung des Auftrags ist die beauftragte Person ermächtigt, auf meine Kosten Hilfsmittel anzuschaffen und Hilfspersonen und Substituten (wie z.B. Haushaltshilfen, Pflegedienste, Steuerberater, Anwälte, Vermögensverwalter etc.) beizuziehen und mit diesen Verträge abzuschliessen, abzuändern oder aufzulösen.',
  expensesAndCompensation: 'Spesen und Entschädigungen',
  expenses: 'Spesen',
  expensesDeclare:
    'Die notwendigen und nachgewiesenen Auslagen sind der für die Personen- oder Vermögenssorge beauftragten Person zu ersetzen .',
  indemnities: 'Entschädigungen',
  indemnitiesPositive:
    'Die für die Personen- oder Vermögenssorge beauftragte Person hat für ihre Leistungen Anspruch auf angemessene Entschädigung, welche sich nach den bei der Erwachsenenschutzbehörde üblichen Ansätzen für die Entschädigung von Beiständen bemisst.',
  indemnitiesNegative:
    'Die Person(en), die für die Kinder, Personen- oder Vermögenssorge beauftragt wurde und gemäss Wunsch des Dokumentenerstellers eine Entschädigung erhalten soll, erhält eine solche, die sich nach den üblichen Ansätzen der Erwachsenenschutzbehörde von Beiständen richtet.',
  storageRegistration: 'Aufbewahrung und Registrierung des Vorsorgeauftrages',
  storage: 'Aufbewahrung',
  storageRegistrationDeclare:
    'Es ist mir bekannt, dass Vorsorgeaufträge bei der für die Wohnsitzgemeinde zuständigen Kindes- und Erwachsenenschutzbehörde zur Aufbewahrung hinterlegt werden können. Es liegt in meiner freien Entscheidung, ob ich diese Möglichkeit wahrnehme.',
  storageRegistrationDeclare2:
    'Die mit der Vorsorge betraute Person ist beauftragt und ermächtigt, sich mit diesem Vorsorgeauftrag im Bedarfsfall an die Erwachsenenschutzbehörde meines Wohnortes zu wenden, damit die Behörde ihr die zu meiner Vertretung notwendige Urkunde im Sinne von Art. 363 ZGB ausstellen kann. ',
  registration: 'Registrierung',
  registrationDeclare:
    'Die Möglichkeit der Orientierung des zuständigen Zivilstandsamtes über die Errichtung und den Hinterlegungsort des vorliegenden Vorsorgeauftrages zwecks Registrierung in der zentralen Datenbank gemäss Art. 361 Abs 3 ZGB ist mir bekannt. Es liegt in meiner freien Entscheidung, ob ich diese Möglichkeit wahrnehme. ',
  applicableLaw: 'Anwendbares Recht und Gerichtsstand',
  applicableLawDeclare:
    'Dieser Vorsorgeauftrag untersteht – ungeachtet meiner Nationalität oder meinem Wohnsitz schweizerischem Recht, dies unter Ausschluss kollisionsrechtlicher Normen (IPRG). Gerichtsstand ist:',
  revocation: 'Widerruf',
  revocationDeclare:
    'Ich widerrufe hiermit sämtliche Vorsorgeaufträge früheren Datums.',
  abilityToAct: 'Handlungsfähigkeit',
  abilityToActDeclare:
    'Ich gebe diese Erklärung nach sorgfältiger Überlegung und in der vollen Verantwortung für mich selbst ab sowie im Bewusstsein, dass bezüglich der medizinischen Massnahmen meine Ärzte, Betreuer oder Bevollmächtigten an den Entscheid der beauftragten Person gebunden sind. Ich habe diese Verfügung mit den eingangs auf der ersten Seite erwähnten Personen besprochen, welche bestätigen können, dass ich zu diesem Zeitpunkt nach deren Wahrnehmung urteilsfähig war und der Inhalt meinem Willen entspricht. ',
};

export default outputDoc;
