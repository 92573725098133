import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 5000 * 60, // 5 minutes
      staleTime: 5000 * 60, // 5 minutes
      // retry: false,
    },
    mutations: {
      onError: (e, v) => console.log('Error on query client', e, v),
    },
  },
});

export default queryClient;
