import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useListMyConnections, useSwitchAsGuest } from '#/api/successor';
import useAuth from '#/hooks/useAuth';
import useLocalStorage from '#/hooks/useLocalStorage';

type CurrentAccount = {
  [key: string]: any;
};

type AccSwitchContextProps = {
  currentAccount: CurrentAccount | null;
  handleSwitchAccount: (id: number) => void;
  isSwitching?: boolean;
};
type AccSwitchProviderProps = {
  children: ReactNode;
};

const AccountSwitchContext = createContext<AccSwitchContextProps>({
  currentAccount: null,
  handleSwitchAccount: () => {},
  isSwitching: false,
});

const AccountSwitchProvider: FC<AccSwitchProviderProps> = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState<CurrentAccount | null>(
    null
  );
  const [guestOn, setLocalStorageGuestOn, removeGuestOn] = useLocalStorage(
    'guest_on',
    null
  );

  const navigate = useNavigate();
  const { user } = useAuth();
  const { data: accountsList } = useListMyConnections(!!user);
  const { mutateAsync: switchToAccount, isLoading: isSwitching } =
    useSwitchAsGuest();

  const handleSwitchAccount = async (id: number) => {
    if (!user) return;
    if (id === user?.id) {
      setCurrentAccount(user);
      removeGuestOn();
      return;
    }
    try {
      const response = await switchToAccount({ id });
      setLocalStorageGuestOn(id);
      setCurrentAccount(response?.data);
      navigate(`/dashboard/gast-bei/${id}`);
    } catch (error) {
      console.error('Error switching account: ', error);
    }
  };

  useEffect(() => {
    if (guestOn || user?.id) {
      handleSwitchAccount(guestOn || user?.id);
    }
  }, [guestOn, user]);

  useEffect(() => {
    if (!user?.primary_account && accountsList?.length) {
      handleSwitchAccount(accountsList[0].id);
    }
  }, [user, accountsList]);

  useEffect(() => {
    if (currentAccount?.id && currentAccount?.id !== user?.id) {
      navigate(`/dashboard/gast-bei/${currentAccount?.id}`);
    }
  }, [currentAccount, user]);

  const values = useMemo(
    () => ({ currentAccount, handleSwitchAccount, isSwitching }),
    [handleSwitchAccount]
  );

  return (
    <AccountSwitchContext.Provider value={values}>
      {children}
    </AccountSwitchContext.Provider>
  );
};

export { AccountSwitchProvider, AccountSwitchContext };
