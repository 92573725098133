const outputDoc = {
  title: 'Directive anticipée',
  from: 'de ',
  born: 'né(e) le ',
  civilStatus: {
    single: 'Célibataire',
    married: 'Marié(e)',
    widowed: 'Veuf(ve)',
    divorced: 'Divorcé(e)',
    registered_partnership: 'Partenariat enregistré',
  },
  civil_status: 'État civil: {{civil_status}}',
  address: 'Adresse: {{address}}',
  declare: 'Je, {{first_name}} {{last_name}}, déclare ce qui suit:  ',
  paragraph1:
    "Pour le cas où je deviendrais incapable de juger à cause d'une maladie ou d'un accident et ne serais plus en mesure de le faire, je règle avec cette directive anticipée ma volonté juridiquement contraignante et mes souhaits concernant le traitement médical, les soins, la mort et le mourir. Comme personne(s) autorisée(s) à me représenter, je désigne la(les) personne(s) suivante(s): ",
  paragraph2:
    "La personne autorisée met en œuvre ma volonté déterminée en cas d'incapacité de juger et prend les décisions médicales et de soins pour moi. Elle doit être informée de mon état de santé ainsi que de l'évolution probable de la maladie. Je délie expressément l'équipe de traitement du secret professionnel dans la mesure nécessaire pour une décision à venir. La personne autorisée doit – chaque fois que c'est possible – être impliquée avant que des décisions médicales soient prises. Si cette directive anticipée ne règle pas une question, la personne autorisée décide selon ma volonté présumée.",
  paragraph3:
    "La personne nommée est également autorisée, après ma mort, à consulter la documentation patient ainsi que tout rapport d'autopsie éventuel.",
  repInformParagraph:
    "J'ai discuté de la mission avec la personne autorisée le {{representative_inform_date}}.",
  secondaryRepParagraph:
    'Dans le cas où la personne désignée selon le chiffre 1 ne pourrait pas assumer sa tâche, je nomme comme personne de remplacement: ',
  motivation: {
    title: 'Motivation et attitude de base',
    paragraph1: 'Je rédige cette directive anticipée ',
    takePrecautions: 'en bonne santé, mais avec une planification prévoyante.',
    makeProvisions:
      'avec une certaine maladie qui me conduit à prendre des précautions.',
    makeProvisionsForFuture:
      'après avoir atteint un certain âge, où des précautions à court terme doivent également être prises.',
    paragraph2: 'Avec cette directive anticipée, je souhaite surtout ',
    alleviateSuffering:
      "que les possibilités médicales soient utilisées principalement pour soulager mes souffrances. Prolonger ma vie à tout prix n'est pas ma priorité. Je prends en compte que le renoncement à un traitement médical peut raccourcir ma vie.",
    exhaustedMedicalPossibilities:
      'que toutes les possibilités médicales soient utilisées pour maintenir ma vie. Mon souffrance doit être soulagée autant que possible. Je prends en compte que cela peut être associé à des charges (par exemple, une conscience altérée).',
  },
  medical: {
    title: 'Directives médicales / Objectif de traitement',
    paragraph1:
      "Si je me trouve dans l'une des situations suivantes, j'attends de l'équipe de traitement le comportement suivant:",
    exhaustedMedicalPossibilities:
      "Je souhaite avant tout un traitement efficace de la douleur et d'autres symptômes comme l'angoisse. Je prends en compte que cela peut être associé à des charges (par exemple, une altération de la conscience).",
    alleviateSuffering:
      "Je souhaite surtout conserver la conscience et ma capacité de communication. Je prends en compte que cela peut potentiellement compromettre l'atténuation optimale de la douleur et d'autres symptômes comme l'angoisse.",
  },
  forego_measures: {
    yes: "Je renonce à toutes les mesures qui prolongent uniquement la vie et la souffrance, si je deviens incapable de juger à la suite d'un événement aigu imprévu et si, après une évaluation médicale approfondie, il est impossible ou peu probable que je recouvre jamais ma capacité de jugement. ",
    no: "Je souhaite que toutes les mesures possibles soient mises en œuvre, si je deviens incapable de juger à la suite d'un événement aigu imprévu et même si, après une évaluation médicale approfondie, il est impossible ou peu probable que je recouvre jamais ma capacité de jugement. ",
  },
  revive_preference: {
    paragraph: "En cas d'arrêt cardio-respiratoire, il doit y avoir ",
    yes: 'une',
    no: 'aucune ',
    paragraph1: 'réanimation.',
  },
  moreDetails: {
    treatment_goal_sudden_incapacity: {
      paragraph: "En cas d'incapacité soudaine (urgence) ",
      resuscitationAndTreatment:
        'je souhaite une réanimation et un traitement en unité de soins intensifs (objectif de traitement prolongation de la vie). ',
      treatedInIntensiveCareUnit:
        'je souhaite aucune réanimation, mais un traitement en unité de soins intensifs. ',
    },
    suffering_relief_sudden_incapacity: {},
    treatment_goal_prolonged_incapacity: {
      paragraph: "En cas d'incapacité prolongée ",
      resuscitationAndTreatment:
        'je souhaite une réanimation et un traitement en unité de soins intensifs (objectif de traitement prolongation de la vie). ',
      treatedInIntensiveCareUnit:
        'je souhaite aucune réanimation, mais un traitement en unité de soins intensifs.',
    },
    treatment_goal_permanent_incapacity: {
      paragraph: "En cas d'incapacité permanente ",
      resuscitationAndTreatment:
        'je souhaite une réanimation et un traitement en unité de soins intensifs (objectif de traitement prolongation de la vie). ',
      treatedInIntensiveCareUnit:
        'je souhaite aucune réanimation, mais un traitement en unité de soins intensifs. ',
    },
  },
  revocation: {
    title: 'Révocation',
    paragraph1:
      'Je révoque par la présente toutes les directives anticipées de dates antérieures.',
  },
  abilityToConsent: {
    title: "Capacité d'agir",
    paragraph1:
      "Je fais cette déclaration après mûre réflexion et en pleine responsabilité de moi-même, ainsi qu'en conscience du fait que mes médecins, soignants ou mandataires sont liés par la décision de la personne autorisée.",
    informedRepresentative:
      "J'ai discuté de cette disposition avec les personnes mentionnées au début, sur la première page, qui peuvent confirmer que j'étais capable de juger à ce moment-là, selon leur perception, et que le contenu correspond à ma volonté. ",
  },
};

export default outputDoc;
