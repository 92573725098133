const aboutUs = {
  heroTitle: 'gut geregelt – ein Schweizer Unternehmen aus Baselland',
  heroSubtitle:
    'gut geregelt AG ist ein Startup aus Sissach bei Basel. Wir digitalisieren und demokratisieren den Zugang zur Vorsorge.',
  heroLabel: 'Über uns',
  heroButton: 'Kontakt',
  section1: {
    title: 'Wir sind gut geregelt',
    subtitle:
      'gut geregelt ist ein junges und dynamisches Unternehmen mit tiefen Wurzeln in der Schweiz und im Baselbiet. Unser Team besteht aus Experten in den Bereichen Recht, Finanzen, Versicherungen und Softwareentwicklung. Wir digitalisieren den Vorsorgeprozess und machen ihn schneller, günstiger und zugänglicher.',
  },
  section2: {
    title: 'Vorsitzender: {{chairMan}}',
    subtitle:
      '{{chairMan}} ist ein Schweizer Seriengründer aus Sissach. Er ist Verwaltungsratspräsident und treibende Kraft bei gut geregelt. Gemeinsam mit dem gut geregelt-Team treibt Alain die iterative Softwareentwicklung voran, präsentiert unsere Lösungen bei Banken und Pensionskassen und ist gefragter Referent an diversen Events im Banken- und Startup-Bereich.',
  },
  ourGoal: {
    label: 'Unser Ziel',
    title: 'Altersvorsorge zugänglich machen',
    subtitle:
      'Bei gut geregelt verfolgen wir die Mission, den gesamten Vorsorgeprozess zu digitalisieren und auf unserer Plattform abzubilden. In kurzer Zeit haben wir eine umfassende Lösung entwickelt, die kontinuierlich ausgebaut wird. gut geregelt macht Vorsorge einfacher, verständlicher und zugänglicher.',
  },
  section3: {
    title: 'Teil der Betascale-Gruppe',
    subtitle:
      'gut geregelt AG ist ein Unternehmen der Schweizer betascale Gruppe mit Sitz in Sissach bei Basel. Unter dem Motto „Building the ventures of tomorrow, today" hat betascale gut geregelt erfolgreich getestet und am Markt positioniert. Innert kürzester Zeit haben wir uns als verlässlicher Partner für die Vorsorge etabliert und unsere Software auf den Markt gebracht.',
  },
  partners: {
    label: 'Unsere Partner',
    title: 'Wir setzen auf starke Partner',
    subtitle:
      'Starke Partnerschaften sind ein wesentlicher Bestandteil unseres Erfolgs. Um unseren Kunden optimale Lösungen bieten zu können, setzen wir auf die Zusammenarbeit mit führenden Unternehmen und Institutionen in der Schweiz.',
  },
};

export default aboutUs;
