const outputDoc = {
  title: 'Testament',
  from: 'de',
  born: ' né(e) le ',
  civilStatus: {
    single: 'Célibataire',
    married: 'Marié(e)',
    widowed: 'Veuf/Veuve',
    divorced: 'Divorcé(e)',
  },
  civil_status: 'État civil : {{civil_status}}',
  address: 'Adresse : {{address}}',
  declare: 'Je, {{first_name}} {{last_name}}, déclare ce qui suit :',

  paragraph1: "Je révoque tous les testaments que j'ai rédigés précédemment.",

  paragraph2:
    'Les dettes en cours et les frais liés au décès seront payés à partir de la succession.',

  paragraph3: '{{civil_status}}',

  receivesNothing: 'ne reçoit rien.',

  p4MarriedSpouse: {
    receives: 'reçoit',
    marriedStatus: {
      male: 'Mon époux',
      female: 'Mon épouse',
    },
    livingPartnershipStatus: {
      male: 'Mon partenaire enregistré',
      female: 'Ma partenaire enregistrée',
    },
    partnershipContract: {
      yes: 'conjugalement tout le patrimoine commun et successoralement tous les actifs dans le cadre de la disponibilité libre',
      no: 'conjugalement toute la proposition et successoralement tous les actifs dans le cadre de la disponibilité libre',
    },
    quota: 'Quote',
    noContractButChildren: {
      main: 'seulement en usufruit cependant la part réservataire, qui revient à {{children}}',
      oneChild: "l'enfant commun",
      multipleChildren: 'les enfants communs sont exemptés.',
    },

    propertyContractAndChildren: {
      main: 'seulement en usufruit cependant la part réservataire, qui revient à {{children}}',
      oneChild: "l'enfant",
      multipleChildren: 'les enfants sont exemptés.',
    },
  },

  p4UnmarriedPartner: {
    receives: 'reçoit tous les actifs dans le cadre de la disponibilité libre.',
    receivesProperty: 'reçoit notamment',
    theProperty: 'la propriété',
    atTheAddress: "à l'adresse",
    assignedToProperty: 'attribuée en propriété.',
    genderStatus: {
      male: 'Mon partenaire',
      female: 'Ma partenaire',
    },
  },

  p5UnmarriedPartnerWithChildren: {
    commonPartnership: {
      oneChild: {
        main: 'Notre enfant commun,',
        born: ' né(e) le {{birthday}},',
        receive: 'ou ses descendants reçoivent la part réservataire.',
      },
      multipleChildren: {
        main: 'Nos enfants communs,',
        born: ' nés le {{birthday}},',
        receive:
          'ou tous leurs descendants reçoivent chacun la part réservataire.',
      },
    },
    otherPartnership: {
      oneChild: {
        main: 'Mon enfant non commun,',
        born: ' né(e) le {{birthday}},',
        receive: 'ou ses descendants reçoivent la part réservataire.',
      },
      multipleChildren: {
        main: 'Mes enfants non communs,',
        born: ' nés le {{birthday}},',
        receive:
          'ou tous leurs descendants reçoivent chacun la part réservataire.',
      },
    },
    deathCase: 'Si je décède en même temps que {{partner}}, {{childrenCase}}',
    childrenCase: {
      oneChild: "l'enfant commun reçoit",
      onesDescendants: 'ou ses descendants reçoivent tous les actifs.',
      multipleChildren: 'les enfants communs reçoivent',
      allDescendants: 'ou tous leurs descendants reçoivent tous les actifs.',
    },
  },

  p4Child: {
    commonMarriage: {
      oneChild: {
        main: 'Notre enfant commun,',
        born: ' né(e) le {{birthday}},',
        receive:
          'ou ses descendants reçoivent chacun tous les actifs dans le cadre de la disponibilité libre.',
      },
      multipleChildren: {
        main: 'Nos enfants communs,',
        born: ' nés le {{birthday}},',
        receive:
          'ou tous leurs descendants reçoivent chacun tous les actifs dans le cadre de la disponibilité libre.',
      },
    },
    otherMarriage: {
      oneChild: {
        main: 'Mon enfant non commun,',
        born: ' né(e) le {{birthday}},',
        receive:
          'ou ses descendants reçoivent chacun tous les actifs dans le cadre de la disponibilité libre.',
      },
      multipleChildren: {
        main: 'Mes enfants non communs,',
        born: ' nés le {{birthday}},',
        receive:
          'ou tous leurs descendants reçoivent chacun tous les actifs dans le cadre de la disponibilité libre.',
      },
    },
  },

  unmarriedChildrenChoice: {
    commonPartnership: {
      oneChild: {
        main: 'Notre enfant commun,',
        born: ' né(e) le {{birthday}},',
        receives: 'ou ses descendants reçoivent',
      },
      multipleChildren: {
        main: 'Nos enfants communs,',
        born: ' nés le {{birthday}},',
        receives: 'ou tous leurs descendants reçoivent chacun tous les actifs',
      },
      quota: 'dans le cadre de la disponibilité libre.',
    },
    otherPartnership: {
      oneChild: {
        main: 'Mon enfant non commun,',
        born: ' né(e) le {{birthday}},',
        receives: 'ou ses descendants reçoivent.',
      },
      multipleChildren: {
        main: 'Mes enfants non communs,',
        born: ' nés le {{birthday}},',
        receives: 'ou tous leurs descendants reçoivent chacun tous les actifs',
      },
      quota: 'dans le cadre de la disponibilité libre.',
    },
  },

  unmarriedInstitutionChoice: {
    commonPartnership: {
      oneChild: {
        main: 'Notre enfant commun,',
        born: ' né(e) le {{birthday}},',
        receive: 'ou ses descendants reçoivent la part réservataire.',
      },
      multipleChildren: {
        main: 'Nos enfants communs,',
        born: ' nés le {{birthday}},',
        receive:
          'ou tous leurs descendants reçoivent chacun la part réservataire.',
      },
    },
    otherPartnership: {
      oneChild: {
        main: 'Mon enfant non commun,',
        born: ' né(e) le {{birthday}},',
        receive: 'ou ses descendants reçoivent la part réservataire.',
      },
      multipleChildren: {
        main: 'Mes enfants non communs,',
        born: ' nés le {{birthday}},',
        receive:
          'ou tous leurs descendants reçoivent chacun la part réservataire.',
      },
    },
  },

  p4Institution: {
    oneInstitution: {
      main: "L'institution",
      receives: 'reçoit ',
    },
    multipleInstitutions: {
      main: 'Les institutions',
      receives: 'reçoivent ',
    },
    final:
      'chacune la part réservataire dans le cadre de la disponibilité libre.',
    partnerReceives: 'reçoit la part réservataire.',
  },

  p5MarriedSpouse: {
    marriedStatus: {
      male: 'Mon époux',
      female: 'Mon épouse',
    },
    livingPartnershipStatus: {
      male: 'Mon partenaire enregistré',
      female: 'Ma partenaire enregistrée',
    },
    particularReceives: 'reçoit notamment',
    theProperty: 'la propriété',
    atTheAddress: "à l'adresse",
    assignedToProperty: 'attribuée en propriété.',
  },

  p5Institution: {
    commonMarriage: {
      oneChild: {
        main: 'Notre enfant commun,',
        born: ' né(e) le {{birthday}},',
        receive: 'ou ses descendants reçoivent la part réservataire.',
      },
      multipleChildren: {
        main: 'Nos enfants communs,',
        born: ' nés le {{birthday}},',
        receive:
          'ou tous leurs descendants reçoivent chacun la part réservataire.',
      },
    },
    otherMarriage: {
      oneChild: {
        main: 'Mon enfant non commun,',
        born: ' né(e) le {{birthday}},',
        receive: 'ou ses descendants reçoivent la part réservataire',
      },
      multipleChildren: {
        main: 'Mes enfants non communs,',
        born: ' nés le {{birthday}},',
        receive:
          'ou tous leurs descendants reçoivent chacun la part réservataire.',
      },
    },
  },

  p6MarriedSpouse: {
    oneChild: {
      commonMarriage: 'Notre enfant commun,',
      otherMarriage: 'Mon enfant non commun,',
      born: ' né(e) le {{birthday}},',
      receives: 'ou ses descendants reçoivent.',
    },
    multipleChildren: {
      commonMarriage: 'Nos enfants communs,',
      otherMarriage: 'Mes enfants non communs,',
      born: ' nés le {{birthday}},',
      receives: 'ou tous leurs descendants reçoivent chacun',
    },
    final:
      "la part réservataire, mais avec l'usufruit mentionné au premier paragraphe.",
  },

  willExecutor:
    "En tant qu'exécuteur testamentaire de l'ensemble de ma succession, je nomme {{executor1}}. En tant qu'exécuteur testamentaire de remplacement, je nomme {{executor2}}.",

  forFollowing: {
    main: "Cependant, une disposition différente s'applique aux actifs suivants :",
    asset: '{{asset}}, {{address}} va à : {{benefiter}}. ',
    devisionProvision:
      "Il s'agit d'une disposition de partage en faveur de {{benefiter}}, pas d'un avantage supplémentaire.",
  },
};

export default outputDoc;
