const pensionProvision = {
  title: 'bien réglé: Nous vous aidons à protéger vos proches et vous-même',
  alreadyHaveAcc: 'Vous avez déjà un compte?',
  signIn: 'Se connecter',
  p1: "L'équipe bien réglé est à votre disposition pour des sujets importants que tout le monde devrait aborder. Nous commençons par trois sujets clés pour la retraite et la planification de la prévoyance et nous continuerons à élargir notre offre pour répondre à vos besoins.",
  p2: 'Nous vous accompagnons comme un coach personnel dans des processus importants que vous devez entreprendre pour éviter des problèmes en situations difficiles, pour vous et vos proches.',
  p3: 'Discutons des détails. Nous recommandons que chacun ait finalisé les documents juridiques suivants au plus tard à l’âge de 50 ans :',
  p3a: 'Si vous commencez maintenant et prenez 20 minutes, nous vous garantissons que vous serez bien réglé.',
  p4: 'Sur la base de vos réponses, il y a un faible risque que, comme la plupart de nos clients, vous ne puissiez pas prendre les mesures nécessaires du premier coup pour bien organiser votre vie.',
  p4a: 'La plupart de nos clients ne parviennent pas du premier coup à prendre les mesures nécessaires pour bien organiser leur vie.',
  p5a: 'Pour vous soutenir dans cette démarche, nous proposons un service de rappel.',
  p5b: 'Pour cela, nous avons besoin de quelques informations de votre part maintenant afin de pouvoir vous rappeler les étapes en attente.',
  p6a: 'C’est pourquoi nous aimerions vous proposer notre service de rappel en soutien.',
  p6b: 'Veuillez nous indiquer maintenant un canal de communication par lequel nous pouvons vous rappeler les étapes en attente.',

  confirmCheckbox:
    'En cochant cette case, je reconnais et confirme que je consens à être enregistré sur bien réglé.',
  processingAgreement: 'Voir notre accord de traitement des commandes.',

  c1: {
    title: 'Directive anticipée',
    description:
      'vous permet de spécifier comment les professionnels de la santé doivent agir en cas d’urgence.',
  },
  c2: {
    title: 'Mandat de protection',
    description:
      'évite les problèmes avec le KESB et règle la tutelle de vos enfants.',
  },
  c3: {
    title: 'Testament',
    description: 'pour laisser votre héritage comme vous le souhaitez.',
  },

  questions: [
    {
      question:
        'Si vous avez le choix de faire quelque chose aujourd’hui ou de le reporter à demain... Que faites-vous?',
      options: [
        {
          label: 'Je le fais tout de suite.',
        },
        {
          label: 'Je ne sais pas.',
        },
        {
          label: 'Je le reporte à demain.',
        },
      ],
    },
    {
      question:
        'Si quelqu’un essaie de vous motiver à faire quelque chose que vous n’aimez pas... Qu’est-ce qui fonctionne le mieux?',
      options: [
        {
          label: 'Les incitations me mettent en mouvement.',
        },
        {
          label: 'Je ne sais pas.',
        },
        {
          label: 'Ça me fait me sentir mal.',
        },
      ],
    },
    {
      question: 'Comment abordez-vous normalement les choses?',
      options: [
        {
          label: 'Je ferme les yeux et je le fais.',
        },
        {
          label: 'Je ne sais pas.',
        },
        {
          label: 'Plutôt plus tard.',
        },
      ],
    },
    {
      question:
        'Dites-nous à quelle fréquence vous souhaitez être rappelé si vous mettez le processus en pause temporairement:',
      options: [
        {
          label: 'Hebdomadairement.',
        },
        {
          label: 'Mensuellement.',
        },
        {
          label: 'Trimestriellement.',
        },
      ],
    },
  ],

  thankyouCard: {
    title: 'Prévoyance simple et sans complication',
    thankYou: 'Merci beaucoup pour votre intérêt pour bien réglé',
    gentlyReminder:
      'Nous vous rappellerons doucement jusqu’à ce que tout soit bien réglé.',
  },
};

export default pensionProvision;
