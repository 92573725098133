import { Theme } from '@mui/material/styles';

type FontWeight = 'light' | 'regular' | 'medium' | 'semibold' | 'bold';

type FontConfig = {
  family: string;
  size: string;
  color: string;
  weight: FontWeight;
};

type PartnerTypographySettings = {
  title_font?: FontConfig;
  subtitle_font?: FontConfig;
  label_font?: FontConfig;
  prose_font?: FontConfig;
  link_font?: FontConfig;
};

// Add a helper function to convert weight strings to numbers
const getFontWeight = (weight: FontWeight): number => {
  const weights = {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  };
  return weights[weight];
};

export const createTypographyOverrides = (
  theme: Theme,
  settings?: PartnerTypographySettings
) => ({
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontFamily:
          settings?.title_font?.family || theme.typography.h1.fontFamily,
        fontSize: settings?.title_font?.size || theme.typography.h1.fontSize,
        color: settings?.title_font?.color || theme.typography.h1.color,
        ...(settings?.title_font?.weight && {
          fontWeight: getFontWeight(settings.title_font.weight),
        }),
      },
      h2: {
        fontFamily:
          settings?.title_font?.family || theme.typography.h2.fontFamily,
        fontSize: settings?.title_font?.size || theme.typography.h2.fontSize,
        color: settings?.title_font?.color || theme.typography.h2.color,
        ...(settings?.title_font?.weight && {
          fontWeight: getFontWeight(settings.title_font.weight),
        }),
      },
      h3: {
        fontFamily:
          settings?.title_font?.family || theme.typography.h3.fontFamily,
        fontSize: settings?.title_font?.size || theme.typography.h3.fontSize,
        color: settings?.title_font?.color || theme.typography.h3.color,
        ...(settings?.title_font?.weight && {
          fontWeight: getFontWeight(settings.title_font.weight),
        }),
      },
      h4: {
        fontFamily:
          settings?.title_font?.family || theme.typography.h4.fontFamily,
        fontSize: settings?.title_font?.size || theme.typography.h4.fontSize,
        color: settings?.title_font?.color || theme.typography.h4.color,
        ...(settings?.title_font?.weight && {
          fontWeight: getFontWeight(settings.title_font.weight),
        }),
      },
      h5: {
        fontFamily:
          settings?.title_font?.family || theme.typography.h5.fontFamily,
        fontSize: settings?.title_font?.size || theme.typography.h5.fontSize,
        color: settings?.title_font?.color || theme.typography.h5.color,
        ...(settings?.title_font?.weight && {
          fontWeight: getFontWeight(settings.title_font.weight),
        }),
      },
      h6: {
        fontFamily:
          settings?.title_font?.family || theme.typography.h6.fontFamily,
        fontSize: settings?.title_font?.size || theme.typography.h6.fontSize,
        color: settings?.title_font?.color || theme.typography.h6.color,
        ...(settings?.title_font?.weight && {
          fontWeight: getFontWeight(settings.title_font.weight),
        }),
      },
      subtitle1: {
        fontFamily:
          settings?.subtitle_font?.family ||
          theme.typography.subtitle1.fontFamily,
        fontSize:
          settings?.subtitle_font?.size || theme.typography.subtitle1.fontSize,
        color:
          settings?.subtitle_font?.color || theme.typography.subtitle1.color,
        ...(settings?.subtitle_font?.weight && {
          fontWeight: getFontWeight(settings.subtitle_font.weight),
        }),
      },
      body1: {
        fontFamily:
          settings?.prose_font?.family || theme.typography.body1.fontFamily,
        fontSize: settings?.prose_font?.size || theme.typography.body1.fontSize,
        // color: settings?.subtitle_font?.color || theme.typography.body1.color,
        ...(settings?.prose_font?.weight && {
          fontWeight: getFontWeight(settings.prose_font.weight),
        }),
      },
      // body2: {
      //   fontFamily:
      //     settings?.label_font?.family || theme.typography.body2.fontFamily,
      //   fontSize: settings?.label_font?.size || theme.typography.body2.fontSize,
      //   color: settings?.label_font?.color || theme.typography.body2.color,
      //   fontWeight: settings?.label_font?.weight
      //     ? getFontWeight(settings.label_font.weight)
      //     : 400,
      // },
      // link: {
      //   fontFamily:
      //     settings?.link_font?.family || theme.typography.body1.fontFamily,
      //   fontSize: settings?.link_font?.size || theme.typography.body1.fontSize,
      //   color: settings?.link_font?.color || theme.palette.primary.main,
      //   textDecoration: 'underline',
      //   cursor: 'pointer',
      //   fontWeight: settings?.link_font?.weight
      //     ? getFontWeight(settings.link_font.weight)
      //     : 400,
      // },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontFamily:
          settings?.label_font?.family || theme.typography.body2.fontFamily,
        fontSize: settings?.label_font?.size || theme.typography.body2.fontSize,
        color: settings?.label_font?.color || theme.typography.body2.color,
        ...(settings?.label_font?.weight && {
          fontWeight: getFontWeight(settings.label_font.weight),
        }),
      },
    },
  },
  // MuiInputBase: {
  //   styleOverrides: {
  //     input: {
  //       fontFamily:
  //         settings?.prose_font?.family || theme.typography.body1.fontFamily,
  //       fontSize: settings?.prose_font?.size || theme.typography.body1.fontSize,
  //       color: settings?.prose_font?.color || theme.typography.body1.color,
  //       fontWeight: settings?.prose_font?.weight
  //         ? getFontWeight(settings.prose_font.weight)
  //         : 400,
  //     },
  //   },
  // },
  MuiLink: {
    styleOverrides: {
      root: {
        fontFamily:
          settings?.link_font?.family || theme.typography.body1.fontFamily,
        fontSize: settings?.link_font?.size || theme.typography.body1.fontSize,
        color: settings?.link_font?.color || theme.palette.primary.main,
        textDecoration: 'underline',
        '&:hover': {
          color: settings?.link_font?.color
            ? `${settings.link_font.color}dd` // Add transparency for hover
            : theme.palette.primary.dark,
        },
        ...(settings?.link_font?.weight && {
          fontWeight: getFontWeight(settings.link_font.weight),
        }),
      },
    },
  },
  // MuiMenuItem: {
  //   styleOverrides: {
  //     root: {
  //       fontFamily:
  //         settings?.prose_font?.family || theme.typography.body1.fontFamily,
  //       fontSize: settings?.prose_font?.size || theme.typography.body1.fontSize,
  //       color: settings?.prose_font?.color || theme.typography.body1.color,
  //       fontWeight: settings?.prose_font?.weight
  //         ? getFontWeight(settings.prose_font.weight)
  //         : 400,
  //     },
  //   },
  // },
  // MuiButton: {
  //   styleOverrides: {
  //     root: {
  //       fontFamily:
  //         settings?.label_font?.family || theme.typography.button.fontFamily,
  //       fontSize:
  //         settings?.label_font?.size || theme.typography.button.fontSize,
  //       fontWeight: settings?.label_font?.weight
  //         ? getFontWeight(settings.label_font.weight)
  //         : 400,
  //     },
  //   },
  // },
  // MuiTableCell: {
  //   styleOverrides: {
  //     root: {
  //       fontFamily:
  //         settings?.prose_font?.family || theme.typography.body1.fontFamily,
  //       fontSize: settings?.prose_font?.size || theme.typography.body1.fontSize,
  //       color: settings?.prose_font?.color || theme.typography.body1.color,
  //       fontWeight: settings?.prose_font?.weight
  //         ? getFontWeight(settings.prose_font.weight)
  //         : 400,
  //     },
  //     head: {
  //       fontFamily:
  //         settings?.label_font?.family || theme.typography.body2.fontFamily,
  //       fontSize: settings?.label_font?.size || theme.typography.body2.fontSize,
  //       color: settings?.label_font?.color || theme.typography.body2.color,
  //       fontWeight: settings?.label_font?.weight
  //         ? getFontWeight(settings.label_font.weight)
  //         : 600,
  //     },
  //   },
  // },
  // MuiBreadcrumbs: {
  //   styleOverrides: {
  //     li: {
  //       fontFamily:
  //         settings?.link_font?.family || theme.typography.body1.fontFamily,
  //       fontSize: settings?.link_font?.size || theme.typography.body1.fontSize,
  //       color: settings?.link_font?.color || theme.palette.primary.main,
  //       fontWeight: settings?.link_font?.weight
  //         ? getFontWeight(settings.link_font.weight)
  //         : 400,
  //     },
  //   },
  // },
});
