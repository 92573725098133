import React, { ReactNode, createContext, useCallback, useMemo } from 'react';

import { defaultSettings } from '#/config';
import useLocalStorage from '../lib/hooks/useLocalStorage';

const initialState: SettingsContextProps = {
  ...defaultSettings,
  onToggleMode: () => {},
  onChangeMode: () => {},
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
  children: ReactNode;
};

function SettingsProvider({ children }: SettingsProviderProps) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
  });

  const onToggleMode = useCallback(() => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
    });
  }, [settings, setSettings]);

  const onChangeMode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSettings({
        ...settings,
        themeMode: (event.target as HTMLInputElement).value as ThemeMode,
      });
    },
    [settings, setSettings]
  );

  const value = useMemo(
    () => ({
      ...settings,
      onToggleMode,
      onChangeMode,
    }),
    [settings, onChangeMode, onToggleMode]
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
