const outputDoc = {
  title: 'Marriage Contract',
  from: 'from ',
  born: 'born on ',
  civilStatus: {
    single: 'Single',
    married: 'Married',
    widowed: 'Widowed',
    divorced: 'Divorced',
    registered_partnership: 'Registered Partnership',
  },
  civil_status: 'Marital Status: {{civil_status}}',
  address: 'Address: {{address}}',
  male: 'husband',
  female: 'wife',
  and: 'and',
  spousePresentation:
    '{{spouseLastName}} {{spouseName}}, {{relation}}, born on {{spouseBirthDate}}, from {{spousePlaceOfCitizenship}}, {{spouseCivilStatus}}, residing at {{spouseAddress}}.',
  personalPresentation:
    '{{firstName}} {{lastName}}, {{relation}}, born on {{birthDate}}, from {{placeOfCitizenship}}, {{civilStatus}}, residing at {{address}}.',
  determinations: {
    title: 'Provisions',
    section1: {
      firstParagraphWithChildren:
        'The contracting parties married on {{weddingDate}} in {{weddingLocation}}. {{childrenParagraph}}',
      oneChildParagraph: 'From their marriage, they have one child:',
      multipleChildrenParagraph:
        'From their marriage, they have {{numberOfChildren}} children:',
      childBirthday: 'born on {{birthday}}',
      firstParagraphWithoutChildren:
        'The contracting parties married on {{weddingDate}} in {{weddingLocation}}. They have no children from their marriage.',
      noChildrenOutsideMarriage:
        'The contracting parties have no children from other relationships.',
    },
    section2: {
      noMarriageContract:
        'To date, the contracting parties have never entered into a marriage contract.',
    },
    section3: {
      participationAgreement:
        'The community of property includes the acquired property and the personal property of each spouse. Within the legal limits, each spouse manages and uses their acquired property and personal property and disposes of it.',
    },
    section4: {
      personalAssets:
        'In addition to the items exclusively for personal use, the contracting parties possess the following personal properties:',
      pointA: 'a.) Husband: ',
      pointB: 'b.) Wife: ',
    },
    section5: {
      otherAssets: 'All other assets',
      evenEstates: 'including ',
      otherAssetsContinued:
        'were jointly acquired by the contracting parties after marriage; these constitute acquired property under Art. 197 of the Swiss Civil Code.',
    },
  },
  marriageContractAgreements: {
    title: 'Marriage Contract Agreements',
    section1: {
      firstParagraph:
        'The contracting parties expressly agree to retain the ordinary property regime of community of property.',
    },
    section2: {
      firstParagraph:
        'Pursuant to Art. 216 para. 1 of the Swiss Civil Code and in deviation from the division of property provided for in Art. 215 of the Swiss Civil Code, the contracting parties agree that in the event of the dissolution of the marriage due to the death of one spouse, the surviving spouse will receive the entire sum of the marital property of both spouses as their unrestricted property.',
    },
    section3: {
      firstParagraph:
        'In application of Art. 206 para. 3 of the Swiss Civil Code, the spouses exclude mutual participation in the increase in value in the event of the dissolution of the marriage upon death.',
    },
  },
  jointProvisions: {
    title: 'Joint Provisions',
    section1: {
      firstParagraph:
        'With the establishment of this contract, the contracting parties revoke all previous dispositions of property upon death. Excluded from this are any beneficiary declarations to insurance and pension institutions.',
    },
    section2: {
      firstParagraph:
        'In terms of a partition instruction, it is determined that the surviving spouse can freely choose those assets and capital values that appeal to them, in credit to their property and inheritance rights. This partition instruction explicitly applies to real estate, which is to be registered in the sole ownership of the surviving spouse in the relevant land register. The official market value (tax value) as of the date of death of the first deceased is considered the credit value.',
      secondParagraph:
        'The surviving spouse is also entitled to settle the inheritance claims of the other heirs either in cash or by assigning other assets. The entire household goods will be the unrestricted property of the surviving spouse without credit to the inheritance share.',
    },
    section3: {
      firstParagraph:
        "Should the surviving spouse remarry, they must pay the descendants the amount they would have received upon the death of the first deceased spouse in a property and inheritance settlement according to legal provisions and without this contract. The descendants are credited with what they have already received from the estate of the first deceased spouse despite this contract and what the surviving spouse has since given them as gifts. The estate inventory prepared by the cantonal authorities or the heirs themselves at the time of death of the first deceased parent/spouse is decisive for the estate's assets. The claims of the descendants become due on the day the condition is met and are neither to be paid interest nor secured until that day. The surviving parent/spouse is free to choose the form of payment.",
    },
    section4: {
      firstParagraph:
        'This marriage contract is subject to Swiss law, unless otherwise mandatory legal provisions apply. As far as permissible, Swiss authorities are also responsible for the marital and inheritance relations of the contracting parties.',
    },
    section5: {
      firstParagraph:
        'This marriage contract loses its validity if, at the time of the death of the first deceased spouse, the marriage of the contracting parties has been legally dissolved, separated in accordance with Art. 117 of the Swiss Civil Code, or a divorce or separation request is pending.',
    },
  },
  contractualAgreements: {
    title: 'Inheritance Contract Agreements',
    section1: {
      firstParagraph:
        'Upon the death of the first deceased spouse, the surviving spouse retains their personal property and receives ownership of both spouses’ marital property based on the foregoing marriage contract agreement.',
    },
    section2: {
      firstParagraph:
        'Furthermore, the contracting parties also favor each other in inheritance matters to the extent legally permissible.',
      nonCommonChildren:
        'The mandatory shares of non-common children are excluded. The surviving spouse can choose between:',
      pointA:
        'a.) the highest possible inheritance claim (currently 5/8) to ownership and payment of the mandatory share to the descendants',
      pointB:
        'b.) the free quota (currently 1/4) to ownership and the usufruct of the remaining inheritance to the descendants without having to provide security under Art. 473 of the Swiss Civil Code.',
    },
    section4: {
      firstParagraph:
        'The spouses grant each other the right to freely dispose of CHF {{disposal_fixed_amount}} (or {{disposal_percentage}}% of their net estate) by testamentary disposition.',
    },
  },
  signatures: {
    title: 'Signature {{gender}}',
    female: 'Wife',
    male: 'Husband',
  },
  notarization: {
    title: 'Notarization',
    firstParagraph: 'The undersigned notary hereby certifies that',
    firstCondition: 'he has read this document to the contracting parties,',
    secondCondition:
      'the document corresponds to the will communicated to him by the contracting parties,',
    thirdCondition:
      'the document has been signed by the contracting parties in his presence,',
    noterTitle: 'Signature Notary',
  },
};

export default outputDoc;
