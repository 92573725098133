const guest = {
  title: 'Vorsorge von {{first_name}} {{last_name}}',
  description:
    'Solange Ihr Gastgeber lebt, haben Sie begrenzten Zugang zu den Daten und Informationen.',
  seeWhatsIncluded: 'siehe, was enthalten ist',
  includedTooltip:
    'Mit dem Gastzugang haben diese Personen lediglich Zugang zur Patientenverfügung und zum Vorsorgeauftrag.',
  reportDeath: 'Tod melden',
  reportDeathDescription:
    'Damit Sie vollen Zugriff auf alle Unterlagen und Informationen von {{first_name}} haben, müssen wir leider zunächst den Tod bestätigen und benötigen die offizielle Sterbeurkunde sowie das Willensvollstreckerzeugnis (beides erhalten Sie bei der Gemeinde).',
  alreadyReported: 'Bereits gemeldet',
};

export default guest;
