export interface INavItem {
  title: string;
  url: string;
  auth: boolean;
  megaMenu?: boolean;
  items?: Item[];
  scroller?: boolean;
  type?: string;
  title1?: string;
  url1?: string;
}

export type Item = {
  title: string;
  url: string;
  auth: boolean;
  external?: boolean;
};

export const navItems: INavItem[] = [
  {
    auth: false,
    megaMenu: true,
    url: '/patientenverfuegung',
    title: 'global.services.forIndividuals',
    items: [
      {
        title: 'global.services.livingWill',
        url: '/patientenverfuegung',
        auth: true,
      },
      {
        title: 'global.services.powerOfAttorney',
        url: '/vorsorgeauftrag',
        auth: true,
      },
      {
        title: 'global.services.lastWill',
        url: '/testament',
        auth: true,
      },
      {
        title: 'global.services.marriageContract',
        url: '/ehevertrag',
        auth: true,
      },
      {
        title: 'global.services.digitalFootprint',
        url: '/digitaler-nachlass',
        auth: true,
      },
      {
        title: 'global.services.registerAssets',
        url: '/vermoegens-und-sachregister',
        auth: true,
      },
      {
        title: 'global.services.cohabitationAgreement',
        url: '/konkubinatsvertrag',
        auth: true,
      },
    ],
  },
  {
    auth: false,
    megaMenu: true,
    url: '/partner-werden',
    title: 'global.services.forCompanies',
    items: [
      {
        title: 'global.services.pensionCheck',
        url: '/vorsorgecheck',
        auth: false,
      },
      {
        title: 'global.services.partnerWithUs',
        url: '/partner-werden',
        auth: false,
      },
    ],
  },
  {
    title: 'global.services.pricing',
    url: '/',
    auth: false,
    scroller: true,
  },
  {
    auth: false,
    megaMenu: true,
    title: 'global.services.gutGeregelt',
    url: '/ueber-uns',
    items: [
      {
        title: 'global.services.blog',
        url: 'https://blog.gutgeregelt.ch/',
        external: true,
        auth: false,
      },
      {
        title: 'global.services.aboutUs',
        url: '/ueber-uns',
        auth: false,
      },
    ],
  },
  {
    title: 'global.services.login',
    url: '/auth/login',
    auth: false,
  },
  {
    title: 'global.services.makeProvisions',
    title1: 'Dashboard',
    url: '/lebenssituations-check',
    url1: '/dashboard/home',
    auth: true,
    type: 'button',
  },
];

export const navAdminItems = [
  {
    title: 'global.services.dashboard',
    url: '/dashboard/home',
    auth: true,
  },
  {
    title: 'global.services.consulting',
    url: '/dashboard/beratung',
    auth: true,
  },
];
