const registerAssets = {
  mainBanner: {
    title: 'Gain clarity and an overview of your entire estate',
    subtitle:
      'Having a comprehensive overview of your entire estate will not only give you peace of mind, it will also make life easier for your family members.',
    buttonLabel: 'Gain clarity',
  },
  youtubeTutorial: 'How to prepare your estate?',
  aboutCards: {
    logPhysicalAssets: {
      title: 'Log physical assets',
      description:
        'Record physical assets, where your relatives can find them and to whom you want to bequeath them.',
    },
    outlineRelationships: {
      title: 'Outline relationships',
      description:
        'Make a note of your bank details & Co. in which you have invested assets to make it easier for your family to investigate.',
    },
    simplifyCleanup: {
      title: 'Simplify the cleanup',
      description:
        'Keep everything centralized and well-protected in one place so that trusted people can quickly access relevant information.',
    },
  },
  subBanner: {
    title: 'The big picture that saves your family dozens of hours',
    subtitle:
      "Many of our clients know this from their own experience - when little is documented and recorded, hours are wasted trying to get a clear overview of the deceased's property and assets. We help them to spare their loved ones this experience.",
    buttonLabel: 'Create an overview',
  },
  testimonials: {
    testimonial1: {
      name: 'Elisabeth Müller',
      text: 'My daughter helped me to enter the assets of my husband and my assets into the inventory of the asset directory. It was a relief for all of us to know where we have what.',
    },
    testimonial2: {
      name: 'Sascha Zürcher',
      text: 'I already had a will. But with gut geregelt, I now also have an overview of all my belongings, which I can update continuously and make directly accessible to the relevant people in the event of my death.',
    },
    testimonial3: {
      name: 'Vreni Frey',
      text: 'A simple solution that is a good mandatory exercise to write down everything of value. I was surprised how much came together that I did not think of directly.',
    },
  },
  newsletter: {
    title: 'Get your estate gut geregelt',
    buttonLabel: 'Organize it now',
  },
  faq: {
    questions: {
      question1: 'What is a register of assets and why is it important?',
      question2:
        'What types of assets can be included in the register of assets?',
      question3: 'How does the register of assets service work?',
      question4:
        'How can I ensure that my loved ones have access to my register of assets?',
      question5: 'What happens to my assets after my death?',
      question6: 'Can I update or change my register of assets?',
      question7:
        'How secure are my personal information in the register of assets service?',
      question8:
        'What legal aspects should be considered when managing a register of assets?',
      question9:
        'Can the service help in assisting heirs with resolving financial matters?',
    },
    answers: {
      answer1:
        "A register of assets is a comprehensive record of all physical assets and relationships with financial institutions belonging to an individual. It is important to maintain such a register to have a clear overview of one's assets and to facilitate the settlement for the relatives after death. This can save loved ones dozens of hours.",
      answer2:
        'The register can include a variety of assets, including real estate, vehicles, jewelry, artworks, bank accounts, investment accounts, and more',
      answer3:
        'Our service provides a user-friendly platform where customers can input and manage their assets. They can define the location, ownership status, and beneficiaries for each asset',
      answer4:
        "You define trusted individuals who, after your passing, will receive unrestricted access to your account with well-regulated with a death certificate and executor's certificate.",
      answer5:
        'The register of assets, along with the will, facilitates the settlement for the relatives by providing clear instructions on how the assets should be distributed or managed and where to find what.',
      answer6:
        'Yes, customers can update or change their register at any time to reflect changes in their assets or beneficiaries.',
      answer7:
        'We implement industry-standard security measures to ensure the confidentiality and security of customer information, including encryption and access controls.',
      answer8:
        'Customers should consider legal issues such as will creation and inheritance. It is advisable to seek legal advice to ensure that the register complies with applicable laws.',
      answer9:
        "Yes, by providing a clear overview of the deceased's assets and facilitating the identification and management of assets, the service assists heirs in resolving financial matters.",
    },
  },
};

export default registerAssets;
