import {
  distributionQuestions,
  governanceQuestions,
  inheritorsDivorcedVersion,
  inheritorsMarriedVersion,
  inheritorsSingleVersion,
} from './questions';
import outputDoc from './outputDoc';

const lastWill = {
  mainHeaders: {
    shortPainFree: 'Ihr einfaches und wirksames Testament',
    madeIt: 'Ihr wirksames Testament',
  },

  yes: 'Ja',
  no: 'Nein',

  addAnotherChildren: 'Fügen Sie weitere Kinder hinzu',
  areYouSureYouWantToDeleteChild:
    'Sind Sie sicher, dass Sie das Kind löschen möchten?',
  deleteInformerChild:
    'Wenn Sie ein Kind aus der Liste löschen, werden alle seine Daten gelöscht',
  cancelBtn: 'Abbrechen',
  deleteBtn: 'Löschen',
  childBirthDate: 'Geburtsdatum',

  noChildrenModal: {
    title: 'Sind Sie sicher, dass Sie die Kindernummer zurücksetzen möchten?',
    description:
      'Wenn Sie die Kindernummer zurücksetzen, werden alle Informationen über Ihre Kinder gelöscht.',
    resetNumberBtn: 'Nummer zurücksetzen',
    cancelBtn: 'Abbrechen',
  },

  stepperInfo: [
    {
      label: 'Begünstigte',
      title: 'Worum geht es und wieso ist es so wichtig?',
    },

    {
      label: 'Verteilung',
      title: 'Definieren Sie die Verteilung',
    },

    {
      label: 'Governance',
      title: 'Definieren Sie die Governance',
    },

    {
      label: 'Ihr letzter Wille',
      title: 'Hinterlegen Sie Ihr Testament',
    },
  ],

  stepOne: {
    introParagraph:
      'Ein Testament bietet Ihnen die Möglichkeit, Ihren Nachkommen in einer schwierigen Zeit klare Anweisungen über Ihre Wünsche zu geben. Es bietet Ihnen auch die Möglichkeit, (im Rahmen der gesetzlichen Bestimmungen) sicherzustellen, dass Ihre Wünsche hinsichtlich des Umgangs mit Ihrem Nachlass berücksichtigt werden. Darüber hinaus bietet Ihnen das Testament die Möglichkeit, durch die Benennung eines Testamentsvollstreckers schwierige und emotionale Diskussionen innerhalb des Familiensystems zu vermeiden. ',
    helperParagraph:
      'Gleichzeitig ist das Verfassen eines Testaments für Sie persönlich keine leichte Angelegenheit, also nehmen Sie sich bitte Zeit und fühlen Sie sich nicht gestresst. Mit unserem Erinnerungsservice können Sie auch jederzeit pausieren und später weitermachen. Klicken Sie einfach auf die Schaltfläche "Ich bin fertig".',
    questions: {
      inheritorsMarriedVersion,
      inheritorsSingleVersion,
      inheritorsDivorcedVersion,
    },
    addAnotherBenefiter: 'Begünstigte hinzufügen',
    isChildFromCommonPreviousMarriage: 'Dieses Kind ist aus der vorherigen Ehe',
    isChildFromCommonMarriage: 'Dieses Kind ist aus der gemeinsamen Ehe',
    isChildFromCommonRelationship:
      'Dieses Kind ist aus der gemeinsamen Partnerschaft',
  },

  stepTwo: {
    introParagraph:
      'Was wollen Sie mit diesem Testament hauptsächlich erreichen?',
    mainly: 'hauptsächlich',
    yesIHaveProperty:
      'Ja, ich habe Eigentum oder (eine) Wohnung(en), die ich verschenken möchte an:',
    followingDistribution:
      'Ja, ich möchte, dass die folgenden Nachlassgegenstände / die oben genannte Geldsumme an die oben genannte Person übertragen wird:',
    questions: distributionQuestions,
  },
  stepThree: {
    questions: governanceQuestions,
    orAddSomeoneNew: 'oder jemanden neu hinzufügen',
    addAnotherExecutor: 'Testamentsvollstrecker',
  },
  stepFour: {
    document: outputDoc,
    introParagraph: 'Herzlichen Glückwunsch! Sie haben es geschafft.',
    helperParagraph:
      'In der Schweiz gilt das Schriftformerfordernis, das heisst, um Ihr Testament rechtsgültig zu machen, haben Sie die Wahl: ',
    helperParagraph2:
      'Es ist auch ratsam, das Testament bei einem Anwalt zu hinterlegen und beim offiziellen Schweizerischen Testamentsregister (https://www.ztr.ch/notariatsinfo/) registrieren zu lassen.',
    contactForm: 'Kontaktformular',
    helperParagraph3:
      'Wenn Sie möchten, dass wir Ihnen helfen, nutzen Sie bitte unser Kontaktformular.',
    obligation1:
      'Eine handschriftliche Kopie des Testaments mit Ort, Datum und Ihrer Unterschrift',
    obligation2:
      'Ein öffentliches Testament (Selbstleseverfahren, Art. 500 f. ZGB oder Verleseverfahren, Art. 502 ZGB), dazu benötigen Sie einen Notar und zwei Zeugen',
    nextStepTitle: 'Die nächsten Schritte:',
    nextStep1:
      'Entweder das Testament vollständig von Hand abschreiben, datieren und unterschreiben oder mit einem Anwalt und zwei Zeugen notariell beurkunden (nach dem Selbstleseverfahren, Art. 500 ff. ZGB oder Verleseverfahren, Art. 502 ZGB) und schliesslich hier hochladen',
    nextStep2:
      'Sorgen Sie dafür, dass Vertrauenspersonen einen Gastzugang für gut geregelt haben, damit diese im Notfall auf das Testament zugreifen können (hier klicken)',
    nextStep3: 'Besprechen Sie das Testament gegebenenfalls mit der Familie',
    highlightedText: {
      noterizeWithLawyer: 'einem Anwalt und zwei Zeugen notariell beurkunden',
      linkToSettings: 'https://gutgeregelt.ch/user/account',
    },
    stopProcess: {
      title:
        'Wenn Sie einen Ehevertrag abgeschlossen haben, können wir diesen Prozess nicht weiterverfolgen. ',
      title1:
        'Wenn Sie hier "Nein" wählen, können wir diesen Prozess nicht fortsetzen.',
      description:
        'Wir empfehlen Ihnen, sich für ein Testament, welches einen Ehevertrag berücksichtigt, an einen Anwalt zu wenden. Hier finden Sie einen Anwalt zur Unterstützung',
      description1:
        'Falls Sie "Nein" wählen wollen, empfehlen wir Ihnen ein Testament aufzusetzen, welches den Ehevertrag berücksichtigt. \n' +
        'Dafür sollten Sie sich direkt an einen Anwalt wenden.\n' +
        'Wir leiten Sie dafür gerne an einen Spezialisten weiter.',
      changeAnswerBtn: 'Antwort ändern',
      continueBtn: 'Prozess beenden und mit einem Anwalt weiter machen',
    },
  },
  updateCivilStatusModal: {
    title: 'Möchten Sie Ihren Familienstand aktualisieren?',
    description:
      'Eine Änderung Ihrer Antwort wird Ihren Familienstand aktualisieren und Ihr Dokument zurücksetzen. Sind Sie sicher, dass Sie fortfahren möchten?',
    updateButton: 'Aktualisieren',
    cancelButton: 'Abbrechen',
  },

  checkChildrenRelationship: {
    title:
      'Da Sie Ihren aktuellen Beziehungsstatus geändert haben, werden die damit verbundenen Kinder entfernt',
    description:
      'Wenn Sie Kinder aus einer früheren Beziehung haben, fügen Sie diese bitte erneut hinzu',
    updateButton: 'Kinderinformationen aktualisieren',
    cancelButton: 'Abbrechen',
  },

  alternativeGoverner:
    'Wenn die von dir gewählte Person nicht als Testamentsvollstrecker handeln kann, wird die folgende Person ernannt:',
};

export default lastWill;
