import { Box, Button, Stack, Typography } from '@mui/material';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';

type Props = {
  isLoading: boolean;
  onAccActivate: () => void;
};

export default function ActivatePrimary({ isLoading, onAccActivate }: Props) {
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        paddingX: 2.5,
        paddingY: 1.5,
      }}
    >
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Stack direction="column" spacing={2}>
          <Button
            variant="contained"
            sx={{
              borderRadius: '50px',
            }}
            startIcon={
              isLoading ? (
                <Iconify icon="line-md:loading-twotone-loop" />
              ) : (
                <Iconify icon="gala:add" />
              )
            }
            onClick={onAccActivate}
          >
            <Typography>
              {String(translate('global.activatePersonalAccount'))}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
