import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import useAccountSwitch from '#/hooks/useAccountSwitch';

type Props = {
  id?: number;
  first_name: string;
  last_name: string;
  profile_picture: string;
  isMain: boolean;
  closeModal: () => void;
};

export default function AccountRow({
  id,
  first_name,
  last_name,
  profile_picture,
  isMain,
  closeModal,
}: Props) {
  const { translate } = useLocales();
  const { handleSwitchAccount, isSwitching } = useAccountSwitch();

  const onAccSwitch = async (id: number) => {
    try {
      handleSwitchAccount(id);
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        paddingX: 2.5,
        paddingY: 1.5,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{
          marginLeft: 2,
        }}
      >
        <Avatar
          src={profile_picture}
          sx={{
            width: 55,
            height: 55,
          }}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width={1}
          spacing={2}
        >
          <Stack direction="column">
            <Typography variant="subtitle1" sx={{ lineHeight: 1 }} noWrap>
              {first_name} {last_name}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body2" noWrap color="gray">
                {String(translate('global.account'))}:
              </Typography>
              <Typography variant="body2">
                {isMain
                  ? String(translate('global.main'))
                  : String(translate('global.guest'))}
              </Typography>
            </Stack>
          </Stack>
          <Button
            variant={id ? 'outlined' : 'contained'}
            color="primary"
            size="small"
            onClick={() => onAccSwitch(Number(id))}
            startIcon={
              isSwitching ? (
                <Iconify icon="line-md:loading-twotone-loop" />
              ) : null
            }
          >
            <Typography variant="body2">
              {String(translate('global.switch'))}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
