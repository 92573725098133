const outputDoc = {
  title: 'Konkubinatsvertag',
  from: 'von ',
  born: 'geboren am ',
  civilStatus: {
    single: 'Ledig',
    married: 'Verheiratet',
    widowed: 'Verwitwet',
    divorced: 'Geschieden',
    registered_partnership: 'Eingetragener partnerschaft',
  },
  civil_status: 'Familienstand: {{civil_status}}',
  address: 'Adresse: {{address}}',
  male: 'Ehefrau',
  female: 'Ehemann',
  and: 'und',
  between: 'Zwischen',
  contractPartiesAgree: 'Die Vertragsparteien vereinbaren hiermit folgendes: ',
  spousePresentation:
    '{{partnerSurname}} {{partnerName}}, geboren am {{partnerBirthday}}, von {{partnerCitizenship}}, {{partnerCivilStatus}}, wohnhaft {{partnerAdress}}.',
  personalPresentation:
    '{{firstName}} {{lastName}}, geboren am {{birthday}}, von {{citizenship}}, {{civilStatus}}, wohnhaft {{adress}}.',
  preliminaryRemark: {
    title: 'Vorbemerkung',
    section1: {
      firstDefault:
        'Wir haben uns im Jahre {{meet_year}} kennengelernt und wohnen sie dem {{date_of_moving_in}} gemeinsam an der folgenden {{adress}}.',
      secondDefault:
        'Wir beabsichtigen, unser Konkubinatsverhältnis für unbestimmte Zeit beizubehalten.',
      bothEmployedNoChildren:
        'Wir gehen beide einer vollzeitlichen unselbstständigen Erwerbstätigkeit nach.',
      oneWorksFullTime:
        '{{who_works_full_time}} geht einer Vollzeiterwerbstätigkeit, {{who_works_part_time}} einer Teilzeiterwerbstätigkeit ({{workload_distribution}}%) nach.',
      oneFullTimeOneHousehold:
        '{{who_works_full_time}} geht einer Vollzeiterwerbstätigkeit nach, {{householder}} führt den Haushalt und sorgt für {{childrenNo}}.',
      singularChild: 'das Kind',
      plurarChildren: 'die Kinder',
    },
  },
  ownership: {
    title: 'Eigentumsverhältnisse ',
    section1: {
      text: 'Über die Einrichtungsgegenstände und Wertgegenstände wird ein Inventar erstellt, das laufend zu aktualisieren ist. Dieses von beiden Parteien zu unterzeichnende Inventar bildet einen integrierenden Bestandteil dieser Vereinbarung. Wer behauptet, dass ein bestimmter Gegenstand, der in diesem Inventar nicht aufgeführt ist, Eigentum des einen oder des anderen Konkubinatspartners ist, hat dies zu beweisen. Kann dieser Beweis nicht erbracht werden, so wird Miteigentum beider Konkubinatspartner vermutet. ',
    },
  },
  costOfLiving: {
    title: 'Kosten des Lebensunterhaltes ',
    section1: {
      firstText:
        'Ein jeder von uns leistet im Rahmen seiner wirtschaftlichen Möglichkeiten einen Beitrag an den gemeinsamen Lebensunterhalt. Zu diesem Zweck eröffnen wir ein gemeinsames Bankkonto bei {{joint_household_account_bank}}.',
      bothEmployedNoChildren: {
        firstText:
          'Jeweils im Voraus bis spätestens den Monatsersten zahlen {{firstParty}} und {{secondParty}} {{equal_payment_frequency}} CHF {{equal_payment_amount}} ein.',
        secondText: 'Erstmals per {{date_of_first_payment}}.',
        thirdText:
          '{{compensation_payment_cadence}} wird der Saldo des Kontos geprüft. Weist das Konto einen ungenügenden Kontostand auf, wird dies von beiden Partnern zu gleichen Teilen gedeckt.',
      },
      oneWorksFullTime: {
        firstText:
          'Jeweils im Voraus bis spätestens den Monatsersten zahlen {{firstParty}} CHF {{amount_paid_by_me}} und {{secondParty}} CHF {{amount_paid_by_partner}} {{equal_payment_frequency}}',
        secondText:
          '(= Verhältnis von {{percentage_paid_by_me}}/{{percentage_paid_by_partner}}) auf das Bankkonto ein.',
        thirdText: 'Erstmals per {{date_of_first_payment}}.',
        fourthText:
          '{{compensation_payment_cadence}} wird der Saldo des Kontos geprüft. Weist das Konto einen ungenügenden Kontostand auf, wird dies von beiden Partnern im Verhältnis {{percentage_paid_by_me}}/{{percentage_paid_by_partner}} gedeckt.',
      },
      oneFullTimeOneHousehold: {
        firstText:
          'Jeweils im Voraus bis spätestens den Monatsersten zahlt {{who_works_full_time}} CHF {{equal_payment_amount}} {{equal_payment_frequency}} auf das Bankkonto ein.',
        secondText: 'Erstmals per {{date_of_first_payment}}.',
        thirdText:
          '{{compensation_payment_cadence}} wird der Saldo des Kontos geprüft. Weist das Konto einen ungenügenden Kontostand auf, wird dies durch {{who_works_full_time}} gedeckt.',
      },
    },
    section2: {
      title: 'Aus diesem Konto werden folgende Ausgaben beglichen:',
      expensesOptions: {
        rent: 'Mietzins',
        additional:
          'Wohnnebenkosten wie Elektrizität, Heizkosten, Wasser, Abfallgebühren, etc.',
        radioTV: 'Radio- und TV-Anschluss ',
        groceries: 'Lebensmittel',
        cleaning: 'Reinigungsaufwände',
      },
      bothEmployedNoChildren:
        'Die Führung des Haushalts wird von beiden Parteien gemeinsam bestritten. Im Übrigen verwaltet und verwendet jeder sein Einkommen und Vermögen selbst und verfügt darüber. Insbesondere die Ausgaben für persönliche Gegenstände und Tätigkeiten (Kleidung, Weiterbildung, Fahrtkosten usw.) werden von jedem Partner selbst getragen. Für Schulden haftet jeder Partner allein mit seinem gesamten Vermögen. Bei Auflösung des Konkubinats wird ein allfälliger Saldo des gemeinsamen Haushaltskontos hälftig geteilt.',
      oneWorksFullTime:
        'Die Führung des Haushalts wird vorwiegend von {{who_works_part_time}} bestritten. Die Entschädigung von {{who_works_full_time}} an {{who_works_part_time}} ist in {{partnerGender}} Beitrag an die Haushaltskasse inbegriffen. Im Übrigen verwaltet und verwendet jeder sein Einkommen und Vermögen selbst und verfügt darüber. Insbesondere die Ausgaben für persönliche Gegenstände und Tätigkeiten (Kleidung, Weiterbildung, Fahrtkosten usw.) werden von jedem Partner selbst getragen. Für Schulden haftet jeder Partner allein mit seinem gesamten Vermögen. Bei Auflösung des Konkubinats wird ein allfälliger Saldo des gemeinsamen Haushaltskontos im Verhältnis {{percentage_paid_by_me}}/{{percentage_paid_by_partner}} geteilt.',
      malePartner: 'dessen',
      femalePartner: 'deren',
      oneFullTimeOneHousehold: {
        firstText:
          'Die Führung des Haushalts wird von {{household}} bestritten.',
        isFreeDisposal:
          '{{who_works_full_time}} bezahlt {{household}} monatlich den Betrag von CHF {{free_disposal_amount}}.',
        thirdText: 'Dieser Betrag steht {{household}} zur freien Verfügung. ',
        fourthText:
          'Im Übrigen verwaltet und verwendet jeder sein Einkommen und Vermögen selbst und verfügt darüber. Insbesondere die Ausgaben für persönliche Gegenstände und Tätigkeiten (Kleidung, Weiterbildung, Fahrtkosten usw.) werden von jedem Partner selbst getragen. Für Schulden haftet jeder Partner allein mit seinem gesamten Vermögen. ',
        fifthText:
          'Bei Auflösung des Konkubinats wird ein allfälliger Saldo des gemeinsamen Haushaltskontos hälftig geteilt.',
      },
    },
  },
  children: {
    title: '{{childrenNo}}',
    plural: 'Kinder',
    singular: 'Kind',
    text: 'Der Unterhalt {{childrenNo}} regeln die Parteien mit der von der Vormundschaftsbehörde genehmigten Vereinbarung. Sie ist integrierter Bestandteil dieser Vereinbarung.',
    pluralText: 'des Kindes',
    singularText: 'der Kinder',
  },
  living: {
    title: 'Wohnen',
    onePartyNameAgreement:
      'Der gegenwärtige Mietvertrag lautet nur auf den Namen von {{agreement_name}}. Wir beabsichtigen, beide als Mieter in den Vertrag aufgenommen zu werden und vereinbaren mit dem Vermieter einen entsprechenden Nachtrag zum Mietvertrag.',
    onePartyNameAgreementSublease:
      'Der gegenwärtige Mietvertrag lautet nur auf den Namen von {{agreement_name}}. Die Parteien sehen vor, dass {{agreement_name}} mit {{non_agreement_name}} einen Untermietvertrag abschliesst. Dieser Untermietvertrag ist integrierter Bestandteil dieser Vereinbarung.',
    onePartyNameAgreementNoPlans:
      'Der gegenwärtige Mietvertrag lautet nur auf den Namen von {{agreement_name}}.',
    bothPartiesNameAgreement: 'Der Mietvertrag lautet auf beide Namen.',
    rentBudget: 'Der Mietzins wird aus der gemeinsamen Haushaltskasse bezahlt.',
    equalRights:
      'Beide Partner haben das Recht, bei einem allfälligen Wohnungswechsel den Vertrag als Mieter zu unterzeichnen. ',
  },
  cirsumstancesChange: {
    title: 'Änderung der Verhältnisse',
    noKids:
      'Wir verpflichten uns, bei Änderung der persönlichen Verhältnisse, insbesondere im Falle der Erwartung eines Kindes, diesen Vertrag entsprechend anzupassen.',
    withKids:
      'Wir verpflichten uns, bei Änderung der persönlichen Verhältnisse, insbesondere im Falle der Erwartung eines weiteren Kindes, diesen Vertrag entsprechend anzupassen.',
  },
  dissolution: {
    title: 'Auflösung des Konkubinatsverhältnisses ',
    firstText:
      'Wird das Konkubinatsverhältnis aufgelöst, nimmt jeder Konkubinatspartner seine eigenen Vermögenswerte zurück, die sich im Besitz des anderen Konkubinatspartners befinden. Vermögenswerte, die im Miteigentum stehen, sind möglichst gleichmässig und angemessen aufzuteilen. Kein Konkubinatspartner hat Anspruch auf Rückerstattung seiner Geschenke.',
    secondText:
      'Diese Vereinbarung tritt mit Unterzeichnung sofort in Kraft und unterliegt schweizerischem Recht. Ausschliesslicher Gerichtsstand für Streitigkeiten aus dieser Vereinbarung ist {{city}}.',
  },
  signature: {
    place: 'Ort',
    date: 'Datum',
  },
  inventory: {
    title: 'Inventar',
    agreementBetween: 'Ergänzt den Konkubinatsvertrag zwischen',
    partnerPresentation:
      '{{partnerSurname}}, {{partnerName}}, geboren am {{partnerBirthday}}, von {{partnerCitizenship}}, {{partnerCivilStatus}}, wohnhaft {{partnerAdress}}.',
    personalPresentation:
      '{{firstName}} {{lastName}}, geboren am {{birthday}}, von {{citizenship}}, {{civilStatus}}, wohnhaft {{adress}}.',
    soleOwnership:
      'Im Alleineigentum von {{owner}} stehen und verbleiben folgende Gegenstände:',
    notListedItems:
      'Nicht aufgeführte Gegenstände stehen im Alleineigentum derjenigen Partei, welche über den entsprechenden Zahlungsbeleg verfügt. Im Zweifelsfall gilt die gesetzliche Vermutung des Miteigentums. ',
    joinedOwnership:
      'Die nachstehend aufgeführten Gegenstände wurden gemeinsam erworben:',
    otherPersonals:
      'In diesem Inventar sind die Gegenstände des täglichen Gebrauchs wie Kleiner und andere persönliche Gegenstände nicht berücksichtigt. ',
    ownershipBenefiter:
      'Bei einer allfälligen Trennung gehen die folgenden Gegenstände ins Alleineigentum von {{owner}} über: ',
  },
};

export default outputDoc;
