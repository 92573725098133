import { IQuestion } from '#/types/globalTypes';

export const contractParties: IQuestion[] = [
  {
    question: 'Your Spouse',
  },
  {
    question: 'You',
  },
];

export const marriageQuestions: IQuestion[] = [
  {
    question: 'When did you get married?',
  },
  {
    question: 'Do you have children from this marriage?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'How many?',
  // },
];

export const estateQuestions: IQuestion[] = [
  {
    question:
      'What assets did you and your spouse already own before the marriage (e.g., inheritances, items of high emotional value, etc.)?',
  },
  {
    question:
      'Is it correct that you acquired all other assets (not listed) after the marriage?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Did you acquire any property after the marriage?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Do you agree to retain the ordinary marital property regime of participation in acquired property?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'The spouses confirm that the surviving spouse will retain their own assets and have the legally optimal choice regarding the remaining estate.',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'I want my spouse to have the right to freely dispose of a specific portion of the estate.',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Who should be the executor of the will in the event of the simultaneous death of both spouses or the death of the last surviving spouse?',
  },
  {
    question:
      'If the person you chose cannot act as executor, the following person will be appointed:',
  },
];

export const otherQuestions: IQuestion[] = [
  {
    question: 'Do you have any additional instructions or preferences?',
  },
  {
    question: 'Would you like to include any specific bequests or legacies?',
  },
  {
    question: 'Are there any specific conditions or terms you want to set?',
  },
];
