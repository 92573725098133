const registerAssets = {
  mainBanner: {
    title: "Clarté et vue d'ensemble avec le registre des biens et des actifs",
    subtitle:
      "Une vue d'ensemble complète de tous vos actifs vous donne non seulement la tranquillité d'esprit, mais facilite également la vie de vos membres de la famille.",
    buttonLabel: 'Gagner en clarté',
  },
  youtubeTutorial: 'Comment préparer votre succession ?',
  aboutCards: {
    logPhysicalAssets: {
      title: 'Enregistrer les actifs physiques',
      description:
        'Enregistrez les actifs physiques là où vos proches peuvent les trouver et à qui vous souhaitez les léguer.',
    },
    outlineRelationships: {
      title: 'Définir les relations',
      description:
        'Notez vos coordonnées bancaires et autres où vous avez investi des actifs pour faciliter les recherches de votre famille.',
    },
    simplifyCleanup: {
      title: 'Simplifier le nettoyage',
      description:
        'Conservez tout centralisé et bien protégé en un seul endroit pour que les personnes de confiance aient rapidement accès aux informations pertinentes.',
    },
  },
  subBanner: {
    title:
      "La vue d'ensemble qui épargne des dizaines d'heures à votre famille",
    subtitle:
      "Beaucoup de nos clients le savent par expérience - lorsque peu de choses sont documentées et enregistrées, des heures sont perdues à essayer d'obtenir une vue claire de la propriété et des actifs du défunt. Nous vous aidons à épargner cette expérience à vos proches.",
    buttonLabel: "Obtenir une vue d'ensemble",
  },
  testimonials: {
    testimonial1: {
      name: 'Elisabeth Müller',
      text: "Ma fille m'a aidé à entrer les actifs de mon mari et mes actifs dans l'inventaire du registre des actifs. C'était un soulagement pour nous tous de savoir où nous avons quoi.",
    },
    testimonial2: {
      name: 'Sascha Zürcher',
      text: "J'avais déjà un testament. Mais avec bien réglé, j'ai maintenant aussi une vue d'ensemble de tous mes biens, que je peux mettre à jour en continu et rendre directement accessible aux personnes concernées en cas de mon décès.",
    },
    testimonial3: {
      name: 'Vreni Frey',
      text: "Une solution simple qui est un bon exercice obligatoire pour noter tout ce qui a de la valeur. J'ai été surpris de voir combien j'ai accumulé, à quoi je n'avais pas pensé directement.",
    },
  },
  newsletter: {
    title: 'Votre succession, bien réglée',
    buttonLabel: 'Organiser maintenant',
  },
  faq: {
    questions: {
      question1:
        "Qu'est-ce qu'un registre des biens et des actifs et pourquoi est-il important ?",
      question2:
        "Quels types d'actifs peuvent être inclus dans le registre des biens et des actifs ?",
      question3: 'Comment fonctionne le service de registre des actifs ?',
      question4:
        "Comment puis-je m'assurer que mes proches ont accès à mon registre des actifs ?",
      question5: 'Que se passe-t-il avec mes actifs après ma mort ?',
      question6:
        'Puis-je mettre à jour ou modifier mon registre des biens et des actifs ?',
      question7:
        'Comment mes informations personnelles sont-elles sécurisées ?',
      question8:
        "Quels aspects juridiques doivent être pris en compte lors de la gestion d'un registre des biens et des actifs ?",
      question9:
        'Le service peut-il aider les héritiers à régler les affaires financières ?',
    },
    answers: {
      answer1:
        "Un registre des biens et des actifs est un enregistrement complet de tous les actifs physiques et des relations avec les institutions financières d'une personne. Il est important de tenir un tel registre pour avoir une vue claire de ses propres actifs et pour faciliter le règlement pour les proches après la mort. Cela peut épargner des dizaines d'heures à vos proches.",
      answer2:
        "Le registre peut comprendre une variété d'actifs, y compris l'immobilier, les véhicules, les bijoux, les œuvres d'art, les comptes bancaires, les comptes d'investissement et plus encore",
      answer3:
        "Notre service offre une plateforme conviviale où les clients peuvent entrer et gérer leurs actifs. Ils peuvent définir l'emplacement, le statut de propriété et les bénéficiaires pour chaque actif.",
      answer4:
        "Vous définissez des personnes de confiance qui, après votre décès, auront un accès illimité à votre compte, bien réglé avec un certificat de décès et un certificat d'exécuteur testamentaire.",
      answer5:
        'Le registre des biens et des actifs, conjointement avec le testament, facilite le règlement pour les proches en donnant des instructions claires sur la façon dont les actifs doivent être répartis ou gérés et où trouver quoi.',
      answer6:
        'Oui, les clients peuvent mettre à jour ou modifier leur registre des biens et des actifs à tout moment pour refléter les changements dans leurs actifs ou leurs bénéficiaires.',
      answer7:
        "Nous mettons en œuvre des mesures de sécurité standard de l'industrie pour garantir la confidentialité et la sécurité des informations des clients, y compris le cryptage et les contrôles d'accès.",
      answer8:
        "Les clients devraient prendre en compte des questions juridiques telles que la rédaction d'un testament et les questions de succession. Il est conseillé de consulter un conseiller juridique pour s'assurer que le registre est conforme aux lois en vigueur.",
      answer9:
        "Oui, en fournissant une vue claire des actifs du défunt et en facilitant l'identification et la gestion des actifs, le service aide les héritiers à régler les affaires financières.",
    },
  },
};

export default registerAssets;
