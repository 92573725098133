import { useTranslation } from 'react-i18next';
import { allLangs, defaultLang } from '#/config';

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();

  const langStorage = localStorage.getItem('i18nextLng');

  const currentLang =
    allLangs.find((lang) => lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (newlang: string) => {
    i18n
      .changeLanguage(newlang)
      .then(() => localStorage.setItem('i18nextLng', newlang));
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: any, options?: any) => translate(text, options),
    currentLang,
    allLangs,
  };
}
