const agb = {
  title: 'Conditions Générales de Vente',
  scopeOfApp: {
    title: "Champ d'application",
    content: {
      p1: "L'objet de ces Conditions Générales de Vente (CGV) est l'utilisation des services de Gut Geregelt AG (ci-après dénommé gut geregelt). gut geregelt met à la disposition du client les services pour la durée du contrat dans leur version actuelle, moyennant rémunération.",
      p2: "La relation contractuelle entre gut geregelt et le client est établie par l'acceptation de l'offre. En acceptant l'offre, le client reconnaît expressément ces CGV, même si elles sont en tout ou en partie en contradiction avec ses propres conditions générales.",
      p3: "Le client est tenu de fournir à tout moment des informations précises, complètes et à jour. Si cette exigence n'est pas respectée, cela constitue une violation des conditions d'utilisation, qui peut entraîner la résiliation immédiate du contrat.",
    },
  },
  rightOfUse: {
    title: "Droits d'utilisation",
    content: {
      p1: "gut geregelt accorde au client le droit non exclusif et non transférable d'utiliser les services de gut geregelt pour la durée du contrat conformément à leur destination.",
      p2: "Le client n'est pas autorisé à mettre le service à la disposition de tiers, que ce soit à titre onéreux ou gratuit. Toute forme de mise à disposition du service à des tiers est expressément interdite.",
      p3: "Le client est tenu de structurer toutes ses relations contractuelles avec des tiers de manière à empêcher ces derniers d'utiliser le service gratuitement.",
    },
  },
  support: {
    title: 'Support',
    content: {
      p1: 'Le support de gut geregelt est à la disposition du client du lundi au vendredi de 8h00 à 12h00 et de 13h00 à 17h00 par e-mail à support@gutgeregelt.ch.',
    },
  },
  intellectualPropertyRights: {
    title: 'Droits de propriété intellectuelle',
    content: {
      p1: "Le client reconnaît les droits de protection, en particulier les droits d'auteur de gut geregelt sur les services logiciels et les documentations. Le client n'est pas autorisé à mettre ces logiciels à la disposition de tiers, que ce soit à titre onéreux ou gratuit, ou à les utiliser en dehors du cadre de la relation contractuelle avec gut geregelt, ou à contester gut geregelt de quelque manière que ce soit.",
    },
  },
  confidentiality: {
    title: 'Confidentialité',
    content: {
      p1: "gut geregelt ne peut divulguer des informations et/ou des matériaux confidentiels à des tiers sans l'autorisation écrite expresse du client.",
      p2: "gut geregelt s'engage à ne pas utiliser les informations confidentielles qui lui sont parvenues et/ou à ne pas les mettre à la disposition de tiers sans l'autorisation écrite expresse du client.",
      p3: "Les informations confidentielles doivent être utilisées par gut geregelt exclusivement dans le cadre et pour la prestation du service. gut geregelt ne peut transmettre des informations confidentielles à ses employés que si cela est nécessaire pour la réalisation du projet susmentionné. Les employés sont tenus de respecter la confidentialité conformément à cet accord. L'obligation de confidentialité s'applique également aux entreprises associées à gut geregelt. gut geregelt les obligera également à respecter la confidentialité.",
      p4: "gut geregelt peut divulguer des informations confidentielles si la loi ou d'autres réglementations gouvernementales l'exigent. Dans ces cas, gut geregelt informera immédiatement le client et prendra, à la demande de ce dernier, les mesures de protection nécessaires, dans la mesure où elles sont appropriées. gut geregelt prend des précautions appropriées pour protéger les informations et les matériaux confidentiels contre l'accès non autorisé par des tiers.",
    },
  },
  dataProtectionAndSecurity: {
    title: 'Protection des données et sécurité des données',
    content: {
      p1: "gut geregelt traitera les données du client avec le plus grand soin et les protégera contre l'abus et la perte. À cet effet, gut geregelt prend des mesures techniques et organisationnelles qui répondent aux exigences actuelles du RGPD. Des accords spéciaux concernant le stockage des données dans certains pays peuvent être conclus dans le cadre des possibilités techniques.",
      p2: "Le client est responsable de la légalité de la transmission des données ou de leur utilisation. Toutes les données du client stockées et traitées par gut geregelt sont la propriété exclusive du client et sont utilisées par gut geregelt exclusivement aux fins de l'exécution du contrat.",
    },
  },
  disclaimer: {
    title: 'Exclusion de responsabilité',
    content: {
      p1: 'gut geregelt ne propose pas de conseil juridique mais fournit des modèles adaptés. Dans les affaires compliquées, il est essentiel de faire appel à un avocat. gut geregelt ne remplace pas un conseil juridique.',
    },
  },
};

export default agb;
