import { IQuestion } from '#/types/globalTypes';

export const contractParties: IQuestion[] = [
  {
    question: '{{partnersGender}}',
  },
  {
    question: 'Sie',
  },
];

export const lifeSituation: IQuestion[] = [
  {
    question: 'In welchem Jahr haben Sie sich kennengelernt?',
  },
  {
    question: 'Seit wann leben Sie zusammen?',
  },
  {
    question: 'Was beschreibt Ihre Situation am besten?',
    options: [
      {
        label: 'Beide sind berufstätig, keine Kinder',
      },
      {
        label: 'Einer arbeitet Vollzeit, der andere Teilzeit; keine Kinder',
      },
      {
        label:
          'Einer arbeitet Vollzeit, der andere führt den Haushalt; mit Kindern',
      },
    ],
  },
  {
    question: 'Auf wen lautet der aktuelle Mietvertrag?',
    options: [
      {
        label: 'Sie',
      },
      {
        label: 'Partner',
      },
      {
        label: 'Beide Namen',
      },
    ],
  },
  {
    question: 'Gibt es Pläne für eine Vertragsänderung?',
    options: [
      {
        label: 'Ja, wir beabsichtigen, einen Untermietvertrag abzuschliessen',
      },
      {
        label: 'Ja, wir planen, einen Vertrag auf beide Namen zu erstellen',
      },
      {
        label: 'Nein',
      },
    ],
  },
  // {
  //   question: 'Bei welcher Bank haben Sie Ihr gemeinsames Haushaltskonto?',
  // },
];
