import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import Header from './Header';

export default function CompactLayout() {
  return (
    <>
      <Header isOffset />
      <Container
        component="main"
        sx={{
          minHeight: '100vh',
          position: 'relative',
          paddingBottom: '10px',
        }}
      >
        <Outlet />
      </Container>
    </>
  );
}
