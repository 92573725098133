const digitalAssets = {
  introHead: 'Stellen Sie einen sauberen digitalen Fussabdruck sicher',
  introDescription:
    'Stellen Sie sicher, dass Ihre Nachkommen Zugang haben und definieren Sie, wer was erbt.',
  passwordWarning: 'Teilen Sie niemals Passwörter direkt mit gut geregelt!',
  passwordWarningTooltip:
    'Bitte geben Sie gut geregelt niemals Passwörter. Geben Sie nur Informationen darüber, wo Verwandte einen Überblick über Ihre Passwörter finden können.',
  downloadTemplate: 'Vorlage für sichere Passwortspeicherung herunterladen',
  requestOtherPasswordManager: 'Andere Passwortmanager anfordern',
  addItems: 'Elemente manuell hinzufügen',
  downloadList: 'Liste herunterladen',
  addItem: 'Element hinzufügen',
  yourPhoneNumber: 'Ihre Telefonnummer',

  afterDeathOptions: {
    delete: 'Löschen',
    transfer: 'Übertragen',
    inherit: 'Vererben',
  },
  assetTypes: {
    email: 'E-Mail',
    social_media_account: 'Soziale Medien',
    membership: 'Mitgliedschaft',
    subscription: 'Abonnement',
    domain: 'Domain',
    royalty: 'Lizenzeinnahmen',
    crypto_wallet: 'Krypto-Wallet',
    device: 'Gerät',
    password_manager: 'Passwortmanager',
    phone_provider: 'Telefonanbieter',
    other: 'Andere',
  },

  assetsTable: {
    tableHeads: {
      name: 'Name',
      access: 'Zugang',
      type: 'Typ',
      after_death: 'Nach dem Tod',
      beneficiary: 'Begünstigter',
    },
  },

  confirmDeleteModal: {
    title:
      'Sind Sie sicher, dass Sie {{asset}} aus Ihren digitalen Vermögenswerten löschen möchten?',
    description: 'Sie können es später jederzeit wieder hinzufügen.',
    onClose: 'Schliessen',
    onConfirm: 'Löschen',
  },
};

export default digitalAssets;
